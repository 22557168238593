.notFoundPage {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    /* background: lightgray; */
    text-align: center;
    padding: 50px;
}
.notFoundPage h1 {
    font-weight: 800;
    font-size: 50px;
    margin-bottom: 15px;
}
.notFoundPage p {
    margin-bottom: 30px;
}
