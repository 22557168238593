.nav-tabs .nav-item a.active {
   color: #a31920;
   background-color: var(--bs-gray-200);
}
.nav-tabs .nav-item a {
   transition: border-color 0.125s ease-in;
   white-space: nowrap;
   font-size: 14px;
   font-weight: 500;
   color: #535165;
   display: block;
   padding: 0.5rem 1rem;
}
.nav-tabs--left a > i {
   margin-right: 10px;
}
