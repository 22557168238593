.activitiez {
    display: block;
    list-style: none;
    margin-bottom: 0;
    padding: 0 20px;
    width: 100%;
    max-height: 370px;
    overflow: hidden;
    margin-bottom: 15px;
}
.activitiez>li {
    display: block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    padding-left: 15px;
}
.activitiez>li::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 2px;
    background-color: var(--bs-primary);
}

.activity_meta {
    display: block;
    width: 100%;
} 
.activity_meta > i {
    color: #999;
    font-size: 12px;
    width: 100%;
    line-height: 1;
}
.activity_meta > span {
    display: block;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
}
.activity_meta > h6 {
    font-size: 13px;
    width: 100%;
    margin-bottom: 0;
}