.cover_img {
    aspect-ratio: 24/6;
    overflow: hidden;
}
.cover_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.profile_top{
    border-bottom: 1px solid var(--border-color);
    /* display: flex;
    align-items: start;
    padding: 0 20px; */
    padding-bottom: 15px;
}
.completation {
    position: relative;
    width: 72px;
    height: 72px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -38px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 50%;
}
.completation img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70px;
    width: 70px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    object-fit: cover;
}
.page_meta {
    text-align: center;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
}
.page_meta > a {
    font-size: 18px;
    color: var(--bs-primary);
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 6px;
    display: block;
}
.page_meta > span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 100%;
}
.page_meta > span.desig {
    margin-bottom: 7px;
    line-height: 1.4;
    font-size: 12px;
}
.page_likes {
    padding: 0 20px 20px;
    margin-top: 15px;
}
.page_likes ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.page_likes ul li {
    text-align: center;
    width: 49%;
}
.profile_follow{
    padding: 20px 0;
    border-bottom: 1px solid var(--border-color);
}
.profile_nav{
    list-style: none;
    padding: 0;
    margin: 0;
}
.profile_nav li{
    padding: 0 20px 8px;
}
.profile_nav li:last-child{
    padding-bottom: 0;
}
.profile_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile_link > span{
    font-size: 14px;
    font-weight: 400;
    color: #000;
}
.profile_bottom{
    padding: 20px 20px;
}
.profile_bottom span{
    font-size: 15px;
    font-weight: 400;
    color: #000;
}
.subscrib_div{
    display: flex;
    justify-content: space-between;
}
.profile_bottom .subscrib_div span{ font-size: 14px; }
.subscrib_div .sub_btn{
    background: #dfe3ff;
    padding: 2px 15px;
    border-radius: 8px;
    color: var(--bs-primary);
    margin-bottom: 4px;
    display: block;
}

.profile_bottom .heading{
    font-size: 12px;
    font-weight: 400;
    color: var(--bs-gray);
    display: block;
    text-align: center;
    text-decoration: underline;
}


.total_count {
    color: var(--title_text_color);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    width: 100%;
    display: block;
    line-height: 1;
}

.total_count svg {
    display: inline-block;
    margin-right: 6px;
    font-size: 18px;
}

.users_thumb_list {
    text-align: center;
    width: 100%;
    margin-top: 10px;
}
.users_thumb_list > a {
    display: inline-block;
    margin-left: -17px;
    position: relative;
}
.users_thumb_list > a:first-child {
    margin-left: 0;
}
.users_thumb_list > a img {
    border: 2px solid var(--bs-white);
    border-radius: 50%;
}

.progresdiv {
    margin-top: 0;
    width: 78px;
    height: 78px;
}
.progresdiv svg {
    height: 78px;
    width: 78px;
}
.progresdiv svg circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s ease;
    stroke: var(--bs-primary);
    stroke-width: 6px;
}
.ant-progress-text {
    display: none !important;
}

.progresdiv svg circle.bar_light {
    stroke: var(--bs-gray-200);
}
.progresdiv svg circle.bar_dark {
    stroke: var(--bs-gray-200);
}

.progresdiv a {
    position: absolute;
    top: 100%;
    left: 50%;
    font-size: 12px;
    transform: translate(-50%, -50%);
    content: attr(data-percent)"%";
    background: #ffffff;
    /* color: #dc3545; */
    padding: 4px 6px;
    line-height: 1;
    border-radius: 50px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    font-weight: 600;
}
.prog{
    display: block;
    margin: 0 auto;
    overflow: visible !important;
 }