.dob-meta {
    display: inline-block;
    padding: 20px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
}

.dob-meta h4 {
    font-size: 20px;
    font-weight: 500;
}

.dob-meta .btn.btn-primary {
    width: 100%;
    border-radius: 20px;
}

.dob {
    color: var(--bs-primary);
    margin-top: 4px;
    text-align: center;
    margin-left: auto;
}

.birthdayImage {
    padding: 10px;
}

.dob-meta>h6 {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
}

.birthdayImage img {
    max-width: 130px;
}


.low-opacity:before {
    -webkit-opacity: 0.75;
    -moz-opacity: 0.75;
    -ms-opacity: 0.75;
    -o-opacity: 0.75;
    opacity: 0.75;
}

.widget .bg-image {
    z-index: 0;
}

.bg-image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slick-slide img {
    display: block;
    margin: auto;
}

/* for slick arrow */
.dob-meta .slick-next {
    font-size: 20px !important;
    line-height: 0;
    top: 50% !important;
    right: 5px !important;
    width: 20px;
    height: 20px;
    position: absolute !important;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    background: rgb(233, 236, 239) !important;
    border-radius: 50%;
}

.dob-meta .slick-prev {
    font-size: 20px !important;
    line-height: 0;
    top: 50% !important;
    left: 5px !important;
    width: 20px;
    height: 20px;
    position: absolute !important;
    transform: translate(0, -50%);
    cursor: pointer;
    color: black !important;
    background: rgb(233, 236, 239) !important;
    border-radius: 50%;
}