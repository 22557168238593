.post_comment {
    margin-top: 15px;
    background: var(--light_overBg);
    margin-left: -15px;
    margin-right: -15px;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: -15px;
    border-radius: 0 0 6px 6px;
    border-top: 0;
}

.commentTitle {
    font-size: 14px;
    margin-bottom: 7px;
    color: var(--title_text_color);
}
.parent_comment.comment_item {
    z-index: 5;
}
.comment_item {
    background: #fff;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 7px;
    position: relative;
}
.childCommentList:before {
    content: "";
    height: calc(100% - 46px);
    width: 2px;
    background: var(--bs-gray-300);
    position: absolute;
    left: 10px;
    top: -10px;
}
.comment_item.child_comment:after {
    content: "";
    width: 10px;
    height: 2px;
    background: var(--bs-gray-300);
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.post_comment_list .post_comment_item {
    margin-top: 10px;
}

.post_comment_list .post_comment_item:last-child {
    margin-bottom: 0;
}
.comment_options {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bs-gray-200);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: auto;
    margin-bottom: auto;
}


.comment__action {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 12px;
    display: inline-block;
    border-radius: 2px;
    margin-right: 10px;
    border: 1px solid var(--bs-gray-200);
    height: 26px;
    min-width: 26px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.comment__action.like:hover .react-box {
    display: block;
}

.comment_actions .reactionPost {
    display: inline-flex;
}
.comment_actions .reactionPost button {
    background: transparent;
    padding: 0;
    border: 0;
    color: var(--bs-gray-700);
    display: flex;
    align-items: center;
    line-height: 1;
}
.comment_actions .reactionPost button svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    color: var(--bs-gray-700);
}
.comment_actions .reactionPost .postReactList button svg {
    height: 14px;
    width: 14px;
    margin-right: 0;
}
.comment_actions .reactionPost button span {
    color: var(--bs-gray-900);
    font-weight: 600;
    font-size: 12px;
}



.comment_user_info {
    display: flex;
    align-items: center;
    line-height: 1;
}
.comment_user_info > div:nth-child(2) a {
    display: inline-block;
    margin-bottom: 5px;
}
.post_comment_item img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex: 0 0 30px;
    object-fit: cover;
}
.comment_user_info > div:nth-child(2) {
    padding-left: 12px;
}
.commentText {
    margin: 7px 0;
}



/* EDIT COMMENT */
.post_comment .post_comment_item .write_comment {
    width: 100%;
}
.post_comment .post_comment_item .write_comment textarea {
    background: var(--bs-gray-100);
}
