.notification_mainDiv {
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:5px;
}
.notification_avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}