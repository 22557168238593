
.post_comment .write_comment {
    position: relative;
 }
 .post_comment .write_comment textarea {
    /* overflow: hidden; */
 }
 .post_comment .write_comment textarea {
    width: 100%;
    border: 0 !important;
    padding: 10px 15px;
    color: var(--bs-gray-800);
    font-size: 14px;
    box-shadow: none !important;
    padding-right: 60px;
 }
 .post_comment .write_comment textarea::placeholder {
    font-size: 14px;
    color: var(--bs-gray-600);
 }
 .post_comment .write_comment textarea:focus,
 .post_comment .write_comment textarea:active {
    outline: medium none;
 } 
 .write_comment button {
   min-width: auto;
   position: absolute;
   bottom: 3px;
   right: 3px;
 }



.childCommentList {
   padding-left: 20px;
   position: relative;
}
.childCommentList .comment_item:last-child {
   margin-bottom: 0;
}
.post_comment .write_comment .mentionList {
   background: var(--bs-white);
   border-radius: 6px;
}


.mentionList__suggestions {
   padding: 2px;
   border-radius: 6px;
   outline: none;
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
               0 3px 6px -4px rgba(0, 0, 0, 0.12), 
               0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.mentionList__suggestions li.mentionList__suggestions__item {
   background: #f1f1f1;
   padding: 2px 7px;
   border-radius: 4px;
   font-weight: 500;
}

.write_comment .mentionList .mentionList__control {
   height: 100%;
}
.write_comment .mentionList .mentionList__control .mentionList__highlighter {
   height: 100%;
   width: 100%;
   border: 0 !important;
   padding: 10px 15px;
   color: var(--bs-gray-800);
   font-size: 14px;
   box-shadow: none !important;
   padding-right: 60px;
}
.write_comment .mentionList .mentionList__control .mentionList__highlighter strong {
   color: var(--bs-primary);
   position: relative;
   z-index: 1;
}
.mentionList__highlighter .mentionList__highlighter__substring {
   visibility: visible !important;
}


.comment_item .write_comment .mentionList {
   background: var(--bs-gray-100);
}