.aboutIcon{
    margin-right: 3px;
}

.interestItems {
    margin-top: 7px;
}
.interestItems>span {
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 4px;
    margin-bottom: 6px;
    border-radius: 50px;
    padding: 6px 14px;
    background: #3cb37124;
    display: inline-block;
    color: mediumseagreen;
    font-weight: 600;
}