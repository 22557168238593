.topbar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--bs-white);
    display: block;
    height: 66px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    z-index: 1039;
}

.topbar.fixed {
    position: sticky;
}

.b {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logo {
    display: inline-block;
    vertical-align: inherit;
    width: 15%;
    position: relative;
    z-index: 10;
}

.logo a img {
    height: 50px;
}

.logo>a {
    display: inline-flex;
    vertical-align: middle;
    color: #1262c6;
    align-items: center;
    line-height: 1;
}

.topbar>form {
    display: inline-block;
    vertical-align: sub;
    width: 26%;
    position: relative;
}

.top-area {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
    width: calc(100% - 15%);
}

.top-area>ul {
    display: flex;
    align-items: center;
    float: left;
    line-height: 64px;
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 50px;
    vertical-align: middle;
}

.top-area>ul>li {
    position: relative;
    vertical-align: top;
    z-index: 2;
}

.top-area>ul>li>a.text {
    color: var(--bs-primary);
    font-size: 13px;
    padding: 0 0 0 40px;
    width: auto;
    text-transform: capitalize;
    font-weight: 400;
}

.top-area>ul>li>a {
    color: var(--bs-primary-dark);
    font-size: 15px;
    position: relative;
    display: inline-flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: auto;
    text-align: center;
    transition: all 0.2s linear 0s;
    vertical-align: middle;
    margin: 0 15px;
}

/*.top-area>ul>li>a.active::after {
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -2px;
    content: "";
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    z-index: 9;
}*/

.top-area>ul>li>a:hover,
.top-area>ul>li>a.active {
    color: var(--bs-orange);
}

.top-area>ul>li>a svg{
    width: 20px;
    height: 20px;
    color: var(--bs-primary);
    margin-bottom: 6px;
}

.top-area>ul>li>a span{
    line-height: 1;
    font-size: 13px;
}

.top-area>ul>li>a:hover svg,
.top-area>ul>li>a.active svg{
    color: var(--bs-orange);
}

.top-area .user-img {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 65px;
}

.user-img>img {
    width: 50px;
    height: 50px;
}

.topbar .dropdown-menu {
    max-height: 340px;
    width: 350px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    top: 100%;
    overflow: hidden;
    border: 0;
    line-height: initial;
    inset: initial !important;
    transform: translate(0px, 0) !important;
    padding: 0;
}

.topbar .dropdown-toggle.btn.btn-primary {
    padding: 0;
    border: 0;
    box-shadow: none;
    width: 50px;
    line-height: 64px;
}

.topbar .dropdown-toggle.btn.btn-primary:focus,
.topbar .dropdown-toggle.btn.btn-primary:active {
    box-shadow: none;
}

.topbar .dropdown-toggle.btn.btn-primary img {
    width: 24px;
    height: 24px;
}

.topbar .dropdown-toggle.btn.btn-primary em {
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    height: 17px;
    line-height: 17px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 15px;
    width: 17px;
    font-style: normal;
    background-color: #d2252d !important;
    color: #ffffff !important;
    font-weight: 600;
}

.topbar .dropdown-menu .dropdown_title {
    color: #515365;
    border-bottom: 1px solid #e6ecf5;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    padding: 7px 15px;
    text-align: left;
    width: 100%;
    text-transform: capitalize;
}

.topbar .dropdown-menu {
    border-radius: 0;
}

.topbar .dropdown-menu .drops-menu {
    list-style: outside none none;
    padding-left: 4px;
    height: 300px;
    overflow-y: auto;
    padding-right: 4px;
}

.topbar .dropdown-menu .drops-menu::-webkit-scrollbar {
    width: 5px;
}

.topbar .dropdown-menu .drops-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.topbar .dropdown-menu .drops-menu::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 4px;
}

.topbar .dropdown-toggle::before {
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 40px;
    left: 50%;
    position: absolute;
    top: 53%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.2s linear 0s;
    width: 40px;
    z-index: -1;
}

.topbar .dropdown-toggle:hover::before {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

.topbar .dropdown-toggle:after {
    display: none;
}


.main-menu {
    display: inline-block;
    vertical-align: inherit;
}

.user-img>h5 {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    vertical-align: middle;
}

.top-area .user-img>span.status {
    bottom: 10px;
    right: 5px;
}

.top-area>span.main-menu {
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    margin-right: 20px;
    vertical-align: inherit;
    color: var(--bs-primary);
}

.top-area .user-img>img {
    border-radius: 50%;
    display: inline-block;
    transform: scale(0.8);
    vertical-align: inherit;
    border: 2px solid var(--bs-gray-600);
}


/* HEADER SEARCH */
.top-search {
    float: left;
    text-align: center;
    width: 15%;
    border: 1px solid var(--bs-gray-200);
    border-radius: 10px;
    vertical-align: inherit;
    z-index: 10;
    margin-top: 20px;
    cursor: pointer;
}

/* .top-search form {
    display: inline-block;
    position: relative;
    width: 100%;
}

.top-search form input::placeholder {
    color: var(--bs-gray-600);
    font-weight: 300;
}

.top-search form input {
    border: medium none;
    font-size: 13px;
    padding: 7px 30px;
    padding-right: 50px;
    width: 100%;
    color: var(--bs-gray-800);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    border-radius: 30px;
    height: 45px;
}

.light-theme .top-search form input {
    background: var(--bs-gray-200);
}

.dark-theme .top-search form input {
    background: var(--bs-gray-500);
    color: var(--bs-gray-200);
}

.dark-theme .top-search form input::placeholder {
    color: var(--bs-gray-200);
} */

.top-search button {
    background: transparent;
    font-size: 15px;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    color: inherit;
    padding: 0;
    height: 45px;
    width: 45px;
    border: 0;
}

.light-theme .top-search button i {
    color: var(--bs-gray-700);
}

.dark-theme .top-search button i {
    color: var(--bs-gray-200);
}

/* wallet */
.wallet {
    display: inline-block;
    line-height: 65px;
    padding: 0 25px;
}

.wallet-icon {
    cursor: pointer;
}

/* Weather Widget */
.weather-widget {
    /* background-image: url("./images/resources/weather.jpg"); */
    float: left;
    min-height: 300px;
    text-align: center;
    width: 100%;
    z-index: 0;
    background-size: cover;
    position: relative;
}

.weather-widget canvas {
    display: inline-block;
    margin-top: 70px;
}

.weather-widget::after {
    background: #000000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.theme-layout::before {
    background: rgba(52, 70, 93, 0.7);
    background-repeat: repeat;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: all 0.5s linear 0s;
    visibility: hidden;
    opacity: 0;
}

.theme-layout.active::before {
    opacity: 1;
    visibility: visible;
}

.user-setting {
    background: var(--bs-white);
    border-radius: 0 0 3px 3px;
    position: absolute;
    right: 0px;
    text-align: left;
    top: 100%;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    line-height: initial;
    transition: all 0.2s linear 0s;
}

.user-setting::before {
    content: "";
    border-bottom: 10px solid var(--bs-white);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    right: 13px;
    position: absolute;
    top: -9px;
}

.user-img:hover .user-setting {
    opacity: 1;
    visibility: visible;
}

.user-setting .user_top{
    padding: 15px 15px;
    background: var(--lightOverOverBg);
    display: block;
}

.user-setting .user_top .author-name {
    color: var(--bs-primary);
    font-size: 16px;
}

.user-setting>ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block;
    width: 100%;
}

.user-setting>ul li a {
    display: inline-block;
    font-size: 13px;
    padding: 10px 15px;
    text-transform: capitalize;
    width: 100%;
    position: relative;
    color: var(--bs-gray-600);
}

.dark-theme .user-setting>ul li a {
    color: var(--bs-gray-200)
}

.user-setting ul.chat-setting {
    margin: 10px 0;
}

.user-setting ul.chat-setting li a {
    padding-left: 35px;
}

.user-setting ul.log-out {
    margin: 10px 0 0;
}

.user-setting .status {
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.user-setting>ul li a i, .user-setting>ul li a svg {
    font-size: 14px;
    margin-right: 18px;
}

.light-theme .user-setting>ul li a i, .light-theme .user-setting>ul li a svg {
    color: var(--bs-gray-600);
}

.dark-theme .user-setting>ul li a {
    color: var(--bs-gray-200);
}

.user-setting>ul li a:hover {
    color: var(--bs-orange);
}

.user-setting>ul li a:hover i {
    color: var(--bs-orange);
}

.header-main-container {
    width: 96%;
    margin: auto;
}

.main-menu.style2{
    position: relative;
}

.number_badge{
    position: absolute;
    top: 0;
    right: -6px;
    background: var(--bs-orange);
    font-size: 10px;
    font-weight: 700;
    color: var(--bs-primary);
    border-radius: 50%;
    line-height: 1;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1100px) {
    .top-search {
        width: 30%;
    }
}

@media(max-width: 1024px) {
    .top-area>ul {
        padding-left: 20px;
    }
}