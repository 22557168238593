.headerMobile {
    background: var(--bs-white);
    position: sticky;
    top: 12px;
    left: 12px;
    right: 12px;
    z-index: 1;
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,.1);
    border-radius: 14px;
    width: calc(100% - 24px);
    z-index: 999;
    margin-bottom: var(--common_space);
}
.sticky {
    box-shadow: 0 0.5rem 2rem rgba(0,0,0,.2);
}
.headerMobile>.container {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}
.toggleMenu {
    margin-right: 15px;
}
.toggleMenu img {
    height: 28px;
    width: 28px;
}
.logo {
    height: 40px;
}


.headerRight {
    margin-left: auto;
}
.headerRight img {
    height: 24px;
    width: auto;
}


.rightBtn {
    padding: 0;
    background: 0;
    border: 0;
    line-height: 1;
    color: var(--bs-primary);
    height: 24px;
    width: 24px;
}
.rightBtn svg {
    height: 24px;
    width: 24px;
}



.menuDrawer {
    border-right: 0 !important;
    max-width: 90%;
}
.profile {
    height: 28px;
    width: 28px;
    display: inline-block;
    border-radius: 50%;
    padding: 0;
    border: 0;
    margin-left: 20px;
}
.profile img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.profile_info {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.profile_info {
    background: var(--bs-gray-200);
    padding: 12px;
    border-radius: var(--bs-border-radius-lg);
}





.otherInfo {
    width: calc(100% - 88px);
}
.PercentComplete {

}
.PercentComplete>div>a {
    top: calc(100% - 4px);
}
.userName {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 4px;
}
.email {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
.email, .gender {
    font-size: 14px;
    color: var(--bs-gray-900);
}


.drawerNav {
    list-style: none;
    margin: 0;
    padding-left: 0;
    margin-bottom: 20px;
}
.drawerNav>li {
    font-weight: 600;
    padding: 7px 0;
}



.adminMenu {
    margin-bottom: 10px !important;
    border: 1px solid var(--bs-gray-200);
    border-radius: var(--bs-border-radius-lg) var(--bs-border-radius-lg);
}
.adminMenu .toggle_button {
    border-color: var(--bs-gray-200);
}
.adminMenu ul {
    
}
.adminMenu ul>li:last-child {
    margin-bottom: 0;
}

.adminMenu .toggle_button {
    padding: 10px 20px;
}



