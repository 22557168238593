.chatLeftUserListWrapper {}

.chatLeftBar {
    --ChatColor1: #4d5868;
    height: 100vh;
    overflow: hidden;
    background: #e8eaff !important;
}

.searchUserForm {
    position: relative;
}

.searchUserForm svg {
    height: 1.125rem;
    width: 1.125rem;
    color: var(--ChatColor1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 2;
}

.searchUserForm .form-control {
    color: var(--ChatColor1);
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ebf1f7;
    height: 56px;
    padding-left: 50px;
    font-weight: 500;
}

.chatLeftUserListWrapper {
    overflow-y: auto;
}

.chatLeftUserListWrapper::-webkit-scrollbar {
    display: none;
}

.add-connection-btn {
    background: #3193f4;
    width: 50px;
    font-size: 12px;
    min-width: 50px;
    font-weight: 600;
    padding: 8px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    color: aliceblue;
}

@media (min-width: 1200px) {
    .chatLeftBar h2 {
        font-size: 1.375rem;
    }
}