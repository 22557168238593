.group_list {
    max-height: 260px;
    position: relative;
    list-style: none;
    padding-left: 0;
    margin: 0;
    padding: 0 20px 20px;
}

.group_list>li {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
}

.group_list>li:last-child {
    margin-bottom: 0;
}

.group_list>li figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.group_list>li figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.group_list>li .group_list_meta {
    display: inline-flex;
    padding-left: 10px;
    width: calc(100% - 40px);
    justify-content: space-between;
    align-items: center;
}

.group_list>li .group_list_meta>.info {
    color: #535165;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
    width: 62%;
    margin-top: 0;
    padding-left: 0;
}

.light-theme .group_list>li .group_list_meta>.info a {
    color: var(--bs-gray-800);
    font-weight: 600;
}

.dark-theme .group_list>li .group_list_meta>.info a {
    color: var(--bs-gray-200);
}

.group_list>li .group_list_meta>.info a:hover {
    color: var(--bs-primary);
}

.group_list>li .group_list_meta>.info span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    color: var(--bs-gray-600);
    margin-top: 8px;
}

.dark-theme .group_list>li .group_list_meta>.info span {
    color: var(--bs-gray-300);
}

.group_list>li .group_list_meta .btn.btn-link {
    color: var(--bs-primary);
    line-height: 1.5;
    height: auto;
    padding: 0;
}