.light-theme .planMain{
    background-color: var(--bs-gray-200);
}

.dark-theme .planMain{
    background-color: var(--bs-gray-900);
}

.planMain .row{
    justify-content: center;
}