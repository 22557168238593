.pageCard {
   display: inline-block;
   text-align: center;
   width: 100%;
   padding: 0;
   border-radius: 6px;
   position: relative;
   background: var(--bs-white);
}

.pageCard .pageCardcoverPhoto {
   aspect-ratio: 16/7;
   background-position: center;
   border-radius: 6px 6px 0 0;
   background-size: cover;
}

.pageCard .page_content {
   padding: 0 10px 10px 10px;
}

.light-theme .pageCard {
   border: 1px solid var(--bs-gray-300);
}

.dark-theme .pageCard {
   border: 1px solid var(--bs-gray-500);
}

.pageCard .more-opotnz {
   position: absolute;
   top: 7px;
   right: 11px;
   text-align: left;
}

.pageCard .more-opotnz ul {
   bottom: initial;
   top: 25px;
   right: -8px;
}

.pageCard figure {
   display: block;
   width: 100%;
   margin-bottom: 10px;
   margin-top: -38px;
}

.pageCard a {
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   text-align: center;
   width: 100%;
}

.light-theme .pageCard a {
   color: var(--bs-gray-800);
}

.dark-theme .pageCard a {
   color: var(--bs-gray-200);
}

.pageCard figure img {
   border-radius: 50%;
   width: 70px;
   height: 70px;
}

.pageCard span {
   font-size: 12px;
   font-weight: 400;
}

.pageCard button {
   text-transform: capitalize;
}

.light-theme .pageCard .connection-btn {
   background-color: var(--bs-gray-200);
   border-color: var(--bs-gray-200);
   color: var(--bs-gray-900);
}

.light-theme .pageCard .connection-btn:hover {
   background-color: var(--bs-gray-300);
}

.dark-theme .pageCard .connection-btn {
   background-color: var(--bs-gray-500);
   border-color: var(--bs-gray-500);
   color: var(--bs-gray-200);
}

.dark-theme .pageCard .connection-btn:hover {
   background-color: var(--bs-gray-600);
}

.pageCard a:hover {
   color: var(--bs-primary);
}

.pageCard .add-remove-frnd {
   display: inline-flex;
   list-style: outside none none;
   margin: 12px 0 0;
   padding: 0 0;
   text-align: center;
   width: 100%;
   justify-content: center;
}

.pageCard .add-remove-frnd li a {
   border-radius: 4px;
   color: #fff;
   display: inline-block;
   transition: all 0.2s linear 0s;
   font-size: 13px;
   height: 34.5px;
   width: 34.5px;
   line-height: 34.5px;
}

.pageCard .add-remove-frnd>li a>i {
   font-size: 14px;
}

.pageCard .add-remove-frnd.following li.following {
   flex: 1;
}

.pageCard .remove-frnd a {
   background: #a8adcd none repeat scroll 0 0;
}

.pageCard .add-remove-frnd li {
   display: inline-block;
   margin-right: 6px;
}

.pageCard .add-remove-frnd li:last-child {
   margin-right: 0;
}



.pageCard button.ant-dropdown-trigger {
   position: absolute;
   top: 7px;
   right: 7px;
   height: 24px;
   width: 24px !important;
   border: 0;
   border-radius: 50%;
   padding: 0;
}

.light-theme .pageCard button.ant-dropdown-trigger {
   background: var(--bs-white);
   color: var(--bs-gray-900);
}

.dark-theme .pageCard button.ant-dropdown-trigger {
   background: var(--bs-white);
   color: var(--bs-gray-100);
}


/* DROPDOWN CODE */
.more-opotnz {
   cursor: pointer;
   float: right;
   line-height: initial;
   margin-top: 4px;
   position: relative;
}

.more-opotnz::after {
   border-radius: 100%;
   content: "";
   height: 30px;
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) scale(0);
   transition: all 0.2s linear 0s;
   width: 30px;
   z-index: 0;
}

.more-opotnz:hover::after {
   transform: translate(-50%, -50%) scale(1);
}

.more-opotnz>i {
   font-size: 18px;
   position: relative;
   z-index: 1;
}

.more-opotnz>ul {
   border-radius: 4px;
   bottom: 20px;
   font-size: 12px;
   list-style: outside none none;
   margin: 0;
   opacity: 0;
   padding: 12px;
   position: absolute;
   right: 0;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 145px;
   z-index: 3;
}

.light-theme .more-opotnz>ul {
   background: var(--bs-white);
}

.dark-theme .more-opotnz>ul {
   background: var(--bs-white);
}

.dark-theme .more-opotnz>ul li {
   color: var(--bs-gray-200);
}

.light-theme .more-opotnz>i {
   color: var(--bs-gray-600);
}

.dark-theme .more-opotnz>i {
   color: var(--bs-gray-200);
}

.light-theme .more-opotnz::after {
   background: var(--bs-gray-200);
}

.dark-theme .more-opotnz::after {
   background: var(--bs-gray-600);
}

.more-opotnz li {
   display: inline-block;
   padding: 6px 7px;
   width: 100%;
}

.more-opotnz:hover>ul {
   opacity: 1;
   visibility: visible;
}

.more-opotnz>ul li {
   color: var(--bs-gray-700);
   transition: all 0.2s linear 0s;
   text-transform: capitalize;
   font-weight: 500;
}

.more-opotnz>ul li:hover {
   color: var(--bs-primary);
}

.pageCard .add-remove-frnd>li>button.deleteBtn {
   background: var(--bs-primary);
   color: #fff;
}

.pageCard .add-remove-frnd>li a {
   color: var(--bs-gray-900);
   border-radius: 4px;
   display: inline-block;
   transition: all 0.2s linear 0s;
   font-size: 13px;
   height: 34.5px;
   width: 34.5px;
   line-height: 34.5px;
   padding: 0;
   border: 0;
}

.pageCard .add-remove-frnd>li>button.deleteBtn {
   border-radius: 4px;
   display: inline-block;
   transition: all 0.2s linear 0s;
   font-size: 13px;
   height: 34.5px;
   width: 34.5px;
   line-height: 34.5px;
   padding: 0;
   border: 0;
}
.pageCard .add-remove-frnd > li a:hover {
   background-color: var(--bs-gray-300);
   border-color: var(--bs-gray-300);
   color: var(--bs-gray-900);
}