.profile-menu {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
    padding: 0 0;
    width: auto;
}

.profile-menu>li {
    display: inline-block;
    margin: 0 15px;
    vertical-align: middle;
}

.profile-menu>li>a {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    position: relative;
}

.light-theme .profile-menu>li>a {
    color: var(--bs-gray-800);
}

.dark-theme .profile-menu>li>a {
    color: var(--bs-gray-200);
}

.profile-menu>li>a>i {
    font-size: 30px;
    vertical-align: middle;
}
.profile-menu>li>a.active {
    color: var(--bs-primary);
}
.profile-menu>li>a.active::after {
    bottom: -7px;
    content: "";
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    width: 30px;
    height: 3px;
    border-radius: 2px;
    background: var(--bs-primary);
}
.admin_menu>ul>li>ul>li {
    margin-bottom: 7px;
}
.admin_menu>ul>li>ul>li:last-child {
    margin-bottom: 0;
}
.profile-menu+.more {
    margin-left: 15px;
}

.profile-menu .more {
    display: inline-flex;
    float: none;
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
    align-items: censter;
    justify-content: center;
}

.profile-menu .more>i {
    font-size: 18px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}

.folw-detail>li {
    display: inline-block;
    margin: 0 2px;
    text-align: center;
}

.folw-detail>li span {
    display: inline-block;
    font-weight: 500;
    width: 100%;
}

.light-theme .folw-detail>li span {}

.dark-theme .folw-detail>li span {}

.folw-detail ins {
    font-style: normal;
    text-decoration: none;
}


@media(max-width: 1070px) {
    .profile-menu>li {
        margin: 0 12px;
    }
}

@media(max-width: 500px) {
    .folw-detail>li {
        margin: 0 3px;
        text-align: center;
        background: #fff;
        padding: 5px 7px;
        border-radius: var(--bs-border-radius-lg);
    }
    .folw-detail ins {
        margin-top: 0;
    }
}