.whether {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-right: 30px;
    margin-left: 30px; */
    vertical-align: middle;
    border-radius: 50px;
    padding: 20px;
}

.whether .left {
    display: inline-block;
    vertical-align: middle;
    /* margin-left: 10px; */
    margin-right: 15px;
    line-height: 18px;
    text-align: left;
}

.whether .left .temp {
    color: var(--bs-primary);
    font-size: 30px;
    margin-bottom: 0px;
    font-weight: 600;
    margin-right: 10px;
}
.whether .left .temp span{
    font-size: 24px;
    margin-left: -6px;
}
.whether .left .city {
    margin-top: 1px;
}
.whether .left .city .edit_loc {
    border: 0;
    display: block;
    padding: 0;
}
.whether .left .city input.edit_loc {
    text-decoration: none;
    border-bottom: 1px solid #ccc;
}
.edit {
    color: var(--bs-primary);
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
}

.whether .left .other {
    font-size: 12px;
    font-weight: 500;
    max-width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.light-theme .whether .left .other {
    color: var(--bs-gray-900);
}
.dark-theme .whether .left .other {
    color: var(--bs-gray-100);
}

.whether .left .city a {
    margin-left: 7px;
    font-size: 12px;
}

.whether .right {
    display: inline-block;
    vertical-align: middle;
    /* border: 1px solid var(--border-color); */
    border-radius: 50%;
}
.whether .right img {
    border-radius: 50%;
}
.whether .right svg {
    height: 24px;
    display: inline-block;
    width: 24px;
}

@media (max-width: 1100px) {
    .whether {
        margin-left: 5px;
    }
}