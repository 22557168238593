.createWidget {
    display: block;
    width: 100%;
    height: 100%;
}
.createWidget .createWidgetWrap {
    cursor: pointer;
    display: block;
    height: 100%;
    padding: 38px 27px;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.createWidget .createWidgetWrap > i {
    color: #fa6342;
    font-size: 40px;
}

.createWidget .titleMeta {
    display: block;
    margin-top: 10px;
    width: 100%;
}
 
.createWidget .titleMeta > h5 {
    display: block;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 5px;
}
 
.createWidget .titleMeta > span {
    display: block;
    width: 100%;
    font-size: 12px;
}




.light-theme .createWidget .createWidgetWrap {
    border: 1px dashed var(--bs-gray-500);
    background: var(--bs-gray-100);
}
.dark-theme .createWidget .createWidgetWrap {
    border: 1px dashed var(--bs-gray-500);
    color: var(--bs-gray-200);
}
.light-theme .createWidget .titleMeta > h5 {
    color: var(--bs-gray-800);
}
.dark-theme .createWidget .titleMeta > h5 {
    color: var(--bs-gray-200);
}
.light-theme .createWidget .titleMeta > span {
    color: var(--bs-gray-700);
}
.dark-theme .createWidget .titleMeta > span {
    color: var(--bs-gray-200);
}
.createPlus{
    font-size: 26px;
    color: orange;
}