.we-login-register {
    padding: 40px 50px;
    position: relative;
    width: 100%;
    background: var(--bs-white);
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 25%);
    border-radius: 24px;
}
@media(max-width: 500px) {
    .we-login-register {
        padding: 40px;
    }
}
.form-title {
    color: var(--bs-text-blue);
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
}

.form-title>span {
    color: #8f8f8f;
    display: block;
    font-size: 13px;
    padding-bottom: 20px;
    /* padding-top: 20px; */
    text-transform: capitalize;
    width: 100%;
    /* border-top: 1px solid rgb(203 203 203 / 34%); */
    margin-top: 20px;
    font-weight: normal;
}

.form-title>p {
    color: #8f8f8f;
    font-size: 13px;
    text-transform: capitalize;
    width: 100%;
    margin-top: 8px;
    font-weight: normal;
}

.we-form {
    float: left;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
}

.we-form>input {
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    padding: 13px 20px;
    width: 100%;
    border-radius: 30px;
    font-size: 12px;
}


.we-form>input[type="checkbox"] {
    margin-top: 10px;
    width: auto;
}

.we-form>button {
    border: medium none;
    clear: both;
    color: #fff;
    display: block;
    font-weight: normal;
    letter-spacing: 1px;
    margin-top: 30px;
    /* padding: 10px 20px; */
    position: relative;
    text-transform: capitalize;
    z-index: 2;
    float: none;
    background: var(--bs-primary);
}

.we-form label {
    display: block;
    margin-bottom: 4px;
    text-transform: capitalize;
}

.light-theme .we-form label {
    color: var(--bs-black);
} 
.dark-theme .we-form label {
    color: var(--bs-gray-200);
} 

.we-form .checkbox label {
    padding-left: 25px;
}

.forgot.underline::before {
    background-color: #fff;
}

.we-login-register>span {
    text-transform: capitalize;
    width: 100%;
    color: #8f8f8f;
}

.checkbox {
    position: relative;
}

.we-form .checkbox .check-box {
    top: 50%;
    transform: translateY(-50%);
}



.login-link {
    font-weight: 600;
}

.login-link:hover {
    color: var(--bs-primary-dark);
    text-decoration: underline;
}

.key-logo {
    color: var(--bs-text-blue);
    font-size: 24px;
}


.password_with_view {
    position: relative;
}
.password_with_view .input-group-text {
    position: absolute;
    top: 11px;
    right: 12px;
    background: transparent;
    border: 0;
    padding: 0;
}
