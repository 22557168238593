.userCard {
   display: inline-block;
   text-align: center;
   width: 100%;
   border-radius: 6px;
   position: relative;
}
.userCard .userCardcoverPhoto {
   aspect-ratio: 16/7;
   background-position: center;
   border-radius: 6px 6px 0 0;
   background-size: cover;
}
.userCard .user_content {
   padding: 0 10px 10px 10px;
   line-height: 1;
}
.light-theme .userCard {
   border: 1px solid var(--bs-gray-300);
}
.dark-theme .userCard {
   border: 1px solid var(--bs-gray-500);
}
.userCard .more-opotnz {
   position: absolute;
   top: 7px;
   right: 11px;
   text-align: left;
}

.userCard .more-opotnz > ul {
   bottom: initial;
   top: 25px;
   right: -8px;
}

.userCard figure {
   display: block;
   width: 100%;
   margin-bottom: 10px;
   margin-top: -38px;
}
.userCard a {
   display: inline-block;
   font-size: 14px;
   font-weight: 600;
   text-align: center;
   width: 100%;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   margin-bottom: 5px;
}
.light-theme .userCard a {
   color: var(--bs-gray-800);
}

.dark-theme .userCard a {
   color: var(--bs-gray-200);
}

.userCard figure img {
   border-radius: 100%;
   width: 70px;
   height: 70px;
   object-fit: cover;
}


.userCard > button {
   margin-top: 10px;
   text-transform: capitalize;
}

.userCard a:hover {
   color: var(--bs-primary);
}

.userCard .add-remove-frnd {
   display: inline-flex;
   list-style: outside none none;
   margin: 12px 0 0;
   padding: 0 0;
   text-align: center;
   width: 100%;
   justify-content: center;
}

.userCard .add-remove-frnd > li a {
   border-radius: 4px;
   color: #fff;
   display: inline-block;
   transition: all 0.2s linear 0s;
   font-size: 13px;
   height: 34.5px;
   width: 34.5px;
   line-height: 34.5px;
   margin-bottom: 0;
}

.userCard .add-remove-frnd > li a > i {
   font-size: 14px;
}

.userCard .add-remove-frnd.following li.following {
   flex: 1;
}

.remove-frnd > a {
   background: #a8adcd none repeat scroll 0 0;
}

.userCard .add-remove-frnd > li {
   display: inline-block;
   margin-right: 6px;
}
.userCard .add-remove-frnd > li:last-child {
   margin-right: 0;
}

.userCard button.ant-dropdown-trigger {
   position: absolute;
   top: 7px;
   right: 7px;
   height: 24px;
   width: 24px !important;
   border: 0;
   border-radius: 50%;
   padding: 0;
}
.light-theme .userCard button.ant-dropdown-trigger {
   background: var(--bs-gray-200);
   color: var(--bs-gray-900);
}
.dark-theme .userCard button.ant-dropdown-trigger {
   background: var(--bs-white);
   color: var(--bs-gray-100);
}





/* DROPDOWN CODE */
.more-opotnz {
   cursor: pointer;
   float: right;
   line-height: initial;
   margin-top: 4px;
   position: relative;
}

.more-opotnz::after {
   border-radius: 100%;
   content: "";
   height: 30px;
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%) scale(0);
   transition: all 0.2s linear 0s;
   width: 30px;
   z-index: 0;
}

.more-opotnz:hover::after {
   transform: translate(-50%, -50%) scale(1);
}

.more-opotnz > i {
   font-size: 18px;
   position: relative;
   z-index: 1;
}

.more-opotnz > ul {
   border-radius: 4px;
   bottom: 20px;
   font-size: 12px;
   list-style: outside none none;
   margin: 0;
   opacity: 0;
   padding: 12px;
   position: absolute;
   right: 0;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 145px;
   z-index: 3;
}

.light-theme .more-opotnz > ul {
   background: var(--bs-white);
}
.dark-theme .more-opotnz > ul {
   background: var(--bs-white);
}
.dark-theme .more-opotnz > ul li {
   color: var(--bs-gray-200);
}
.light-theme .more-opotnz > i {
   color: var(--bs-gray-600);
}
.dark-theme .more-opotnz > i {
   color: var(--bs-gray-200);
}

.light-theme .more-opotnz::after {
   background: var(--bs-gray-200);
}
.dark-theme .more-opotnz::after {
   background: var(--bs-gray-600);
}

.more-opotnz li {
   display: inline-block;
   padding: 6px 7px;
   width: 100%;
}

.more-opotnz:hover > ul {
   opacity: 1;
   visibility: visible;
}

.more-opotnz > ul li {
   color: var(--bs-gray-700);
   transition: all 0.2s linear 0s;
   text-transform: capitalize;
   font-weight: 500;
}

.more-opotnz > ul li:hover {
   color: var(--bs-primary);
}
