.sidebar {
    display: inline-block;
    float: none;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    overflow: auto;
    position: sticky;
    top: 96px;
    padding-bottom: 110px;
}

.sidebar::-webkit-scrollbar {
    width: 0;
}

.sidebar .widget-title {
    margin-bottom: 35px;
    width: 100%;
}

.sidebar .widget {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    background: var(--bs-white);
    border-radius: 6px;
    overflow: hidden;
}

.sidebar .widget:last-child {
    margin-bottom: 0;
}


.sidebar .widget-title {
    border-bottom: 1px solid var(--border-color);
    color: var(--title_text_color);
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    padding: 15px 20px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
}

.sidebar .widget-title::before {
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
}

.sidebar .widget ul.naves li i {
    display: inline-block;
    font-size: 16px;
    vertical-align: middle;
}

.see-all {
    float: right;
    font-size: 11px;
    margin-top: 2px;
}

.see-all:hover {
    color: var(--bs-gray-600);
}