.central_meta {
    background: var(--bs-white);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}
.central_meta .card_body {
    padding: 0;
}
.central_meta .card_title {
    color: var(--title_text_color);
}
/* .formInputs{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 5px;
} */
.invalidMessage{
    color: var(--red);
}

button.delete_from_input {
    background: transparent;
    border: 0;
    background: var(--bs-gray-200);
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 0;
}

.editEventUploadImgCard {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 150px;
}

.editEventUploadImgCard button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}