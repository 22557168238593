.menu_link {
    display: inline-flex;
    align-items: center;
    padding: 7px 7px 7px 15px;
    border-radius: 50px;
    line-height: 1;
    font-weight: 500;
    margin-right: 5px;
}

.menu_link.noCount {
    padding: 7px 15px;
    height: 39px;
}

.light-theme .menu_link {
    color: var(--bs-gray-800);
}

.dark-theme .menu_link {
    color: var(--bs-gray-200);
}

.light-theme .menu_link.active,
.light-theme .menu_link:hover {
    background: var(--lightOverOverBg);
    color: var(--lightOverBgText);
}

.dark-theme .menu_link.active,
.dark-theme .menu_link:hover {
    background: var(--bs-gray-500);
    color: var(--bs-gray-200);
}

.menu_link span {
    background: var(--bs-primary);
    color: #fff;
    border-radius: 100%;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    height: 25px;
    line-height: 25px;
    text-align: center;
    width: 25px;
    margin-left: 10px;
    display: inline-block;
}

@media(max-width: 500px){
    .menu_link.active {
        color: var(--bs-primary) !important;
    }
    .menu_link {
        background: transparent !important;
        padding: 0;
        font-weight: 600;
        margin-right: 30px;
    }
    .menu_link span {
        background: #fff;
        color: #000;
        font-size: 12px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        width: 20px;
        margin-left: 7px;
        display: inline-block;
        font-weight: 700;
        box-shadow: var(--bs-box-shadow);
        border: 1px solid #00000021;
    }
}