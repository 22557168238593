.uploadedReactBox {
    padding: 1rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    max-width: 100%;
    height: auto;
    display: inline-block;
    position: relative;
}
.uploadedReactBox button {
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
    background: var(--bs-border-color);
    color: #000;
    border: var(--bs-border-width) solid var(--bs-border-color);
}
.react_icon {
    display: block;
}
.react_icon svg {
    height: 70px;
    width: 70px;
}