.gmap_canvas {
    margin-top: -15px;
}
.iframe {
    display: block;
}
.title {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 15px 10px;
    margin: 0;
}