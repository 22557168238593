.productCard .ant-dropdown-trigger {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.proDesc.card-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: normal;
}
