.fileContainer {
    cursor: pointer;
}
.profile-author {
    display: block;
    margin-top: -185px;
    text-align: center;
    width: 100%;
}
.profile-author-thumb {
    display: block;
    position: relative;
}
.profile-author-thumb > img {
    border: 3px solid #fff;
    border-radius: 100%;
    max-width: 160px;
    max-height: 160px;
    display: block;
    width: 100%;
    height: 100%;
    aspect-ratio: 2/2;
    object-fit: cover;
    margin: 0 auto;
}
.profile-author-thumb:hover .edit-dp {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
}

.edit-dp {
    /* background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0; */
    /* border: 1px solid #b3b3b3; */
    border-radius: 100%;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    opacity: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    transform: scale(0);
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 40px;
}
.edit-dp > label.fileContainer {
    color: #222;
    margin: 0;
    width: 100%;
}

.author-content {
    display: inline-block;
    margin-top: 28px;
    width: 100%;
}
.author-name {
    color: var(--title_text_color);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px;
    display: block;
}

.coverUploadAction {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.coverUploadAction button {
    margin: 0 3px;
    border: 0;
    padding: 7px 12px;
    border-radius: 4px;
    font-weight: 600;
    min-width: 100px;
}
.coverUploadAction button.save {
    background: #ebfff4;
    color: #0d8141;
}
.coverUploadAction button.cancel {
    background: #ffe2e2;
    color: #b30909;
}
.coverUploadAction button svg {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}



@media(max-width: 500px) {
    .profile-author-thumb > img {
        height: 80px;
        width: 80px;
    }
    .profile-author {
        display: inline-block;
        margin-top: -48px;
        margin-left: 0;
    }
    .author-content {
        margin-top: 7px;
    }
}

button.ant-dropdown-trigger {
    background: var(--bs-gray-200);
    color: var(--bs-gray-900);
}