.banner {
    color: #fff;
    padding: 30px;
    width: 100%;
    position: relative;
    z-index: 0;
}
.baner-top {
    display: block;
    text-align: left;
    width: 100%;
    z-index: 1;
    position: relative;
}
.banner .bg-image {
    z-index: auto;
}
.baner-top > span {
    display: block;
    text-align: left;
    width: 90%;
}
.banermeta {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
    z-index: 9;
    position: relative;
}
.banermeta > p {
    color: #fff;
    font-size: 23px;
    font-weight: 400;
    line-height: initial;
    text-transform: uppercase;
}
.banermeta > span {
    font-size: 13px;
    font-weight: 300;
    width: 100%;
}
.banermeta > a {
    background: #fff none repeat scroll 0 0;
    color: #333;
    line-height: initial;
    margin-top: 30px;
    padding: 8px 0;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    transition: all 0.2s linear 0s;
    display: block;
}
.banermeta > a:hover {
    color: #fff;
}