.user-profile {
    display: inline-block;
    position: relative;
    width: 100%;
}

.user-profile>figure {
    display: block;
    margin: 0;
    position: relative;
    width: 100%;
}

.user-profile figure img {
    display: block;
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: cover;
    aspect-ratio: 12/4;
    height: auto !important;
}

.edit-pp {
    /* background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0; */
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    left: 10px;
    line-height: 38px;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 10px;
    transform: scale(0);
    transition: all 0.2s linear 0s;
    width: 40px;
}

.user-profile:hover .edit-pp {
    opacity: 1;
    transform: scale(1);
}

.fileContainer {
    display: inline-block;
    font-size: 14px;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}

.profile-controls {
    background: rgba(255, 255, 255, 0.4) none repeat scroll 0 0;
    border-radius: 7px 0 0 7px;
    bottom: 20px;
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    padding: 7px 14px;
    position: absolute;
    right: 0;
    z-index: 1;
}

.profile-controls>li {
    color: #fff;
    display: inline-block;
    margin: 0 2px;
    position: relative;
    vertical-align: middle;
}

.profile-controls>li>a,
.profile-controls>li>div {
    background: #888da8 none repeat scroll 0 0;
    border-radius: 100%;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    font-size: 16px;
}

.light-theme .profile-controls>li ul.more-dropdown {
    background: var(--bs-gray-200) none repeat scroll 0 0;
    border: 1px solid var(--border-color);
}

.dark-theme .profile-controls>li ul.more-dropdown {
    background: var(--bs-gray-800) none repeat scroll 0 0;
    border: 1px solid var(--border-color);
}

.profile-controls>li ul.more-dropdown {
    background: var(--bs-gray-800) none repeat scroll 0 0;
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    color: #858aa5;
    right: 0;
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 110%;
    transform: scale(0);
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 150px;
    z-index: 2;
    text-align: left;
}

.profile-controls>li ul.more-dropdown>li {
    display: inline-block;
    font-size: 12px;
    width: 100%;
}

.profile-controls>li ul.more-dropdown>li>a {
    display: inline-block;
    padding: 0 15px;
    transition: all 0.2s linear 0s;
    width: 100%;
    font-weight: 500;
}

.profile-controls>li ul.more-dropdown>li>a:hover {
    color: var(--bs-dark);
}

.planBtn {
    border-radius: 6px;
    bottom: 65px;
    color: #fff;
    left: 230px;
    position: absolute;
    background: linear-gradient(45deg, #5770ff, #283790);
    border-radius: 30px;
    padding: 3px;
    padding-right: 20px;
}

.planBtn span {
    background: #fff;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    border-radius: 35px;
    margin-right: 10px;
    box-shadow: 0 0 0px 5px #c1c1c1;
    color: #283790;
}

.planBtn:hover {
    color: #fff;
}

.profile-section {
    background: var(--light_overBg);
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    width: 100%;
}
.light-theme .profile-section {
    background: var(--light_overBg);
}
.dark-theme .profile-section {
    background: var(--bs-white);
}


.fileContainer input {
    display: none;
}

.country {
    font-size: 14px;
}

.folw-detail {
    display: block;
    list-style: outside none none;
    margin: 0;
    padding-left: 0;
    margin-top: 0;
    line-height: 1;
}

.folw-detail>li span {
    display: inline-block;
    font-weight: 500;
    width: 100%;
}

.folw-detail ins {
    font-style: normal;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-top: 4px;
    display: inline-block;
}

@media(max-width: 500px) {
    .folw-detail {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 15px;
    }
    .user-profile {
        background: var(--light_overBg);
    }
    .planBtn {
        position: initial;
        display: inline-block;
        border-radius: 30px;
        padding: 3px;
        padding-right: 10px;
        font-weight: 600;
        font-size: 12px;
        margin-top: 4px;
    }
    .planBtn span {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 30px;
        margin-right: 7px;
        box-shadow: none;
    }
    .folw-detail ins {
        font-size: 16px;
    }
}