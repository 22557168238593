.chat-header .avatar {
    height: 50px;
    width: 50px;
}
.chat-header .avatar img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    background: var(--bs-gray-200);
}

.chat-header .dropdown button {
    min-width: auto;
    padding: 0;
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 50%;
    border: 0;
    box-shadow: var(--bs-box-shadow-sm);
}

.chat-header .dropdown button:hover {
    box-shadow: var(--bs-box-shadow);
}

.chat-header .dropdown button::after {
    display: none;
}

.chat-header  .dropdown button svg {
    height: 16px;
    color: var(--bs-gray-800);
}

.chat-header .dropdown .dropdown-item svg {
    height: 14px;
    width: 14px;
    color: inherit;
    margin-left: auto;
}

.chat-header .dropdown .dropdown-item {
    display: flex;
}
.chat-header .dropdown-menu {
    width: 220px;
    box-shadow: -1px 0rem 0.8rem rgb(0 0 0 / 12%) !important;
}