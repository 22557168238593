.cutom_ant_datepicker {
    position: relative;
}

.cutom_ant_datepicker .form-label {
    position: absolute;
    top: 9px;
    left: 12px;
    z-index: 1;
    color: rgba(var(--bs-body-color-rgb), .65);
    font-size: 12px;
    letter-spacing: 0.2px;
}

.cutom_ant_datepicker .ant-picker {
    padding-top: 1.525rem;
    padding-bottom: 0.7rem;
    height: auto !important;
}

.cutom_ant_datepicker .ant-picker .ant-picker-input input::placeholder {
    color: var(--bs-body-color);
}

.bioTextarea {}

.bioTextarea.form-floating>label {
    position: absolute;
    top: 9px;
    left: 12px;
    z-index: 1;
    color: rgba(var(--bs-body-color-rgb), .65);
    font-size: 12px;
    letter-spacing: 0.2px;
    padding: 0 0;
    z-index: 2;
}

.bioTextarea textarea.ant-input {
    padding-top: 22px;
}

.ant-input-affix-wrapper-focused+label {}

.form-floating {}