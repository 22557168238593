.helpPageHeader {
   background: #495b72 none repeat scroll 0 0;
   position: relative;
   float: left;
   padding: 50px 15px 0;
   width: 100%;
}
.helpHeaderInner {
   display: block;
   margin: 0 auto;
   max-width: 50%;
   text-align: center;
   width: 100%;
   z-index: 3;
   position: relative;
}
.helpHeaderInner > p {
   color: var(--bs-gray-200);
   display: block;
   font-size: 14px;
   letter-spacing: 0.1px;
   line-height: 22px;
   margin: 0 auto;
   width: 84%;
}
.helpHeaderInner > h2 {
   color: var(--bs-gray-200);
   display: inline-block;
   font-size: 40px;
   font-weight: 400;
   line-height: initial;
   position: relative;
   text-transform: capitalize;
   vertical-align: middle;
   width: 100%;
   margin-bottom: 10px;
}

.helpHeaderInner > form {
   display: inline-block;
   margin-bottom: 15px;
   margin-top: 10px;
   position: relative;
   width: 100%;
}

.helpHeaderInner > form input {
   border: medium none;
   border-radius: 30px;
   font-size: 13px;
   padding: 13px 90px 13px 20px;
   width: 100%;
}

.dark-theme .helpHeaderInner > form input {
   background: var(--bs-gray-500) none repeat scroll 0 0;
   color: var(--bs-gray-200);
}

.light-theme .helpHeaderInner > form input {
   background: var(--bs-gray-200) none repeat scroll 0 0;
}

.helpPageHeader > figure {
   float: left;
   margin-bottom: 0;
   margin-top: 30px;
   text-align: center;
   width: 100%;
   z-index: 3;
   position: relative;
}
.helpPageHeader > figure > img {
   height: auto;
   max-width: 100%;
}
