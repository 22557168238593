.employmentUploadImgCard {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 150px;
}

.employmentUploadImgCard button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

.moreLessBtn {
    font-size: 20px;
    cursor: pointer;
}

.jobQuill {
    border: 1px solid var(--bs-border-color);
    padding: 10px;
    border-radius: 10px;
}