.planPriceBox {
   background: var(--bs-white) none repeat scroll 0 0;
   display: flex;
   flex-flow: column;
   text-align: center;
   width: 100%;
   border-radius: 10px;
   box-shadow: 0 1rem 2rem rgba(0, 0, 0, 20%);
   min-height: 100%;
   position: relative;
}

.planPriceBox > span {
   display: inline-block;
   width: 100%;
   text-transform: capitalize;
   font-weight: 500;
   font-size: 24px;
   padding: 10px;
   border-radius: 10px 10px 0 0;
}

.planPriceBox > .pricings {
   display: inline-block;
   width: 100%;
}

.planPriceBox > .pricings > .pricings-header {
   background: rgb(241, 241, 241, 0.5);
   padding: 20px 30px;
}

.planPriceBox > .pricings img {
   margin: 5px 0;
}

.planPriceBox > .pricings > .pricings-header > h5 {
   color: #0e304c;
   display: flex;
   font-size: 35px;
   position: relative;
   width: 100%;
   text-align: left;
   justify-content: center;
   align-items: center;
}

.planPriceBox > .pricings > .pricings-header > h5 span {
   font-size: 11px;
   font-style: italic;
   margin-left: 6px;
}

.planPriceBox > .pricings > .pricings-bottom {
   padding: 20px;
   padding-bottom: 90px;
}

.planPriceBox > .pricings > p {
   color: #535165;
   line-height: 20px;
   margin-bottom: 20px;
   margin-top: 19px;
}

.planPriceFeatures {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   text-align: left;
   width: 100%;
}

.planPriceFeatures > li {
   display: flex;
   align-items: baseline;
   font-size: 14px;
   margin-bottom: 15px;
   width: 100%;
}

.planPriceFeatures > li > i {
   color: red;
   font-size: 14px;
   margin-right: 8px;
}
.planBtnActive {
   background: #d2252d;
   color: #fff;
   border: 2px solid #d2252d;
   background: transparent;
   color: #d2252d;
   position: absolute;
   bottom: 25px;
   left: 20px;
   right: 20px;
}
.planBtnNonActive {
   color: #fff;
   border: 2px solid #d2252d;
   background: transparent;
   color: #d2252d;
   position: absolute;
   bottom: 25px;
   left: 20px;
   right: 20px;
}