.optionContainer {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 0 20px 20px;
   width: 100%;
}
.optionContainer > li {
   display: flex;
   margin-bottom: 10px;
   position: relative;
   width: 100%;
}
.optionContainer > li:hover{
   color: var(--red);
}
.optionContainer > li > svg {
   display: inline-block;
   font-size: 16px;
   vertical-align: middle;
   margin-right: 6px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   font-style: normal;
}
.optionContainer > li >a{
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    vertical-align: top;
    color: inherit;
    outline: none;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
}