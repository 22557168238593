.helpHelpBox {
   border: 1px solid var(--border-color);
   border-radius: 5px;
   display: inline-block;
   padding: 20px;
   width: 100%;
   background: var(--bs-white);
}
.helpHelpBox > img {
   float: right;
}

.helpHelpBox > span {
   display: inline-block;
   font-size: 14px;
   margin-bottom: 10px;
   color: var(--bs-primary);
}
.helpHelpBox > h6 {
   font-size: 20px;
   margin-bottom: 50px;
}
.helpHelpBox > .help{
    color: var(--bs-primary);
}
.helpHelpBox > .chat{
    color: white;
}