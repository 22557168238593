.live_widget {
    width: 100%;
    padding: 0 20px 20px 20px;
}

.sidebar .widget.live .widget-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sidebar .widget.live .widget-title > div {
    display: flex;
    align-items: center;
}
.sidebar .widget.live .widget-title button svg {
    display: inline-block;
    height: 11px;
}
.sidebar .widget.live .widget-title button.prev, .sidebar .widget.live .widget-title button.next {
    background: transparent;
    border: 0;
    color: var(--bs-primary);
    padding: 0;
    display: flex;
    align-items: center;
}
.light-theme .sidebar .widget.live .widget-title button.next:hover,
.light-theme .sidebar .widget.live .widget-title button.prev:hover {
    color: var(--bs-gray-700);
}
.dark-theme .sidebar .widget.live .widget-title button.next:hover,
.dark-theme .sidebar .widget.live .widget-title button.prev:hover {
    color: var(--bs-gray-200);
}
.sidebar .widget.live .widget-title > div svg {
    stroke: var(--title_text_color);
}

button.post_action {
    border: 0;
}

.live_widget .content {
    line-height: 1.4;
    margin-top: 5px;
}
.audio_sec {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 32px;
}

.light-theme .audio_sec {
    background-color: var(--bs-gray-200);
}
.dark-theme .audio_sec {
    background: var(--bs-gray-500);
}

.live_widget .content {
    margin-bottom: 10px;
    margin-top: 10px;
}

.live_widget .content *:last-child {
    margin-bottom: 0;
}

.audio_sec .lines img {
    width: auto;
    height: 61px;
}

.audio_sec .play_btn {
    border: 0;
    color: #fff;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    line-height: 1;
    font-size: 12px;
    padding: 0;
    flex: 0 0 24px;
}

.audio_sec .time_item {
    font-size: 12px;
    font-weight: 500;
    width: 54px;
    white-space: nowrap;
    letter-spacing: -1px;
}

.lines_container {
    position: relative;
    width: 100%;
    height: 30px;
    display: inline-flex;
    margin: 0 10px;
    align-items: center;
    overflow: hidden;
}

.lines_container > div {
    position: relative;
    height: 12px;
    width: 5px;
    display: inline-block;
    animation: wave 1s infinite ease-in-out;
    -webkit-animation: wave 1s infinite ease-in-out;
    margin: 1px;
}

.light-theme .lines_container > div {
    background: var(--bs-gray-600);
}
.dark-theme .lines_container > div {
    background: var(--bs-gray-300);
}

.lines_container .rect-2 {
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
}

.lines_container .rect-3 {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}

.lines_container .rect-4 {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
}

.lines_container .rect-5 {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
}

.lines_container .rect-6 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

@keyframes wave {
    0%,
    100% {
        transform: scaleY(1);
    }

    16.67% {
        transform: scaleY(3);
    }

    33.33% {
        transform: scaleY(1);
    }

    50% {
        transform: scaleY(3);
    }

    66.67% {
        transform: scaleY(1);
    }

    83.34% {
        transform: scaleY(3);
    }
}









.user_sm_widget {
    display: flex;
}



.user_sm_widget > div {
    display: block;
}

.user_sm_widget > div > a {
    display: block;
    font-weight: 600;
}

.user_sm_widget > div span {
    line-height: 1;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}


.user_sm_widget > img {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.live_comment {
    border-top: 1px solid var(--border-color);
}
.live_comment ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    max-height: 170px;
    overflow: auto;
}
.live_comment ul::-webkit-scrollbar {
    width: 0;
}
.live_comment ul li {
    display: flex;
    margin-bottom: 7px;
}
.live_comment ul li > .comment {
    padding: 10px;
    border-radius: 6px;
    display: block;
    width: 100%;
    border: 1px solid var(--border-color);
}
.live_comment ul li > .comment .comment_action span {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    margin-right: 15px;
}
.light-theme .live_comment ul li > .comment .comment_action span {
    color: var(--bs-gray-700);
}
.dark-theme .live_comment ul li > .comment .comment_action span {
    color: var(--bs-gray-200);
    display: inline-flex;
    align-items: center;
}
.live_comment ul li > .comment .comment_action span svg {
    margin-right: 7px;
    display: inline-block;
}
.live_comment ul li > .comment .comment_action span:hover {
    color: var(--bs-primary);
}
p.write_post.blank:before {
    content: "Write somthing here...";
}
 
.pos_left_icon {
    position: absolute;
    top: 18px;
    right: 10px;
    stroke: var(--bs-gray-200);
    height: 20px;
    cursor: pointer;
}
 

.comment .text {
    margin-top: 6px;
    margin-bottom: 6px;
    line-height: 18px;
}
.live_comment ul li > .comment .comment_user {
    line-height: 1;
}
.live_comment ul li > .comment .comment_user a.nm {
    font-weight: 500;
}
.live_comment ul li > .comment .comment_action {
    margin-top: 12px;
}






.add_post_form .pos_left_icon + .write_post {
    padding-right: 35px;
    min-height: 60px;
}
.add_post_form .write_post {
    border: none;
    text-transform: capitalize;
    border-radius: 6px;
    width: 100%;
    min-height: 90px;
    padding: 15px;
    display: block;
    border: 1px solid transparent;
    margin-bottom: 0;
    outline: 0;
}
.light-theme .add_post_form .write_post {
    background: var(--bs-gray-200);
    color: var(--bs-gray-800);
}
.light-theme .add_post_form .write_post.blank:before {
    color: var(--bs-gray-800);
}
.dark-theme .add_post_form .write_post {
    background: var(--bs-gray-500);
    color: var(--bs-gray-200);
}
.add_post_form .reply_nm {
    position: absolute;
    background: var(--color30);
    padding: 2px 6px;
    bottom: 80%;
    left: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    visibility: hidden;
    transition: 0.3s;
}
.add_post_form {
    display: block;
    position: relative;
}