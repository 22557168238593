.checkboxBtn {
    display: inline-block;
    margin-bottom: 5px;
 }
 .checkboxBtn input[type="checkbox"].graphic {
    display: none;
 }
 .checkboxBtn input[type="checkbox"].graphic + label,
 .checkboxBtn input[type="checkbox"].graphic + label:after {
    padding: 6px 9px;
    display: inline-block;
    cursor: pointer;
 }
 .checkboxBtn input[type="checkbox"].graphic + label {
    position: relative;
    padding-left: 35px;
    background-color: rgb(227 227 227 / 40%);
    color: #444;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin: 1px;
    border-radius: 50px;
    padding-right: 15px;
 }
 .checkboxBtn input[type="checkbox"].graphic + label:after {
    position: absolute;
    top: 3px;
    left: 12px;
    font-size: 126%;
    padding: 0;
    opacity: 0.3;
 }
 .checkboxBtn input[type="checkbox"].graphic + label:hover:after {
    opacity: 1;
 }
 .checkboxBtn input[type="checkbox"].graphic + label:after,
 .checkboxBtn input[type="checkbox"].graphic + label:after {
    content: "\2714";
 }
 .checkboxBtn input[type="checkbox"].graphic:checked + label {
    background: mediumseagreen;
 }
 .checkboxBtn input[type="checkbox"].graphic:checked + label:after {
    content: "\2714";
    opacity: 1;
    color: #fff;
 }
 .checkboxBtn input[type="checkbox"].graphic:checked + label {
    color: #fff;
 }