.chat-section {
   overflow: hidden;
   height: 100vh;
}

.overlap {
   position: relative;
   margin-top: -70px;
   z-index: 2;
}

.overlap22 {
   position: relative;
   margin-top: -62px;
   z-index: 2;
}

.theme-layout {
   float: left;
   position: relative;
   width: 100%;
   /* height: 100vh;
    overflow: hidden; */
}

.theme-layout.boxed {
   background: none repeat scroll 0 0 #ffffff;
   float: none;
   margin: 0 auto;
   overflow: hidden;
   position: relative;
   z-index: 1;
   width: 1230px;
   -webkit-box-shadow: 0 0 6px #747474;
   -moz-box-shadow: 0 0 6px #747474;
   -ms-box-shadow: 0 0 6px #747474;
   -o-box-shadow: 0 0 6px #747474;
   box-shadow: 0 0 6px #747474;
}

.col-1-5 {
   width: 20%;
   max-width: 20%;
   flex: 0 0 20%;
   float: left;
   position: relative;
   min-height: 1px;
   padding-right: 15px;
   padding-left: 15px;
}

.container-fluid.ext-padding {
   padding-left: 130px;
   padding-right: 130px;
}

.container-fluid.no-padding {
   padding-left: 0;
   padding-right: 0;
}

section {
   position: relative;
   width: 100%;
}

.gap {
   padding: 60px 0;
   position: relative;
   width: 100%;
   background: var(--bs-gray-200);
}

.gap.no-gap {
   padding: 0;
}

.gap.no-top {
   padding-top: 0px;
}

.gap.no-bottom {
   padding-bottom: 0px;
}



.gap2.no-gap {
   padding-bottom: 0;
   padding-top: 0;
}

.gap2.no-bottom {
   padding-bottom: 0;
}

.gap2.no-top {
   padding-top: 0;
}

.gap100 {
   padding: 100px 0;
   position: relative;
   float: left;
   width: 100%;
}

.gap100.no-gap {
   padding-top: 0;
   padding-bottom: 0;
}

.gap100.no-top {
   padding-top: 0;
}

.gap100.no-bottom {
   padding-bottom: 0;
}

.ext-gap {
   padding: 100px 0;
   float: left;
   width: 100%;
   position: relative;
}

.pb-50 {
   padding-bottom: 50px !important;
}

.mt-50 {
   margin-top: 50px;
}

.remove-ext {
   margin-bottom: -30px;
}

.remove-ext-40 {
   margin-bottom: -40px;
}

.remove-ext-50 {
   margin-bottom: -50px;
}

.merged {
   margin: 0px;
   padding: 0;
}

.merged>div {
   padding: 0;
}

.row.merged>div {
   padding: 0;
}

.merged.row {
   margin: 0;
}

.merged.row>div {
   padding: 0;
}

.pad-ext-40 {
   padding-top: 40px;
}

.d-flex a,
.d-flex a:hover,
.d-flex a:focus,
.d-flex a:active {
   border: medium none !important;
}

.parallax-fixed {
   height: 100%;
   left: 0;
   margin: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -2;
}

.parallax {
   height: 100%;
   left: 0;
   margin: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 0;
}

.blackish:before,
.bluesh:before,
.purple:before,
.pattern:before,
.orange:before,
.mate-black:before,
.pinkish:before {
   background: #000;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 1;
}

.pinkish:before {
   background: #ff7c7c;
}

.pattern:before {
   background: #23d2e2 repeat scroll 0 0;
   opacity: 0.45;
}

.mate-black:before {
   background: #021727;
}

.bluesh:before {
   background: #23d2e2;
}

.purple:before {
   background: #9e72fa;
}

.orange:before {
   background: #fa6342;
}

.exthigh-opacity:before {
   -webkit-opacity: 0.98;
   -moz-opacity: 0.98;
   -ms-opacity: 0.98;
   -o-opacity: 0.98;
   opacity: 0.98;
}

.high-opacity:before {
   -webkit-opacity: 0.95;
   -moz-opacity: 0.95;
   -ms-opacity: 0.95;
   -o-opacity: 0.95;
   opacity: 0.95;
}

.medium-opacity:before {
   -webkit-opacity: 0.85;
   -moz-opacity: 0.85;
   -ms-opacity: 0.85;
   -o-opacity: 0.85;
   opacity: 0.85;
}

.ext-low-opacity::before {
   -webkit-opacity: 0.5;
   -moz-opacity: 0.5;
   -ms-opacity: 0.5;
   -o-opacity: 0.5;
   opacity: 0.5;
}

.bg-valvot {
   background-color: #7750f8 !important;
}

.bg-purple {
   background: #7750f8;
}

.bg-blue {
   background: #23d2e2;
}

.bg-red {
   background: var(--bs-primary);
}

.bg-yellow {
   background: #e5bc16;
}

.bg-green {
   background: #38bff1;
}

.like-dislike>li a.bg-purple {
   background: #7750f8;
}

.like-dislike>li a.bg-blue {
   background: #23d2e2;
}

.like-dislike>li a.bg-red {
   background: #e44a3c;
}

[type="submit"],
button,
html [type="button"] {
   cursor: pointer;
}

textarea {
   resize: none;
}

a:hover.facebook {
   background: #516eab;
}

a:hover.instagram {
   background: #444;
}

a:hover.twitter {
   background: #55acee;
}

a:hover.google {
   background: #dd4b39;
}

a:hover.pinterest {
   background: #ca212a;
}

a:hover.youtube {
   background: #e62117;
}

a:hover.dribble {
   background: #1572b8;
}

a:hover.linkedin {
   background: #0077b5;
}

a:hover.tumblr {
   background: #36465d;
}

a:hover.rss {
   background: #e3a103;
}

a:hover.vk {
   background: #466991;
}

.checked {
   color: orange;
}

a.facebook {
   background: #516eab;
}

a.twitter {
   background: #00a6d3;
}

a.vk {
   background: #466991;
}

a.dribble {
   background: #1572b8;
}

a.google {
   background: #dd4b39;
}

a.instagram {
   background: #444;
}

a.pinterest {
   background: #ca212a;
}

/*--- social colors ---*/
.facebook-color {
   color: #516eab;
}

.twitter-color {
   color: #00a6d3;
}

.vk-color {
   color: #466991;
}

.dribble-color {
   color: #1572b8;
}

.google-color {
   color: #dd4b39;
}

.instagram-color {
   color: #444;
}

.pinterest-color {
   color: #ca212a;
}

#nprogress .bar {
   background: #d1d1d1;
}

/*carousel nave style*/

ul.owl-carousel {
   padding-left: 0;
   list-style: none;
   float: left;
   width: 100%;
}

.owl-prev,
.owl-next {
   left: 0;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.owl-next {
   left: auto;
   right: 0;
}

.owl-prev,
.owl-next {
   color: transparent;
}

.owl-prev::before,
.owl-next::before {
   background: #fff;
   border-radius: 50%;
   color: #fa6342;
   content: "\f0d9";
   display: inline-block;
   font-family: fontawesome;
   font-size: 18px;
   left: -15px;
   line-height: 30px;
   position: absolute;
   text-align: center;
   top: 50%;
   transform: translateY(-50%);
   width: 30px;
   box-shadow: 0 3px 7px rgba(0, 0, 0, 0.5);
   transition: all 0.2s linear 0s;
}

.owl-next::before {
   content: "\f0da";
   left: auto;
   right: -15px;
}

.owl-prev:hover:before,
.owl-next:hover:before {
   color: #fff;
}

/*--- owl nav style ---*/
.owl-dot {
   border: 1px solid #fa6342;
   border-radius: 100%;
   display: inline-block;
   height: 10px;
   margin: 0 2px;
   position: relative;
   width: 10px;
}

.owl-dots {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.owl-dot.active::before {
   background: #fa6342;
   border-radius: 100%;
   content: "";
   height: 6px;
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 6px;
}

#html5-watermark {
   display: none !important;
}

iframe#html5boxiframevideo {
   height: 100%;
}

iframe {
   width: 100%;
}

.pdng0 {
   padding: 0;
}

#main,
#mainb,
#echart_mini_pie,
#echart_pie,
#echart_pie2,
#echart_donut,
#echart_line {
   height: 350px;
}

#echart_bar_horizontal,
#echart_world_map {
   height: 370px;
}

/*======================/
***** Home page 1 
======================*/

/*-- page loader ----*/

/*.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url(./images/loader.gif) center no-repeat #fff;
}*/

#topcontrol {
   border-radius: 100%;
   color: #fff;
   display: inline-block;
   font-size: 18px;
   line-height: 40px;
   text-align: center;
   transform: rotate(-15deg);
   transition: all 0.2s linear 0s;
   width: 40px;
   z-index: 99;
}

#topcontrol:hover {
   transform: rotate(0);
}

/*--- sticky header ---*/

header.stick {
   z-index: 99;
   position: absolute;
   top: 0;
   left: 0;
}

header.sticky {
   position: fixed;
}

/*--- topbar ---*/

.form-search {
   position: absolute;
   right: -15px;
   top: 42px;
   width: 300px;
   opacity: 0;
   visibility: hidden;
}

.searched.active .form-search {
   opacity: 1;
   visibility: visible;
}

.form-search input {
   border-style: none none solid;
   border-width: medium medium 1px;
   color: #999999;
   font-size: 15px;
   padding: 9px 18px;
   width: 100%;
}

.form-search button {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border: medium none;
   color: #999;
   position: absolute;
   right: 5px;
   top: 9px;
}

.top-area>ul>li>a>em {
   border-radius: 100%;
   color: #fff;
   font-size: 10px;
   height: 17px;
   line-height: 17px;
   position: absolute;
   right: 0;
   text-align: center;
   top: 15px;
   width: 17px;
   font-style: normal;
   background-color: var(--bs-primary) !important;
   color: #ffffff !important;
   font-weight: 600;
}

/*--- timeline area ---*/

.feature-photo .container-fluid {
   padding: 0 43px;
}

.feature-photo {
   display: block;
   position: relative;
   width: 100%;
}

.feature-photo>figure {
   margin-bottom: 0;
}

.feature-photo>figure img {
   display: inline-block;
   width: 100%;
}

.user-avatar {
   border: 8px solid rgba(255, 255, 255, 0.8);
   border-radius: 3px 25px 0;
   box-shadow: 0 1px 0 #e1e8ed;
   float: right;
   margin-top: -168px;
   overflow: hidden;
   position: relative;
   width: 100%;
}

.user-avatar>figure>img {
   width: 100%;
}

.user-avatar>figure {
   margin-bottom: 0;
   position: relative;
}

.timeline-info {
   background: #fff none repeat scroll 0 0;
   display: block;
   line-height: 59px;
   width: 100%;
}

.timeline-info>ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 20px;
   vertical-align: middle;
   width: 100%;
}

.timeline-info>ul li {
   display: inline-block;
   margin-right: 50px;
   position: relative;
}

.timeline-info>ul li a {
   display: inline-block;
   font-size: 14px;
   margin-right: 30px;
   position: relative;
   line-height: initial;
   text-transform: capitalize;
   padding-bottom: 3px;
   font-weight: 400;
}

.timeline-info>ul li a:last-child {
   margin-right: 0;
}

.timeline-info .admin-name>span {
   bottom: 14px;
   color: #3a3a3a;
   font-size: 10px;
   line-height: 5px;
   position: absolute;
   right: 0;
}

.timeline-info .admin-name>h5 {
   color: #23d2e2;
   display: inline-block;
   font-size: 24px;
   font-weight: 500;
   margin-bottom: 0;
   vertical-align: super;
}

.timeline-info>ul li a::before {
   bottom: 0;
   content: "";
   height: 1px;
   left: 0;
   opacity: 0;
   position: absolute;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 0;
}

.timeline-info>ul li a::after {
   border-bottom: 8px solid;
   border-left: 8px solid transparent;
   border-right: 8px solid transparent;
   bottom: -12px;
   content: "";
   left: 50%;
   position: absolute;
   transform: translate(-50%);
   display: none;
}

.timeline-info>ul li a.active::after {
   display: block;
}

.timeline-info>ul li a:hover::before {
   opacity: 1;
   visibility: visible;
   width: 100%;
}

.attach-file {
   display: inline-block;
   vertical-align: middle;
   width: auto;
   margin-right: 10px;
}

.attach-file .fileContainer {
   background: #d2252d;
   border-radius: 100%;
   color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   height: 30px;
   line-height: 30px;
   margin: 0;
   position: relative;
   text-align: center;
   text-transform: capitalize;
   width: 30px;
}

.attach-file .dropdown-toggle::after {
   content: none;
}

.option.dropdown .dropdown-toggle::after {
   content: none;
}

/* .option > .dropdown-toggle {
    cursor: pointer;
    display: inline-block;
    position: relative;
}

.option > .dropdown-toggle::before {
    background: #eee none repeat scroll 0 0;
    border-radius: 100%;
    content: "";
    height: 33px;
    left: 50%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.2s linear 0s;
    width: 33px;
    z-index: 1;
}
.option > .dropdown-toggle > i {
    position: relative;
    z-index: 2;
    font-size: 12px;
} */

.btn-red {
   background: #d2252d;
   color: #fff;
   border-radius: 50px;
}

.btn-red:hover {
   background: #d2252d;
   color: #fff;
}

.attach-file .dropdown-item:focus {
   background: #d2252d;
   color: #fff;
}

.attach-file .dropdown-item:focus i {
   color: #fff;
}

.attach-file .dropdown-item i {
   font-size: 17px;
   color: #d2252d;
   margin-right: 5px;
   width: 22px;
}

.attach-file .dropdown-item {
   font-size: 15px;
   color: #525252;
   padding: 5px 10px;
}

.attach-file .dropdown-item label {
   margin-bottom: 0;
}

.attach-file .dropdown-menu {
   width: 100%;
   border: 0;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
   padding: 8px;
}

.dropdown-menu {
   bottom: 100%;
   width: 180px;
   border-radius: 8px;
}

.uploadimage {
   display: inline-block;
   margin-right: 5px;
   vertical-align: middle;
}

.uploadimage .fileContainer [type="file"] {
   left: -22px;
   top: -10px;
   width: 20px;
}

.uploadimage>i {
   font-size: 16px;
   position: relative;
   top: 1px;
}

.user-avatar .edit-phto {
   border-radius: 0;
   bottom: -40px;
   left: 0;
   opacity: 0;
   visibility: hidden;
   width: 100%;
   transition: all 0.3s linear 0s;
   padding: 5px;
   text-align: center;
}

.user-avatar:hover .edit-phto {
   bottom: 0;
   opacity: 1;
   visibility: visible;
}

/*--- dropdowns ---*/
.setting-area .dropdowns {
   overflow: hidden !important;
}

.dropdowns .custom_scroll {
   height: calc(340px - 30px - 35px);
   position: relative;
}

.ps-container {
   position: relative;
}

/* .setting-area .drops-menu{
    background: scroll;
    overflow-y: auto;
    height: 300px;
    padding-bottom: 35px;
} */

.dropdowns {
   background: #fff;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   line-height: initial;
   list-style: outside none none;
   max-height: 340px;
   padding-left: 0;
   position: absolute;
   right: -50px;
   text-align: left;
   top: 100%;

   width: 350px;
   transform-origin: 0 0;
   transform: scaleY(0);
   transition: opacity 0.4s cubic-bezier(0.6, -0, 0.255, 1.3),
      transform 0.4s cubic-bezier(0.6, -0, 0.255, 0.9);
}

.dropdowns.active {
   transform: scaleY(1);
}

.drops-menu {
   list-style: outside none none;
   padding-left: 0;
}

.drops-menu>li>a,
.drops-menu>li>div {
   border-bottom: 1px solid #e1e8ed;
   display: inline-block;
   padding: 10px;
   width: 100%;
}

.drops-menu>li {
   display: inline-block;
   position: relative;
   width: 100%;
}

.drops-menu>li>.tag {
   color: #fff;
   display: inline-block;
   font-size: 11px;
   padding: 0 6px;
   position: absolute;
   right: 0;
   top: 0;
}

.drops-menu>li:nth-child(2n)>a,
.drops-menu>li:nth-child(2n)>div {
   background: whitesmoke none repeat scroll 0 0;
}

.drops-menu>li a figure,
.drops-menu>li>div figure {
   display: inline-block;
   vertical-align: top;
   margin: 0;
   position: relative;
}

.drops-menu>li a figure img,
.drops-menu>li>div figure img {
   border-radius: 100%;
}

.dropdowns.helps {
   width: 250px;
}

.mesg-meta {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: calc(100% - 40px);
}

.mesg-meta>h6 {
   font-size: 13px;
   font-weight: 500;
   margin-bottom: 4px;
   text-transform: capitalize;
   color: #515365;
}

.mesg-meta>span {
   display: inline-block;
   font-size: 12px;
   line-height: 15px;
   max-width: 70%;
   overflow-x: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

.mesg-meta>span>b {
   font-weight: 500;
}

.mesg-meta>span>i {
   color: blue;
   font-size: 9px;
   margin-right: 5px;
}

.mesg-meta>i {
   float: right;
   font-size: 11px;
   font-style: normal;
   color: #b1b1b1;
}

.drops-menu>li>a:hover {
   background: #fafafa none repeat scroll 0 0;
}

.mesg-meta>img {
   display: block;
}

.mesg-meta figure span {
   display: inline-block;
   font-size: 11px;
   vertical-align: middle;
   width: 82%;
}

.drops-menu .mesg-meta>figure>img {
   border-radius: 0;
   display: inline-block;
   max-width: 40px;
   width: 40px;
}

.dropdowns>span {
   color: #515365;
   border-bottom: 1px solid #e6ecf5;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   padding: 7px 15px;
   text-align: left;
   width: 100%;
   text-transform: capitalize;
}

.dropdowns>span a {
   color: #48b9ff;
   float: right;
   font-weight: normal;
   line-height: 15px;
   transition: all 0.2s linear 0s;
   font-size: 12px;
}

.dropdowns>span a:hover {
   text-decoration: underline;
}

.dropdowns>a.more-mesg {
   display: inline-block;
   font-size: 14px;
   padding-bottom: 5px;
   text-align: center;
   text-transform: capitalize;
   width: 100%;
}

/*help dropdown ---*/
.dropdowns>form {
   display: inline-block;
   padding: 7px 15px;
   width: 100%;
}

.dropdowns>form input {
   background: #eaeaea none repeat scroll 0 0;
   border: 1px solid #eaeaea;
   border-radius: 5px;
   font-size: 13px;
   padding: 7px 10px;
   width: 100%;
}

.help-drop {
   display: inline-block;
   list-style: outside none none;
   margin: 10px 0;
   padding: 0 15px;
   width: 100%;
}

.help-drop>li {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 10px;
   width: 100%;
}

.help-drop>li a:hover {
   color: #000;
}

.help-drop>li>a i {
   color: #787878;
   margin-right: 10px;
}

.blue {
   background: #337ab7;
}

.red {
   background: #ed6b75;
}

.green {
   background: #33b7a0;
}

.dropdowns.active>a {
   background: #fafafa none repeat scroll 0 0;
   display: block;
   font-size: 13px;
   margin-bottom: 2px;
   padding: 10px;
   text-align: center;
   /* position: absolute;
    bottom: -2px; */
}

.dropdowns.active>a i {
   font-size: 11px;
   left: 8px;
   position: absolute;
   top: 10px;
}

.dropdowns.languages {
   padding: 6px 25px;
   width: 800px;
}

.dropdowns.active>a:hover {
   background: #f1f1f1 none repeat scroll 0 0;
}

.row[data-gutter="10"]>[class^="col-"] {
   padding-left: 5px;
   padding-right: 5px;
}

.dropdown-meganav-select-list-lang {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.dropdown-meganav-select-list-lang li {
   font-size: 13px;
}

.dropdown-meganav-select-list-lang>li>a {
   color: #7a7a7a;
   display: block;
   padding: 7px 5px;
   text-decoration: none !important;
   transition: all 0.2s linear 0s;
}

.dropdown-meganav-select-list-lang>li>a:hover {
   background: #e6e6e6 none repeat scroll 0 0;
   border-radius: 30px;
   color: #505050;
   padding-left: 10px;
}

.dropdown-meganav-select-list-lang>li>a>img {
   height: 17px;
   margin-right: 7px;
}

.add-btn {
   bottom: 100px;
   position: absolute;
   right: 43px;
}

.add-btn>span {
   color: #fff;
   margin-right: 20px;
   text-shadow: 0 2px 0 #4a4a4a;
}

.add-btn>a:hover {
   background: transparent none repeat scroll 0 0;
   border: 1px solid;
}

.add-btn>a {
   border-radius: 2px;
   color: #fff;
   display: inline-block;
   font-size: 14px;
   padding: 4px 20px;
   border: 1px solid transparent;
}

/*--- sidebar widgets ---*/

/*.sidebar {
    display: table;
    float: none;
    margin: 0 auto;
    padding-bottom: 20px;
    width: 100%;
}*/

.forum-static>li>span {
   color: #535165;
   font-size: 12px;
   vertical-align: text-top;
}

.central-meta .forum-list>table {
   margin-bottom: 30px;
}

.widget ul.recent-photos li a i {
   overflow: unset;
}

/* LIVE AUDIO */

/*--- badges widget ---*/

.sidebar .badgez-widget>li {
   margin-bottom: 7px;
   margin-right: 3px;
   width: 38.4px;
}

.sidebar .badgez-widget>li:nth-child(5) {
   margin-right: 0;
}

/*--- central posts meta box ---*/

.about {
   padding: 0 15px;
}

.central-meta.padding30 {
   padding: 30px;
}

.tab-content .button {
   border: medium none;
   border-radius: 30px;
   color: #fff;
   background: #dc3545;
   float: right;
   font-size: 13px;
   font-weight: 500;
   padding: 6px 24px;
   transition: all 0.2s linear 0s;
   text-decoration: none;
}

.new-postbox .post-btn {
   background: #23d2e2 none repeat scroll 0 0;
   border: medium none;
   border-radius: 5px;
   color: #fff;
   font-weight: 500;
   margin-top: 11px;
   padding: 5px;
   width: 100%;
   transition: all 0.2s linear 0s;
}

.friend-info {
   display: inline-block;
   position: relative;
   width: 100%;
}

.friend-info>figure {
   display: inline-block;
   margin-bottom: 0;
   margin-top: 0;
   position: relative;
   vertical-align: middle;
   width: 40px;
}

.friend-info>figure>i {
   border: 2px solid #636175;
   border-radius: 100%;
   color: #636175;
   display: inline-block;
   font-size: 20px;
   height: 40px;
   line-height: 38px;
   text-align: center;
   width: 40px;
}

.friend-name {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: 91.2%;
}

.friend-name .more {
   float: right;
}

.friend-name .more .more-post-optns .ti-more-alt {
   width: 30px;
   height: 30px;
   text-align: center;
   line-height: 30px;
   display: inline-block;
}

.friend-name>em {
   color: #aaa;
   display: inline-block;
   font-size: 12px;
   font-style: normal;
}

.friend-name>em.verify {
   color: mediumseagreen;
}

.friend-name>ins {
   display: inline-block;
   width: 70%;
   /* font-size: 12px; */
   text-decoration: none;
}

.friend-name>ins>a {
   font-size: 13.5px;
   font-weight: 500;
}

.user-post {
   border-bottom: 1px dashed #ccc;
   display: inline-block;
   margin-bottom: 20px;
   padding-bottom: 20px;
   width: 100%;
}

.central-meta .user-post:last-child {
   border: 0 none;
   margin-bottom: 0;
   padding: 0;
}

/* .more-post-optns:last-child > ul {
    background: #fff none repeat scroll 0 0;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
    list-style: outside none none;
    margin: 0;
    opacity: 0;
    padding: 15px;
    position: absolute;
    right: -100px;
    bottom: -220%;
    transform: scale(0);
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 175px;
    z-index: 9;
} */

.friend-name>span {
   color: #999;
   float: left;
   font-size: 12px;
   text-transform: capitalize;
   width: 70%;
}

.like-dislike {
   left: 50%;
   list-style: outside none none;
   margin-bottom: 0px;
   padding: 0;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   width: auto;
   z-index: 88;
}

.like-dislike>li {
   display: inline-block;
   opacity: 0;
   visibility: hidden;
   margin: 0 3px;
   transform: scale(0);
   transition: all 0.2s linear 0s;
}

.like-dislike>li:nth-child(1) {
   transition: all 0.2s linear 0.1s;
}

.like-dislike>li:nth-child(2) {
   transition: all 0.2s linear 0.2s;
}

.like-dislike>li:nth-child(3) {
   transition: all 0.2s linear 0.3s;
}

.post-meta:hover .like-dislike>li {
   visibility: visible;
   opacity: 1;
   transform: scale(1);
}

.like-dislike>li a {
   background: #989dbd none repeat scroll 0 0;
   border-radius: 100%;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
   color: #fff;
   display: inline-block;
   font-size: 20px;
   height: 34px;
   line-height: 34px;
   text-align: center;
   transition: all 0.2s linear 0s;
   width: 34px;
}

.like-dislike>li a:hover {
   background: #fff none repeat scroll 0 0;
   transform: scale(1.1);
}

.post-meta {
   float: left;
   margin-top: 15px;
   width: 100%;
   position: relative;
}

.post-meta>figure,
.post-map {
   display: inline-block;
   margin: 0;
   position: relative;
   width: 100%;
}

.post-meta>figure .like-dislike,
.post-map .like-dislike {
   bottom: -12px;
   top: auto;
   transform: translate(-50%, 0px);
}

.post-meta>figure img {
   float: left;
   width: 100%;
}

.post-meta .description:first-child {
   margin-top: 10px;
}

.description {
   float: left;
   margin-top: 30px;
   width: 100%;
   position: relative;
}

.description>h2 {
   color: #515365;
   font-weight: 400;
   font-size: 23px;
   margin-bottom: 20px;
}

.description p a {
   font-size: 13px;
}

.description p a:hover {
   text-decoration: underline;
}

.blog-post .friend-info>figure {
   width: 11%;
}

.blog-post .friend-name {
   width: 87.7%;
}

.blog-post .we-video-info>ul {
   width: 100%;
}

.vdeo-link {
   display: inline-block;
   position: relative;
   width: 100%;
}

.vdeo-link>h2 {
   color: #fff;
   font-size: 20px;
   font-weight: 500;
   left: 0;
   margin: 0;
   position: absolute;
   text-align: center;
   top: 30px;
   width: 100%;
   z-index: 2;
}

a.learnmore {
   border-radius: 30px;
   color: #fff;
   display: inline-block;
   font-size: 12px;
   line-height: initial;
   padding: 6px 10px;
   position: absolute;
   right: 20px;
   top: -50px;
   transition: all 0.2s linear 0s;
}

a.learnmore:hover {
   background: #989dbd;
}

.description>p {
   margin-bottom: 20px;
}

.description>span {
   color: #2a2a2a;
   font-size: 18px;
   font-weight: 500;
   line-height: 28px;
   display: inline-block;
   margin-bottom: 5px;
}

/*-- sharing post area ----*/

.we-video-info {
   border-top: 1px solid #ede9e9;
   float: left;
   padding: 8px 0 0;
   position: relative;
   width: 100%;
   z-index: 0;
}

.we-video-info>ul {
   float: left;
   line-height: 27px;
   margin-bottom: 0;
   margin-top: 13px;
   padding-left: 0;
   width: 60%;
}

.social-media {
   vertical-align: sub;
}

.we-video-info>ul li {
   display: inline-block;
   font-size: 13px;
   margin-right: 32px;
   vertical-align: middle;
}

.we-video-info>ul li span {
   font-size: 16px;
   font-weight: 400;
   position: relative;
   color: #b3b1c5;
}

.we-video-info>ul li span.comment {
   cursor: pointer;
}

.we-video-info>ul li span:hover i {
   animation: 0.6s linear 0s normal none 1 running pulse;
}

.we-video-info>.users-thumb-list {
   float: right;
   text-align: center;
   width: 40%;
}

.we-video-info>ul li:last-child {
   margin-right: 0;
}

.we-video-info>.users-thumb-list>span {
   display: block;
   font-size: 11px;
}

.we-video-info>.users-thumb-list>span b {
   color: #222;
   font-weight: 500;
}

.we-video-info>ul li.upload-date {
   float: right;
   font-size: 12px;
   font-weight: 500;
   text-transform: uppercase;
}

.upload-date>i {
   font-size: 13px;
   font-style: normal;
   font-weight: normal;
   margin-left: 5px;
   text-transform: capitalize;
}

.we-video-info>ul li span.dislike {
   cursor: pointer;
   transition: all 0.2s linear 0s;
}

.we-video-info>ul li span ins {
   font-size: 11px;
   left: 20px;
   position: absolute;
   text-decoration: none;
   top: -10px;
}

.we-video-info>ul li span.like {
   cursor: pointer;
   transition: all 0.2s linear 0s;
}

.we-video-info>ul li span.like:hover .we-video-info>ul li span.dislike:hover {
   transform: scale(1.1);
}

.we-video-info>ul li span i {
   font-size: 17px;
}

.we-video-info ul li .heart {
   display: inline-block;
   font-size: 20px;
   position: relative;
   vertical-align: sub;
}

.we-video-info ul li .heart>span {
   color: inherit;
   font-size: 11px;
   left: 20px;
   position: absolute;
   top: -10px;
}

.rate-n-apply {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.job-price {
   display: inline-block;
   margin-top: 5px;
   vertical-align: middle;
}

.rate-n-apply .main-btn {
   float: right;
   padding: 6px 15px;
}

.job-price>span {
   color: #535165;
   font-size: 13px;
   font-weight: 500;
}

.job-price>ins {
   font-weight: 500;
   text-decoration: none;
}

/*--- social icons opening hovers style ---*/

.absolute-center,
.menu,
.menu .btn .fa,
.menu .btn.trigger .line {
   color: #fff;
   line-height: 25px;
   position: relative;
   right: 0;
   text-align: center;
   top: 0;
}

.menu {
   width: 2em;
   height: 2em;
   z-index: 2;
}

.menu .btn {
   position: absolute;
   top: 0;
   left: 0;
   width: 30px;
   line-height: 29px;
   height: 30px;
   border-radius: 50%;
   background: #adadfd;
   opacity: 0;
   z-index: -10;
   cursor: pointer;
   padding: 0;
   border: none;
   font-size: 13px;
   -webkit-transition: opacity 1s, z-index 0.3s, -webkit-transform 1s;
   transition: opacity 1s, z-index 0.3s, transform 1s;
   -webkit-transform: translateX(0);
   -ms-transform: translateX(0);
   transform: translateX(0);
}

.menu .btn .fa {
   font-size: 0.9em;
   -webkit-transition: color 0.3s;
   transition: color 0.3s;
}

.menu .btn.trigger {
   opacity: 1;
   z-index: 100;
   cursor: pointer;
   -webkit-transition: -webkit-transform 0.3s;
   transition: transform 0.3s;
   width: 25px;
   height: 25px;
   line-height: 24px;
}

.menu .btn.trigger:hover {
   -webkit-transform: scale(1.2);
   -ms-transform: scale(1.2);
   transform: scale(1.2);
}

.menu .btn.trigger:hover .line {
   background-color: rgba(255, 255, 255, 0.7);
}

.menu .btn.trigger:hover .line:before,
.menu .btn.trigger:hover .line:after {
   background-color: rgba(255, 255, 255, 0.7);
}

.menu .btn.trigger .line {
   width: 60%;
   height: 6px;
   background: #000;
   border-radius: 6px;
   -webkit-transition: background-color 0.3s, height 0.3s, top 0.3s;
   transition: background-color 0.3s, height 0.3s, top 0.3s;
}

.menu .btn.trigger .line:before,
.menu .btn.trigger .line:after {
   content: "";
   display: block;
   position: absolute;
   left: 0;
   width: 100%;
   height: 6px;
   background: #000;
   border-radius: 6px;
   -webkit-transition: background-color 0.3s, -webkit-transform 0.3s;
   transition: background-color 0.3s, transform 0.3s;
}

.menu .btn.trigger .line:before {
   top: -12px;
   -webkit-transform-origin: 15% 100%;
   -ms-transform-origin: 15% 100%;
   transform-origin: 15% 100%;
}

.menu .btn.trigger .line:after {
   top: 12px;
   -webkit-transform-origin: 25% 30%;
   -ms-transform-origin: 25% 30%;
   transform-origin: 25% 30%;
}

.menu .rotater {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   -webkit-transform-origin: 50% 50%;
   -ms-transform-origin: 50% 50%;
   transform-origin: 50% 50%;
}

.menu.active .btn-icon {
   opacity: 1;
   z-index: 50;
   transition: all 0.2s linear 0s;
}

.menu.active .trigger .line {
   height: 0px;
   top: 45%;
}

.menu.active .trigger .line:before {
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
   width: 110%;
}

.menu.active .trigger .line:after {
   -webkit-transform: rotate(-45deg);
   -ms-transform: rotate(-45deg);
   transform: rotate(-45deg);
   width: 110%;
}

/* horrible things are happening here
for some reason nth-child(1) is always busy and elements starting from 2 */

.rotater:nth-child(1) {
   -webkit-transform: rotate(-22.5deg);
   -ms-transform: rotate(-22.5deg);
   transform: rotate(-22.5deg);
}

.menu.active .rotater:nth-child(1) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(22.5deg);
   -ms-transform: translateX(-4em) rotate(22.5deg);
   transform: translateX(-4em) rotate(22.5deg);
}

.rotater:nth-child(2) {
   -webkit-transform: rotate(22.5deg);
   -ms-transform: rotate(22.5deg);
   transform: rotate(22.5deg);
}

.menu.active .rotater:nth-child(2) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-22.5deg);
   -ms-transform: translateX(-4em) rotate(-22.5deg);
   transform: translateX(-4em) rotate(-22.5deg);
}

.rotater:nth-child(3) {
   -webkit-transform: rotate(67.5deg);
   -ms-transform: rotate(67.5deg);
   transform: rotate(67.5deg);
}

.menu.active .rotater:nth-child(3) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-67.5deg);
   -ms-transform: translateX(-4em) rotate(-67.5deg);
   transform: translateX(-4em) rotate(-67.5deg);
}

.rotater:nth-child(4) {
   -webkit-transform: rotate(112.5deg);
   -ms-transform: rotate(112.5deg);
   transform: rotate(112.5deg);
}

.menu.active .rotater:nth-child(4) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-112.5deg);
   -ms-transform: translateX(-4em) rotate(-112.5deg);
   transform: translateX(-4em) rotate(-112.5deg);
}

.rotater:nth-child(5) {
   -webkit-transform: rotate(157.5deg);
   -ms-transform: rotate(157.5deg);
   transform: rotate(157.5deg);
}

.menu.active .rotater:nth-child(5) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-157.5deg);
   -ms-transform: translateX(-4em) rotate(-157.5deg);
   transform: translateX(-4em) rotate(-157.5deg);
}

.rotater:nth-child(6) {
   -webkit-transform: rotate(202.5deg);
   -ms-transform: rotate(202.5deg);
   transform: rotate(202.5deg);
}

.menu.active .rotater:nth-child(6) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-202.5deg);
   -ms-transform: translateX(-4em) rotate(-202.5deg);
   transform: translateX(-4em) rotate(-202.5deg);
}

.rotater:nth-child(7) {
   -webkit-transform: rotate(247.5deg);
   -ms-transform: rotate(247.5deg);
   transform: rotate(247.5deg);
}

.menu.active .rotater:nth-child(7) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-247.5deg);
   -ms-transform: translateX(-4em) rotate(-247.5deg);
   transform: translateX(-4em) rotate(-247.5deg);
}

.rotater:nth-child(8) {
   -webkit-transform: rotate(292.5deg);
   -ms-transform: rotate(292.5deg);
   transform: rotate(292.5deg);
}

.menu.active .rotater:nth-child(8) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-292.5deg);
   -ms-transform: translateX(-4em) rotate(-292.5deg);
   transform: translateX(-4em) rotate(-292.5deg);
}

.rotater:nth-child(9) {
   -webkit-transform: rotate(337.5deg);
   -ms-transform: rotate(337.5deg);
   transform: rotate(337.5deg);
}

.menu.active .rotater:nth-child(9) .btn-icon {
   -webkit-transform: translateX(-4em) rotate(-337.5deg);
   -ms-transform: translateX(-4em) rotate(-337.5deg);
   transform: translateX(-4em) rotate(-337.5deg);
}

/*--- social media style end ---*/

/*--- comment area ---*/

.coment-area {
   margin-top: 20px;
   width: 100%;
   display: none;
   float: left;
}

.we-comet {
   float: left;
   width: 100%;
   padding-left: 0;
   list-style: none;
   margin-bottom: 0;
}

.we-comet>li {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.we-comet>li:last-child {
   margin-bottom: 0;
}

.comet-avatar {
   display: inline-block;
   max-width: 36px;
   vertical-align: top;
   width: 36px;
}

.we-comment {
   border-bottom: 1px solid #ede9e9;
   display: inline-block;
   padding-bottom: 8px;
   padding-left: 8px;
   position: relative;
   vertical-align: top;
   width: 92%;
}

.inline-itms>a i {
   margin-right: 3px;
}

.inline-itms>a span {
   font-size: 11px;
}

.inline-itms {
   display: inline-block;
   margin-top: 5px;
   width: 100%;
}

/*.we-comment::before,
.peoples-mesg-box > ul > li p:before {
    border-bottom: 10px solid transparent;
    border-right: 11px solid #ede9e9;
    border-top: 10px solid transparent;
    left: -11px;
    top: 10px;
    position: absolute;
    content: '';
}

.we-comment::after,
.peoples-mesg-box > ul > li p:after {
    border-bottom: 8px solid transparent;
    border-right: 9px solid #fff;
    border-top: 8px solid transparent;
    left: -9px;
    top: 12px;
    position: absolute;
    content: '';
}*/

.we-comet>li ul {
   border-left: 2px solid #23d2e2;
   list-style: outside none none;
   margin-left: 20px;
   margin-top: 20px;
   padding-left: 15px;
}

.we-comet>li ul li {
   margin-bottom: 20px;
}

.we-comet li a.showmore {
   display: table;
   font-size: 12px;
   margin: 0 auto;
   text-transform: capitalize;
   font-weight: 500;
}

.we-comet>li ul li:last-child {
   margin-bottom: 0;
}

.coment-head {
   display: inline-block;
   width: 100%;
}

.we-comment>h5 {
   color: #515365;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0;
   margin-right: 8px;
   width: auto;
   text-transform: capitalize;
}

.we-comment>p {
   display: inline;
   font-size: 13.4px;
   line-height: 20px;
   margin-bottom: 0;
   margin-top: 0;
}

.inline-itms>a,
.inline-itms>span {
   display: inline-block;
   font-size: 12px;
   margin-right: 10px;
   text-transform: capitalize;
}

.coment-head>span {
   color: #999;
   font-size: 12px;
   padding-left: 10px;
   display: inline-block;
}

li.post-comment .comet-avatar {
   display: inline-block;
   max-width: 36px;
   vertical-align: top;
   width: 36px;
}

.post-comt-box {
   display: inline-block;
   padding-left: 10px;
   position: relative;
   width: calc(100% - 40px);
}

.post-comt-box form textarea {
   background: #edf2f6 none repeat scroll 0 0;
   border-color: transparent;
   border-radius: 5px;
   color: inherit;
   font-size: 13px;
   height: 80px;
   line-height: 16px;
}

.post-comt-box form textarea:focus {
   border-color: rgba(0, 0, 0, 0.1);
}

.add-smiles {
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translateY(-50%);
   width: auto;
   line-height: 10px;
}

.add-smiles>span {
   font-size: 12px;
   cursor: pointer;
}

.smiles-bunch {
   background: #fff none repeat scroll 0 0;
   border-radius: 5px;
   top: -63px;
   font-size: 13px;
   padding: 5px;
   position: absolute;
   right: -8px;
   text-align: center;
   width: 150px;
   display: none;
   box-shadow: 0px 3px 7px #ccc;
}

.smiles-bunch.active {
   display: block;
}

.smiles-bunch>i {
   cursor: pointer;
   display: inline-block;
   margin-bottom: 4px;
}

.smiles-bunch::before {
   border-top: 7px solid #fff;
   border-left: 7px solid transparent;
   border-right: 7px solid transparent;
   content: "";
   position: absolute;
   right: 11px;
   bottom: -7px;
   width: auto;
}

.smiles-bunch>i:hover {
   transform: scale(1.1);
}

.post-comt-box form button {
   bottom: 2px;
   position: absolute;
   right: 0;
   background: none;
}

/*--- search friend widget ---*/

#searchDir .filterinput {
   border: 1px solid #eaeaea;
   color: #575757;
   font-size: 14px;
   padding: 5px 10px;
   width: 100%;
}

#searchDir {
   padding: 0 20px;
}

#people-list {
   margin-top: 20px;
   max-height: 450px;
   position: relative;
}

#people-list figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: 45px;
   position: relative;
}

span.status {
   background: #bebebe none repeat scroll 0 0;
   border-radius: 50%;
   bottom: 0;
   display: inline-block;
   height: 10px;
   padding: 2px;
   position: absolute;
   right: 0;
   width: 10px;
}

span.status::after {
   background: white none repeat scroll 0 0;
   border-radius: 100%;
   content: "";
   height: 2px;
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 6px;
}

span.status.f-online {
   background: #7fba00;
}

span.status.f-away {
   background: #ffd300;
}

span.status.f-off {
   background: #a9a9a9;
}

.friendz-meta {
   display: inline-block;
   line-height: 17px;
   padding-left: 10px;
   vertical-align: middle;
   width: 74%;
}

.friendz-meta>i {
   color: #999;
   display: inline-block;
   font-size: 13px;
   width: 100%;
}

.friendz-meta>a {
   display: inline-block;
   font-size: 14px;
   text-transform: capitalize;
   width: 100%;
}

/*--- footer ---*/

footer {
   background: #fff;
   float: left;
   padding: 70px 0;
   width: 100%;
   position: relative;
}

footer::before {
   background: rgba(0, 0, 0, 0) linear-gradient(to right, #ffffff 0%, #fa6342 50%, #ffffff 100%) repeat scroll 0 0;
   content: "";
   height: 5px;
   position: absolute;
   width: 100%;
   top: 0;
   left: 0;
}

.location {
   list-style: outside none none;
   padding-left: 0;
   margin: 0;
}

.location>li {
   display: block;
   margin-bottom: 15px;
}

.location>li:last-child {
   margin-bottom: 0;
}

.ti-map-alt {
   display: inline-block;
   margin-right: 10px;
   vertical-align: sub;
   width: 20px;
}

.location>li i {
   margin-right: 10px;
}

.location>li p {
   display: inline-block;
   font-size: 14px;
   vertical-align: top;
   width: 88%;
   margin-bottom: 0;
   color: #757a95;
}

.foot-logo>p {
   color: #757a95;
}

.widget>a {
   display: inline-block;
   margin-bottom: 32px;
}

footer .widget-title h4 {
   color: #535165;
   font-size: 15px;
   font-weight: 500;
   text-transform: uppercase;
   position: relative;
}

footer .widget-title h4::before {
   bottom: -6px;
   content: "";
   height: 2px;
   left: 0;
   position: absolute;
   width: 20%;
}

footer .widget-title {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   margin-bottom: 20px;
}

.widget .list-style {
   list-style: outside none none;
   padding-left: 0;
   margin-bottom: 0;
}

.list-style>li {
   display: inline-block;
   font-size: 14px;
   margin-bottom: 12px;
   text-transform: capitalize;
   width: 100%;
}

.list-style>li:last-child {
   margin-bottom: 0;
}

.list-style>li>i {
   margin-right: 15px;
}

.list-style>li a {
   position: relative;
}


.foot-logo {
   display: inline-block;
   width: 100%;
}

footer .logo {
   margin-bottom: 15px;
   width: 100%;
}



/*--- apps widget ---*/

/*--- bottombar ---*/

.bottombar {
   background: #e6ebef;
   float: left;
   padding: 20px 0;
   width: 100%;
}

.copyright {
   /* float: left; */
   font-size: 13px;
   font-weight: 300;
}

.bottombar i {
   float: right;
}

.seting-title {
   border-bottom: 1px solid #e6ecf5;
   border-top: 1px solid #e6ecf5;
   color: #515365;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   line-height: initial;
   padding: 6px 15px;
   text-transform: capitalize;
   width: 100%;
}

.seting-title>a {
   color: #48b9ff;
   float: right;
   font-weight: normal;
   transition: all 0.2s linear 0s;
   line-height: 15px;
}

.seting-title>a:hover {
   text-decoration: underline;
}

/*---- setting panel side ---*/

.toggle-setting {
   border-bottom: 1px dotted #333333;
   float: left;
   padding: 15px 0;
   width: 100%;
}

.toggle-setting>span {
   color: #4f4f4f;
   float: left;
   font-size: 13px;
}

.toggle-setting>.toggle-switch {
   float: right;
   margin: 0;
}

/*--- toogle switch button ---*/

/*-- widgets ---*/

.sidebar .widget ul.recent-photos li {
   float: left;
   margin-bottom: 5px;
   margin-left: 2.5px;
   margin-right: 2.5px;
   width: 30.9%;
}

.widget ul.recent-photos {
   display: inline-block;
   padding-bottom: 20px;
   width: 100%;
}

/*-- short profile widget ---*/

.short-profile li span {
   color: #515365;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
   text-transform: capitalize;
   width: 100%;
}

.short-profile>li {
   border-bottom: 1px solid #eaeaea;
   padding-bottom: 10px;
}

.short-profile>li:last-child {
   border-bottom: 0 none;
}

/*--- social widget ---*/

.widget .socials>li {
   margin-bottom: 10px;
   border-radius: 4px;
}

.socials>li a {
   color: #fff;
   padding: 6px 10px;
   width: 100%;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
   border-radius: 4px;
}

.socials>li a i {
   color: #333;
   float: left;
}

li.facebook {
   background: #2f5b9d;
}

li.twitter {
   background: #38bff1;
}

li.google {
   background: #f24756;
}

.socials>li a span {
   font-size: 13px;
   color: #fff;
}

.socials>li a ins {
   float: right;
   font-size: 12px;
   margin-top: 4px;
   text-decoration: none;
   color: #fff;
}

.socials>li a:hover {
   color: #fff !important;
}

/*--- twitter feed widget ---*/

.twiter-feed {
   display: inline-block;
   position: relative;
   width: 100%;
   max-height: 395px;
}

.twiter-feed>li>i,
.twiter-feed>li span {
   display: inline-block;
   vertical-align: top;
}

.twiter-feed>li>span {
   color: #999;
   font-size: 12px;
   vertical-align: top;
   width: 82%;
}

.widget .twiter-feed>li>i {
   color: #38bff1;
   font-size: 20px;
   width: 20px;
}

.widget .twiter-feed>li>span i {
   color: #545454;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   text-transform: capitalize;
   width: 100%;
}

.widget .twiter-feed>li>em {
   color: #727272;
   font-size: 11px;
   font-style: normal;
   text-transform: capitalize;
}

.twiter-feed>li p,
.short-profile li p {
   line-height: 22px;
   margin-top: 8px;
   font-size: 13px;
   margin-bottom: 0;
}

.short-profile li p {
   margin-top: 0;
}

.twiter-feed>li p a {
   font-size: 12px;
}

/*--- svg play button ---*/

.edit-phto,
.add-btn>a {
   -webkit-transition: all 0.3s linear 0s;
   -moz-transition: all 0.3s linear 0s;
   -o-transition: all 0.3s linear 0s;
   -ms-transition: all 0.3s linear 0s;
   transition: all 0.3s linear 0s;
}

@keyframes spin {
   to {
      transform: rotate(360deg);
   }
}

.stroke-solid {
   stroke-dashoffset: 0;
   stroke-dasharray: 300;
   stroke-width: 4px;
   transition: stroke-dashoffset 1s ease, opacity 1s ease;
   opacity: 0.7;
}

.icon {
   transform: scale(0.8);
   transform-origin: 50% 50%;
   transition: transform 200ms ease-out;
}

.play:hover .stroke-solid {
   opacity: 1;
   stroke-dashoffset: 300;
}

.play:hover .icon {
   transform: scale(0.9);
}

.play {
   cursor: pointer;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translateY(-50%) translateX(-50%);
}

/*--- banner widget ---*/

/*--- map and pickpoint map style ---*/

.g-map {
   float: left;
   width: 100%;
   height: 400px;
}

#map-canvas {
   height: 400px;
}

#map,
#us3 {
   height: 300px;
   width: 100%;
   display: inline-block;
}

#vector-map {
   display: inline-block;
   height: 500px;
   width: 100%;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
   background: #535165 none repeat scroll 0 0;
   border-radius: 4px;
   color: #fff;
   cursor: pointer;
   margin: 5px 0;
   text-align: center;
   width: 24px;
}

.add-location-post>span {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 10px;
   width: 100%;
}

.add-location-post input {
   border: medium none;
   color: inherit;
   padding: 0;
}

.add-location-post label {
   color: #23d2e2;
}

.contact-form {
   float: left;
   padding: 50px 60px;
   position: relative;
   width: 60%;
}

/*--- knowledge base page ---*/
.main-title {
   display: inline-block;
   margin-bottom: 40px;
   text-align: center;
   text-transform: capitalize;
   width: 100%;
}

.vid-links {
   display: inline-block;
   width: 100%;
}

.vid-links>h4 {
   display: inline-block;
   text-transform: capitalize;
   width: 100%;
}

.tutor-links {
   list-style: outside none none;
   margin-top: 20px;
   padding-left: 0;
}

.tutor-links>li {
   border-bottom: 1px solid #eaeaea;
   margin-bottom: 10px;
   padding-bottom: 10px;
   text-transform: capitalize;
}

.tutor-links>li i {
   margin-right: 15px;
}

.tutor-links>li:last-child {
   border-bottom: 0;
}

.knowldeg-box .underline:hover::before {
   background: #fff none repeat scroll 0 0;
}

/*--- faq page ---*/

nav.breadcrumb {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   display: inline-block;
   margin-bottom: 0;
   padding: 0;
   vertical-align: middle;
   width: 100%;
   font-size: 13px;
   z-index: 3;
   position: relative;
}

.top-banner {
   display: inline-block;
   vertical-align: middle;
   width: 100%;
   position: relative;
   z-index: 2;
   text-align: center;
   padding-top: 20px;
}

.top-banner>i {
   display: inline-block;
   max-width: 100px;
   vertical-align: middle;
   width: 100px;
}

.top-banner>h1 {
   display: inline-block;
   vertical-align: middle;
   color: #fff;
}

.breadcrumb>.breadcrumb-item.active {
   color: inherit;
}

.breadcrumb-item+.breadcrumb-item::before {
   color: inherit;
}

.breadcrumb-item.active {
   color: #dedede;
}


.strip {
   display: inline-block;
   position: relative;
}

.header-inner>p {
   color: #fff;
   display: block;
   font-size: 14px;
   letter-spacing: 0.1px;
   line-height: 22px;
   margin: 0 auto;
   width: 84%;
}

.page-header .breadcrumb {
   padding-right: 30px;
   width: auto;
}

.header-inner>h2 {
   color: #fff;
   display: inline-block;
   font-size: 40px;
   font-weight: 400;
   line-height: initial;
   position: relative;
   text-transform: capitalize;
   vertical-align: middle;
   width: 100%;
   margin-bottom: 10px;
}

.color-bg .breadcrumb-item.active {
   color: #d3d3d3;
}

.nearby-map #map-canvas {
   height: 330px;
}

.page-header .bg-image {
   z-index: 0;
}

.header-inner>form {
   display: inline-block;
   margin-bottom: 15px;
   margin-top: 10px;
   position: relative;
   width: 100%;
}

.header-inner>form input {
   background: #fff none repeat scroll 0 0;
   border: medium none;
   border-radius: 30px;
   font-size: 13px;
   padding: 13px 90px 13px 20px;
   width: 100%;
}

.header-inner>form>button {
   font-size: 14px;
   padding: 13.3px 35px;
   position: absolute;
   right: -1px;
   top: -1px;
}

/*---- your page widget ---*/

.nav.nav-tabs {
   border: medium none;
}

.frnds {
   float: left;
   width: 100%;
}

.frnds .nav-tabs .nav-item {
   margin-right: 50px;
}

.frnds .nav-tabs .nav-item:last-child {
   margin-right: 0;
}

.frnds .nav-tabs .nav-item a.active {
   border-bottom: 1px solid;
}

.frnds .nav-tabs .nav-item a {
   font-weight: 500;
}

.frnds .nav-tabs .nav-item>span {
   border: 1px solid #ccc;
   border-radius: 30%;
   display: inline-block;
   font-size: 14px;
   line-height: initial;
   margin-left: 5px;
   text-align: center;
   width: 25px;
   color: #8d8d8d;
}

a.add-butn.more-action {
   background: lightslategray none repeat scroll 0 0;
   right: 74px;
}

#frends a.add-butn.more-action {
   right: 89px;
}

.nav-tabs--vertical {
   border-bottom: none;
   border-right: 1px solid #ddd;
   display: flex;
   flex-flow: column nowrap;
   padding-right: 15px;
   width: 30%;
}

.nav-tabs--left .nav-item+.nav-item {
   text-transform: capitalize;
}

.nav-tabs--left .nav-link {
   transition: border-color 0.125s ease-in;
   white-space: nowrap;
   font-size: 14px;
   font-weight: 500;
   color: #535165;
}

.nav-tabs--left .nav-link>i {
   margin-right: 10px;
}

.nav-tabs--left .nav-link:hover {
   border-color: transparent;
}

.nav-tabs--left .nav-link.active {
   border-radius: 0;
   padding: 10px 16px;
   border: none;
}

.nav-tabs--left .nav-link.active:hover {
   border-color: #dee2e6 #fff #dee2e6 #dee2e6;
}

.d-flex .tab-content {
   display: inline-block;
   padding-left: 30px;
   padding-top: 8px;
   width: 100%;
   border-left: 1px dashed #c4d6ed;
}

.location-map #map-canvas {
   height: 290px;
}

#work>div {
   color: #909090;
   font-size: 14px;
   margin-bottom: 0;
}

#work>div a {
   margin-bottom: 5px;
}

.personal>p {
   color: #7c7c7c;
   font-size: 14px;
}

.about .personal {
   display: inline-block;
   width: 100%;
}

#work>p a {
   display: inline-block;
   text-decoration: underline;
}

#work>p img {
   margin-right: 10px;
}

#work {
   padding: 20px;
}

.groups>span i {
   margin-right: 5px;
}

.groups>span {
   font-size: 20px;
   font-weight: 400;
}

/*--- edit basic info page ---*/

.editing-info>form {
   margin-top: 25px;
}

form .form-group.half {
   float: left;
   margin-right: 20px;
   width: 46%;
}

form .form-group.half {
   float: left;
   margin-right: 10px;
   width: 48.2%;
}

form .form-group.half:nth-child(2) {
   margin-right: 0;
}


.chosen-container-single .chosen-single {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border: 1px solid #dedede;
   box-shadow: none;
   /* color: #535165 !important; */
   font-size: 13px;
   font-weight: 300;
   height: auto;
   padding: 12px 12px;
}

form .dob .form-group {
   display: inline-block;
   margin-right: 20px;
   width: 30.6%;
}

.chosen-container {
   width: 100% !important;
}

form .dob .form-group:last-child {
   margin-right: 0;
}

a.forgot-pwd {
   float: right;
   font-size: 14px;
}

.onoff-options>form {
   float: left;
   margin-top: 20px;
   width: 100%;
}

/*--- invition widget ---*/

.invition figure {
   display: inline-block;
}

.invition .friend-meta a.invite {
   color: #999;
   float: left;
   font-size: 14px;
   margin-top: 0;
   text-transform: capitalize;
}

.sidebar .invition>li {
   border-bottom: 1px dashed #e4e4e4;
}

.sidebar .widget .invition {
   max-height: 320px;
   position: relative;
}

.invition .friend-meta>h4 {
   font-size: 15px;
}

/*--- advertisment widget ---*/

/*--- notification page ---*/

.notification-box>ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.notification-box {
   display: inline-block;
   width: 100%;
}

.notification-box>ul>li {
   border-bottom: 1px solid #e1e8ed;
   display: inline-block;
   margin-bottom: 15px;
   padding-bottom: 15px;
   width: 100%;
}

.notification-box>ul li figure {
   display: inline-block;
   margin-bottom: 0;
   margin-right: 10px;
   max-width: 45px;
   min-width: 45px;
   vertical-align: middle;
}

.notifi-meta {
   display: inline-block;
   line-height: initial;
   vertical-align: middle;
   width: 86%;
}

.notifi-meta>p {
   color: #535165;
   font-size: 14px;
   font-weight: 400;
   line-height: 15px;
   margin-bottom: 0;
   text-transform: capitalize;
}

.notifi-meta>span {
   font-size: 12px;
}

.notifi-meta>span>i {
   font-size: 13px;
}

.notification-box>ul>li:last-child {
   border-bottom: 0 none;
   margin-bottom: 0;
   padding-bottom: 0;
}

.notification-box>ul li>i.del {
   cursor: pointer;
   display: inline-block;
   transition: all 0.2s linear 0s;
   vertical-align: top;
   opacity: 0;
   visibility: hidden;
}

.notification-box>ul li:hover>i.del {
   opacity: 1;
   visibility: visible;
}

.more-options {
   display: inline-block;
   float: right;
   position: relative;
}

.more-options>i {
   color: #999 !important;
   font-size: 16px;
}

.x_panel {
   float: left;
   margin-top: 10px;
   width: 100%;
   position: relative;
}

.x_title>h2 {
   float: left;
   font-size: 18px;
}

.toolbox {
   float: right;
   list-style: outside none none;
   padding-left: 0;
}

.toolbox>li {
   color: #b2b2b2;
   display: inline-block;
   font-size: 12px;
   margin-left: 15px;
   cursor: pointer;
}

.x_title {
   border-bottom: 1px dashed #d6d6d6;
   float: left;
   margin-bottom: 30px;
   width: 100%;
}

.x_content {
   float: left;
   width: 100%;
   position: relative;
}

.toolbox .dropdown-menu.show {
   box-shadow: 0 7px 10px #d3d3d3;
   left: auto !important;
   padding: 10px 20px;
   right: 0;
   top: 100% !important;
}

.toolbox .dropdown-menu.show>li {
   color: #545454;
   font-size: 14px;
   margin-bottom: 10px;
}

.toolbox .dropdown-menu.show>li:last-child {
   margin-bottom: 0;
}

.liked-pages {
   float: left;
   list-style: outside none none;
   margin-top: 10px;
   padding-left: 0;
   width: 100%;
}

.liked-pages>li {
   background: #fff none repeat scroll 0 0;
   border: 1px solid #e1e8ed;
   display: inline-block;
   margin-right: 3px;
   transition: all 0.15s linear 0s;
   position: relative;
   max-width: 32.5%;
   min-width: 32.5%;
   width: 32.5%;
   padding-bottom: 15px;
   margin-top: 20px;
   overflow: hidden;
}

.f-page figure,
.f-page figure img {
   width: 100%;
}

.liked-pages>li:nth-child(3n) {
   margin-right: 0;
}

.liked-pages>li:hover {
   border-color: transparent;
   box-shadow: 0 8px 8px #e1e8ec;
}

.page-infos {
   float: left;
   padding: 0 15px;
   width: 100%;
}

.page-infos>h5 {
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   width: 100%;
   margin-bottom: 0;
}

.page-infos>span {
   color: #999;
   display: inline-block;
   font-size: 12px;
   text-transform: capitalize;
   vertical-align: top;
}

a.dislike-btn {
   bottom: 0;
   color: #fff;
   font-size: 13px;
   font-weight: 500;
   padding: 2px 10px;
   position: absolute;
   right: 0;
}

.f-page>figure em {
   bottom: 5px;
   float: right;
   font-size: 13px;
   font-style: normal;
   padding: 0 7px;
   position: absolute;
   right: -50%;
   transition: all 0.2s linear 0s;
   opacity: 0;
   color: #fff;
   visibility: hidden;
}

.liked-pages>li:hover .f-page figure em {
   right: 0;
   opacity: 1;
   visibility: visible;
}

.f-page>figure {
   display: inline-block;
   position: relative;
}

.dropdown.pgs {
   position: absolute;
   right: 10px;
   top: 10px;
}

.pgs .btn.dropdown-toggle {
   border-radius: 0;
   font-size: 12px;
   padding: 0 7px;
   text-transform: capitalize;
}

.pgs .btn.dropdown-toggle i {
   font-size: 10px;
   margin-right: 3px;
}

.btn.focus,
.btn:focus {
   box-shadow: none;
}

.pgs .dropdown-menu {
   background: #fff none repeat scroll 0 0;
   border-radius: 0;
   min-width: 100%;
   padding: 0;
   text-align: left;
   width: 100%;
}

.pgs .dropdown-menu .dropdown-item {
   font-size: 12px;
   padding: 0;
   text-align: center;
}

canvas {
   display: inline-block;
   vertical-align: baseline;
}

.linked-image {
   display: inline-block;
   width: 30%;
   vertical-align: top;
   float: none;
   margin-bottom: 15px;
}

.align-left {
   float: left;
   margin-right: 20px;
}

.post-meta .detail {
   display: inline-block;
   width: 64%;
}

.post-meta .detail p {
   margin-bottom: 5px;
}

.post-meta .detail>span {
   font-size: 24px;
   font-weight: 300;
}

.post-meta .detail>a {
   font-size: 12px;
   letter-spacing: 1px;
}

.align-right {
   float: right;
   margin-left: 20px;
}

/*--- load more ---*/

.lodmore {
   display: inline-block;
   padding: 15px 0;
   text-align: center;
   width: 100%;
}

.lodmore>span {
   color: #bcbcbc;
   display: inline-block;
   font-size: 13px;
   margin-bottom: 0;
   vertical-align: middle;
}

.btn-view.btn-load-more {
   background: #fff;
   border: medium none;
   color: black;
   display: block;
   position: relative;
   margin: 0 auto 0;
   transition: all 0.2s linear 0s;
   z-index: 77;
   -ms-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   -o-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
   padding: 5px 15px;
   border-radius: 30px;
   margin-top: 20px;
}

.central-meta button.btn-view {
   background: #edf2f6;
}

/*--- responsive header ---*/

.responsive-header {
   display: none;
}

.mh-head {
   background: #4bb5ef;
   color: #fff;
   height: auto;
}

.mh-text {
   font-size: 16px;
   font-weight: 500;
}

.mh-head .hamburger-inner,
.mh-head .hamburger-inner:after,
.mh-head .hamburger-inner:before {
   background: #fff;
}

.circle-media.mm-listview {
   float: left !important;
   padding-left: 0;
   margin: 30px 0 0 0 !important;
}

.circle-media.mm-listview>li a {
   background: #e7e7e7 none repeat scroll 0 0;
   line-height: 32px;
   padding: 0;
   text-align: center;
   width: 32px;
}

/*.circle-media.mm-listview > li a:hover {
  color: #fff;
}*/

.mm-listview>li:not(.mm-divider)::after {
   display: none;
}

.res-menu ul.mm-listview>li,
.res-menu ul.mm-listview>li span {
   text-transform: uppercase;
   font-size: 12px;
}

.mm-navbar .mm-title {
   text-transform: uppercase;
}

.mh-head {
   float: left;
   width: 100%;
}

.mh-head .mh-text {
   display: inline-block;
   margin-top: 6px;
   padding: 0;
}

.mh-text>a {
   display: inline-block;
   font-family: hobostd;
   font-size: 20px;
   vertical-align: middle;
}

.mh-text img {
   display: inline-block;
   vertical-align: sub;
}

.mh-head .mh-form {
   padding: 10px 0;
}

.mm-panel.mm-hasnavbar.mm-opened .setting-row span {
   width: 72%;
}

/*--- sticky sidebar ---*/

#page-contents {
   position: relative;
}

/*--- sticky sidebar End ---*/

/*--- 100% radius images ---*/

#people-list figure img,
.new-postbox>figure img,
.friend-info>figure img,
.comet-avatar>img,
.invition figure img,
.nearly-pepls>figure img,
.peoples>li figure img,
.conversation-head>figure img,
.peoples-mesg-box>ul li>figure img,
.notification-box>ul li figure img {
   border-radius: 100%;
}

/*--- main menu ---*/

.top-area>ul.main-menu>li ul {
   left: 0;
   list-style: outside none none;
   padding-left: 0;
   position: absolute;
   text-align: left;
   top: 100%;
   background: #fff;
   width: 200px;
   opacity: 0;
   visibility: hidden;
   box-shadow: 4px 7px 20px 0 rgba(0, 0, 0, 0.09);
   transition: all 0.2s linear 0s;
   border-radius: 0 0 5px 5px;
   border-top: 2px solid;
}

.top-area>ul.main-menu>li:hover>ul {
   opacity: 1;
   visibility: visible;
}

.top-area>ul.main-menu {
   float: left;
   margin-left: 0px;
   line-height: 60px;
}

.top-area>ul.main-menu>li {
   line-height: 59px;
   margin-right: 30px;
   padding-right: 30px;
   position: relative;
}

.top-area>ul.main-menu>li a {
   color: #575b5f;
   font-size: 14px;
   font-weight: 500;
   text-transform: capitalize;
}

.top-area>ul.main-menu>li::after {
   content: "ï„‡";
   font-family: fontawesome;
   font-size: 13px;
   position: absolute;
   right: 10px;
   top: 0;
}

.top-area>ul.main-menu>li>ul li {
   line-height: initial;
}

.top-area>ul.main-menu>li>ul li a {
   color: #5f5f5f;
   display: inline-block;
   font-size: 13.5px;
   font-weight: normal;
   padding: 10px 15px;
   width: 100%;
   text-transform: capitalize;
   transition: all 0.2s linear 0s;
   border-radius: 0 0 5px 5px;
}

.top-area>ul.main-menu>li>ul li a:hover {
   background: #f5f5f5 none repeat scroll 0 0;
   padding-left: 17px;
}

/*--- side chat message box ---*/

.chat-box {
   background: #fbfbfb none repeat scroll 0 0;
   border-radius: 3px;
   bottom: -2px;
   position: absolute;
   right: 100%;
   width: 300px;
   z-index: 9999999;
   transform: translate(40px) scale(0);
   opacity: 0;
   visibility: hidden;
   transition: all 0.2s linear 0s;
   border: 1px solid #e1e8ed;
   text-align: left;
   box-shadow: -15px -15px 20px rgba(0, 0, 0, 0.2);
}

.chat-box.show {
   transform: translate(0) scale(1);
   opacity: 1;
   visibility: visible;
}

.chat-head {
   color: #fff;
   display: inline-block;
   padding: 10px 20px 10px 30px;
   position: relative;
   width: 100%;
   background: #7750f8;
}

.chat-head .more:before {
   display: none;
}

.chat-head .more {
   float: right;
}

.chat-list>ul>li {
   margin-bottom: 20px;
   display: inline-block;
   width: 100%;
}

.chat-head>h6 {
   display: inline-block;
   font-weight: 500;
   margin: 0;
   vertical-align: top;
}

.chat-head span.status {
   left: 10px;
   top: 50%;
   transform: translateY(-50%);
}

.more {
   position: relative;
   display: inline-block;
}

.more-optns {
   display: inline-block;
}

.more>span {
   margin-left: 15px;
   cursor: pointer;
   position: relative;
}

.chat-box form.text-box {
   margin-left: 0;
   max-width: 100%;
   width: 100%;
}

.more-optns>ul {
   background: #ffffff none repeat scroll 0 0;
   border-radius: 3px;
   box-shadow: 0 10px 23px #8b8b8b;
   color: #333;
   line-height: initial;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 10px;
   position: absolute;
   right: 0;
   top: 100%;
   z-index: 22;
   opacity: 0;
   visibility: hidden;
   transition: all 0.2s linear 0s;
   width: 100px;
}

.more-optns:hover>ul {
   opacity: 1;
   visibility: visible;
}

.more-optns>ul li {
   cursor: pointer;
   display: inline-block;
   font-size: 11px;
   line-height: 24px;
   text-transform: capitalize;
   width: 100%;
}

/* .chat-list {
   display: inline-block;
   padding: 15px;
   position: relative;
   width: 100%;
} */

/* .chat-list>ul {
   margin: 0;
   padding-left: 0;
   max-height: 290px;
   overflow: hidden;
   position: relative;
   padding-right: 8px;
} */

/* .chat-thumb {
   display: inline-block;
   vertical-align: top;
   width: 28px;
} */

.notification-event {
   display: inline-block;
   margin-left: 8px;
   vertical-align: top;
   width: 84%;
}

/* .chat-thumb>img {
   border-radius: 100%;
} */

/* .chat-message-item {
   background: #f0f4f9 none repeat scroll 0 0;
   border-radius: 7px;
   display: inline-block;
   font-size: 13px;
   padding: 12px;
   color: #333;
} */

.notification-date {
   color: #999;
   display: inline-block;
   font-size: 11px;
   width: 100%;
}

.chat-list>ul li.you .chat-thumb {
   float: right;
}

.chat-list>ul li.you .notification-event {
   float: right;
   margin-left: 0;
   margin-right: 8px;
}

.chat-list>ul li.you .chat-message-item {
   background: rgba(202, 239, 142, 0.55) none repeat scroll 0 0;
}

.text-box {
   position: relative;
}

.text-box>textarea {
   font-size: 14px;
   height: 50px;
   line-height: 27px;
   border-radius: 0;
   background: #f0f0f0;
}

.text-box>button {
   opacity: 0;
   position: absolute;
   right: 0;
   top: 0;
   visibility: hidden;
}

/*--- New home page ---*/

.page-name {
   border-right: 1px solid rgba(255, 255, 255, 0.1);
   color: #fff;
   float: left;
   font-size: 14px;
   font-weight: 500;
   line-height: 65px;
   padding-left: 30px;
   padding-right: 30px;
   position: relative;
   text-align: left;
   text-transform: uppercase;
}

.row.merged20 {
   margin-right: -10px;
   margin-left: -10px;
}

.row.merged20>div {
   padding: 0 10px;
}

.row.merged10>div {
   padding: 0 5px;
}

.row.merged10 {
   margin-left: -5px;
   margin-right: -5px;
}

.row.merged20 .row.merged20 {
   padding-left: 5px;
   padding-right: 5px;
}

.row.merged20 .row.merged20 {
   margin-left: -15px;
   margin-right: -15px;
}

/*--- fixed sidebar right ---*/

.fixed-sidebar {
   background: #fff none repeat scroll 0 0;
   height: 100vh;
   left: 0;
   padding-bottom: 30px;
   padding-top: 30px;
   position: fixed;
   top: 59px;
   width: 70px;
   z-index: 99;
   -webkit-box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
   -moz-box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
   -ms-box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
   -o-box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
   box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
}

.fixed-sidebar.right {
   left: auto;
   right: 0;
}

.chat-friendz {
   display: inline-block;
   text-align: center;
   width: 100%;
   position: relative;
}

.chat-users {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
   position: relative;
   max-height: 79vh;
}

.chat-users>li {
   display: inline-block;
   margin-bottom: 20px;
   position: relative;
   cursor: pointer;
}

.author-thmb {
   display: inline-block;
   position: relative;
   width: 100%;
}

.chat-users>li .author-thmb img {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
}

.chat-users>li:last-child {
   margin-bottom: 0;
}

.fixed-sidebar .ps-container>.ps-scrollbar-y-rail>.ps-scrollbar-y {
   background-color: transparent !important;
}

.chat-friendz .chat-box {
   bottom: -43px;
}

.menu-left {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.left-menu {
   list-style: outside none none;
   margin: 0;
   padding: 0;
   position: relative;
   max-height: 83vh;
}

.left-menu>li {
   display: inline-block;
   margin-bottom: 15px;
   margin-top: 15px;
   position: relative;
   width: 100%;
}

.left-menu>li a {
   color: #535165;
   display: inline-block;
   font-size: 17px;
   position: relative;
   width: 100%;
}

.left-menu>li a::before {
   background: #edf2f6;
   content: "";
   height: 40px;
   left: 50%;
   position: absolute;
   top: 45%;
   transform: translate(-50%, -50%) rotate(45deg);
   transition: all 0.2s linear 0s;
   width: 40px;
   border-radius: 100%;
   z-index: -1;
}

.left-menu>li a:hover::before {
   transform: translate(-50%, -50%) rotate(45deg);
   border-radius: 100%;
   background: rgba(173, 173, 253, 0.4) none repeat scroll 0 0;
}

.left-menu>li a.active {
   color: #fa6342;
}

.left-menu>li a.active::before {
   background: rgba(173, 173, 253, 0.4) none repeat scroll 0 0;
}

.left-menu>li:last-child {
   margin-bottom: 0;
}

.left-menu>li a.menu-small::before {
   background: #54667d none repeat scroll 0 0;
   z-index: -1;
}

.left-menu>li a.menu-small.blue-bg::before {
   background: #6563ff;
}

.left-menu>li a.menu-small {
   color: #fff;
}

/*--- full menu left ---*/
.fixed-sidebar.left .menu-left {
   transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s,
      -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
   transition-delay: 0.3s;
}

.fixed-sidebar.left.open .menu-left {
   transform: translateX(-100%);
   transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s,
      -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
}

.fixed-sidebar.left.open .left-menu-full {
   transform: translateX(0px);
   transition-delay: 0.3s;
}

.fixed-sidebar.left .left-menu-full {
   background: #fff none repeat scroll 0 0;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
   display: inline-block;
   height: 100%;
   left: 0;
   padding: 30px 20px;
   position: absolute;
   top: 0;
   transform: translateX(-100%);
   width: 240px;
   transition: transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s,
      -webkit-transform 0.5s cubic-bezier(0.55, 0, 0.1, 1) 0s;
}

.left-menu-full>ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
   padding-top: 10px;
   position: relative;
   max-height: 515px;
}

.fixed-sidebar .left-menu-full>ul li {
   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
   text-transform: capitalize;
   position: relative;
}

.fixed-sidebar .left-menu-full>ul li i {
   border-radius: 100%;
   display: inline-block;
   width: 40px;
   text-align: center;
}

.fixed-sidebar .left-menu-full>ul li:last-child {
   margin-bottom: 0;
}

.fixed-sidebar .left-menu-full>ul li a {
   color: #535165;
   display: inline-block;
   font-size: 14px;
   width: 100%;
   padding: 10px 0;
}

.fixed-sidebar .left-menu-full>ul li a i {
   color: #999;
}

.fixed-sidebar .left-menu-full>ul li a:hover i {
   color: #2a2a2a;
}

/*--- left menu slide --*/
.menu-slide .menu-item-has-children::before {
   content: "\f0d7";
   font-family: fontawesome;
   position: absolute;
   right: 0;
   top: 8px;
}

.submenu {
   list-style: outside none none;
   padding-left: 28px;
   display: none;
}

.menu-item-has-children ul.submenu>li a {
   color: #797979;
   font-size: 13px;
   font-weight: 400;
   padding-left: 15px;
   padding-right: 10px;
   transition: all 0.2s linear 0s;
}

.menu-item-has-children ul.submenu>li a:hover {
   padding-left: 16px;
}

.menu-item-has-children ul.submenu>li a::before {
   border-radius: 100%;
   content: "";
   height: 2px;
   left: 0;
   position: absolute;
   top: 19px;
   width: 8px;
}

.content-bg-wrap {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   margin: 0;
   -webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
   -ms-box-sizing: border-box;
   -o-box-sizing: border-box;
   box-sizing: border-box;
   -webkit-animation: slide 50s linear infinite;
   -moz-animation: slide 50s linear infinite;
   -ms-animation: slide 50s linear infinite;
   -o-animation: slide 50s linear infinite;
   animation: slide 50s linear infinite;
   will-change: background-position;
   -webkit-background-size: contain !important;
   -moz-background-size: contain !important;
   -ms-background-size: contain !important;
   -o-background-size: contain !important;
   background-size: contain !important;
}

@keyframes slidedown {
   0% {
      background-position: 0 0;
   }

   100% {
      background-position: -2000px 2000px;
   }
}

@keyframes slide {
   0% {
      background-position: 0 0;
      background-position-x: 0px;
      background-position-y: 0px;
   }

   100% {
      background-position: -4000px 0;
      background-position-x: -4000px;
      background-position-y: 0px;
   }
}

/*--- help and support page ----*/

.widget .help-list {
   border-top: 0 none;
   padding-top: 0;
   margin: 0;
}

.sidebar .widget .help-list li a {
   display: flex;
}

.sidebar .widget .help-list li>a>i {
   color: inherit;
   font-size: inherit;
   position: unset;
   transform: translate(0px);
   margin-top: 5px;
}

.help-list>ul li a i {
   font-size: 12px;
   margin-right: 8px;
}

.help-list>a {
   border-radius: 30px;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   letter-spacing: 1px;
   margin-top: 20px;
   padding: 10px 20px;
   text-align: center;
   text-transform: capitalize;
   transition: all 0.2s linear 0s;
   width: 100%;
   color: #fff;
}

.help-list>a:hover {
   background: #888da8 none repeat scroll 0 0;
}

a.main-btn,
a.main-btn2,
a.main-btn3 {
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   border-radius: 30px;
   color: #fff;
   font-size: 13px;
   font-weight: 500;
   padding: 10px 26px;
   display: inline-block;
   transition: all 0.2s linear 0s;
   box-shadow: 4px 7px 12px 0 rgba(250, 99, 66, 0.2);
}

a.main-btn,
a.main-btn3 {
   border-color: transparent;
}

a.main-btn2 {
   padding: 9px 26px;
}

.category-box>p {
   color: #878787;
   display: inline-block;
   font-size: 14px;
   line-height: 26px;
   margin-bottom: 35px;
   -webkit-transition: all 0.2s linear 0s;
   -moz-transition: all 0.2s linear 0s;
   -ms-transition: all 0.2s linear 0s;
   -o-transition: all 0.2s linear 0s;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.category-box:hover>h2,
.category-box.selected>h2 {
   color: #fff;
}

.category-box:hover>p,
.category-box.selected>p {
   color: #f2f2f2;
}

.category-box:hover .main-btn,
.category-box.selected .main-btn {
   color: #000;
   background: #fff;
}

.overlap-75 {
   margin-top: -75px;
}

/*--- get a quote top baner ---*/

.getquot-baner {
   float: left;
   padding: 45px 50px;
   width: 100%;
   z-index: 2;
   position: relative;
}

.getquot-baner>span {
   color: #fff;
   float: left;
   font-size: 30px;
   font-weight: 500;
   z-index: 3;
   position: relative;
}

.getquot-baner>a {
   background: #fff none repeat scroll 0 0;
   -webkit-border-radius: 50px;
   -moz-border-radius: 50px;
   -ms-border-radius: 50px;
   -o-border-radius: 50px;
   border-radius: 50px;
   float: right;
   font-size: 16px;
   padding: 10px 58px;
   z-index: 3;
   position: relative;
   color: #535165;
   -webkit-transition: all 0.2s linear 0s;
   -moz-transition: all 0.2s linear 0s;
   -ms-transition: all 0.2s linear 0s;
   -o-transition: all 0.2s linear 0s;
   transition: all 0.2s linear 0s;
}

.getquot-baner>a:hover {
   -webkit-box-shadow: 0 0 20px #fff;
   -moz-box-shadow: 0 0 20px #fff;
   -ms-box-shadow: 0 0 20px #fff;
   -o-box-shadow: 0 0 20px #fff;
   box-shadow: 0 0 20px #fff;
}

/*--- help and support detail page ---*/

.help-topic {
   float: left;
   width: 100%;
   padding: 20px;
}

.help-topic>h2 {
   color: #535165;
   display: inline-block;
   font-size: 24px;
   font-weight: 400;
   margin-bottom: 40px;
   width: 100%;
}

.help-assistant {
   display: inline-block;
   vertical-align: middle;
   width: 50%;
}

.verified {
   color: green;
   display: inline-block;
   font-size: 30px;
   margin-right: 20px;
   vertical-align: middle;
}

.help-assistant>figure {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   display: inline-block;
   margin-bottom: 0;
}

.help-assistant>figure img {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
}

.assistant-name {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: 63%;
}

.assistant-name>span {
   display: inline-block;
   text-transform: uppercase;
   width: 100%;
}

.assistant-name>i {
   color: #999;
   font-size: 13px;
   font-style: normal;
}

.post-dated {
   display: inline-block;
   vertical-align: middle;
   width: 49%;
}

.help-description {
   border-top: 1px solid #eaeaea;
   display: inline-block;
   margin-top: 40px;
   padding-top: 30px;
   width: 100%;
}

.help-description>figure {
   border: 1px solid #eaeaea;
   -webkit-box-shadow: 0 0 13px #d7d7d7;
   -moz-box-shadow: 0 0 13px #d7d7d7;
   -ms-box-shadow: 0 0 13px #d7d7d7;
   -o-box-shadow: 0 0 13px #d7d7d7;
   box-shadow: 0 0 13px #d7d7d7;
   padding: 8px;
}

.post-dated>i {
   font-size: 14px;
}

.post-dated>span {
   color: #999;
   font-size: 13px;
   padding-left: 10px;
}

.is-helpful {
   border-bottom: 1px solid #eaeaea;
   border-top: 1px solid #eaeaea;
   display: inline-block;
   margin-bottom: 30px;
   margin-top: 60px;
   padding-bottom: 30px;
   padding-top: 30px;
   width: 100%;
}

.is-helpful>span {
   font-size: 14px;
   margin-right: 10px;
   text-transform: capitalize;
}

.is-helpful>a {
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   border-radius: 30px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   line-height: 10px;
   padding: 10px 20px;
   text-align: center;
}

.is-helpful>em {
   float: right;
   font-size: 14px;
   font-style: normal;
}

.share-article>span {
   display: inline-block;
   font-size: 15px;
   margin-bottom: 15px;
   text-transform: capitalize;
   width: 100%;
}

.share-article>a {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   color: #fff;
   display: inline-block;
   font-size: 14px;
   line-height: 40px;
   margin: 0 2px;
   text-align: center;
   width: 40px;
}

/*--- help result found page ---*/

.help-topic-result {
   float: left;
   margin-bottom: 40px;
   padding-left: 60px;
   width: 100%;
   position: relative;
}

.help-topic-result .checkd {
   color: green;
   font-size: 30px;
   left: 0;
   position: absolute;
   top: 0;
}

.help-topic-result>h2 {
   font-size: 19px;
   font-weight: 400;
   color: #535165;
}

.help-topic-result .help-description {
   margin-top: 20px;
   padding-top: 0px;
   border-top: 0;
}

.help-topic-result .help-description>p {
   margin-bottom: 0;
}

h3.resutl-found {
   color: #535165;
   display: inline-block;
   font-size: 20px;
   font-weight: 400;
   margin-bottom: 50px;
   width: 100%;
   padding-top: 20px;
}

/*--- fourm page ---*/
.central-meta table {
   color: inherit;
   margin-bottom: 0;
}

.forum-list table thead tr th {
   border-top: 0 none;
   color: #535165;
   font-size: 14px;
   font-weight: 500;
   padding: 0 20px 10px;
   text-transform: capitalize;
}

.forum-list table tbody tr td {
   padding: 20px;
   text-align: center;
   width: 100%;
   border-top: 1px dashed #d6dce5;
}

.forum-list table tbody tr td:first-child {
   text-align: left;
}

.forum-list table tbody tr:last-child td {
   padding-bottom: 0;
}

.forum-list table tbody tr td i {
   margin-right: 5px;
}

.forum-list table tbody tr td>a {
   font-size: 15px;
   color: #535165;
}

.forum-list table tbody tr td p {
   font-size: 14px;
   line-height: 20px;
   margin-bottom: 0;
   margin-top: 10px;
}

.forum-list table tbody tr td h6 {
   font-size: 11.5px;
   padding-left: 26px;
   padding-top: 6px;
   font-weight: 400;
}

a.addnewforum {
   border-radius: 100px;
   color: #fff;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 20px;
   padding: 10px 30px;
   float: right;
}

.forum-warper>h4 {
   color: #535165;
   display: inline-block;
   margin-bottom: 0;
   margin-top: 5px;
   text-transform: capitalize;
   vertical-align: middle;
}

/*--- login sidebar ---*/

.singin>form {
   float: left;
   padding: 0 20px 20px;
   position: relative;
   width: 100%;
}

.singin>form>input {
   background: #edf2f6 none repeat scroll 0 0;
   border: medium none;
   font-size: 13px;
   margin-bottom: 5px;
   padding: 10px 20px;
   width: 100%;
}

.singin {
   float: left;
   width: 100%;
}

.singin>form>input[type="checkbox"] {
   display: inline-block;
   margin-bottom: 0;
   margin-right: 5px;
   width: auto;
}

.singin>form>label {
   color: #999;
   font-size: 12px;
   margin: 0;
}

.singin>form>button {
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   -ms-border-radius: 3px;
   -o-border-radius: 3px;
   border-radius: 3px;
   margin-top: 20px;
   padding: 8px 10px;
   width: 100%;
}

.singin form .checkbox {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.singin form .checkbox label {
   font-size: 12px;
   margin: 0;
}

/*-- */

.sidebar.full-style .widget ul.recent-topics,
.sidebar.full-style .widget ul.feature-topics {
   border: 1px solid #eaeaea;
   display: inline-block;
   margin-bottom: 0;
   padding: 20px;
   width: 100%;
}

.widget ul.recent-topics>li>a,
.widget ul.feature-topics>li>a {
   color: #535165;
   font-size: 13px;
   font-weight: 500;
   line-height: 18px;
   -webkit-transition: all 0.2s linear 0s;
   -moz-transition: all 0.2s linear 0s;
   -ms-transition: all 0.2s linear 0s;
   -o-transition: all 0.2s linear 0s;
   transition: all 0.2s linear 0s;
}

.widget ul.recent-topics>li>span,
.widget ul.feature-topics>li>span {
   color: #999;
   display: inline-block;
   font-size: 11px;
   width: 100%;
}

.widget ul.feature-topics>li>i {
   font-size: 16px;
   color: orange;
}

.feature-topics>li>i {
   vertical-align: text-top !important;
}

.widget ul.feature-topics>li>a {
   vertical-align: top;
   width: 86%;
   font-size: 12px;
}

.widget ul.feature-topics>li>span {
   padding-left: 25px;
}

/*---- forum open topic detail page ---*/

.topic-data>img {
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   display: block;
   margin: 0 auto;
}

.topic-data {
   text-align: center;
}

.topic-data>span {
   float: left;
   font-size: 12px;
   padding: 5px 0 2px;
   width: 100%;
}

.topic-data>em {
   color: #999;
   float: left;
   font-size: 11px;
   width: 100%;
}

.date-n-reply>span {
   float: left;
   font-size: 12px;
   width: 100%;
   margin-top: 25px;
}

.date-n-reply {
   text-align: center;
   width: 220px;
}

.forum-open .table tr td p {
   font-size: 13px;
   margin: 0;
}

.date-n-reply>a {
   float: left;
   font-size: 12px;
   margin-top: 20px;
   width: 100%;
}

.forum-open>h5 {
   display: inline-block;
   font-size: 15px;
   font-weight: 400;
   margin-bottom: 40px;
   width: 100%;
}

.forum-open>h5>i {
   color: orange;
}

.forum-open table thead tr th {
   border-bottom-width: 1px;
   color: #535165;
   font-weight: 500;
   text-align: center;
}

.forum-open table tr td {
   border-top: 1px dashed #d6dce5;
   padding-bottom: 30px;
   padding-top: 30px;
}

.forum-open table tr:last-child td {
   border-bottom: 0;
}

.forum-open table tr td.topic-data {
   width: 100px;
}

/*--- sponsor logos --*/

.sponsor-logo li img {
   display: inline-block !important;
   width: auto !important;
}

.sponsor-logo li {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.sponsor-logo {
   margin-bottom: 0;
   padding: 0 15px !important;
}

.sponsors li a img:hover {
   transform: scale(1.1);
}

.sponsors li a img {
   transition: all 0.2s linear 0s;
}

/*--- career detail page ---*/

.employer-thmb {
   display: inline-block;
   margin-bottom: 0;
   margin-right: 20px;
   vertical-align: middle;
}

.employer-thmb>img {
   max-width: 100px;
   min-width: 100px;
   width: 100px;
}

.employer-info {
   display: inline-block;
   vertical-align: middle;
   width: 75%;
}

.employer-info>i {
   display: inline-block;
   font-size: 14px;
   font-style: normal;
   width: 100%;
}

.employer-info h2 {
   display: inline-block;
   font-size: 34px;
   font-weight: 300;
   margin-bottom: 20px;
   width: 100%;
   margin-top: 5px;
}

.salary-area {
   display: inline-block;
   text-align: right;
   width: 100%;
   margin-top: 40px;
}

.sociaz-media {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.sociaz-media>li {
   float: left;
   line-height: 29px;
   margin: 0 4px;
}

.sociaz-media>li:first-child {
   margin-left: 0;
}

.sociaz-media>li>a {
   border-radius: 100%;
   color: #fff;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   height: 30px;
   line-height: 30px;
   text-align: center;
   transition: all 0.2s linear 0s;
   width: 30px;
   background: #989dbd;
}

.sociaz-media>li>a:hover {
   color: #fff;
}

.sociaz-media>li>a i {
   font-size: 14px;
}

.salary-area>h4 {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.salary-area>span {
   color: #999;
   display: inline-block;
   font-size: 12px;
   margin-bottom: 20px;
   text-transform: capitalize;
   width: 100%;
}

.salary-area>a {
   background: #38bff1;
   border-radius: 30px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   padding: 7px 10px;
   text-transform: capitalize;
}

.salary-area>a>i {
   margin-right: 6px;
}

.employer-info>h4 {
   color: #535165;
   font-size: 14px;
}

.employer-info>h2>span {
   background: #7750f8;
   border-radius: 3px;
   color: #fff;
   display: inline-block;
   font-size: 11px;
   padding: 2px 5px;
   vertical-align: middle;
}

.job-tgs {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.job-tgs>span {
   color: #535165;
   font-size: 16px;
   font-weight: 500;
}

.job-tgs>a {
   border: 1px solid #eaeaea;
   border-radius: 5px;
   color: #535165;
   display: inline-block;
   font-size: 12px;
   padding: 3px 6px;
   transition: all 0.2s linear 0s;
   margin-bottom: 4px;
}

.job-tgs>a:hover {
   color: #fff;
   border-color: transparent;
}

.job-detail {
   display: inline-block;
   width: 100%;
}

.job-detail>h3 {
   color: #535165;
   display: inline-block;
   font-size: 22px;
   margin-bottom: 20px;
   width: 100%;
}

.job-detail>p {
   color: inherit;
   display: inline-block;
   font-size: 15px;
   line-height: 30px;
   margin-bottom: 30px;
   width: 100%;
   font-weight: 300;
}

.job-detail h5 {
   color: #535165;
   display: inline-block;
   font-weight: normal;
   margin-bottom: 20px;
   width: 100%;
   font-size: 20px;
}

.job-detail>ul {
   display: inline-block;
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
}

.job-detail>ul li i {
   display: inline-block;
   margin-right: 5px;
}

.job-detail>ul li span {
   color: inherit;
   display: inline-block;
   font-size: 15px;
   font-weight: 300;
   vertical-align: top;
   width: 95%;
}

.job-detail>ul li {
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.apply-bttons {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
   margin-bottom: 10px;
}

.related-links {
   display: inline-block;
   list-style: outside none none;
   margin: 0 0 -15px;
   padding-left: 0;
   width: 100%;
}

.related-links>li {
   display: inline-block;
   margin-bottom: 15px;
   width: 49%;
}

.related-links>li>i {
   color: #535156;
   margin-right: 8px;
}

.related-links>li>a {
   display: inline-block;
   transition: all 0.2s linear 0s;
}

.company-intro {
   border: 1px solid #eee;
   border-radius: 4px;
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.company-intro>p {
   color: inherit;
   font-size: 13px;
}

.company-intro>figure img {
   border-radius: 4px;
}

.company-intro>h4 {
   color: #535165;
   display: inline-block;
   font-size: 15px;
   margin-bottom: 13px;
   text-transform: capitalize;
   width: 100%;
}

.company-intro>a {
   font-size: 12px;
   font-weight: 500;
   text-transform: uppercase;
}

.wave .dot {
   display: inline-block;
   width: 6px;
   height: 6px;
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   margin-right: 1px;
   background: #fff;
   -webkit-animation: wave 1.3s linear infinite;
   -moz-animation: wave 1.3s linear infinite;
   -ms-animation: wave 1.3s linear infinite;
   -o-animation: wave 1.3s linear infinite;
   animation: wave 1.3s linear infinite;
}

/*--- wave dots for load more ---*/
.wave .dot:last-of-type {
   margin-right: 0;
}

.wave .dot:nth-child(2) {
   -webkit-animation-delay: -1.1s;
   -moz-animation-delay: -1.1s;
   -ms-animation-delay: -1.1s;
   -o-animation-delay: -1.1s;
   animation-delay: -1.1s;
}

.wave .dot:nth-child(3) {
   -webkit-animation-delay: -0.9s;
   -moz-animation-delay: -0.9s;
   -ms-animation-delay: -0.9s;
   -o-animation-delay: -0.9s;
   animation-delay: -0.9s;
}

@keyframes wave {

   0%,
   100%,
   60% {
      transform: initial;
   }

   30% {
      transform: translateY(-5px);
   }
}

/*--- top stories ---*/
.story-postbox {
   display: inline-block;
   position: relative;
   width: 100%;
}

.story-postbox>.row>div {
   padding: 0 3px;
}

.story-box>figure {
   border-radius: 10px;
   margin-bottom: 0;
   overflow: hidden;
}

.story-box {
   display: inline-block;
   overflow: hidden;
   width: 100%;
   cursor: pointer;
   position: relative;
}

.story-box:before {
   content: "";
   background: rgba(0, 0, 0, 0.2);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   transition: all 0.2s linear 0s;
   z-index: 1;
   border-radius: 10px;
}

.story-box:hover:before {
   z-index: 0;
}

.story-postbox .row {
   margin: 0;
}

.story-thumb {
   left: 12px;
   position: absolute;
   top: 12px;
   z-index: 1;
}

.story-thumb img {
   border: 2px solid #fff;
   border-radius: 100%;
   transition: all 0.25s linear 0s;
}

.story-box:hover figure img {
   transform: scale(1.05);
}

.story-box figure img {
   transition: all 0.2s linear 0s;
   width: 100%;
   border-radius: 10px;
}

.story-box figure span {
   bottom: -15px;
   color: #000;
   font-size: 12px;
   font-weight: 500;
   left: 50%;
   opacity: 0;
   position: absolute;
   text-align: center;
   text-transform: capitalize;
   transform: translateX(-50%);
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 100%;
   z-index: 9;
}

.story-box:hover figure span {
   bottom: 9px;
   left: 50%;
   opacity: 1;
   visibility: visible;
}

.story-thumb>i {
   background: transparent none repeat scroll 0 0;
   border: 2px solid #fff;
   border-radius: 100%;
   color: #fff;
   font-size: 18px;
   height: 40px;
   line-height: 36px;
   text-align: center;
   width: 40px;
   transition: all 0.2s linear 0s;
}

.refresh-content>i {
   color: #fff;
   cursor: pointer;
   font-size: 17px;
   z-index: 1;
}

.refresh-content {
   display: inline-block;
   padding-right: 10px;
   padding-top: 7px;
   text-align: right;
   width: 100%;
}

.weather-infos {
   float: left;
   padding: 0 40px;
   position: relative;
   text-align: left;
   width: 100%;
   z-index: 2;
}

.weather-tem {
   color: #ffffff;
   float: left;
   font-size: 31px;
   margin-right: 33px;
   position: relative;
}

.weather-tem::before {
   border: 2px solid #fff;

   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   -ms-border-radius: 50%;
   -o-border-radius: 50%;
   border-radius: 50%;

   content: "";
   height: 10px;
   position: absolute;
   right: -10px;
   top: 3px;
   width: 10px;
}

.weather-infos>h3 {
   color: #ffffff;
   float: left;
   font-size: 20px;
   margin: 0;
}

.weather-infos>h3>i {
   display: table;
   font-size: 13px;
   font-style: normal;
   margin-top: 6px;
}

.weather-date {
   height: 60px;
   position: absolute;
   right: 0;
   width: 60px;
}

.weather-date>span {
   border-radius: 10px 0 0 10px;
   color: #fff;
   display: inline-block;
   float: left;
   font-size: 14px;
   line-height: initial;
   padding: 14px;
   text-align: center;
   width: 100%;
}

.weather-date>span>strong {
   float: left;
   font-size: 20px;
   letter-spacing: -1px;
   line-height: 13px;
   margin-top: 3px;
   width: 100%;
}

.weather-week {
   display: inline-block;
   position: relative;
   width: 100%;
   z-index: 2;
}

.postoverlay {
   background-color: rgba(0, 0, 0, 0.8);
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   z-index: 99;
   display: none;
}

.monthly-weather {
   display: inline-block;
   margin: 20px 0;
   position: relative;
   width: 100%;
   z-index: 2;
}

.monthly-weather>ul {
   background: rgba(255, 255, 255, 0.3) none repeat scroll 0 0;
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 10px;
   width: 100%;
   line-height: initial;
}

.widget .monthly-weather>ul>li {
   color: #fff;
   display: inline-block;
   font-size: 10px;
   line-height: 14px;
   margin-bottom: 0;
   vertical-align: middle;
   width: 12.9%;
}

.widget .monthly-weather>ul>li a {
   display: inline-block;
}

.widget .monthly-weather>ul>li a i {
   font-size: 15px;
   margin: 0;
   position: unset;
   transform: translate(0px);
}

.widget .monthly-weather>ul>li span {
   display: inline-block;
   padding-bottom: 2px;
   width: 100%;
}

.widget .monthly-weather>ul>li em {
   display: inline-block;
   width: 100%;
   font-style: normal;
}

/*--- suggested friend carousel ---*/
.suggested-frnd-caro {
   float: left;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   position: relative;
   width: 100%;
}

.sugtd-frnd-meta {
   display: inline-block;
   margin-top: 10px;
   text-align: center;
   width: 100%;
}

.sugtd-frnd-meta>a {
   color: #515365;
   display: inline-block;
   font-size: 13.5px;
   font-weight: 500;
   width: 100%;
   transition: all 0.2s linear 0s;
}

.sugtd-frnd-meta>span {
   display: inline-block;
   font-size: 11px;
   width: 100%;
}

.sugtd-frnd-meta>span>a {
   text-decoration: underline;
}

.suggested-frnd-caro .owl-item>li,
.frndz-list .owl-item>li {
   background: #f2f7fb none repeat scroll 0 0;
   border: 1px solid #ede9e9;
   border-radius: 3px;
   padding-bottom: 7px;
}

.suggested-frnd-caro .owl-item>li img,
.frndz-list .owl-item>li img {
   border-radius: 3px 3px 0 0;
}

.frndz-list {
   margin: 0;
   padding: 0;
   list-style: none;
}

/*--- profile page ---*/








.user-profile .pit-rate {
   display: inline-block;
   margin: 0;
   position: absolute;
   right: 20px;
   text-align: right;
   top: 20px;
   width: auto;
}

.user-profile .pit-rate>li {
   font-size: 15px;
}

.pit-rate>li>span {
   color: #fff;
}





.profile-controls>li:first-child>a {
   background: #fa6342;
}

.profile-controls>li:nth-child(2)>a {
   background: #7750f8;
}

.profile-controls>li:nth-child(3)>a {
   background: #23d2e2;
}

.profile-controls>li:nth-child(4)>div {
   background: #857ec3;
}

.edit-seting {
   cursor: pointer;
}

.profile-controls .edit-seting:hover .more-dropdown {
   transform: scale(1);
   visibility: visible;
}

.profile-controls .edit-seting:hover .more-dropdown:before {
   content: "";
   position: absolute;
   top: -15px;
   width: 100%;
   height: 15px;
   background: #0000;
   z-index: -1;
}

.author-thumb {
   display: inline-block;
}

.more .more-dropdown {
   background: #fff none repeat scroll 0 0;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   left: 50%;
   line-height: initial;
   list-style: outside none none;
   padding: 0;
   position: absolute;
   text-align: left;
   top: 30px;
   transform: translate(-50%) scale(0);
   visibility: hidden;
   width: 150px;
   z-index: 999;
   transition: all 0.2s linear 0s;
   border-radius: 8px;
}

.help-topic-result:last-child {
   margin-bottom: 15px;
}

.more:hover .more-dropdown {
   transform: translate(-50%) scale(1);
   visibility: visible;
}

.more .more-dropdown>li {
   display: inline-block;
   font-size: 13px;
   width: 100%;
}

.more .more-dropdown>li a {
   display: inline-block;
   padding: 10px 12px;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.more .more-dropdown>li a:hover {
   color: #505050;
   font-weight: 500;
}

.more .more-dropdown>li a.active {
   color: #fa6342;
}

.right {
   display: flex;
   flex-flow: column;
   align-items: flex-end;
}

.right span {
   white-space: nowrap;
   font-weight: 500;
}

.full_screen {
   cursor: pointer;
}





/*--- login page ---*/
.gap.signin:before {
   z-index: 0;
   height: 100vh;
}

.gap.signin .bg-image {
   height: 100vh;
}

/* .we-login-register::before {
    border-color: rgba(250, 99, 66, .99) transparent transparent;
    border-style: solid;
    border-width: 40px 175px 0;
    bottom: -39px;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    width: 0;
} */

.register .we-form {
   margin-bottom: 25px;
}

.register .we-login-register {
   padding-bottom: 40px;
   padding-top: 30px;
}

a.with-smedia {
   border-radius: 30px;
   color: #fff;
   display: inline-block;
   font-size: 12px;
   font-weight: normal;
   height: 40px;
   line-height: 40px;
   margin: 10px auto 0;
   text-transform: capitalize;
   width: 40px;
}

.we-account {
   text-transform: capitalize;
}

/* .we-form::before {
    background: rgb(203 203 203 / 34%);
    content: "";
    height: 100%;
    left: -30px;
    position: absolute;
    top: -17px;
    width: 1px;
}

.we-form::after {
    background: rgb(203 203 203 / 34%);
    content: "";
    height: 100%;
    position: absolute;
    right: -30px;
    top: -17px;
    width: 1px;
} */

.form-title>i {
   margin-right: 10px;
}

.we-account.underline::before {
   background-color: #fff;
}

/*-- share post version ---*/
.share-post {
   background: #edf2f6;
   border-radius: 5px;
   display: inline-block;
   margin-bottom: 20px;
   padding: 5px 10px;
   width: 100%;
}

.share-post>figure {
   display: inline-block;
   margin: 0;
   vertical-align: middle;
}

.origin-name {
   display: inline-block;
   padding-left: 5px;
   vertical-align: middle;
}

.share-post>figure img {
   border: 1px solid #fff;
   border-radius: 100%;
   max-width: 40px;
   min-width: 40px;
   width: 100%;
}

.origin-name>a {
   display: inline-block;
   font-weight: 500;
   width: 100%;
}

.origin-name>span {
   display: inline-block;
   font-size: 11px;
}

.share-post>p {
   font-size: 12.7px;
   margin-top: 10px;
}

/*--- image punch post style ---*/

/*--- setting page ---*/

.set-title {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.set-title>h5,
.set-title>h6 {
   color: #515365;
}

.set-title>span {
   float: left;
   font-size: 13px;
   width: 84%;
}

.set-title>span a {
   font-weight: 500;
}

.submit-btns {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.onoff-options {
   display: inline-block;
   width: 100%;
}

.change-photo {
   display: inline-block;
   width: 100%;
}

.change-photo .fileContainer {
   margin-bottom: 0;
   padding: 2px 5px;
}

.edit-phto:hover {
   color: #fff;
}

.setting-meta {
   display: inline-block;
   width: 100%;
}

.change-photo>figure {
   display: inline-block;
   margin: 0;
   width: 100px;
   height: 100px;
}

.change-photo>figure img {
   border: 3px solid #ccc;
   border-radius: 100%;
   height: 100%;
   width: 100%;
   object-fit: cover;
}

.edit-img {
   display: inline-block;
   margin-left: 10px;
   vertical-align: middle;
}

.stg-form-area {
   display: inline-block;
   padding: 20px 0;
   width: 100%;
}

.stg-form-area .c-form>div {
   margin-bottom: 20px;
}

.c-form {
   display: inline-block;
   position: relative;
   width: 100%;
}

.c-form>div label {
   color: #515365;
   display: inline-block;
   font-size: 14px;
   margin-bottom: 4px;
   width: 100%;
   text-transform: capitalize;
}

.c-form>div input,
.c-form>div textarea,
.c-form>input,
.c-form>textarea {
   background: #ffffff;
   border-radius: 5px;
   font-size: 13px;
   padding: 12px 20px;
   width: 100%;
   color: #535165;
   border: 1px solid #eaeaea;
}

.c-form .chosen-container-single .chosen-single {
   background: #e8f0fe;
   border-radius: 5px;
   font-size: 13px;
   padding: 12px 20px;
   width: 100%;
   color: #535165;
   border: 1px solid #eaeaea;
}

.c-form>div.uzer-nam>input {
   width: 81%;
}

.c-form>div:last-child {
   margin-bottom: 0;
}

.c-form>div button,
.submit-btns>button {
   margin-left: 10px;
}

.form-radio {
   margin: 0;
   display: inline-block;
   width: 100%;
}

.form-radio .radio {
   display: inline-block;
   margin-bottom: 0;
   margin-right: 30px;
   margin-top: 0;
   vertical-align: middle;
}

.form-radio .radio>label {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   color: #757a95;
   font-size: 13.4px;
}

.checkbox label,
.form-radio label {
   color: #757a95;
   font-size: 13.4px;
}

.form-radio .radio>label input {
   width: auto;
}

.account-delete {
   background: #edf2f6 none repeat scroll 0 0;
   border-radius: 3px;
   display: inline-block;
   margin-top: 30px;
   padding: 15px;
   width: 100%;
}

.account-delete>h5 {
   color: #414355;
   display: inline-block;
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 10px;
}

.account-delete>div {
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.account-delete>div>span {
   display: inline-block;
   font-size: 13.5px;
   margin-top: 7px;
   vertical-align: middle;
}

.account-delete>div>button {
   background: #989dbd none repeat scroll 0 0;
   border: medium none;
   border-radius: 30px;
   color: #fff;
   float: right;
   font-size: 13px;
   font-weight: 500;
   padding: 7px 18px;
   text-transform: capitalize;
   transition: all 0.2s linear 0s;
}

.onoff-options form>.set-title {
   margin-top: 30px;
}

.notifi-seting .form-radio {
   margin-bottom: 30px;
}

.notifi-seting .set-title {
   margin-bottom: 0;
}

.checkbox>p,
.notifi-seting>p {
   color: #535663;
   font-size: 13px;
}

.checkbox>p a,
.notifi-seting>p a {
   font-weight: 500;
   text-transform: capitalize;
}

.notifi-seting .chosen-container,
.mesg-seting .chosen-container {
   margin: 14px 0;
}

.p-info {
   color: #535663;
   display: inline-block;
   width: 100%;
}

.seting-box {
   display: inline-block;
   width: 100%;
}

.seting-box>span {
   color: #515365;
   font-weight: 500;
}

.mesg-seting {
   display: inline-block;
   width: 100%;
}

.mesg-seting .set-title {
   margin-bottom: 5px;
   margin-top: 30px;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
   background-position: -16px 10px;
}

.chosen-container-single .chosen-single div b {
   background: rgba(0, 0, 0, 0) url("chosen-sprite.png") no-repeat scroll 0 10px;
}

.select-options .chosen-single>span {
   line-height: initial;
   margin-right: 0;
}

/*--- about page ---*/
.personal-head {
   display: inline-block;
   width: 100%;
}

.f-title {
   color: #515365;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   margin-bottom: 5px;
   width: 100%;
   text-transform: capitalize;
}

.personal-head>p {
   font-size: 13px;
   line-height: 20px;
   margin-bottom: 20px;
   padding-left: 20px;
}

.f-title i {
   margin-right: 5px;
}

/*--- timeline friends ---*/
.friend-box {
   background: #fff none repeat scroll 0 0;
   border-radius: 4px;
   border: 1px solid #ede9e9;
   display: inline-block;
   margin-bottom: 20px;
   position: relative;
   width: 100%;
}

.friend-box>figure {
   margin: 0;
   position: relative;
}

.friend-box>figure img {
   display: inline-block;
   width: 100%;
   border-radius: 4px;
}

.friend-box>figure::before {
   background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
   bottom: 0;
   content: "";
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   border-radius: 4px;
}

.friend-box>figure>span {
   bottom: 10px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   position: absolute;
   right: 10px;
}

.frnd-meta {
   display: inline-block;
   padding: 0 15px 20px;
   position: relative;
   width: 100%;
}

.frnd-meta>img {
   border: 2px solid #fff;
   border-radius: 100%;
   display: inline-block;
   margin-top: -40px;
   position: relative;
   vertical-align: middle;
   z-index: 2;
}

.frnd-name {
   display: inline-block;
   left: 117px;
   position: absolute;
   text-align: left;
   top: 10px;
}

.frnd-name>a {
   color: #313345;
   display: inline-block;
   font-size: 15px;
   font-weight: 500;
   width: 100%;
   transition: all 0.2s linear 0s;
}

.frnd-name>span {
   display: inline-block;
   font-size: 12px;
}

.frnd-info {
   display: inline-block;
   list-style: outside none none;
   margin: 20px 0;
   padding: 0;
   width: 100%;
}

.frnd-info>li {
   display: inline-block;
   font-size: 13px;
   padding: 2px 0;
   width: 100%;
}

.frnd-info>li span {
   color: #515365;
   display: inline-block;
   font-weight: 500;
   vertical-align: middle;
   width: 65px;
}

.frnd-meta>a {
   border-radius: 30px;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   position: relative;
}

/*--- friend list style 2 ---*/
.friend-block {
   background: #fff;
   border-radius: 5px;
   display: inline-block;
   padding: 20px 15px;
   position: relative;
   width: 100%;
   margin-bottom: 20px;
   border: 1px solid #ede9e9;
}

.friend-block>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: 84px;
}

.friend-block .frnd-meta {
   padding: 0 0 0 10px;
   vertical-align: middle;
   width: 58%;
}

.friend-block .frnd-meta .frnd-name {
   margin-bottom: 10px;
   position: unset;
}

.friend-block>figure img {
   border-radius: 100%;
}

.friend-block .more-opotnz {
   position: absolute;
   right: 15px;
   top: 5px;
}

.friend-block .more-opotnz>ul {
   bottom: auto;
   right: 0;
   top: 25px;
}

/*--- photos page ---*/
.row.merged5>div {
   padding: 0 5px;
}

.row.merged5 {
   margin: 0 -5px;
}

.item-box {
   float: left;
   margin-bottom: 10px;
   overflow: hidden;
   position: relative;
   width: 100%;
}

.item-box>a {
   display: inline-block;
   width: 100%;
}

.item-box>a img {
   border-radius: 5px;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.photos-list .over-photo .likes.heart {
   margin-right: 6px;
}

.photos-list .over-photo .likes.heart>span {
   font-size: 12px;
}

.over-photo {
   bottom: -10px;
   color: #fff;
   display: inline-block;
   left: 0;
   opacity: 0;
   padding: 0 10px;
   position: absolute;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 100%;
   z-index: 2;
}

.over-photo>span {
   color: #eee;
   float: right;
   font-size: 11.4px;
   margin-top: 5px;
   letter-spacing: -0.6px;
}

.over-photo>a,
.over-photo>div {
   display: inline-block;
   margin-right: 10px;
   vertical-align: middle;
}

.over-photo>a:last-child,
.over-photo>div:last-child {
   margin-right: 0;
}

.over-photo .likes.heart {
   font-size: 16px;
   margin-right: 15px;
   vertical-align: middle;
}

.item-box::after,
.feature-video:after {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+83&0+0,0.65+83 */
   background: -moz-linear-gradient(top,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.65) 83%);
   /* FF3.6-15 */
   background: -webkit-linear-gradient(top,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.65) 83%);
   /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,
         rgba(0, 0, 0, 0) 0%,
         rgba(0, 0, 0, 0.65) 83%);
   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
   /* IE6-9 */
   border-radius: 0 0 6px 6px;
   bottom: 0;
   content: "";
   height: 45px;
   left: 0;
   opacity: 0;
   position: absolute;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 100%;
   z-index: 1;
}

.item-box:hover::after {
   opacity: 1;
   visibility: visible;
}

.item-box:hover>a img {
   transform: scale(1.1);
}

.item-box:hover .over-photo {
   bottom: 10px;
   opacity: 1;
   visibility: visible;
}

/*--- Upload popup ---*/
.popup-wraper,
.popup-wraper1,
.popup-wraper2,
.popup-wraper3,
.popup-wraper4,
.popup-wraper5,
.popup-wraper6,
.popup-wraper7,
.stories-wraper {
   background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 99999;
   -webkit-opacity: 0;
   -moz-opacity: 0;
   -ms-opacity: 0;
   -o-opacity: 0;
   opacity: 0;
   visibility: hidden;
   transition: all 0.25s linear 0s;
}

.popup-wraper.active,
.popup-wraper1.active,
.popup-wraper2.active,
.popup-wraper3.active,
.popup-wraper4.active,
.popup-wraper5.active,
.popup-wraper6.active,
.popup-wraper7.active,
.stories-wraper.active {
   -webkit-opacity: 1;
   -moz-opacity: 1;
   -ms-opacity: 1;
   -o-opacity: 1;
   opacity: 1;
   visibility: visible;
}

.popup {
   background: #fff none repeat scroll 0 0;
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   border-radius: 5px;
   left: 50%;
   padding: 20px;
   position: absolute;
   top: 50%;
   -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   width: 650px;
}

.popup.creat-group {
   padding-bottom: 0;
}

.message-pop>h4 {
   font-size: 30px;
}

.popup-closed {
   cursor: pointer;
   font-size: 15px;
   position: absolute;
   right: 18px;
   top: 15px;
   z-index: 9;
}

.popup-closed>i {
   line-height: initial;
}

.popup form label {
   font-size: 15px;
   margin-bottom: 10px;
   margin-top: 16px;
   text-transform: capitalize;
   width: 100%;
}

.popup form textarea {
   margin-bottom: 30px;
   width: 100%;
}

.popup form .canceled {
   float: right;
   font-size: 16px;
   text-transform: capitalize;
}

.popup-head {
   border-bottom: 1px solid #dedede;
   display: inline-block;
   padding-bottom: 10px;
   width: 100%;
}

.popup-head>h5 {
   color: #515365;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0;
   width: 100%;
}

.smal-box {
   border: 1px dashed #c3c3c3;
   border-radius: 5px;
   display: inline-block;
   padding: 25px;
   width: 100%;
   text-align: center;
}

.upload-boxes {
   display: inline-block;
   margin-top: 30px;
   width: 100%;
}

.smal-box .fileContainer,
.from-gallery {
   color: inherit;
   cursor: pointer;
   text-align: center;
}

.smal-box .fileContainer>i,
.from-gallery>i {
   font-size: 30px;
   margin-bottom: 15px;
   display: inline-block;
}

.smal-box .fileContainer>em,
.from-gallery>em {
   color: #515365;
   display: block;
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: 1.2;
   margin-bottom: 0;
   width: 100%;
}

.from-gallery>span {
   display: inline-block;
   font-size: 13px;
   line-height: 30px;
   width: 100%;
}

.sugested-photos {
   display: inline-block;
   margin-top: 30px;
   width: 100%;
   margin-bottom: 30px;
}

.sugested-photos>h5 {
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   margin-bottom: 15px;
   width: 100%;
}

.sugested-photos>h5 a {
   float: right;
   font-size: 12px;
}

.sugestd-photo-caro {
   margin: 0;
   list-style: none;
   padding: 0;
}

.sugestd-photo-caro>li {
   cursor: pointer;
   display: inline-block;
   margin: 0 2px;
   width: 115px;
}

.sugestd-photo-caro>li img {
   border-radius: 3px;
}

.sugestd-photo-caro>li.active img {
   border: 2px solid;
   padding: 5px;
   width: 100%;
}

.upload-boxes .main-btn {
   float: right;
}

.already-gallery {
   background: #fff none repeat scroll 0 0;
   left: 50%;
   padding: 20px;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 650px;
   border-radius: 5px;
   opacity: 0;
   visibility: hidden;
   transition: all 0.2s linear 0s;
}

.already-gallery.active {
   opacity: 1;
   visibility: visible;
   z-index: 99;
}

.already-gallery .sugestd-photo-caro>li {
   margin-bottom: 8px;
}

.already-gallery .sugestd-photo-caro {
   margin: 20px 0;
   max-height: 370px;
   position: relative;
}

.already-gallery>.main-btn {
   float: right;
   margin: 0 5px;
}

.featurepost {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.feature-video {
   display: inline-block;
   width: 100%;
   position: relative;
}

.feature-video .over-photo {
   bottom: 10px;
   opacity: 1;
   visibility: visible;
}

.feature-video a img {
   border-radius: 5px;
   display: inline-block;
   width: 100%;
}

.feature-videometa {
   display: inline-block;
   padding: 10px 0;
   width: 100%;
}

.feature-videometa>h4 {
   color: #515365;
   display: inline-block;
   font-weight: 500;
   margin-bottom: 20px;
   width: 100%;
}

.feature-videometa>p {
   display: inline-block;
   font-size: 14px;
   line-height: 24px;
   width: 100%;
}

.feature-video::after {
   opacity: 1;
   visibility: visible;
}

.featurepost>h5 {
   color: #515365;
   display: inline-block;
   font-size: 16px;
   margin-bottom: 20px;
   width: 100%;
}

.featurepost>h5>i {
   font-size: 20px;
   margin-right: 8px;
}

.fav-community>li>i {
   font-size: 14px !important;
}

.fav-community>li {
   font-size: 13px;
}

.fav-community>li p {
   margin-bottom: 0;
   margin-top: 10px;
   font-size: 13px;
   color: #757a95;
}

.fav-community>li span {
   color: #515365;
   font-weight: 500;
}

/*--- video widget ---*/

.sidebar .widget-video li>a,
.sidebar .widget-video li>a img {
   display: inline-block;
   width: 100%;
}

/*--- E contact widget ---*/

.contact-box #map-canvas {
   height: 160px;
}

.contact-box>span {
   color: #515365;
   display: inline-block;
   font-weight: 500;
   margin-bottom: 6px;
   width: 100%;
}

.contact-box>p {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 7px;
   width: 100%;
}

.contact-box>p>i {
   font-size: 14px !important;
}

/*--- faved widget ---*/
.widget .faved-page>li {
   display: inline-block;
   margin-bottom: 5px;
   width: 41px;
}

.widget .faved-page>li a {
   border-radius: 100%;
   display: inline-block;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.widget .faved-page>li img {
   border-radius: 100%;
   display: inline-block;
   width: 100%;
}

.widget .faved-page>li.all-faved a {
   background: #23d2e2 none repeat scroll 0 0;
   border-radius: 100%;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   height: 41.6px;
   line-height: 41px;
   text-align: center;
   position: relative;
}

.widget .faved-page>li a::before {
   background: rgba(250, 99, 66, 0.7) none repeat scroll 0 0;
   border-radius: 100%;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   transform: scale(0);
   transition: all 0.3s linear 0s;
   visibility: hidden;
   width: 100%;
}

.widget .faved-page>li a:hover::before {
   transform: scale(1);
   visibility: visible;
}

/*--- suggested page widget ---*/
.suggestd>li a.sug-pic {
   display: inline-block;
   margin-right: 5px;
   vertical-align: middle;
}

.suggestd>li a.sug-pic img {
   border-radius: 100%;
}

.suggestd>li a.sug-title {
   color: #515365;
   display: inline-block;
   font-weight: 500;
   vertical-align: middle;
}

.suggestd>li .sug-like {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border: medium none;
   color: #757a95;
   float: right;
   line-height: initial;
   margin-top: 9px;
   padding: 0;
   width: auto;
}

.suggestd>li .sug-like>i {
   font-size: 24px;
   color: inherit;
   position: unset;
   transform: translate(0px);
   margin-right: 0;
   transition: all 0.2s linear 0s;
}

.suggestd>li .sug-like:hover i {
   transform: scale(1.1);
}

/*--- error page ---*/
.eror::after {
   content: "";
   height: 10px;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 9;
}

.eror::before {
   content: "";
   height: 10px;
   left: 0;
   position: absolute;
   bottom: 0;
   width: 100%;
   z-index: 9;
}

.eror .bg-image {
   height: 100vh;
}

.eror {
   float: left;
   position: relative;
   width: 100%;
   height: 100vh;
}

.big-font {
   display: table;
   height: 100vh;
   text-align: center;
   width: 100%;
}

.big-font>span {
   color: #fff;
   display: table-cell;
   font-size: 104px;
   font-weight: bold;
   text-orientation: upright;
   vertical-align: middle;
   width: 100%;
   writing-mode: vertical-lr;
}

.gap.z0::before {
   z-index: 0;
}

.error-meta {
   display: table;
   height: 100%;
   padding-left: 80px;
   width: 100%;
}

.error-meta>div {
   display: table-cell;
   vertical-align: middle;
}

.error-meta h1 {
   color: #fff;
   font-size: 50px;
   font-weight: 400;
   text-transform: uppercase;
   margin-top: 40px;
   display: inline-block;
   width: 100%;
}

.error-meta p {
   color: #cecece;
   font-size: 15px;
   font-weight: 300;
   margin-bottom: 50px;
   margin-top: 10px;
   max-width: 80%;
}

.error-meta span {
   color: #fff;
   display: inline-block;
   font-size: 14px;
   margin-bottom: 10px;
   text-transform: capitalize;
   width: 100%;
}

/*--- last video widget ---*/
.widget .widget-video>li>a>i {
   overflow: unset;
   z-index: 2;
   transition: all 0.2s linear 0s;
}

.widget .widget-video>li>a img {
   border-radius: 5px;
}

.widget .widget-video>li>a::after {
   background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
   border-radius: 5px;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 0;
}

.widget-video>li>span {
   color: #fff;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   left: 50%;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   text-align: center;
   text-overflow: ellipsis;
   top: 10px;
   transform: translate(-50%);
   transition: all 0.2s linear 0s;
   visibility: hidden;
   white-space: nowrap;
   width: 80%;
}

.widget-video>li:hover>a i {
   top: 55%;
}

.widget-video>li:hover>span {
   top: 20px;
   opacity: 1;
   visibility: visible;
}

.widget-video>li em {
   bottom: 5px;
   color: #fff;
   font-size: 12px;
   font-style: normal;
   position: absolute;
   right: 15px;
}

.gen-metabox {
   display: inline-block;
   margin-bottom: 25px;
   width: 100%;
}

.gen-metabox>h6 {
   color: #515365;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 10px;
   width: 100%;
}

.gen-metabox>span {
   display: inline-block;
   font-size: 14px;
   font-weight: 600;
   margin-bottom: 3px;
   width: 100%;
}

.light-theme .gen-metabox>span {
   color: var(--bs-gray-900);
}

.dark-theme .gen-metabox>span {
   color: var(--bs-gray-200);
}

.gen-metabox>p {
   font-size: 13.4px;
   line-height: 22px;
   margin-bottom: 0;
}

.fav-play {
   display: inline-block;
   width: 100%;
}

.fav-play>figure img {
   border-radius: 4px;
   width: 100%;
}

.photos-list,
.videos-list {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.owl-item .item-box {
   margin-bottom: 0;
}

.no-margin {
   margin: 0;
}

.badged {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.badged>li {
   display: inline-block;
   margin-right: 5px;
   max-width: 30px;
}

/*--- invite people for fav page ---*/
.invitepage>li>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: 79%;
}

.invitepage>li>a {
   border-radius: 30px;
   color: #23d2e2;
   display: inline-block;
   float: right;
   font-size: 11.5px !important;
   margin-top: 13px;
   position: relative;
   width: 28px;
}

.invitepage>li>figure img {
   border-radius: 100%;
}

.invitepage>li>figure a {
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   padding-left: 5px;
   vertical-align: middle;
}

.widget .invitepage>li {
   margin-bottom: 10px;
}

.widget .invitepage>li>a i {
   float: none;
   font-size: 13px;
   margin-right: 0;
   top: 10px;
}

/*--- event page --*/
.event-thumb {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.event-thumb img {
   border-radius: 4px;
   width: 100%;
}

.event-title {
   display: table;
   height: 100%;
   padding-bottom: 10px;
   padding-top: 15px;
   width: 100%;
}

.event-title>h4 {
   display: block;
   font-size: 15px;
   font-weight: 500;
   line-height: 26px;
   margin-bottom: 12px;
   vertical-align: middle;
   color: #535165;
}

.event-title>p {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.event-title>h4 a {
   transition: all 0.2s linear 0s;
}

.event-box {
   float: left;
   margin-bottom: 30px;
   width: 100%;
}

.event-time .main-btn {
   background: #989dbd none repeat scroll 0 0;
}

/*--- event detail popup ---*/
.popup.events {
   max-height: 590px;
}

.event-detail {
   display: inline-block;
   width: 100%;
}

.event-detail>figure {
   margin-bottom: 0;
}

.event-detailmeta {
   background: #fff none repeat scroll 0 0;
   border-radius: 4px;
   display: inline-block;
   margin-left: 15px;
   margin-top: -50px;
   padding: 20px;
   width: calc(100% - 30px);
   border: 1px solid #eee;
}

.event-detailmeta>h4 {
   color: #535165;
   display: inline-block;
   font-size: 24px;
   font-weight: 400;
   margin-bottom: 15px;
}

.event-detailmeta>p {
   display: inline-block;
   line-height: 26px;
   width: 100%;
}

.location-map {
   display: inline-block;
   width: 100%;
}

.location-map>span {
   color: #535165;
   display: inline-block;
   font-size: 18px;
   font-weight: 500;
   width: 100%;
}

.location-map>p {
   display: inline-block;
   font-size: 13px;
   margin-top: 5px;
   width: 100%;
}

.event-detailmeta .main-btn.event {
   margin-top: 20px;
}

.event-title>span {
   display: inline-block;
   font-size: 13px;
   letter-spacing: 1px;
   margin-bottom: 5px;
   width: 100%;
}

.group-adding {
   display: inline-block;
   width: 100%;
}

/*--- add friend group ---*/
.friend-group {
   display: inline-block;
   padding: 25px 0;
   width: 100%;
}

.friend-group .change-photo {
   border: 1px dashed #cecece;
   border-radius: 4px;
   margin-bottom: 20px;
   padding: 20px;
}

.friend-group .edit-phto .fileContainer {
   margin: 5px 3px;
   color: #515365;
}

.friend-group .edit-phto .fileContainer:hover {
   color: #fff;
}


.friend-group>form>input {
   background: #edf2f6;
   border: 1px solid #e4e4e4;
   border-radius: 5px;
   margin-bottom: 20px;
   padding: 10px 15px;
   width: 100%;
   font-size: 13px;
}

.friend-group>form>button {
   margin-top: 30px;
}

.friend-group .edit-phto {
   background: none;
}

/*--- typography page ---*/
/* .modal-backdrop.show {
   display: none;
}

.modal.fade.show::after {
   background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
   bottom: 0;
   content: "";
   left: 0;
   position: fixed;
   right: 0;
   top: 0;
   z-index: -1;
} */

.color-palet {
   display: inline-block;
   text-align: center;
   width: 100%;
   vertical-align: middle;
}

.color-box {
   border-radius: 5px;
   color: #fff;
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.color-box.dark-gry-box {
   background: #535165 none repeat scroll 0 0;
}

.color-box.orange-box {
   background: #fa6342 none repeat scroll 0 0;
}

.color-box.blue-box {
   background: #23d2e2 none repeat scroll 0 0;
}

.color-box.purple-box {
   background: #7750f8 none repeat scroll 0 0;
}

.color-box.red-box {
   background: #e44a3c none repeat scroll 0 0;
}

.color-box.darker-box {
   background: #34465d none repeat scroll 0 0;
}

.title2 {
   display: inline-block;
   font-size: 24px;
   font-weight: 500;
   margin-bottom: 20px;
   margin-top: 0;
   text-transform: capitalize;
   width: 100%;
   color: #34465d;
   position: relative;
}

.title2::before {
   content: "";
   height: 100%;
   left: -19px;
   position: absolute;
   top: 0;
   width: 3px;
}

.dropcap-head>.dropcap {
   color: #fff;
   float: left;
   font-size: 2.25em;
   font-weight: 700;
   height: 1.8em;
   line-height: 1.8em;
   margin: 0.15em 0.5em 0 0;
   text-align: center;
   width: 1.8em;
}

.page-create-form {
   padding: 24px;
   border-radius: 15px;
}

.single_form {
   max-width: 840px;
   margin: 0 auto;
   padding: 30px;
   border: 0;
   border-radius: 15px;
   background: #f9f9f9;
   box-shadow: 0 0 8px rgb(165 165 165 / 38%);
}

.dropcap-head>.dropcap.radius {
   border-radius: 100%;
}

.pc-file {
   border: 3px dashed #dbdbdb;
   padding: 10px;
   color: #535165;
   font-size: 14px;
   font-weight: 500;
}

.pc-form input,
.pc-form textarea,
.pc-form select,
.pc-form textarea {
   background: #fff;
   border-radius: 5px;
   font-size: 14px;
   padding: 12px 20px;
   width: 100%;
   color: #535165;
   border: 0;
}

.typography .c-form>div {
   margin-bottom: 30px;
}

.typography>.central-meta>button.main-btn2 {
   border-radius: 30px;
   font-size: 13px;
   margin-right: 10px;
   margin-top: 10px;
   padding: 8px 20px;
   font-weight: 500;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
   color: #535165;
}

.typography>a {
   display: inline-block;
   margin: 3px 0;
   width: 100%;
}

.main-btn2,
.main-btn3 {
   -moz-border-bottom-colors: none;
   -moz-border-left-colors: none;
   -moz-border-right-colors: none;
   -moz-border-top-colors: none;
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border-image: none;
   border-style: solid;
   border-width: 1px;
   transition: all 0.2s linear 0s;
}

.main-btn3 {
   background: #888da8;
}

.main-btn:hover {
   background: #888da8;
}

.main-btn2:hover {
   color: #fff;
}


.ico-box {
   background: #eee none repeat scroll 0 0;
   border-radius: 4px;
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.typography .setting-row {
   text-align: left;
}

.typography .setting-row>span {
   color: #515365;
   font-size: 13px;
   font-weight: 500;
   width: 100%;
}

/*--- happy birthday widget ---*/

.rec-events {
   border-radius: 5px;
   color: #fff;
   margin: 0 auto 20px;
   padding: 15px 10px;
   text-align: center;
   width: 83%;
   position: relative;
   overflow: hidden;
}

.rec-events>i {
   display: inline-block;
   font-size: 40px;
   margin-bottom: 10px;
}

.rec-events>h6 {
   margin-bottom: 0;
}

.rec-events>p {
   font-size: 12px;
}

.rec-events>img {
   opacity: 0.4;
   position: absolute;
   right: -30px;
   top: 10px;
}

.event_wrap {
   display: flex;
   align-items: flex-start;
}

.event_wrap .info {
   text-align: left;
}

.event_wrap .info h6 {
   margin-bottom: 0;
}

.event_wrap .date {
   flex: 0 0 40px;
   margin-right: 8px;
   line-height: 1;
   font-size: 14px;
   border: 1px solid #fff;
   border-radius: 21px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-flow: column;
   margin-top: 2px;
   padding: 13px 5px;
}

.event_wrap .date span {
   display: block;
   font-size: 18px;
   font-weight: 500;
   margin-bottom: 3px;
}

/*--- recent job widget ---*/

.recent-jobs li h6 {
   color: #535165;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
   width: 100%;
}

.recent-jobs li h6 a {
   display: inline-block;
}

.recent-jobs li h6 span {
   float: right;
   font-size: 13px;
   font-weight: normal;
}

.recent-jobs>li p {
   display: inline-block;
   font-size: 13px;
   line-height: 20px;
   margin-bottom: 0;
   width: 100%;
}

.recent-jobs li>span {
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   margin-top: 3px;
   text-transform: capitalize;
   width: 100%;
}

.recent-jobs li>span a {
   font-weight: normal;
   text-decoration: underline;
}

.recent-jobs>li {
   border-bottom: 1px solid #dedede;
   padding-bottom: 10px;
}

.recent-jobs>li:last-child {
   border-bottom: 0 none;
   padding-bottom: 0;
}

/*--- post a job widget ---*/
.post-job {
   border-radius: 5px;
   display: inline-block;
   position: relative;
   text-align: center;
   width: 100%;
   z-index: 2;
}

.post-job>img {
   max-width: 80px;
}

.post-job>span {
   display: inline-block;
   font-size: 23px;
   font-weight: 600;
   margin-top: 10px;
   text-transform: uppercase;
   width: 100%;
}

.post-job>p {
   display: inline-block;
   font-size: 13px;
   line-height: 20px;
   width: 100%;
   color: #eee;
}

/*--- company job posts widget ---*/
.company-posts>li figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: top;
}

.position-meta {
   display: inline-block;
   padding-left: 5px;
   vertical-align: middle;
   width: 77.5%;
}

.position-meta>h6 {
   color: #535165;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0;
}

.position-meta>span {
   float: right;
   font-size: 12px;
   margin-top: -5px;
   text-transform: capitalize;
}

.position-meta>a {
   display: inline-block;
   font-size: 12px;
   text-transform: uppercase;
}

.company-posts>li {
   border-bottom: 1px dashed #cdcdcd;
   padding-bottom: 15px;
}

.company-posts>li:last-child {
   border-bottom: 0;
   padding-bottom: 0;
}

/*--- event calendar widget ---*/
.jalendar .jalendar-container .jalendar-pages {
   background: none !important;
}

.jalendar .jalendar-container .jalendar-pages .days .day.this-month span {
   color: inherit;
   font-weight: 400;
   font-size: 12px;
}

.jalendar .jalendar-container .jalendar-pages>.header h1 {
   color: #535165;
   text-shadow: none;
}

.jalendar .jalendar-container .jalendar-pages .add-event .events .events-list .event-single a,
.jalendar .jalendar-container .jalendar-pages>.header .nxt-m,
.jalendar .jalendar-container .jalendar-pages>.header .prv-m {
   color: inherit;
}

.jalendar .jalendar-container .jalendar-pages .add-event .events .events-list .event-single {
   padding: 0;
   margin: 0;
}

.jalendar .jalendar-container .jalendar-pages .add-event .close-button {
   margin-bottom: -25px;
}

.jalendar .jalendar-container {
   margin-top: -30px;
}

.jalendar .jalendar-container .jalendar-pages .days .day.have-event span::before {
   border-radius: 0;
   bottom: 2px;
   top: auto;
   content: "";
   display: inline-block;
   height: 3px;
   position: absolute;
   right: 0;
   width: 100%;
}

.jalendar .jalendar-container .jalendar-pages .total-bar {
   background: #7750f8;
   line-height: 15px;
   height: 18px;
   width: 18px;
}

.jalendar {
   width: 100%;
}

.jalendar .jalendar-container .jalendar-pages .add-event.selected {
   background: #dde2e6 none repeat scroll 0 0;
   color: #535165;
}

.jalendar .jalendar-container .add-event.selected .events>h3 {
   color: #535165;
   font-size: 14px;
   margin-bottom: 10px;
}

.jalendar .jalendar-container .jalendar-pages .add-event .close-button {
   font-size: 13px;
}

.jalendar .jalendar-container .jalendar-pages .add-event .close-button:hover {
   background: #757a95;
}

/*--- calendar style end ---*/
.widget .featured-comp>li {
   float: left;
   text-align: center;
   width: 25%;
}

.company-posts>li figure img {
   border-radius: 100%;
}

.widget .featured-comp>li a img {
   border-radius: 100%;
}

.widget .featured-comp>li:nth-last-child(1),
.widget .featured-comp>li:nth-last-child(2),
.widget .featured-comp>li:nth-last-child(3),
.widget .featured-comp>li:nth-last-child(4) {
   margin-bottom: 0;
}

/*--- event invites page ---*/

.event-invites {
   border-bottom: 1px dashed #ddd;
   display: inline-block;
   margin-bottom: 30px;
   padding-bottom: 30px;
   width: 100%;
}

.invite-figure {
   display: inline-block;
   position: relative;
   width: 100%;
}

.invite-figure>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: auto;
}

.invite-figure>h6 {
   display: inline-block;
   font-size: 13px;
   font-weight: 400;
   margin-bottom: 0;
   padding-left: 10px;
   vertical-align: middle;
   width: 82%;
}

.invite-figure>h6>a {
   font-weight: 500;
   font-size: 14px;
}

.invite-figure>h6 a.invitor {
   color: #434155;
}

.invite-figure>figure img {
   border: 2px solid #ddd;
   border-radius: 100%;
}

.invite-figure>p {
   color: #858aa5;
   display: inline-block;
   font-size: 13px;
   line-height: 22px;
   margin-bottom: 0;
   margin-top: 10px;
   width: 100%;
}

.invite-location {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.invite-location>span {
   display: inline-block;
   width: 100%;
}

.invite-location .users-thumb-list>span {
   display: inline-block;
   font-size: 12px;
   vertical-align: middle;
   cursor: pointer;
}

.invite-location>i {
   font-size: 30px;
   color: #cdcdcd;
}

.invite-location>span.datentime {
   color: inherit;
   font-size: 13px;
}

.invite-location .users-thumb-list {
   margin-top: 5px;
}

.invites-btns {
   display: inline-block;
   margin-top: 22px;
   vertical-align: middle;
   width: 100%;
   text-align: center;
}

.invites-btns .main-btn {
   margin-bottom: 10px;
   padding: 6px 25px;
}

.invites-btns .main-btn3,
.invites-btns .main-btn2 {
   padding: 6px 25px;
}

.central-meta .event-invites:last-child {
   border-bottom: 0 none;
   margin-bottom: 0;
}

.event-invites>.row {
   margin-left: -10px;
   margin-right: -10px;
}

.users-thumb-list>span {
   font-size: 13px;
   font-weight: 500;
}

.mesg-seting form button {
   margin: 20px 0 0 10px;
}

/*--- friend's birthday page --*/
.birthday-box {
   background: #fff none repeat scroll 0 0;
   border-radius: 4px;
   display: inline-block;
   padding: 15px;
   position: relative;
   width: 100%;
   margin-bottom: 20px;
   border: 1px solid #ede9e9;
}

.birthday-box>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.birthday-box>figure img {
   border-radius: 100%;
}

.birthday-box .frnd-meta {
   display: inline-block;
   vertical-align: middle;
   width: 78%;
   padding: 0 0 0 10px;
}

.birthday-box .frnd-name {
   position: unset;
   vertical-align: middle;
   width: 49%;
}

.birthday-box .more-opotnz>ul {
   bottom: auto;
   top: 20px;
}

/*--- error page robot ---*/

/* svg {
   display: block;
   margin: 0 auto;
   overflow: visible !important;
} */

svg #robot_1_ {
   animation-name: verticalAnimation;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: ease-in-out;
}

svg #light_1_ {
   animation-name: blinkLight;
   animation-duration: 0.5s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: steps(2);
}

svg #leftEye_1_,
svg #rightEye_1_ {
   animation-name: blinkEye;
   animation-duration: 1.8s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: linear;
   transform-origin: 50% 50%;
}

svg #leftArm_1_ {
   animation-name: moveLeftArm;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: ease-in-out;
   transform-origin: 50% 10%;
   transition: all;
}

svg #rightArm_1_ {
   animation-name: moveRightArm;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: ease-in-out;
   transform-origin: 50% 10%;
}

svg #shadow_1_ {
   animation-name: shadowAnimation;
   animation-duration: 1s;
   animation-iteration-count: infinite;
   animation-direction: alternate;
   animation-timing-function: ease-in-out;
   transform-origin: 50%;
}

@keyframes wave {
   0% {
      transform: rotate(120deg);
   }

   100% {
      transform: rotate(170deg);
   }
}

@keyframes moveLeftArm {
   0% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(4deg);
   }
}

@keyframes moveRightArm {
   0% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(-4deg);
   }
}

@keyframes shadowAnimation {
   0% {
      transform: scale(1);
      opacity: 0.4;
   }

   100% {
      transform: scale(1.15);
      opacity: 0.6;
   }
}

@keyframes verticalAnimation {
   0% {
      transform: translateY(-10px);
   }

   100% {
      transform: translateY(10px);
   }
}

@keyframes blinkLight {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes blinkEye {
   0% {
      transform: scaleY(0);
   }

   4% {
      transform: scaleY(1);
   }
}

.eror-robot {
   background: #757a95 none repeat scroll 0 0;
   display: block;
   height: 100vh;
   padding: 40px 20px;
}

.robo-image {
   display: table;
   height: 85vh;
   width: 100%;
}

.v-center {
   display: table-cell;
   vertical-align: middle;
   width: 100%;
}

.robo-meta {
   color: #fff;
   display: table;
   height: 85vh;
   width: 100%;
}

.robo-meta h1 {
   font-size: 60px;
   font-weight: 300;
}

.robo-meta span {
   color: #dedede;
   display: inline-block;
   font-size: 22px;
   margin-bottom: 10px;
   width: 100%;
}

.robo-meta p {
   color: #dedede;
   display: inline-block;
   font-size: 15px;
   margin-bottom: 40px;
   width: 100%;
}

.eror500 .bg-image {
   background-size: unset;
   top: 26%;
}

.error-page500 .error-meta h1 {
   color: inherit;
   font-size: 35px;
   text-transform: capitalize;
}

.error-page500 .error-meta {
   padding-top: 120px;
}

.typography .progress {
   margin-bottom: 15px;
}

.progress-bar {
   background-color: #fa6342;
   font-size: 9px;
}

/*--- logout page ---*/
.again-login {
   display: inline-block;
   position: relative;
   width: 100%;
}

.again-login>input {
   background: #fff none repeat scroll 0 0;
   border: medium none;
   border-radius: 35px;
   color: #535165;
   font-size: 13px;
   line-height: initial;
   margin: 10px 0;
   padding: 14px 15px;
   width: 100%;
}

.again-login>button {
   margin-top: 14px;
}

.row.border-center>div {
   border-right: 1px solid #ccc;
   padding: 0 75px;
}

.row.border-center>div:last-child {
   border-right: 0 none;
}

.logout-form>a {
   display: inline-block;
   margin-top: 10px;
   width: 100%;
}

.logout-form>p {
   font-size: 12px;
   line-height: 20px;
   margin-top: 10px;
}

.user-log {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.user-log>i {
   background: #fff none repeat scroll 0 0;
   border-radius: 20px;
   cursor: pointer;
   left: 0;
   padding: 6px;
   position: absolute;
   top: 0;
}

.user-log>i:hover {
   color: #fff;
}

.user-log>a {
   display: inline-block;
   width: 100%;
}

.user-log>a>img {
   border: 5px solid #fff;
   border-radius: 100%;
}

.user-log>a span {
   color: #535165;
   display: inline-block;
   margin-top: 5px;
}

.already-log {
   display: inline-block;
   width: 100%;
}

.already-log>p {
   font-size: 13px;
   line-height: 20px;
}

.already-log>h4 {
   color: #535165;
}

.log-user {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.user-add {
   background: #fff none repeat scroll 0 0;
   border: 1px dashed #afafaf;
   border-radius: 5px;
   display: block;
   line-height: 137px;
   text-align: center;
   width: 100%;
   cursor: pointer;
}

.user-add>div {
   display: inline-block;
   line-height: initial;
   vertical-align: middle;
   width: 100%;
}

.user-add>div>i {
   font-size: 25px;
}

.user-add>div>span {
   display: inline-block;
   margin-top: 10px;
   width: 100%;
}

.logout-f {
   display: inline-block;
   width: 100%;
}

.logout-f>h4 {
   color: #535165;
}

.logout-f>p {
   font-size: 13px;
   line-height: 20px;
}

.logout-form {
   display: inline-block;
   margin-top: 10px;
   width: 100%;
}

.sub-total {
   display: inline-block;
   margin-top: 60px;
   width: 100%;
}

.total {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.total>i {
   border-radius: 100%;
   color: #fff;
   display: inline-block;
   font-size: 22px;
   height: 70px;
   line-height: 70px;
   width: 70px;
}

.total>span {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   margin-top: 10px;
   text-transform: capitalize;
   width: 100%;
}

.total>em {
   color: #34465d;
   font-size: 16px;
   font-style: normal;
}

/*--- login from popup ---*/

.popup.login {
   width: 350px;
}

.popup-meta {
   display: inline-block;
   width: 100%;
}

.login-frm {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.login-frm>input {
   background: #edf2f6 none repeat scroll 0 0;
   border: medium none;
   border-radius: 30px;
   font-size: 13px;
   margin: 5px 0;
   padding: 10px 15px;
   width: 100%;
}

.login-frm .main-btn {
   border: medium none;
   border-radius: 30px;
   color: #fff;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 15px;
   padding: 8px 10px;
   width: 100%;
}

.login-frm>a {
   display: inline-block;
   font-size: 13px;
   text-align: center;
   width: 100%;
}

/*--- badges page ---*/
.badge-box {
   background: #edf2f6 none repeat scroll 0 0;
   border-radius: 5px;
   display: inline-block;
   padding: 15px 10px 10px;
   text-align: center;
   width: 100%;
   margin-bottom: 30px;
}

.badge-box>figure {
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.badge-box>figure img {
   max-width: 80px;
}

.badge-box>h5 {
   color: #515365;
   display: inline-block;
   font-size: 18px;
   margin-top: 5px;
   text-transform: capitalize;
   width: 100%;
}

.badge-box>p {
   font-size: 13px;
   line-height: 20px;
}

.badge-caro {
   margin-bottom: 50px;
}

.badge-caro li>span {
   display: inline-block;
   font-size: 13px;
   line-height: 17px;
   margin-top: 5px;
   text-align: center;
   width: 100%;
}

.author-info {
   display: inline-block;
   margin-bottom: 40px;
   text-align: center;
   width: 100%;
}

.badge-caro li img {
   margin: 0 auto;
   max-width: 80px;
}

.badge-caro li {
   text-align: center;
}

.badge-caro .owl-item.active.center li img {
   max-width: 100px;
}

.badge-caro li .progress {
   margin-top: 8px;
}

/*--- search page ---*/

.search-meta {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.search-meta>span {
   color: #535165;
   display: inline-block;
   font-size: 24px;
   width: 100%;
}

.search-meta>span i {
   font-style: normal;
}

.search-tab {
   display: inline-block;
   width: 100%;
}

.search-tab>.nav-tabs {
   background: #fff none repeat scroll 0 0;
   border-radius: 5px;
   box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
   margin-bottom: 20px;
   width: 100%;
}

.search-tab .nav-tabs .nav-item>a {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   padding: 15px 19px;
   position: relative;
}

.search-tab .nav-tabs .nav-item>a.active::before {
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   border-top: 10px solid red;
   bottom: -9px;
   content: "";
   height: 2px;
   left: 50%;
   position: absolute;
   transform: translate(-50%);
}

.search-tab .nav-tabs .nav-item {
   margin-bottom: 0;
}

.search-tab .nav-tabs .nav-item>a.active::after {
   background: red none repeat scroll 0 0;
   bottom: 0;
   content: "";
   height: 1px;
   left: 0;
   position: absolute;
   width: 100%;
}

.widget .c-form.search {
   padding: 0 20px 20px;
}

.widget .c-form.search>div>label {
   margin-bottom: 10px;
}

.c-form.search .form-radio .radio {
   margin-bottom: 3px;
   margin-right: 0;
   width: 100%;
}

.c-form.search .radio>a {
   font-size: 13px;
}

.c-form.search>div {
   margin: 0 0 20px;
}

.c-form.search>div:last-child {
   margin-bottom: 0;
}

.widget .grey-box {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.grey-box>span {
   display: inline-block;
   margin-bottom: 10px;
}

.grey-box .setting-row {
   border-bottom: 0 none;
   padding-bottom: 0;
}

.fixed-sidebar .menu-left span {
   color: #b1b3c5;
   display: inline-block;
   font-size: 26px;
   font-weight: 500;
   height: 75vh;
   letter-spacing: -4px;
   text-align: center;
   text-orientation: upright;
   text-transform: uppercase;
   writing-mode: vertical-lr;
}

.fixed-sidebar .setting-row {
   padding-bottom: 27px;
}

.demo-box {
   background: #e2e7eb none repeat scroll 0 0;
   border-radius: 5px;
   color: #535165;
   display: inline-block;
   height: 270px;
   padding: 100px 20px;
   text-align: center;
   width: 100%;
}

.demo-box>span {
   display: inline-block;
   font-size: 20px;
   line-height: unset;
   vertical-align: middle;
   width: 100%;
}

.demo-box>p {
   color: #bdc2c6;
   display: inline-block;
   font-size: 12px;
   line-height: initial;
   margin: 0;
   padding: 0;
   vertical-align: middle;
   width: 100%;
}

span.ttl {
   margin-top: 10px;
}

.drag-widget {
   display: inline-block;
   padding: 10px 20px 20px;
   width: 100%;
}

.connected-sortable {
   margin: 0;
   padding-left: 0;
}

.drag-widget .connected-sortable>li {
   border: 1px dashed #c4c4c4;
   border-radius: 4px;
   cursor: move;
   padding: 9px 20px;
   list-style: none;
}

.available-widgets {
   display: inline-block;
   margin-top: 30px;
   width: 100%;
}

.available-widgets>span {
   color: #515365;
   display: inline-block;
   font-size: 20px;
   font-weight: 500;
   text-align: center;
   width: 100%;
}

.available-widgets>p {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 0;
   text-align: center;
   width: 100%;
}

.available-widgets .drag-widget .connected-sortable>li {
   background: #fff none repeat scroll 0 0;
   margin-bottom: 20px;
}

.available-widgets .drag-widget {
   padding-left: 0;
   padding-right: 0;
}

.available-widgets .drag-widget .connected-sortable>li:last-child {
   margin-bottom: 0;
}

.c-form.mng-page>button {
   margin: 0 5px;
   padding: 12px 25px;
}

.c-form.mng-page {
   margin-top: 30px;
}

.page-createbox {
   display: inline-block;
   width: 100%;
}

.page-createbox .row>div {
   margin-bottom: 20px;
   padding: 0 10px;
}

.social-name {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.c-form .social-name>label {
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.c-form .social-name>label>i {
   font-size: 18px;
}

.c-form .social-name>label>i.fa-facebook-square {
   color: #3b5998;
}

.c-form .social-name>label>i.fa-twitter-square {
   color: #55acee;
}

.c-form .social-name>label>i.fa-instagram {
   color: #3f729b;
}

.c-form .social-name>label>i.fa-pinterests {
   color: #bd081c;
}

.light-theme .chosen-container-single .chosen-single {
   background: var(--bs-gray-200);
   border: 1px solid var(--border-color);
   color: var(--bs-gray-900);
}

.dark-theme .chosen-container-single .chosen-single {
   background: var(--bs-gray-500);
   border: 1px solid var(--border-color);
   color: var(--bs-gray-200);
}

.title1 {
   color: #515365;
   display: inline-block;
   font-size: 18px;
   font-weight: 500;
   margin-bottom: 20px;
   margin-top: 10px;
   width: 100%;
}

/*--- statics ---*/

.static-meta {
   display: inline-block;
   padding: 0 20px;
   text-align: center;
   width: 100%;
}

.static-meta>i {
   display: inline-block;
   font-size: 30px;
   vertical-align: middle;
   color: #a5aac5;
}

.info-meta {
   display: inline-block;
   padding-left: 8px;
   vertical-align: middle;
   width: auto;
}

.info-meta>h4 {
   display: inline-block;
   font-size: 22px;
   margin-bottom: 0;
   vertical-align: middle;
   color: #535165;
}

.info-meta>i {
   font-style: normal;
   vertical-align: middle;
   font-size: 12px;
}

.widget>span {
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   padding-bottom: 10px;
   text-align: center;
   width: 100%;
   color: #838195;
}

.spark {
   display: inline-block;
   margin-top: 20px;
   text-align: center;
   width: 100%;
}

.quick-all {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.quick-all>h4 {
   color: #535165;
   font-size: 18px;
   margin-bottom: 0;
   margin-top: 10px;
}

.quick-all>p {
   font-size: 13.4px;
   margin-bottom: 0;
}

/*--- state box ---*/
.stat-box {
   background: #fff none repeat scroll 0 0;
   border: 1px solid #ede9e9;
   border-radius: 4px;
   display: inline-block;
   margin-bottom: 25px;
   padding: 25px 20px 15px;
   width: 100%;
}

.stat-meta {
   display: inline-block;
   width: 100%;
}

.stat-box>i {
   color: #b5bad5;
   display: inline-block;
   font-size: 35px;
   vertical-align: middle;
}

.anlytc-meta {
   display: inline-block;
   padding-left: 20px;
   vertical-align: middle;
   width: 85%;
}

.anlytc-meta>h4 {
   color: #535165;
   display: inline-block;
   margin-bottom: 5px;
   vertical-align: top;
   width: auto;
}

.anlytc-meta>p {
   float: right;
   font-size: 13px;
   margin-bottom: 0;
}

.anlytc-meta>span {
   display: inline-block;
   font-size: 13px;
   font-weight: 400;
   width: 100%;
}

.stat-box .progress {
   margin-top: 20px;
}

/*--- comments popup ---*/

.pit-comet-wraper {
   background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 999;
   display: none;
}

.pit-comet-wraper.active {
   display: block;
}

.pit-comet-popup {
   background: #fff none repeat scroll 0 0;
   height: 100vh;
   left: 50%;
   padding: 75px 20px 20px;
   position: absolute;
   top: 0;
   transform: translate(-50%);
   width: 300px;
}

.pit-popup-closed {
   cursor: pointer;
   display: inline-block;
   float: right;
}

.related-groups {
   padding: 0 30px !important;
}

.post-meta.searched .linked-image {
   width: auto;
   margin-bottom: 0;
}

.post-meta.searched .detail>p {
   margin-top: 10px;
   color: #757a95;
}

.post-meta.searched .detail {
   width: 74%;
}

/*--- search page friends search ---*/

.pit-friends,
.pit-groups,
.pit-pages {
   border-bottom: 1px dashed #cfcfcf;
   display: inline-block;
   margin-bottom: 15px;
   padding-bottom: 10px;
   width: 100%;
}

.pit-friends>figure,
.pit-groups>figure,
.pit-pages>figure {
   display: inline-block;
   margin: 0;
   vertical-align: middle;
   width: 45px;
}

.pit-friends>figure img,
.pit-groups>figure img,
.pit-pages>figure img {
   border-radius: 100%;
   display: inline-block;
   vertical-align: middle;
   width: 45px;
}

.pit-frnz-meta,
.pit-groups-meta,
.pit-pages-meta {
   display: inline-block;
   padding-left: 8px;
   vertical-align: middle;
   width: 91%;
}

.pit-friends .add-remove-frnd,
.pit-groups .add-remove-frnd,
.pit-pages .add-remove-frnd {
   float: right;
   margin: 0;
   position: relative;
   top: -10px;
   width: auto;
   padding: 0;
}

.pit-frnz-meta>a,
.pit-groups-meta>a,
.pit-pages-meta>a {
   color: #535165;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   width: 100%;
}

.pit-frnz-meta>i,
.pit-groups-meta>i,
.pit-pages-meta>i {
   display: inline-block;
   font-size: 12px;
   font-style: normal;
}

.central-meta.item .pit-friends:last-child,
.central-meta.item .pit-groups:last-child,
.central-meta.item .pit-pages:last-child {
   border: 0 none;
   margin-bottom: 0;
   padding: 0;
}

.related-searches {
   display: inline-block;
   margin-bottom: 0;
   padding-left: 20px;
   width: 100%;
}

.related-searches>li {
   list-style: outside none disc;
   margin-bottom: 5px;
   width: 100%;
}

.related-searches>li>a {
   transition: all 0.2s linear 0s;
}

/*--- wiki box ---*/
.wiki-box {
   background: #fff none repeat scroll 0 0;
   display: inline-block;
   padding: 15px;
   width: 100%;
   margin-bottom: 20px;
}

.wiki-box>h4 {
   display: inline-block;
   font-size: 13px;
   font-weight: normal;
}

.wiki-box>h4>a {
   color: #535165;
}

.wiki-box img {
   display: inline-block;
   vertical-align: bottom;
   width: 30px;
}

.wiki-box>p {
   font-size: 12px;
   line-height: 21px;
}

.helpful {
   display: inline-block;
   width: 100%;
}

.helpful>span {
   font-size: 10px;
}

.wiki-box>p>span {
   display: inline-block;
   width: 100%;
   color: #535165;
   font-weight: 500;
}

.wiki-box>p>a {
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   text-align: center;
}

.forum-form>.central-meta {
   margin-bottom: 0;
}

.forum-form {
   display: inline-block;
   width: 100%;
}

.forum-form .c-form>div {
   margin-bottom: 30px;
}

/*--- progress circle in percentage ---*/

/*--- weather top head ---*/

.weather-header {
   display: inline-block;
   padding: 50px 0;
   position: relative;
   width: 100%;
   border-radius: 5px;
}

.weather-header:before {
   border-radius: 5px;
}

.weather-header .bg-image {
   z-index: 0;
   border-radius: 5px;
}

.time {
   color: #fff;
   float: right;
   position: absolute;
   right: 20px;
   top: 20px;
   z-index: 2;
}

.weather-info {
   float: left;
   width: 100%;
}

.weather-top {
   color: #fff;
   display: inline-block;
   position: relative;
   text-align: center;
   width: 100%;
   z-index: 2;
}

.weather-top .weather-week {
   vertical-align: middle;
   width: auto;
}

.weather-area {
   display: inline-block;
   text-align: left;
   vertical-align: middle;
}

.weather-area>h2 {
   font-size: 44px;
   font-weight: 300;
   margin-bottom: 0;
}

.weather-area>h2>span {
   font-size: 20px;
   font-weight: bold;
}

.weather-area>span {
   font-size: 30px;
   font-weight: 300;
}

.weather-area>em {
   font-style: normal;
}

/*--- weather hourly ---*/

.hourly-forecast {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 0;
   width: 100%;
}

.hourly-forecast>li {
   background: #e6ebef none repeat scroll 0 0;
   border-radius: 4px;
   display: inline-block;
   padding: 8px 0;
   text-align: center;
   width: 94.9px;
}

.hourly-forecast>li>i {
   color: #a5aac5;
   font-size: 30px;
   line-height: 47px;
   width: 100%;
}

.hourly-forecast>li>span {
   display: inline-block;
   font-size: 18px;
   width: 100%;
   color: #535165;
}

.hourly-forecast>li>em {
   display: inline-block;
   font-size: 13px;
   font-style: normal;
   margin-top: 5px;
   width: 100%;
}

.table th {
   border-top: 0 none;
   color: #535165;
   font-weight: 500;
   text-transform: capitalize;
   font-size: 13.4px;
}

.monthly-forecast.table tbody tr td>span {
   display: inline-block;
   text-align: left;
   width: 60px;
}

.monthly-forecast.table tbody tr td {
   font-size: 13.4px;
}

/*--- recent links widget ---*/

/*--- useful links widgets ---*/
aside .widget.pitnik-links>ul {
   padding: 0;
   text-align: center;
}

aside .widget.pitnik-links>ul>li {
   display: inline;
}

.widget.pitnik-links {
   padding: 15px;
}

aside .widget.pitnik-links>ul>li>a {
   padding: 3px 10px;
}

.widget.pitnik-links>p {
   color: #959ab5;
   display: inline-block;
   font-size: 12px;
   margin-bottom: 0;
   margin-top: 15px;
   text-align: center;
   width: 100%;
}

/*--- send direct message popup ---*/
/* .popup.direct-mesg {
   width: 400px;
} */

/* .send-message {
   display: inline-block;
   margin-top: 15px;
   width: 100%;
   position: relative;
} */

/* .send-message .c-form>input {
   margin-bottom: 10px;
} */

/* .popup.direct-mesg form>textarea {
   margin-bottom: 15px;
} */

/* .send-message .add-smiles {
   bottom: 0;
   left: 3px;
   right: auto;
   top: auto;
} */

/* .send-message .smiles-bunch {
   left: 20px;
   right: auto;
} */

/* .send-message .smiles-bunch::before {
   left: 12px;
   right: auto;
} */

/*--- support and help page ---*/

.comon-help>.main-btn {
   border-radius: 30px;
   width: 100%;
}

.color-red {
   color: #e44a3c;
}

.color-purple {
   color: #7750f8;
}

.color-orange {
   color: #fa6342;
}

.color-blue {
   color: #23d2e2;
}

.color-green {
   color: #38bff1;
}

/*--- help page video section ---*/

.pitnik-video-help {
   display: inline-block;
   padding: 50px 0;
   width: 100%;
}

.pitnik-video-help>i {
   font-size: 15px;
   font-style: normal;
}

.pitnik-video-help>h4 {
   color: #535165;
   font-size: 35px;
   font-weight: 400;
   margin-bottom: 20px;
   margin-top: 10px;
}

.pitnik-video-help>p {
   color: #757a95;
   font-size: 18px;
   line-height: 30px;
   margin-bottom: 25px;
}

/*--- job overview widget ---*/

.sidebar ul.job-overview>li>i {
   font-size: 18px;
}

.carer-overview {
   display: inline-block;
   vertical-align: top;
}

.carer-overview>span {
   color: #535165;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   width: 100%;
}

.carer-overview>em {
   font-size: 13px;
   font-style: normal;
}

.sidebar .widget .job-overview>li {
   border-bottom: 1px solid #eee;
   padding-bottom: 15px;
}

.sidebar .widget .job-overview>li:last-child {
   border: 0 none;
   padding-bottom: 0;
}

.conect-socials {
   display: inline-block;
   margin-bottom: 10px;
   padding: 0 20px;
   width: 100%;
}

.conect-socials>a {
   border-radius: 4px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   margin-bottom: 10px;
   padding: 9px;
   text-align: center;
   width: 100%;
   text-transform: capitalize;
}

.job-map {
   float: left;
   margin-top: -20px;
   width: 100%;
   height: 300px;
}

.job-map #map-canvas {
   height: 300px;
}

.attachments>ul .add-loc {
   cursor: pointer;
   display: block;
   font-size: 20px;
   margin-top: -5px;
}

.add-location-post {
   width: 100%;
   margin-top: 15px;
   display: none;
}

.add-location-post #map-canvas {
   height: 200px;
}

/*--- main menu nav list ---*/

.topbar>nav {
   margin-left: 55px;
   position: relative;
   width: calc(100% - 110px);
   z-index: -1;
}

.main-menu>span {
   cursor: pointer;
}

.main-menu>span i img {
   display: inline-block;
   height: 22px;
   width: 22px;
}

.top-area>ul>li>a>img {
   width: 24px;
   height: 24px;
}

/*--- job career page ---*/
.pit-rate {
   display: list-item;
   list-style: outside none none;
   margin-top: -6px;
   padding-left: 0;
   text-align: center;
   width: 100%;
}

.pit-rate>li {
   color: #c9c9c9;
   display: inline-block;
   font-size: 11px;
   line-height: 13px;
}

.pit-rate>li.rated {
   color: orange;
}

/*--- pitrest page ---*/
.pitrest-title {
   color: #535165;
   font-size: 28px;
   margin-bottom: 40px;
   margin-top: 20px;
   text-transform: capitalize;
}

.row.merged-8>div {
   padding: 0 7.5px;
}

.pitrest-post {
   border-radius: 8px;
   display: inline-block;
   margin-bottom: 15px;
   position: relative;
   width: 100%;
}

.pitrest-post img {
   border-radius: 8px;
   width: 100%;
}

.add-pitrest>a {
   border: 2px solid;
   border-radius: 100%;
   display: inline-block;
   font-size: 20px;
   height: 45px;
   line-height: 42px;
   text-align: center;
   width: 45px;
   margin-top: -4px;
}

.add-pitrest {
   display: inline-block;
   text-align: right;
   width: 100%;
   margin-top: 10px;
}

.pitrest-post:hover::before {
   opacity: 1;
   visibility: visible;
}

.pitrest-post::before {
   background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
   bottom: 0;
   content: "";
   left: 0;
   opacity: 0;
   position: absolute;
   right: 0;
   top: 0;
   border-radius: 8px;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   z-index: 0;
}

.popup .postbox {
   padding-top: 10px;
}

.pitrest-post:hover .over-photo {
   bottom: 8px;
   opacity: 1;
   visibility: visible;
}

.post-menu {
   color: #fff;
   font-size: 18px;
   line-height: initial;
   position: absolute;
   right: 13px;
   top: 5px;
   z-index: 2;
}

.pitrest-menu {
   cursor: pointer;
   display: inline-block;
   line-height: initial;
}

.pitrest-post .more {
   position: absolute;
   right: 10px;
   top: 10px;
   z-index: 6;
}

.pitrest-post .more-post-optns>i {
   color: #fff;
}

.pitrest-post .more:hover .more-post-optns>i {
   color: #515365;
}

.pitrest-pst-hding {
   bottom: 15px;
   color: #fff;
   display: inline-block;
   font-size: 18px;
   left: 0;
   margin-bottom: 0;
   overflow: hidden;
   padding: 0 10px;
   position: absolute;
   text-overflow: ellipsis;
   transition: all 0.2s linear 0s;
   white-space: nowrap;
   width: 100%;
}

.pitrest-post:hover .pitrest-pst-hding {
   bottom: 40px;
}

.share-pst {
   transition: all 0.1s linear 0s;
}

/*--- animated heart ---*/
.heart {
   height: 20px;
   transform: translateZ(0);
   color: #b3b1c5;
   font-size: 16px;
   cursor: pointer;
   position: relative;
   transition: all 0.3s ease;
}

.heart:hover {
   animation: pulse 0.6s linear;
}

.heart:before {
   content: "â¤";
   position: absolute;
   color: #a12b2b;
   opacity: 0;
}

.heart>span {
   color: #fff;
   display: inline-block;
   font-size: 13px;
   vertical-align: text-top;
}

.heart.happy {
   color: #a12b2b;
}

.heart.happy:before {
   opacity: 0;
   transform: translateY(-30px) rotateZ(5deg);
   animation: fly 1s ease;
}

.heart.broken {
   color: #aaa;
   position: relative;
   transition: all 0.3s ease;
}

.heart.broken:before,
.heart.broken:after {
   content: "â¤";
   opacity: 1;
   color: #ccc;
   position: absolute;
   top: -150px;
   transform: scale(3) rotateZ(0);
}

.heart.broken:before {
   clip: rect(0, 20px, 200px, 0);
   animation: break-left 1s ease forwards;
}

.heart.broken:after {
   clip: rect(0, 50px, 200px, 25px);
   animation: break-right 1s ease forwards;
}

@keyframes pulse {
   50% {
      transform: scale(1.1);
   }
}

@keyframes fly {
   0% {
      opacity: 0;
      transform: translateY(-20px) rotateZ(15deg);
   }

   50% {
      opacity: 0.75;
      transform: scale(4) translateY(-30px) rotateZ(-15deg);
   }

   100% {
      opacity: 0;
      transform: scale(4) translateY(-50px) rotateZ(15deg);
   }
}

@keyframes break-left {
   0% {
      opacity: 1;
      transform: scale(3) rotateZ(0);
   }

   20% {
      opacity: 0.5;
      transform: scale(3) translateX(-10px) rotateZ(-20deg) translateY(0);
   }

   50% {
      opacity: 0.5;
      transform: scale(3) translateX(-10px) rotateZ(-20deg) translateY(0);
   }

   100% {
      opacity: 0;
      transform: scale(3) translateX(-30px) rotateZ(-25deg) translateY(50px);
   }
}

@keyframes break-right {
   0% {
      opacity: 1;
      transform: scale(3) rotateZ(0);
   }

   20% {
      opacity: 0.5;
      transform: scale(3) translateX(10px) rotateZ(20deg) translateY(0);
   }

   50% {
      opacity: 0.5;
      transform: scale(3) translateX(10px) rotateZ(20deg) translateY(0);
   }

   100% {
      opacity: 0;
      transform: scale(3) translateX(30px) rotateZ(25deg) translateY(50px);
   }
}

/*--- share post popup ---*/
.popup.post-sharing {
   width: 450px;
}

.popup-head .chosen-container.chosen-container-multi {
   width: 80% !important;
}

.popup-head .chosen-choices {
   background: #edf2f6 none repeat scroll 0 0;
   border: medium none;
   border-radius: 4px;
   box-shadow: none;
   padding: 3px 7px;
}

.popup-meta .post-comt-box {
   padding-left: 0;
   width: 100%;
}

.popup-meta .post-comt-box textarea {
   margin-bottom: 10px;
}

.postbox .we-video-info>ul {
   width: auto;
}

.postbox .we-video-info>button {
   background: #888da8 none repeat scroll 0 0;
   border: medium none;
   border-radius: 30px;
   color: #fff;
   float: right;
   font-size: 14px;
   margin: 0 3px;
   padding: 5px 20px;
   transition: all 0.2s linear 0s;
}

.postbox .we-video-info>button:hover {
   color: #fff;
}

.postbox .post-comt-box form input {
   background: #edf2f6 none repeat scroll 0 0;
   border: medium none;
   border-radius: 4px;
   color: inherit;
   margin-bottom: 5px;
   padding: 10px;
   width: 100%;
}

.postbox .add-smiles {
   bottom: 10px;
   top: auto;
}

.postbox .friend-name {
   width: 89%;
   margin-bottom: 10px;
}

.postbox .we-video-info>button.main-btn.color {
   color: #fff;
}

.share-to-other {
   display: inline-block;
   width: 69%;
   margin-bottom: 10px;
   vertical-align: middle;
}

.share-to-other>span,
.copy-email>span {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   margin-bottom: 5px;
   width: 100%;
}

.share-to-other>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.share-to-other>ul li {
   display: inline-block;
   font-size: 16px;
   margin-right: 10px;
}

.postbox .we-video-info {
   padding-bottom: 0;
}

.share-to-other>ul>li a:hover i {
   transform: scale(1.2);
}

.copy-email {
   display: inline-block;
   margin-bottom: 10px;
   vertical-align: middle;
   width: 30%;
   text-align: right;
}

.copy-email>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.copy-email>ul>li {
   display: inline-block;
   margin-right: 4px;
}

.copy-email>ul li a {
   background: #888da8 none repeat scroll 0 0;
   border-radius: 100%;
   display: inline-block;
   height: 30px;
   line-height: 30px;
   text-align: center;
   width: 30px;
   color: #fff;
   transition: all 0.2s linear 0s;
}

.pitrest-post .user-avatr {
   border: 2px solid #fff;
   border-radius: 100%;
   left: 10px;
   position: absolute;
   top: 10px;
   width: auto;
}

.pitrest-post .user-avatr img {
   border-radius: 100%;
}

.post-status {
   display: inline-block;
   position: relative;
   vertical-align: middle;
   z-index: 222;
}

.post-status>ul {
   left: -40px;
   list-style: outside none none;
   margin: 0;
   opacity: 0;
   padding: 0;
   position: absolute;
   top: 100%;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 115px;
}

.post-status:hover>ul {
   opacity: 1;
   visibility: visible;
}

.post-status>span {
   display: inline-block;
   font-size: 20px;
   margin-left: 6px;
}

.post-status>ul li {
   background: #fff none repeat scroll 0 0;
   border-bottom: 1px solid #fff;
   padding: 4px 10px;
   transition: all 0.2s linear 0s;
}

.post-status>ul li:hover {
   color: #fff;
}

.post-status>ul li>a {
   display: inline-block;
   font-size: 12px;
   width: 100%;
}

.post-status>ul li>a i {
   font-size: 14px;
   margin-right: 3px;
}

/*--- job career page ---*/

.job .post-meta {
   margin-top: 0;
}

.job .friend-name>span {
   float: right;
   width: auto;
}

.friend-info>ol.pit-rate {
   color: orange;
   float: right;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: auto;
}

.friend-info>ol.pit-rate li {
   display: inline-block;
   margin-right: 1px;
}

.post-meta>h6 {
   color: #515365;
   display: inline-block;
   font-size: 18px;
   font-weight: 600;
   max-width: 87%;
   margin-bottom: 0;
}

.post-meta>h6>a {
   display: inline-block;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.loc-cate {
   display: inline-block;
   width: 100%;
}

.loc-cate>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
}

.loc-cate .cate {
   float: right;
}

.loc-cate>ul>li {
   display: inline-block;
   font-size: 12px;
   margin-right: 5px;
   position: relative;
   vertical-align: middle;
}

.loc-cate>ul>li>span {
   color: #333;
   font-size: 14px;
   font-weight: 500;
}

.loc-cate>ul.loc>li:before {
   display: none;
}

.loc-cate>ul>li:last-child {
   margin-right: 0;
}

.loc-cate>ul>li::before {
   bottom: 0;
   content: ",";
   position: absolute;
   right: -5px;
}

.loc-cate>ul>li:last-child::before {
   display: none;
}

.loc-cate>ul.loc>li {
   margin-right: 20px;
}

.job .description {
   margin-top: 15px;
}

.job .description>p {
   color: #656a85;
   font-size: 14px;
   line-height: 21px;
}

.loc-cate>ul>li {
   color: #9c9c9c;
}

/*--- job search page ---*/
.searched .friend-info .friend-name span {
   float: left;
}

.searched .friend-name {
   width: 80%;
}

.searched .post-meta {
   margin-top: 15px;
}

.searched .post-meta .rate-n-apply {
   margin-bottom: 0;
}

.searched .job .description {
   margin-top: 4px;
}

/*--- job search box ---*/

.job-search-form {
   display: inline-block;
   width: 100%;
}

.jobbox-title {
   float: left;
}

.job-search-form>a {
   float: right;
   font-size: 13px;
   font-weight: 500;
   margin-top: 10px;
}

.job-search-form .c-form {
   margin-top: 20px;
}

.jobbox-title>h5 {
   color: #535165;
   display: inline-block;
   font-size: 24px;
   margin-bottom: 0;
   width: 100%;
}

.jobbox-title>span {
   font-size: 13px;
}

.job-search-form .c-form .main-btn {
   border-radius: 5px;
   float: left;
   margin-left: 0;
   padding: 15px 10px;
   width: 100%;
}

/*--- user feature info box ---*/
.user-feature-info {
   background: #fff none repeat scroll 0 0;
   border-radius: 5px;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   display: inline-block;
   margin-bottom: 50px;
   margin-left: 40px;
   margin-top: -228px;
   padding: 20px !important;
   position: relative;
   width: 770px;
   z-index: 2;
}

.user-figure {
   display: inline-block;
   width: 100%;
}

.user-figure>figure {
   border-radius: 100%;
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.user-figure>figure>img {
   border: 2px solid #ccc;
   border-radius: 100%;
   max-width: 100px;
   min-width: 100px;
}

.user-figure>a {
   display: inline-block;
   font-size: 11px;
   padding-left: 13px;
   text-align: center;
   text-transform: capitalize;
}

.author-meta {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: 70%;
}

.author-meta>h5 {
   color: #535165;
   float: left;
   font-size: 20px;
   line-height: 17px;
   margin-bottom: 0;
   width: 100%;
}

.author-meta>span {
   float: left;
   font-size: 12px;
   padding-left: 20px;
}

.author-meta>ins {
   color: #23d2e2;
   display: inline-block;
   font-size: 13px;
   font-style: normal;
   margin-top: 5px;
   text-decoration: none;
   width: 100%;
}

.user-info {
   display: inline-block;
   padding: 0;
   width: 100%;
}

.user-info>li {
   display: inline-block;
   width: 100%;
}

.user-info>li span {
   display: inline-block;
   font-weight: 500;
   width: 35px;
}

.user-inf-meta {
   display: inline-block;
   width: 100%;
}

.user-inf-meta .pit-rate {
   display: inline-block;
   margin: 0 5px 0 0;
   width: auto;
}

.user-inf-meta>span {
   font-size: 12px;
}

.user-inf-meta .pit-rate>li {
   font-size: 14px;
}

.job-progres {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   margin-top: 15px;
   padding-left: 0;
   width: 100%;
}

.job-progres>li {
   color: #535165;
   display: inline-block;
   margin-right: 62px;
}

.job-progres>li:last-child {
   margin-right: 0;
}

.job-progres>li>span {
   font-weight: 500;
}

.sharing-tools .share-to-other {
   margin-bottom: 0;
   text-align: right;
   width: 34%;
}

.sharing-tools .we-video-info>ul {
   margin-top: 0;
}

.sharing-tools {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.color-valvet {
   color: #7750f8;
}

/*--- Pitred page ---*/
.featured-baner {
   display: inline-block;
   position: relative;
   width: 100%;
}

.featured-baner>img {
   display: inline-block;
   width: 100%;
}

.container-fluid.ext-right {
   padding-left: 30px;
   padding-right: 100px;
}

.featured-baner>h3 {
   color: #fff;
   left: 40px;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   z-index: 2;
   margin-bottom: 0;
}

.pitred-links>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   vertical-align: middle;
}

.con-pts {
   float: right;
}

.pitred-links>ul>li {
   display: inline-block;
   margin-right: 32px;
}

.pitred-links>ul>li:last-child {
   margin-right: 0;
}

.pitred-links>ul>li .more {
   float: unset;
}

.pitred-links {
   margin-top: 20px;
}

.coin-btn {
   background: #f4b334 none repeat scroll 0 0;
   border-radius: 8px;
   color: #895f0d;
   display: inline-block;
   padding: 6px 16px;
   text-align: center;
   transition: all 0.2s linear 0s;
   vertical-align: sub;
}

.pit-points {
   display: inline-block;
   margin-left: 10px;
   vertical-align: sub;
}

.pit-points>span {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   vertical-align: middle;
}

.pit-points>i {
   font-size: 30px;
   font-style: normal;
   font-weight: 500;
   margin-left: 10px;
   vertical-align: middle;
}

.pitred-links>ul>li a {
   color: #535165;
   font-weight: 500;
}

.coin-btn:hover {
   background: #f1a40c none repeat scroll 0 0;
   color: #794f10;
}

.menu-list {
   background: #fff none repeat scroll 0 0;
   border-radius: 5px;
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.menu-list>li {
   display: inline-block;
   width: 100%;
}

.menu-list>li>a {
   border-bottom: 1px solid #eaeaea;
   color: #535165;
   display: inline-block;
   font-weight: 500;
   padding: 13px 20px;
   width: 100%;
}

.menu-list>li>a>i {
   font-size: 16px;
   margin-right: 15px;
}

.menu-list>li>a:hover {
   background: #edf2f6 none repeat scroll 0 0;
}

aside.sidebar .frnd-meta>a {
   display: block;
   margin: 20px auto 0;
   padding: 6px 0;
   text-align: center;
}

aside.sidebar .friend-box .frnd-meta {
   border-bottom: 1px solid #eaeaea;
}

/*---- pitred post ---*/
.pit-post {
   display: inline-block;
   position: relative;
   width: 100%;
}

.pit-post>figure {
   display: inline-block;
   margin-bottom: 0;
   position: relative;
   vertical-align: top;
}

.pit-post>figure>i {
   background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
   border-radius: 9px 0 0;
   bottom: -1px;
   color: #000;
   padding: 5px;
   position: absolute;
   right: 0;
}

.pit-post-deta {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: 54%;
   position: relative;
}

.pit-post>figure>span {
   background: #0084b4 none repeat scroll 0 0;
   color: #fff;
   height: 15px;
   left: 0;
   position: absolute;
   text-align: center;
   top: 7px;
   width: 35px;
}

.pit-post>figure>span i {
   display: inline-block;
   transform: rotate(90deg);
   vertical-align: text-top;
}

.pit-post-deta>h4 {
   color: #535165;
   font-size: 17.5px;
   max-width: 100%;
   margin-bottom: 5px;
   position: relative;
}

.pit-post-deta>h4>i {
   color: #999;
   display: inline-block;
   font-size: 14px;
   margin-right: 5px;
}

.post-up-time {
   display: inline-block;
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
   margin-bottom: 10px;
}

.post-up-time>li {
   color: #959ab5;
   display: inline-block;
   font-size: 11.5px;
   margin-right: 20px;
}

.pit-opt>li>em {
   color: #515365;
   font-size: 11px;
   font-style: normal;
   margin-left: 4px;
}

.pit-opt {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.pit-opt>li {
   display: inline-block;
   margin-right: 25px;
   color: #959ab5;
   cursor: pointer;
   position: relative;
}

.pit-opt>li:last-child {
   margin-right: 0;
}

.pit-opt>li .heart>span {
   color: inherit;
}

.pit-opt>li.save {
   transform: scale(1.1);
}

.right-pst-meta {
   display: inline-block;
   text-align: right;
   width: 220px;
   vertical-align: middle;
}

.right-pst-meta>a {
   background: #95f2cc none repeat scroll 0 0;
   border-radius: 20px;
   color: #079159;
   display: inline-block;
   font-size: 11px;
   line-height: 18px;
   text-align: center;
   vertical-align: middle;
   width: 60px;
}

.right-pst-meta>a.blu {
   background: #a7aaff none repeat scroll 0 0;
   color: #4c4eaa;
}

.right-pst-meta>a.rad {
   background: #f67777 none repeat scroll 0 0;
   color: #ac2525;
}

.pit-post .users-thumb-list {
   display: inline-block;
   margin-top: 15px;
}

.pit-post .users-thumb-list>span {
   font-size: 11px;
   letter-spacing: -0.5px;
}

.pit-post .users-thumb-list>a {
   margin-left: -21px;
}

.number {
   background: #c8d5e6;
   display: inline-block;
   text-align: center;
   vertical-align: middle;
   width: 35px;
   border-radius: 5px;
   float: right;
}

.number>input {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   border: medium none;
   color: #515365;
   text-align: center;
   width: 33px;
   padding: 2px 0;
}

.number>span {
   cursor: pointer;
   display: inline-block;
   padding: 5px 0;
   position: relative;
   width: 100%;
}

.number>span>i {
   color: #515365;
   font-size: 20px;
   position: relative;
   z-index: 3;
}

.number>span:hover {
   background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
   border-radius: 5px 5px 0 0;
}

.number>span.minus:hover {
   border-radius: 0 0 5px 5px;
}

/*--- trending posts ---*/

.pitred-trendings {
   margin-bottom: 0;
   padding: 0;
}

.pitred-trendings li>figure {
   display: inline-block;
   margin: 0;
   position: relative;
   width: 100%;
   overflow: hidden;
}

.pit-post>figure>img {
   border-radius: 5px;
}

.pitred-trendings li>figure>img {
   transition: all 0.2s linear 0s;
}

.pitred-trendings li:hover>figure>img {
   transform: scale(1.1);
}

.pitred-trendings li>figure::before {
   background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0;
   border-radius: 4px;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 1;
   transition: all 0.2s linear 0s;
}

.pitred-trendings li:hover>figure::before {
   background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
}

.pitred-trendings li .pit-uzr {
   bottom: 15px;
   display: inline-block;
   left: 0;
   padding-left: 10px;
   position: absolute;
   width: 100%;
   z-index: 2;
}

.post-up-time>li .usr-fig {
   float: none;
   width: auto;
}

.pitred-trendings li .pit-uzr .usr-fig img,
.post-up-time>li .usr-fig>img {
   border: 1px solid rgba(255, 255, 255, 0.7);
   border-radius: 100%;
   display: inline-block;
   max-width: 25px;
   vertical-align: middle;
   width: auto;
}

.pit-uzr>a {
   color: #fff;
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   line-height: 18px;
   max-width: 100%;
   overflow: hidden;
   text-overflow: ellipsis;
   transition: all 0.2s linear 0s;
   white-space: nowrap;
   width: 100%;
}

.pitred-trendings li {
   float: left;
   width: 100%;
}

.usr-fig {
   float: left;
   width: 100%;
}

.usr-fig>a,
.post-up-time>li .usr-fig>a {
   color: #acacac;
   font-size: 10.5px;
   text-decoration: underline;
}

.pitred-trendings li>figure img {
   border-radius: 6px;
}

.pst-change-style {
   display: inline-block;
   width: 100%;
}

.pst-change-style>span {
   color: #535165;
   font-size: 15px;
}

.view-pst-style {
   display: inline-block;
   margin-bottom: 0;
   padding-left: 15px;
   vertical-align: middle;
}

.pst-change-style .chosen-container {
   vertical-align: middle;
   width: 130px !important;
}

.view-pst-style>li {
   display: inline-block;
   font-size: 20px;
   list-style: outside none none;
   margin-right: 7px;
}

.sub-cat {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.sub-cat .chosen-single,
.pst-change-style .chosen-single {
   background: #ffffff none repeat scroll 0 0;
}

.sub-cat .chosen-single>span,
.pst-change-style .chosen-single>span {
   color: #535165;
   font-weight: 400;
}

.view-pst-style>li>a {
   color: #bcbcbc;
   display: inline-block;
}

/*--- top community widget ---*/
.top-comunitez {
   border-radius: 5px;
   display: inline-block;
   position: relative;
   width: 100%;
}

.top-comunitez>figure {
   border-radius: 5px 5px 0 0;
   display: inline-block;
   margin-bottom: 0;
   position: relative;
   width: 100%;
}

.top-comunitez>figure>span {
   bottom: 10px;
   color: #fff;
   font-weight: 500;
   left: 15px;
   position: absolute;
   width: 100%;
   z-index: 2;
}

.top-comunitez>figure::before {
   background: #021727 none repeat scroll 0 0;
   border-radius: 5px 5px 0 0;
   bottom: 0;
   content: "";
   left: 0;
   opacity: 0.6;
   position: absolute;
   right: 0;
   top: 0;
}

.top-comunitez>figure>img {
   border-radius: 5px;
   width: 100%;
}

.top-comuty {
   list-style: outside none number;
   margin-bottom: 0;
   padding: 20px 20px 20px 30px;
   position: relative;
}

.sidebar .top-comuty>li {
   border-bottom: 1px solid #eaeaea;
   display: list-item;
   list-style: outside none number;
   margin-bottom: 10px;
   padding-bottom: 10px;
   padding-left: 10px;
}

.top-comuty>li>img {
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 100%;
   display: inline-block;
   vertical-align: middle;
}

.sidebar .widget .top-comuty>li>a {
   padding-left: 10px;
   vertical-align: middle;
}

.sidebar .top-comuty>li:last-child {
   border: 0 none;
   margin-bottom: 0;
   padding-bottom: 0;
}

.tags_ {
   display: inline-block;
   margin-bottom: 15px;
   padding: 0 15px;
   width: 100%;
}

.tags_>a {
   border: 1px solid #eaeaea;
   border-radius: 4px;
   display: inline-block;
   font-size: 10.2px;
   padding: 0 6px;
   transition: all 0.2s linear 0s;
}

.tags_>a:hover {
   color: #fff;
}

.top-comunitez>a.main-btn {
   border-radius: 0;
   display: inline-block;
   padding: 4px;
   text-align: center;
   width: 100%;
}

.post-title {
   background: #46586f none repeat scroll 0 0;
   border-radius: 4px 4px 0 0;
   display: inline-block;
   padding: 14px 10px;
   width: 100%;
}

.post-title>h6 {
   display: inline-block;
   margin-bottom: 0;
   color: #fff;
   vertical-align: middle;
   font-size: 20px;
}

.post-title>span {
   border: 1px solid #8c8c8c;
   border-radius: 4px;
   font-size: 11px;
   padding: 2px 8px;
   text-transform: capitalize;
   color: #dcdcdc;
   vertical-align: middle;
}

.post-title>h6>i {
   color: #a6a6a6;
   margin-right: 10px;
}

.post-title.gry-bg>h6>i {
   color: #535165;
}

.post-title .number {
   width: 100px;
}

.post-title .number>span {
   width: 29px;
}

/*---- report popup ---*/
.Rpt-meta {
   display: inline-block;
   width: 100%;
}

.Rpt-meta>span {
   display: inline-block;
   margin-top: 10px;
}

.Rpt-meta .main-btn3.cancel {
   float: right;
}

.copy-notification {
   color: #ffffff;
   background-color: rgba(0, 0, 0, 0.8);
   padding: 10px 20px;
   border-radius: 30px;
   position: fixed;
   top: 50%;
   left: 50%;
   width: 150px;
   margin-top: -30px;
   margin-left: -85px;
   display: none;
   text-align: center;
   z-index: 999999;
}

/*--- create post widget ---*/
.post-creat {
   display: inline-block;
   width: 100%;
}

.bg-feature {
   display: inline-block;
   position: relative;
   vertical-align: middle;
   width: 70px;
}

.create-meta {
   display: inline-block;
   padding-left: 15px;
   vertical-align: middle;
   width: 67%;
}

.bg-feature>span {
   color: #fff;
   font-size: 24px;
   font-weight: bold;
   left: 50%;
   position: absolute;
   text-orientation: upright;
   text-transform: uppercase;
   top: 50%;
   transform: translate(-50%, -50%);
   writing-mode: vertical-lr;
   z-index: 2;
}

.bg-feature::before {
   background: #000 none repeat scroll 0 0;
   bottom: 0;
   content: "";
   left: 0;
   opacity: 0.6;
   position: absolute;
   right: 0;
   top: 0;
}

.create-meta>a {
   border-radius: 5px;
   display: inline-block;
   padding: 6px 10px;
   text-align: center;
   width: 100%;
}

.create-meta>p {
   color: #535165;
   display: inline-block;
   font-size: 13px;
   line-height: 23px;
   margin-bottom: 15px;
   margin-top: 15px;
   width: 100%;
}

/*--- smiles single post ---*/
.smilez {
   float: left;
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
}

.smilez>li {
   display: inline-block;
   margin-right: 3px;
   text-align: center;
}

.smilez>li>a {
   display: inline-block;
   max-width: 36px;
   transform: scale(0.9);
   transition: all 0.2s linear 0s;
}

.smilez>li>a:hover {
   transform: scale(1);
}

.incolor+.tooltip>.tooltip-inner {
   border-radius: 20px;
   font-size: 13px;
}

.smilez>li>span {
   background: #eaeaea none repeat scroll 0 0;
   border-radius: 3px;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   text-align: center;
   width: 100%;
}

/*--- community detail widget ---*/
.comnity-detail {
   display: inline-block;
   padding: 0 20px 20px;
   width: 100%;
}

.comnty-avatar {
   display: inline-block;
   width: 100%;
}

.comnty-avatar>img {
   border: 2px solid rgba(0, 0, 0, 0.1);
   border-radius: 100%;
   display: inline-block;
   max-width: 40px;
   vertical-align: middle;
}

.comnty-avatar>a {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   padding-left: 5px;
   vertical-align: middle;
}

.comnity-data {
   display: inline-block;
   margin-top: 10px;
   width: 100%;
}

.comnity-data>ul {
   border-bottom: 1px solid #eaeaea;
   display: inline-block;
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
}

.sidebar .comnity-data>ul>li {
   border-right: 1px solid #eaeaea;
   font-size: 12px;
   margin-bottom: 0;
   padding-bottom: 10px;
   text-align: center;
   width: 32.2%;
   letter-spacing: -0.4px;
}

.sidebar .comnity-data>ul>li span {
   color: #757a95;
   display: inline-block;
   font-size: 11px;
   text-transform: capitalize;
   width: 100%;
   letter-spacing: normal;
}

.comnity-data>p {
   color: #737185;
   line-height: 20px;
}

.comnity-data>.main-btn {
   display: block;
   text-align: center;
}

.sidebar .comnity-data>ul>li:last-child {
   border-right: 0 none;
}

/*--- Privacy and policy page ---*/
.tab-content.central-meta {
   padding: 30px;
}

.privacy .d-flex.flex-row.mt-2 {
   margin-top: 0;
}

.privacy {
   display: inline-block;
   position: relative;
   width: 100%;
}

.policy.nav.nav-tabs {
   width: 300px;
}

.policy .nav-link {
   position: relative;
}

.policy .nav-link.active::before {
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 3px;
   z-index: 2;
}

/*--- pitpoint index page ---*/

.featured-baner>h2 {
   color: #fff;
   display: inline-block;
   font-family: Kaushan Script;
   font-size: 60px;
   left: 70px;
   margin-bottom: 0;
   position: absolute;
   top: 45%;
   transform: translateY(-50%);
   width: 40%;
   z-index: 2;
}

.sidebar .widget>ul.most-actie-pep {
   margin-bottom: -9px;
}

.sidebar .widget ul.most-actie-pep>li {
   display: inline-block;
   margin-bottom: 9px;
   margin-right: 9px;
   position: relative;
   width: auto;
}

.user-thmb {
   display: inline-block;
   max-width: 40px;
}

.user-thmb>img {
   border: 1px solid rgba(0, 0, 0, 0.4);
   border-radius: 100%;
}

/*--- successful people widget ---*/
.succes-people li>figure {
   margin-bottom: 10px;
}

.succes-people li>h5 {
   color: #535165;
   font-size: 15px;
   font-weight: 400;
}

/*--- find match box ---*/

.search-match {
   background: #fff none repeat scroll 0 0;
   border: 1px solid #eaeaea;
   border-radius: 5px;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
   display: inline-block;
   margin-left: 70px;
   padding: 30px 20px;
   position: relative;
   width: calc(100% - 130px);
}

.search-match.no-shadow {
   box-shadow: none;
   border-radius: 5px 5px 0 0;
}

.search-match>h5 {
   color: #535165;
   display: inline-block;
   line-height: 20px;
   margin-bottom: 0;
   max-width: 130px;
   padding-right: 10px;
   text-align: right;
   text-transform: capitalize;
   vertical-align: middle;
}

aside.sidebar .frnd-meta>a.main-btn2:hover {
   color: #fff;
}

.search-match>h5>span {
   color: #999;
   display: inline-block;
   font-size: 14px;
   width: 100%;
   font-weight: 400;
}

.search-match>form {
   display: inline-block;
   vertical-align: middle;
   width: 87%;
}

.select-gender {
   display: inline-block;
   list-style: outside none none;
   margin: 0 15px 0 0;
   padding-left: 0;
   vertical-align: middle;
}

.select-gender>li {
   background: #edf2f6 none repeat scroll 0 0;
   border: 1px solid #e4e4e4;
   border-radius: 5px;
   display: inline-block;
   padding: 6px;
   vertical-align: middle;
}

.select-gender>li.selected img {
   transform: scale(0.9);
}

.age-opt {
   display: inline-block;
   margin-right: 15px;
   vertical-align: middle;
   width: 21%;
}

.oth-opt {
   display: inline-block;
   margin-right: 15px;
   vertical-align: middle;
   width: 26%;
}

.search-match>form>button {
   display: inline-block;
   float: none;
   font-size: 16px;
   margin-top: 6px;
   padding: 7px 30px;
   vertical-align: text-top;
}

.loc {
   display: inline-block;
   margin-right: 15px;
   vertical-align: middle;
   width: 100%;
}

.age-opt>label {
   color: #535165;
   font-weight: 500;
   width: 100%;
}

.age-opt>.chosen-container .chosen-single {
   padding: 5px 8px;
}

.age-opt>p {
   color: #535165;
   display: inline-block;
   font-size: 20px;
   font-weight: bold;
   margin: 0 8px;
   vertical-align: middle;
}

.age-opt .chosen-container {
   width: 80px !important;
}

.loc>label {
   color: #535165;
   font-weight: 500;
   width: 100%;
}

.loc>input {
   background: #edf2f6 none repeat scroll 0 0;
   border: 1px solid #e4e4e4;
   border-radius: 4px;
   padding: 8px 15px;
   width: 100%;
}

.chk {
   display: inline-block;
   vertical-align: middle;
   width: 15%;
}

.chk .checkbox {
   display: inline-block;
   margin: 0 0 5px;
   width: 100%;
}

.chk .checkbox>label {
   margin-bottom: 0;
}

.chk .checkbox:last-child {
   margin-bottom: 0;
}

.slider-box {
   display: inline-block;
   position: relative;
   width: 100%;
}

.slider-box>label,
.slider-box>input {
   background: transparent none repeat scroll 0 0;
   border: medium none;
   color: #333;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   position: absolute;
   right: 0;
   top: -25px;
   vertical-align: top;
}

.slider-box>input {
   color: #535165;
   font-size: 12px;
   text-align: right;
   width: 100%;
}

.slider-box>.slider {
   /*background: #000 none repeat scroll 0 0;*/
   border: 0 none;
   border-radius: 14px;
   height: 14px;
}

.ui-slider-horizontal .ui-slider-handle {
   background: #fff none repeat scroll 0 0 !important;
   border-radius: 34px;
   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
   cursor: col-resize;
   height: 34px;
   left: -17px;
   top: -10px;
   width: 34px;
}

.ui-slider-horizontal .ui-slider-range {
   background: #e1e5e9 none repeat scroll 0 0;
   border-radius: 14px;
}

span.ui-slider-handle.ui-state-default.ui-corner-all::before,
span.ui-slider-handle.ui-state-default.ui-corner-all::after {
   background: #e8e7e6 none repeat scroll 0 0;
   content: "";
   display: block;
   height: 12px;
   left: 15px;
   position: absolute;
   top: 11px;
   width: 1px;
}

span.ui-slider-handle.ui-state-default.ui-corner-all::after {
   left: 18px;
}

/*--- sublink box pitpoint index page ---*/
.sub-links {
   background: rgba(255, 255, 255, 0.2) none repeat scroll 0 0;
   display: inline-block;
   left: 0;
   padding: 5px 15px;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 2;
}

.sub-links>a {
   float: right;
   margin-top: 5px;
}

.heart-pnts {
   display: inline-block;
   float: left;
   text-align: left;
   vertical-align: middle;
}

.heart-pnts>a {
   color: #fff;
   display: inline-block;
   margin-right: 50px;
   text-transform: capitalize;
   vertical-align: middle;
}

.heart-pnts>div {
   display: inline-block;
   position: relative;
   vertical-align: middle;
}

.heart-pnts>div>i {
   font-size: 50px;
}

.heart-pnts>div span {
   color: #fff;
   left: 50%;
   position: absolute;
   top: 37%;
   transform: translate(-50%, -50%);
}

.view-port {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   vertical-align: middle;
}

.filter-ajex {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 20px;
   vertical-align: middle;
}

.range-slider {
   float: right;
   margin-top: 9px;
   width: 150px;
}

.filter-ajex>li {
   display: inline-block;
   margin-right: 15px;
   position: relative;
}

.filter-ajex>li>a {
   font-size: 13px;
   font-weight: 500;
}

.view-port>li {
   display: inline-block;
   margin-right: 6px;
}

.view-port>li>a {
   font-size: 20px;
   color: #ccc;
}

.filter-ajex>li.active>a {
   font-weight: 500;
}

.filter-ajex>li.active::before {
   content: "";
}

.sub-links>.main-btn:hover {
   background: #888da8 none repeat scroll 0 0;
}

.search-match>form>button:hover {
   background: #888da8 none repeat scroll 0 0;
}

.filter-ajex>li .online {
   color: #7fba00;
}

.filter-ajex>li .offline {
   color: #bebebe;
}

/*--- banner ----*/

.pit-ad-banner {
   display: inline-block;
   padding: 20px;
   position: relative;
   width: 100%;
}

.pit-ad-banner .bg-image {
   z-index: 0;
}

.add-meta {
   display: inline-block;
   position: relative;
   width: 100%;
   z-index: 1;
}

.add-meta>h5 {
   color: #b93333;
   display: inline-block;
   font-family: Kaushan Script;
   font-size: 35px;
   line-height: 34px;
   margin-bottom: 0;
   margin-right: 0;
   text-align: right;
   vertical-align: middle;
   width: 70%;
   text-shadow: 0 1px 2px #fff;
}

.add-meta>h5 span {
   color: #ffffff;
   display: inline-block;
   font-family: roboto;
   font-size: 16px;
   font-weight: normal;
   vertical-align: middle;
   text-shadow: 0 1px 1px #000;
}

.add-meta>i {
   display: inline-block;
   margin: 0 50px;
   vertical-align: middle;
}

.add-meta>a {
   background: #fff none repeat scroll 0 0;
   border: 2px solid #b93333;
   border-radius: 30px;
   color: #b93333;
   display: inline-block;
   font-family: Kaushan Script;
   font-size: 18px;
   padding: 6px 10px;
   text-align: center;
   width: 140px;
   transition: all 0.2s linear 0s;
}

.add-meta>a:hover {
   box-shadow: 0 0 20px #fff;
}

.pit-post-deta>h4 span.status {
   position: unset;
   right: auto;
}

.verify {
   color: mediumseagreen;
   font-style: normal;
}

/*--- pitpoint grid post ---*/
.pitdate-user {
   display: inline-block;
   margin-bottom: 30px;
   position: relative;
   width: 100%;
}

.pitdate-user>figure {
   display: inline-block;
   margin-bottom: 10px;
   position: relative;
   width: 100%;
}

.pitdate-user>figure::before {
   background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   opacity: 0;
   visibility: hidden;
   transition: all 0.2s linear 0s;
}

.pitdate-user>figure:hover:before {
   opacity: 1;
   visibility: visible;
}

.pitdate-user>figure img {
   width: 100%;
   border-radius: 6px;
}

.pitdate-user>figure>span.status {
   bottom: -5px;
   right: 10px;
   border: 1px solid rgba(0, 0, 0, 0.2);
   height: 15px;
   width: 15px;
}

.pitdate-user .save-post {
   cursor: pointer;
   left: 7px;
   line-height: 16px;
   position: absolute;
   top: 7px;
   z-index: 10;
}

.pitdate-user .save-post>i {
   color: #fff;
   font-size: 16px;
}

.save-post.save>i {
   transform: scale(1.2);
}

.pitdate-user .heart {
   bottom: 5px;
   left: 5px;
   position: absolute;
}

.pitdate-user .more {
   position: absolute;
   right: 10px;
   top: 8px;
}

.pitdate-user>h4 {
   color: #535165;
   display: inline-block;
   font-size: 14px;
   margin-bottom: 0;
   width: 80%;
}

.pitdate-user>span {
   display: inline-block;
   font-size: 13px;
   width: 80%;
}

.pitdate-user>a {
   float: right;
   font-size: 20px;
   margin-top: -15px;
   opacity: 0;
   visibility: hidden;
   transform: scale(0);
   transition: all 0.2s linear 0s;
}

.pitdate-user:hover>a {
   opacity: 1;
   visibility: visible;
   transform: scale(1);
}

.pitdate-user>span>i {
   color: #333;
}

/*--- single detail gold page ---*/

.slider-for-gold,
.slider-for-pearl {
   float: left;
   list-style: outside none none;
   padding-left: 0;
   position: relative;
   width: 100%;
   margin-bottom: 0;
}

.slider-for-gold li,
.slider-for-pearl li {
   display: inline-block;
   width: 100%;
}

.slider-for-gold li img,
.slider-for-pearl li img {
   width: 100%;
}

.slider-for-gold .slick-track>li img {
   max-width: 79.5%;
   min-width: 79.5%;
   width: 79.5%;
}

.slider-nav-gold {
   float: left;
   left: auto;
   padding-left: 0;
   position: absolute;
   right: 20px;
   text-align: left;
   top: 50%;
   margin-bottom: 0;
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
   transform: translateY(-50%);
   width: auto;
}

.slider-nav-gold .slick-track {
   display: inline-block;
   width: 135px;
}

.slider-nav-gold .slick-track>li {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   border-radius: 5px;
   margin-top: 10px;
   border: 3px solid #eee;
   width: 124px !important;
   display: inline-block;
   float: right;
   cursor: pointer;
}

.slider-nav-gold .slick-track>li img {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   border-radius: 5px;
   max-height: 130px;
   max-width: 118px;
   min-height: 130px;
   min-width: 118px;
}

/*--- slick arrows style ---*/

.slick-arrow {
   z-index: 9;
}

table.overview.table-responsive {
   display: table;
}

/*---- pitpoint detail meta ---*/
.full-postmeta {
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.left-detail-meta>span {
   color: #a5aac5;
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.right-detailmeta {
   float: right;
   width: 25%;
}

.left-detail-meta {
   display: inline-block;
   vertical-align: top;
   width: 75%;
}

.left-detail-meta>h4 {
   color: #535165;
   display: inline-block;
   width: 100%;
}

.left-detail-meta>h4>i {
   color: mediumseagreen;
   font-size: 15px;
   font-weight: normal;
   vertical-align: middle;
}

.left-detail-meta>ins {
   color: #757a95;
   display: inline-block;
   font-size: 14px;
   font-style: normal;
   text-decoration: none;
}

.left-detail-meta>span>i {
   color: #535353;
}

.right-detailmeta>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 20px 0 0;
   padding: 0;
   width: 100%;
}

.right-detailmeta>ul>li {
   background: #d8ddf8 none repeat scroll 0 0;
   border-radius: 100%;
   color: #aaa;
   cursor: pointer;
   display: inline-block;
   font-size: 22px;
   height: 45px;
   line-height: 45px;
   margin-right: 5px;
   text-align: center;
   vertical-align: middle;
   width: 45px;
}

.right-detailmeta>ul>li>.heart {
   font-size: 22px;
}

.right-detailmeta .online {
   color: #7fba00;
}

.media-info {
   display: inline-block;
   list-style: outside none none;
   margin: 25px 0;
   padding-left: 0;
   width: 100%;
}

.media-info>li {
   display: inline-block;
   vertical-align: middle;
   width: 150px;
}

.media-info>li>i {
   color: #b3b1c5;
   font-size: 22px;
   margin-right: 5px;
   vertical-align: sub;
}

.bottom-meta {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.share>a {
   font-size: 17px;
   margin-right: 4px;
}

.share>span {
   font-weight: 500;
   text-transform: capitalize;
}

.share>a:hover>i {
   transform: scale(1.2);
}

.member-des>h5 {
   color: #535165;
   font-weight: normal;
}

.member-des>p {
   line-height: 30px;
}

.member-des .bottom-meta .main-btn2:hover {
   color: #fff;
}

.member-des .bottom-meta .main-btn:hover {
   background: #989dbd;
}

/*--- pitpoint page in container witout banner ---*/
.container .search-match {
   margin-left: 0;
   width: 100%;
}

.container .heart-pnts {
   width: 87.5%;
}

.container .filter-ajex {
   padding-left: 10px;
}

.container .range-slider {
   width: 110px;
}

.container .add-meta>h5 {
   font-size: 25px;
   width: 67%;
}

.container .sidebar .widget ul.most-actie-pep>li {
   margin-right: 3px;
}

.sub-links.static {
   border-radius: 0 0 7px 7px;
   position: unset;
   background: #66788f;
}

/*--- pittube demo ---*/
.pittube-video .video-js {
   width: 100% !important;
}

.tube-featurd-area {
   display: inline-block;
   width: 100%;
}

.row.merged-4px>div {
   padding: 4px;
}

.row.merged-4px {
   margin: -4px;
}

.featured-tube {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
   position: relative;
}

.featured-tube>img {
   width: 100%;
   border-radius: 6px;
}

.feature-title {
   bottom: 15px;
   left: 0;
   padding: 0 16px;
   position: absolute;
   width: 100%;
}

.feature-title>h2 {
   color: #fff;
   font-size: 28px;
}

.feature-title>h4,
.feature-title>h6 {
   color: #fff;
}

.featured-tube::before {
   background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
   border-radius: 6px;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.user-fig {
   display: inline-block;
}

.user-fig>img {
   border: 1px solid rgba(255, 255, 255, 0.4);
   border-radius: 100%;
   max-width: 30px;
}

.user-fig>a {
   color: #fff;
   font-size: 13px;
   margin-left: 3px;
}

.user-fig>a:hover {
   text-decoration: underline;
}

.feature-title>h2>a,
.feature-title>h4>a,
.feature-title>h6>a {
   display: inline-block;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.tab-pane .friend-info>figure {
   width: 60px;
}

.tab-pane .friend-name {
   width: 94%;
}

.tab-pane .post-meta.searched .linked-image {
   margin-bottom: 10px;
}

.search-tab .tab-pane .friend-name {
   width: 89%;
}

/*--- nave area pittube ---*/
.nave-area {
   display: inline-block;
   list-style: outside none none;
   margin: 8px 0 0;
   padding: 0;
   vertical-align: middle;
}

.nave-area>li {
   display: inline-block;
   font-size: 14px;
   margin-right: 25px;
   position: relative;
}

.user-ben {
   list-style: outside none none;
   margin: 0;
   padding: 0;
}

.user-ben .more {
   float: none;
}

.user-ben>li {
   display: inline-block;
   margin-right: 10px;
   vertical-align: middle;
}

.nave-area>li:last-child {
   margin-right: 0;
}

.nave-area>li>a>i {
   transition: all 0.2s linear 0s;
}

a.circle-btn {
   background: #e4e4e4 none repeat scroll 0 0;
   border-radius: 100%;
   color: #535165;
   display: inline-block;
   height: 35px;
   line-height: 35px;
   text-align: center;
   transition: all 0.2s linear 0s;
   width: 35px;
   position: relative;
}

.user-ben>li:last-child {
   margin-right: 0;
}

.nave-area>li>a {
   display: inline-block;
   transition: all 0.2s linear 0s;
}

a.circle-btn:hover {
   color: #fff;
}

.nave-area>li>a:hover i {
   color: #a1a3b5;
}

.searchees {
   background: #fff none repeat scroll 0 0;
   height: 100%;
   position: absolute;
   right: 0;
   top: 0;
   width: 100%;
   z-index: 22;
   display: none;
}

.searchees>input {
   background: transparent none repeat scroll 0 0;
   border: medium none;
   height: 100%;
   padding: 10px 20px;
   width: 100%;
}

.searchees>button {
   opacity: 0;
   position: absolute;
   right: 20px;
   top: 28px;
   visibility: hidden;
}

.cancel-search {
   color: #535165;
   cursor: pointer;
   position: absolute;
   right: 10px;
   top: 10px;
}

/*--- pittube post ---*/

.vid-time {
   bottom: 3px;
   position: absolute;
   right: 6px;
}

.rlted-video>figure:before {
   background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
   bottom: 0;
   content: "";
   left: 0;
   opacity: 0;
   position: absolute;
   right: 0;
   top: 0;
   transition: all 0.2s linear 0s;
   visibility: hidden;
}

.rlted-video:hover>figure:before {
   opacity: 1;
   visibility: visible;
}

/*--- today's feature channle post ---*/
.feature-chanel {
   display: inline-block;
   width: 100%;
   margin-top: 35px;
}

.feature-chanel>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.feature-chanel>figure img {
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 100%;
   max-width: 115px;
   width: 100%;
}

.chanle-name {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
   width: 50%;
}

.chanle-name>h4 {
   color: #535165;
   margin-bottom: 0;
}

.mega-menu {
   background: #edf2f6 none repeat scroll 0 0;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   opacity: 0;
   padding: 20px;
   position: absolute;
   right: 0;
   top: 35px;
   transition: all 0.2s linear 0s;
   visibility: hidden;
   width: 601px;
   border-radius: 5px;
   z-index: 555;
   border: 1px solid #dcdcdc;
}

.mega-menu>li {
   background: #fff none repeat scroll 0 0;
   display: inline-block;
   margin: 6px 2px;
   text-align: center;
   width: 86px;
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
   transition: all 0.2s linear 0s;
}

.mega-menu>li:hover {
   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.mega-menu>li>a {
   display: inline-block;
   width: 100%;
}

.mega-menu>li>a i {
   background: rgba(0, 0, 0, 0.5);
   color: #fff;
   padding: 6px 0;
   width: 100%;
   position: relative;
   z-index: 1;
}

.mega-menu>li>a i img {
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -1;
}

.mega-menu>li>a>span {
   color: #535165;
   display: inline-block;
   font-size: 12px;
   padding: 5px;
   width: 100%;
   transition: all 0.2s linear 0s;
}

.mega-menu>li:hover>a>span {
   color: #fff;
}

.nave-area>li:hover .mega-menu {
   opacity: 1;
   visibility: visible;
}

.ad-baner {
   font-size: 11px;
}

.lodmore.pad-ext-40 {
   padding-top: 40px;
}

/*--- tube post detail page ---*/
.pittube-video {
   display: inline-block;
   width: 100%;
}

.uploadtime {
   float: right;
   font-size: 13px;
   font-weight: 500;
   margin-top: 10px;
   text-transform: capitalize;
}

.video-frame .pit-opt {
   width: auto;
   margin-top: 10px;
}

.uploadtime>i {
   margin-right: 6px;
}

.video-frame>h4 {
   color: #535165;
   display: inline-block;
   margin-top: 20px;
   padding-right: 20px;
   width: 100%;
}

.chanel-avatar {
   display: inline-block;
   margin-bottom: 10px;
   margin-top: 10px;
   width: auto;
}

.chanel-avatar>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.channl-author {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
}

.chanel-avatar>figure img {
   border-radius: 100%;
}

.channl-author>span {
   display: inline-block;
   width: 100%;
}

.channl-author>h5 {
   color: #535165;
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.channl-author>em {
   font-style: normal;
   text-transform: capitalize;
}

.addnsend {
   display: inline-block;
   vertical-align: middle;
}

.follow {
   display: flex;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   vertical-align: middle;
}

.follow>li {
   margin-right: 30px;
}

.addnsend>a {
   margin-left: 27px;
}

.related-tube-psts {
   display: inline-block;
   max-height: 348px;
   position: relative;
   width: 100%;
}

.rlted-video {
   border-bottom: 1px solid #dcdcdc;
   display: inline-block;
   margin-bottom: 15px;
   padding-bottom: 15px;
   width: 100%;
}

.rlted-video>figure {
   display: inline-block;
   height: 90px;
   margin-bottom: 0;
   max-width: 130px;
   vertical-align: middle;
   position: relative;
}

.rlted-video>figure img {
   border-radius: 3px;
}

.tube-pst-meta {
   display: inline-block;
   max-width: 56%;
   padding-left: 8px;
   vertical-align: middle;
   width: 56%;
}

.tube-pst-meta>h5 {
   color: #515365;
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 0;
   width: 100%;
}

.tube-pst-meta .user-fig>a {
   color: inherit;
   margin-left: 0;
}

.tube-pst-meta .user-fig>img {
   max-width: 22px;
}

.tube-pst-meta>span {
   color: #757a95;
   display: inline-block;
   font-size: 13px;
   margin: 3px 0;
   width: 100%;
}

.pit-tags {
   display: inline-block;
   margin-top: 5px;
   width: 100%;
}

.pit-tags>span {
   display: inline-block;
   font-weight: 500;
   margin-bottom: 5px;
   width: 100%;
}

.pit-tags>a {
   border: 1px solid #dcdcdc;
   border-radius: 15px;
   display: inline-block;
   font-size: 11px;
   padding: 2px 8px;
   margin-bottom: 5px;
   transition: all 0.2s linear 0s;
}

.pit-tags>a:hover {
   color: #fff;
}

.create-post .user-fig>a {
   color: inherit;
}

.tube-pst-meta>h5>a {
   display: inline-block;
   transition: all 0.2s linear 0s;
}

.related-tube-psts .rlted-video:nth-last-child(3) {
   border: 0 none;
   margin-bottom: 0;
   padding-bottom: 0;
}

.addnsend>a i {
   font-size: 16px;
   margin-right: 3px;
}

.follow-me>i,
.contribute>i {
   color: #535165;
}

.follow-me:hover i,
.contribute:hover i {
   transform: scale(1.2);
}

/*--- read more ---*/
.read-more-state {
   display: none;
}

.read-more-target {
   opacity: 0;
   max-height: 0;
   font-size: 0;
   transition: 0.25s ease;
}

.read-more-state:checked~.read-more-wrap .read-more-target {
   opacity: 1;
   font-size: inherit;
   max-height: 999em;
}

.read-more-state~.read-more-trigger:before {
   content: "Show more";
}

.read-more-state:checked~.read-more-trigger:before {
   content: "Show less";
}

.read-more-trigger {
   cursor: pointer;
   display: inline-block;
   padding: 0 0.5em;
   color: #666;
   font-size: 0.9em;
   line-height: 2;
   border: 1px solid #ddd;
   border-radius: 0.25em;
   float: right;
   margin-bottom: 0;
}

/*----*/
.about-video {
   border-bottom: 1px solid #dcdcdc;
   display: inline-block;
   margin-bottom: 20px;
   padding-bottom: 10px;
   width: 100%;
}

.about-video>h6,
.comet-title {
   color: #535165;
   display: inline-block;
   font-size: 15px;
   width: 100%;
}

.read-more-wrap {
   color: #959ab5;
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.more-tube-psts {
   display: inline-block;
   width: 100%;
}

/*--- wave load more ---*/
.wave {
   text-align: center;
}

.wave .dot {
   display: inline-block;
   background: #fa6342;
   width: 9px;
   height: 9px;
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   margin-right: 1px;
   -webkit-animation: wave 1.3s linear infinite;
   -moz-animation: wave 1.3s linear infinite;
   -ms-animation: wave 1.3s linear infinite;
   -o-animation: wave 1.3s linear infinite;
   animation: wave 1.3s linear infinite;
}

.wave .dot:last-of-type {
   margin-right: 0;
}

.wave .dot:nth-child(2) {
   -webkit-animation-delay: -1.1s;
   -moz-animation-delay: -1.1s;
   -ms-animation-delay: -1.1s;
   -o-animation-delay: -1.1s;
   animation-delay: -1.1s;
}

.wave .dot:nth-child(3) {
   -webkit-animation-delay: -0.9s;
   -moz-animation-delay: -0.9s;
   -ms-animation-delay: -0.9s;
   -o-animation-delay: -0.9s;
   animation-delay: -0.9s;
}

@keyframes wave {

   0%,
   100%,
   60% {
      transform: initial;
   }

   30% {
      transform: translateY(-5px);
   }
}

/*--- tube category page ---*/
.pittop-banner,
.soundnik-featured {
   display: inline-block;
   position: relative;
   width: 100%;
}

.pittop-banner figure,
.soundnik-featured figure {
   margin: 0;
}

.pittop-banner figure img,
.soundnik-featured figure img {
   width: 100%;
}

.soundnik-featured .owl-controls {
   bottom: 10px;
   left: 50%;
   position: absolute;
   transform: translateX(-50%);
   z-index: 2;
}

.pittop-meta {
   bottom: 20px;
   display: inline-block;
   left: 35px;
   position: absolute;
   z-index: 2;
}

.pittop-meta>figure {
   display: inline-block;
   margin: 0;
   vertical-align: middle;
}

.pittop-meta>div h6,
.pittop-meta>div span {
   color: #fff;
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: 100%;
}

.pittop-meta>div span {
   color: #bfbfbf;
   font-size: 16px;
}

.pittop-meta>div {
   display: inline-block;
   padding-left: 8px;
   vertical-align: middle;
}

.pittop-meta>div h6 {
   font-size: 24px;
}

.pittop-banner::before,
.soundnik-featured:before {
   background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 2;
}

.pittop-meta>figure img {
   border: 2px solid rgba(255, 255, 255, 0.5);
   border-radius: 100%;
}

.widget .pit-tags {
   margin-top: 0;
   padding: 0 20px 12px;
}

/*--- channel page tab base ---*/

.links-tab {
   line-height: 65px;
   width: 100%;
}

.links-tab li.nav-item {
   margin-right: 50px;
   margin-bottom: 0;
}

.links-tab li.nav-item:last-child {
   margin-right: 0;
}

.links-tab li.nav-item>a {
   color: #535165;
   font-size: 14px;
   font-weight: 500;
   position: relative;
   display: inline-block;
}

.links-tab li.nav-item>a.active:after {
   border-bottom: 10px solid #fa6342;
   border-left: 10px solid transparent;
   border-right: 10px solid transparent;
   bottom: 0;
   content: "";
   left: 50%;
   position: absolute;
   transform: translate(-50%);
}

.links-tab li.nav-item>a span {
   border: 1px solid #ccc;
   border-radius: 5px;
   color: #999;
   font-size: 11px;
   line-height: initial;
   padding: 0 2px;
   position: absolute;
   right: -20px;
   top: 15px;
}

/*--- pittube about ---*/
.about-chnl {
   display: inline-block;
   width: 100%;
}

.about-chnl>ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 10px;
   padding-left: 0;
   position: relative;
   width: 100%;
}

.about-chnl>ul>li {
   color: #959ab5;
   display: inline-block;
   font-size: 13.5px;
   margin-bottom: 16.5px;
   width: 100%;
}

.about-chnl>ul>li span {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   width: 150px;
}

.about-chnl>span {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.contact-box .c-form>div {
   margin-bottom: 20px;
}

.contact-box {
   display: inline-block;
   width: 100%;
}

/*--- contributors carousel--*/
.contributorz {
   padding: 0;
   margin: 0;
}

.contributorz li {
   display: inline-block;
   width: 100%;
}

.contributorz li img {
   display: inline-block;
   width: 100%;
   border-radius: 5px;
}

.contributorz li span {
   display: inline-block;
   width: 100%;
   text-align: center;
   font-weight: 500;
   color: #535165;
   text-transform: capitalize;
   margin-top: 5px;
}

.contributorz li p {
   display: inline-block;
   width: 100%;
   font-size: 12px;
   text-align: center;
   margin-bottom: 0;
}

/*-- donation page ---*/
.don-left {
   display: inline-block;
   margin-bottom: 20px;
   position: relative;
   width: 100%;
}

.our-moto {
   display: inline-block;
   padding: 20px;
   position: relative;
   width: 100%;
   z-index: 1;
}

.our-moto>p {
   color: inherit;
   font-size: 15px;
   margin-bottom: 0;
}

.our-moto>p>span {
   display: inline-block;
   font-size: 30px;
   font-weight: 500;
   vertical-align: top;
   font-family: Expressa-Serial;
}

.don-form {
   display: inline-block;
   position: relative;
   width: 100%;
}

.don-form>h4 {
   color: #535165;
   float: left;
   margin-top: 6px;
   width: 75%;
}

.don-form>.chosen-container {
   float: right;
   width: 20% !important;
}

.amount-select {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 45%;
}

.amount-select>li {
   background: #888da8 none repeat scroll 0 0;
   border-radius: 30px;
   cursor: pointer;
   display: inline-block;
   line-height: 35px;
   margin-right: 5px;
   text-align: center;
   width: 80px;
   transition: all 0.2s linear 0s;
   color: #fff;
}

.don-form .c-form>div {
   margin-bottom: 20px;
   margin-top: 10px;
}

.don-form .c-form>input {
   width: 54.4%;
}

.don-form>h5 {
   color: #535165;
   display: inline-block;
   margin-bottom: 10px;
   margin-top: 35px;
   width: 100%;
}

.pay-methods {
   display: inline-block;
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
}

.pay-methods>li {
   background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
   border-radius: 5px;
   color: #535165;
   cursor: pointer;
   display: inline-block;
   font-size: 14px;
   padding: 10px 20px;
   transition: all 0.2s linear 0s;
}

.pay-methods>li:hover,
.pay-methods>li.active {
   color: #fff;
}

.c-form.pay-card>span,
.c-form.personal>span {
   color: #535165;
   font-weight: 500;
}

.c-form.pay-card {
   margin-top: 20px;
}

.c-form.personal {
   margin-bottom: -20px;
   margin-top: 20px;
}

.c-form.personal input,
.c-form.personal textarea {
   margin-bottom: 10px;
}

.c-form.personal button {
   margin-top: 10px;
}

/*--- soundnik page --*/
.soundnik-meta {
   display: inline-block;
   left: 50%;
   position: absolute;
   text-align: center;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 50%;
   z-index: 2;
}

.soundnik-meta>h1 {
   color: #fff;
   display: inline-block;
   text-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
   width: 100%;
}

.soundnik-meta>p {
   color: #fff;
   font-size: 16px;
   line-height: 20px;
}

/*--- sound track style ---*/
.playerOuter {
   width: 100%;
   display: inline-block;
}

.abc {
   display: inline-block;
   width: 100%;
}

.ac-container.ac-play-container {
   display: inline-block;
   vertical-align: middle;
   width: 60px !important;
}

.ac-container {
   display: inline-block;
   vertical-align: middle;
   text-align: left;
   width: 89% !important;
   height: auto !important;
}

.ac-play-container button:focus {
   outline: none;
}

.ac-play-container .ac-play {
   font-family: "FontAwesome";
   border-radius: 50%;
   background-color: #fa6342;
   border: none;
   color: #fff;
   font-size: 20px;
   padding-left: 11px;
   transition: 0.25s ease background-color;
}

.ac-play-container .ac-play:before {
   content: "\f04b";
}

.ac-play-container .ac-play:hover {
   background-color: #d74f31;
   cursor: pointer;
}

.ac-play-container .ac-playing:before {
   content: "\f04c" !important;
}

.ac-play-container .ac-playing {
   padding-top: 2px;
   padding-left: 7px !important;
   font-size: 20px !important;
}

/*--- sound post ---*/

.todays {
   display: inline-block;
   padding: 20px;
   position: relative;
   width: 100%;
   border-radius: 5px;
   margin-bottom: 20px;
}

.todays .bg-image {
   z-index: 0;
   border-radius: 5px;
}

.sound-title {
   color: #fff;
   display: inline-block;
   margin-bottom: 50px;
   margin-top: 0;
   position: relative;
   text-align: left;
   width: 100%;
   z-index: 2;
}

.sound-post-box {
   border: 1px solid rgba(255, 255, 255, 0.1);
   border-radius: 5px;
   margin-bottom: 0;
   padding: 0 20px 20px;
   position: relative;
   z-index: 2;
}

.sound-user {
   display: inline-block;
   margin-top: 0;
}

.sound-user>figure {
   display: inline-block;
   margin-bottom: 0;
   margin-top: -30px;
   vertical-align: middle;
}

.sound-usr-nm {
   display: inline-block;
   padding-left: 5px;
   padding-top: 9px;
   vertical-align: middle;
}

.sound-user>figure img {
   border: 2px solid #fff;
   border-radius: 100%;
}

.sound-usr-nm>h6 {
   color: #dfdfdf;
   margin-bottom: 0;
}

.sound-usr-nm>span {
   font-size: 12px;
}

.sound-post-box>h4 {
   color: #dfdfdf;
   display: inline-block;
   font-size: 17px;
   max-width: 56%;
   overflow: hidden;
   padding-left: 30px;
   padding-right: 40px;
   text-overflow: ellipsis;
   white-space: nowrap;
   width: 56%;
   vertical-align: middle;
   margin-right: 35px;
}

.sound-post-box>span {
   display: inline-block;
   font-size: 12px;
   vertical-align: middle;
}

.sound-post-box>span>i {
   color: #535165;
}

.sound-track-area {
   display: inline-block;
   vertical-align: middle;
   width: 79%;
}

.sound-right {
   display: inline-block;
   text-align: center;
   vertical-align: middle;
   width: 20%;
}

.play-list {
   display: inline-block;
   width: 100%;
}

.sound-right .send-mesg {
   display: inline-block;
   margin: 10px 0 20px;
   width: 100%;
}

.sound-right .main-btn2 {
   font-size: 12px;
   padding: 7px 16px;
}

.todays.mate-black::before {
   border-radius: 5px;
}

/*-- audio post ---*/
.central-meta.audio-box {
   padding: 0;
}

.audio-post {
   display: inline-block;
   padding: 11px 15px 10px;
   position: relative;
   width: 100%;
}

.add-send {
   float: right;
   margin-top: 13px;
   text-align: center;
   width: 30%;
}

.audio-user {
   display: inline-block;
}

.audio-user>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.audio-user-name {
   display: inline-block;
   padding-left: 4px;
   vertical-align: middle;
}

.audio-user-name>h6 {
   color: #535165;
   margin-bottom: 0;
   font-size: 14px;
}

.audio-user-name>span {
   font-size: 12px;
}

.audio-user>figure img {
   border: 1px solid rgba(0, 0, 0, 0.2);
   border-radius: 100%;
}

.add-send>ul {
   display: inline-block;
   line-height: initial;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.add-send>ul>li {
   display: inline-block;
   margin-right: 10px;
}

.add-send>ul>li:last-child {
   margin-right: 0;
}

.add-send>ul>li a {
   font-size: 17px;
}

.add-send .send-mesg {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 2px;
}

.audio-wave {
   display: inline-block;
   margin-bottom: 0;
   width: 100%;
}

.audio-wave>img {
   width: 100%;
}

.audio-title {
   color: #535165;
   font-size: 17px;
   margin-bottom: 20px;
   padding: 0 20px;
   transition: all 0.2s linear 0s;
}

.container-fluid.ext-50 {
   padding-left: 70px;
   padding-right: 70px;
}

.audio-single {
   display: inline-block;
   padding: 30px;
   position: relative;
   width: 100%;
   z-index: 2;
}

.playlist-box {
   background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
   display: inline-block;
   padding: 15px;
   position: relative;
   width: 100%;
   z-index: 2;
}

.about-user {
   display: inline-block;
}

.about-user>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.about-user>figure img {
   border: 2px solid rgba(255, 255, 255, 0.7);
   border-radius: 100%;
}

.singer-info {
   display: inline-block;
   vertical-align: middle;
   padding-left: 5px;
}

.singer-info>h6 {
   color: #fff;
   font-size: 20px;
   margin-bottom: 0;
}

.singer-info>em {
   color: #ddd;
   display: block;
   font-size: 12px;
   font-style: normal;
}

.singer-info>span {
   display: block;
   text-transform: capitalize;
}

.audio-heading {
   color: #fff;
   display: inline-block;
   font-size: 28px;
   padding: 0 20px;
   text-align: center;
   vertical-align: middle;
   width: 70%;
}

.audio-single .uploadtime {
   margin-top: -20px;
}

.audio-single .follow {
   margin-top: 15px;
   width: auto;
}

.addnsend {
   float: right;
}

.pittube-video .addnsend {
   margin-top: 30px;
}

.audio-single .follow li,
.audio-single .follow li i {
   color: #eaeaea;
}

.playlist-box>h4 {
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   color: #fff;
   font-size: 16px;
   padding-bottom: 10px;
}

.playlist-box>ul {
   display: inline-block;
   height: 320px;
   max-height: 320px;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
   position: relative;
}

.playlist-box>ul>li {
   color: #ddd;
   display: inline-block;
   padding: 5px 0;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.playlist-box figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: auto;
}

.playlist-box figure img {
   border: 2px solid rgba(255, 255, 255, 0.2);
   border-radius: 100%;
}

.playlist-box>ul li a {
   display: inline-block;
   line-height: 20px;
   max-width: 80%;
   padding-left: 10px;
   transition: all 0.2s linear 0s;
   vertical-align: middle;
}

.playlist-box>ul>li:hover {
   background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
   border-radius: 50px 0 0 50px;
}

.playlist-box>ul li a:hover {}

.audio-single .pit-opt>li em {
   color: #959ab5;
}

.audio-single .addnsend>a {
   color: #fff;
}

/*--- more songs widget ---*/

.more-songs {
   max-height: 324px;
   position: relative;
}

.more-songs li figure {
   display: inline-block;
   margin-bottom: 0;
   position: relative;
   vertical-align: middle;
}

.more-songs li figure::before {
   color: rgba(255, 255, 255, 0.3);
   content: "\f001";
   font-family: fontawesome;
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
}

.more-songs li figure img {
   border-radius: 5px;
}

.song-title {
   display: inline-block;
   max-width: 75%;
   padding-left: 8px;
   vertical-align: middle;
}

.song-title>h6 {
   color: #535165;
   display: inline-block;
   font-size: 13px;
   margin-bottom: 0;
   width: 100%;
}

.song-title>a {
   font-size: 12px;
   text-transform: capitalize;
}

.song-title>a:hover {
   text-decoration: underline;
}

.member-des .bottom-meta .main-btn2 {
   border-color: pink;
   color: pink;
}

.member-des .bottom-meta .main-btn {
   background: pink none repeat scroll 0 0;
}

/*--- classified post ---*/

.classic-post {
   display: inline-block;
   position: relative;
   width: 100%;
}

.classic-post>figure {
   border: 1px solid #ccc;
   display: inline-block;
   margin-bottom: 0;
   position: relative;
   vertical-align: top;
}

.classic-post>figure>span,
.image-bunch>span {
   background: #7750f8;
   border-radius: 2px;
   color: #fff;
   font-size: 11px;
   left: -10px;
   line-height: 11px;
   padding: 2px 4px;
   position: absolute;
   top: 7px;
}

.classic-pst-meta {
   display: inline-block;
   max-width: 72%;
   padding-left: 8px;
   vertical-align: middle;
}

.location-area {
   display: inline-block;
   width: 100%;
}

.prise {
   float: right;
}

.classic-pst-meta>h4 {
   color: #535165;
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   margin-bottom: 5px;
   width: 80%;
}

.classic-pst-meta>h4>i {
   color: mediumseagreen;
   font-size: 13px;
}

.classic-pst-meta>p {
   display: inline-block;
   line-height: 20px;
   margin-bottom: 10px;
   width: 80%;
}

.location-area>i {
   display: block;
   font-size: 11px;
   font-style: normal;
   margin-bottom: 4px;
}

.location-area>span {
   display: inline-block;
   font-size: 12px;
}

.location-area .main-btn {
   float: right;
   padding: 5px 10px;
}

.classic-post>figure>span.yellow,
.image-bunch>span.yellow {
   background: orange;
}

.classic-post>figure>span.red,
.image-bunch>span.red {
   background: #ff3033;
}

/*--- cart page ---*/

.cart-list-quantity {
   float: left;
   padding-left: 20px;
   padding-top: 19px;
   width: 100%;
}

.cart-list-quantity>.c-input-number {
   display: inline-block;
}

.manual-adjust {
   background: #e3e3e3 none repeat scroll 0 0;
   border-style: none;
   border-width: 2px;
   color: #727272;
   float: left;
   font-size: 15px;
   font-weight: 500;
   height: 38px;
   padding: 0;
   text-align: center;
   width: 40px;
}

.c-input-number>span>input.userincr-btn-inc {
   border: medium none;
   float: left;
   font-size: 13px;
   height: 38px;
   width: 30px;
}

.c-input-number>span>input.userincr-btn-dec {
   border: medium none;
   float: left;
   font-size: 13px;
   height: 38px;
   width: 30px;
   margin-left: -70px;
}

/*--- cart page ---*/

.cart-sec table tbody {
   display: table;
   width: 100%;
}

.cart-sec table.table tr {
   position: relative;
}

.cart-sec table tr th {
   border: 1px solid #eaeaea;
   font-size: 13px;
   font-weight: 500;
   padding: 20px;
   text-transform: uppercase;
   text-align: center;
}

.cart-sec table tr td {
   border: 1px solid #eaeaea;
   line-height: initial;
   padding: 10px 20px;
   text-align: center;
   position: relative;
}

.cart-sec table tr td .cart-avatar {
   float: left;
   position: relative;
}

.delete-cart {
   background: #e44a3c none repeat scroll 0 0;
   border-radius: 100%;
   color: #fff;
   content: "";
   font-size: 8px;
   font-weight: 500;
   height: 21px;
   left: 10px;
   line-height: 21px;
   position: absolute;
   text-align: center;
   top: 5px;
   width: 21px;
   z-index: 2;
}

.delete-cart:hover {
   color: #fff;
}

.cart-sec table tr td .cart-meta {
   overflow: hidden;
   padding-left: 12px;
   padding-top: 15px;
}

.cart-meta>span {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   line-height: 20px;
   max-width: 100%;
   text-align: left;
}

.cart-prices {
   color: #727272;
   float: left;
   padding-top: 20px;
   width: 100%;
}

.cart-prices>del {
   color: #999999;
   display: inline-block;
   font-size: 12px;
   padding-top: 0;
   vertical-align: sub;
}

.cart-prices ins {
   display: inline-block;
   font-size: 16px;
   -webkit-text-decoration: none;
   -moz-text-decoration: none;
   -ms-text-decoration: none;
   -o-text-decoration: none;
   text-decoration: none;
   vertical-align: sub;
   padding-right: 6px;
}

.total-price {
   color: #535165;
   float: left;
   font-size: 18px;
   font-weight: 500;
   padding-top: 20px;
   width: 100%;
}

.amount-area {
   float: left;
   width: 100%;
   margin-top: 30px;
}

.coupon-code {
   display: inline-block;
   position: relative;
   width: 100%;
   margin-top: 30px;
   margin-bottom: 20px;
}

.coupon-code>input {
   border: 1px solid #eaeaea;
   -webkit-border-radius: 30px;
   -moz-border-radius: 30px;
   -ms-border-radius: 30px;
   -o-border-radius: 30px;
   border-radius: 30px;
   color: #454545;
   float: left;
   font-size: 13px;
   padding: 15px 22px;
   width: 100%;
}

.coupon-code>button {
   border: medium none;
   -webkit-border-radius: 100%;
   -moz-border-radius: 100%;
   -ms-border-radius: 100%;
   -o-border-radius: 100%;
   border-radius: 100%;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   line-height: 37px;
   padding: 0;
   position: absolute;
   right: 10px;
   text-align: center;
   top: 8px;
   width: 37px;
}

.total-area {
   float: left;
   width: 100%;
}

.total-area>ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.total-area>ul li {
   color: #535165;
   font-size: 15px;
   margin-bottom: 10px;
   text-align: right;
}

.total-area>ul li span {
   float: left;
   margin-right: 90px;
}

.total-area>ul li i {
   font-style: normal;
}

.total-area>ul li.order-total {
   border-top: 1px solid #ccc;
   font-weight: 500;
   margin-bottom: 0;
   margin-top: 20px;
   max-width: 100%;
   padding-top: 10px;
}

.proceed {
   text-align: right;
}

a.shopping-cart {
   border-radius: 5px;
   bottom: 10px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   left: 10px;
   line-height: initial;
   padding: 8px 15px;
   position: fixed;
   text-align: center;
   z-index: 33;
}

a.shopping-cart>i {
   border-right: 1px solid #fff;
   margin-right: 10px;
   padding-right: 10px;
}

/*--- classi post with image bunch ---*/
.classi-pst {
   display: inline-block;
   width: 100%;
}

.image-bunch {
   float: left;
   position: relative;
   width: 100%;
}

.image-bunch>figure {
   float: left;
   margin-bottom: 0;
   position: relative;
   width: 50%;
   padding: 1px;
}

.classi-pst-meta {
   display: inline-block;
   margin-top: 14px;
   width: 100%;
}

.classi-pst-meta>h6 {
   color: #535165;
   display: inline-block;
   margin-bottom: 12px;
   width: 100%;
}

.classi-pst-meta>span {
   display: inline-block;
   margin-bottom: 5px;
   width: 100%;
}

.classi-pst-meta>span ins {
   float: right;
   font-size: 20px;
   font-weight: 500;
   text-decoration: none;
   line-height: 20px;
}

.classi-pst-meta>em {
   display: inline-block;
   font-size: 12px;
   font-style: normal;
   width: 100%;
}

.classi-pst .user-fig {
   margin-top: 10px;
}

.classi-pst-des {
   margin-top: 13px;
}

.image-bunch.single>figure,
.image-bunch.single>figure img {
   width: 100%;
}

.classi-pst-meta ins>i {
   color: #535165;
   font-size: 12px;
   font-style: normal;
}

/*--- promo caro ---*/
.promo-caro {
   margin-bottom: 0;
}

.promo-caro li {
   display: inline-block;
   width: 100%;
}

.promo-caro li img {
   border-radius: 4px;
   width: 100%;
}

.promo-meta {
   display: inline-block;
   margin-top: 10px;
   text-align: center;
   width: 100%;
}

.promo-meta>a {
   color: #535165;
   display: inline-block;
   font-weight: 500;
   width: 100%;
}

.promo-meta>span {
   color: red;
   display: inline-block;
   font-weight: 500;
   width: 100%;
}

.promo-meta>span>i {
   color: #757a95;
   font-size: 12px;
   font-style: normal;
   font-weight: normal;
}

/*--- coming soon page ---*/
.coming-head {
   display: inline-block;
   width: 100%;
   z-index: 2;
   position: relative;
   margin-bottom: 70px;
}

.coming-meta {
   display: inline-block;
   position: relative;
   width: 100%;
   z-index: 2;
   text-align: center;
   padding-bottom: 52px;
}

.coming-meta>h1 {
   font-weight: 500;
   font-size: 80px;
   /* background-image: url(./images/resources/text-bg.jpg); */
   text-transform: capitalize;
   padding-right: 30px;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
   -o-background-clip: text;
   -o-text-fill-color: transparent;
   -ms-background-clip: text;
   -ms-text-fill-color: transparent;
   background-clip: text;
   text-fill-color: transparent;
}

.social-circle {
   display: inline-block;
   float: right;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 0;
   width: auto;
}

.social-circle>li {
   margin: 0 5px;
   display: inline-block;
}

.social-circle>li a {
   border: 1px solid;
   border-radius: 100%;
   display: inline-block;
   font-size: 16px;
   height: 40px;
   line-height: 40px;
   text-align: center;
   width: 40px;
}

.countdown {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.countdown>li {
   border: 2px solid rgba(255, 255, 255, 0.1);
   border-radius: 100%;
   display: inline-block;
   height: 100px;
   line-height: 100px;
   text-align: center;
   width: 100px;
   margin-right: 10px;
}

.countdown>li:last-child {
   margin-right: 0;
}

.countdown>li>span {
   font-size: 30px;
   color: #eee;
}

.coming-meta>p {
   display: inline-block;
   font-size: 18px;
   margin-bottom: 40px;
   width: 100%;
}

.coming-meta>form {
   display: inline-block;
   margin-top: 50px;
   position: relative;
   width: 440px;
}

.coming-meta>form input {
   background: rgba(0, 0, 0, 0.2) none repeat scroll 0 0;
   border: medium none;
   border-radius: 40px;
   color: #afafaf;
   padding: 15px 20px;
   width: 100%;
}

.coming-meta>form button {
   height: 100%;
   position: absolute;
   right: 0;
   top: 0;
}

.vh100 {
   height: 100vh;
}

/*models and popup page for demo only---*/
.pop-box {
   border: 1px solid rgba(0, 0, 0, 0.2);
   line-height: 100px;
   margin-bottom: 30px;
   text-align: center;
   background: #fff;
   transition: all 0.2s linear 0s;
}

.modl-box,
.pop-box>button {
   color: #434155;
   cursor: pointer;
   font-weight: 500;
   transition: all 0.2s linear 0s;
   border: none;
}

.pop-box:hover {
   border-color: #fa6342;
}

.pop-box:hover .modl-box,
.pop-box>button:hover {
   color: #fa6342;
}

.pop-box>button:hover {
   background: none;
}

.modl-box.event-title>h4 {
   display: inherit;
   line-height: 74px;
   margin-bottom: 0;
   width: 100%;
}

.media-box .social-circle {
   float: left;
}

.modl-box.item-upload {
   padding: 0;
   border: none;
}

.modl-box.user-add {
   border: medium none;
   display: inline-block;
   line-height: initial;
}

.media-box {
   display: inline-block;
   margin-bottom: 20px;
   margin-top: 10px;
   width: 100%;
}

.media-box .share {
   float: left;
}

/*models and popup style finish---*/

/* stories slides */

.status-story {
   background: #757a95 none repeat scroll 0 0;
   display: inline-block;
   height: 100vh;
   vertical-align: middle;
   width: 100%;
   padding: 50px;
}

.status-story>span {
   color: #fff;
   cursor: pointer;
   font-size: 25px;
   position: absolute;
   right: 20px;
   top: 20px;
}

#slideshow {
   margin: 40px auto 0 auto;
   height: 400px;
   width: 100%;
   position: relative;
   overflow: hidden;
}

.slide {
   position: absolute;
   width: auto;
   left: 50%;
   transform: translateX(-50%);

   opacity: 0;
}

.slide:first-child {
   opacity: 1;
}

/* fade-out & fade-in */
@-webkit-keyframes fadeOut {
   0% {
      opacity: 1;
   }

   100% {
      opacity: 0;
   }
}

@-moz-keyframes fadeOut {
   0% {
      opacity: 1;
   }

   100% {
      opacity: 0;
   }
}

@keyframes fadeOut {
   0% {
      opacity: 1;
   }

   100% {
      opacity: 0;
   }
}

@-webkit-keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@-moz-keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

.fade-out {
   -webkit-animation-name: fadeOut;
   -webkit-animation-duration: 2s;
   -webkit-animation-fill-mode: forwards;
   animation-name: fadeOut;
   animation-duration: 2s;
   animation-fill-mode: forwards;
}

.fade-in {
   -webkit-animation-name: fadeIn;
   -webkit-animation-duration: 1s;
   -webkit-animation-fill-mode: forwards;
   animation-name: fadeIn;
   animation-duration: 1s;
   animation-fill-mode: forwards;
}

/* selector buttons */
#btns-wrapper {
   width: 50%;
   min-width: 300px;
   margin: 30px auto;
   text-align: center;
}

.selector-btn {
   display: inline-block;
   vertical-align: middle;
   width: 15px;
   height: 15px;
   border-radius: 50%;
   padding: 0;
   background-color: rgba(0, 0, 0, 0.4);
   border: 1px solid #bcbcbc;
   cursor: pointer;
   transition: background;
   transition-duration: 0.3s;
   transition-timing-function: linear;
}

.selector-btn:focus {
   outline: none;
}

.selected-btn {
   background-color: #fff;
}

/* progress bar */
.prog-bar {
   display: inline-block;
   vertical-align: middle;
   width: 15%;
   height: 1px;
   background-color: #000;
}

.prog-bar span

/* animated with js */
   {
   display: block;
   height: 100%;
   background-color: #fff;
}

/*--- story box ---*/

.stories-users {
   background: rgba(0, 0, 0, 0.4) none repeat scroll 0 0;
   display: inline-block;
   padding: 20px;
   width: 100%;
   border-radius: 5px;
   margin-top: -15px;
}

.stories-users>h5 {
   color: #fff;
   display: inline-block;
   font-size: 22px;
   width: 100%;
}

.my-status {
   display: inline-block;
   margin-top: 10px;
   width: 100%;
   cursor: pointer;
}

.my-status>figure,
.frnds-stories>li>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.my-status>figure img,
.frnds-stories>li>figure img {
   border: 1px solid #fff;
   border-radius: 100%;
}

.story-user {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
}

.story-user>span {
   color: #fff;
   display: inline-block;
   font-size: 15px;
   font-weight: 500;
   width: 100%;
}

.story-user>ins {
   color: #adadad;
   display: inline-block;
   font-size: 13px;
   text-decoration: none;
   text-transform: capitalize;
}

.frnds-stories {
   border-top: 1px solid rgba(0, 0, 0, 0.2);
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   margin-top: 20px;
   max-height: 420px;
   padding-left: 0;
   padding-top: 20px;
   position: relative;
   width: 100%;
}

.frnds-stories>li {
   margin-bottom: 20px;
   cursor: pointer;
   transition: all 0.2s linear 0s;
}

.frnds-stories>li:hover {
   background: rgba(255, 255, 255, 0.1);
   border-radius: 40px 5px 5px 40px;
}

.frnds-stories>li:nth-last-child(3) {
   margin-bottom: 0;
}

.my-status>a {
   float: right;
   font-weight: 500;
   margin-top: 16px;
   padding: 6px 13px;
   text-transform: capitalize;
}

/*--- range slider ---*/
.filter-meta {
   display: inline-block;
   position: relative;
   width: 100%;
}

#slider-range {
   display: inline-block;
   margin: 6px 0;
   width: 100%;
}

.filter-meta>span {
   text-transform: capitalize;
}

.filter-meta>input {
   border: medium none;
   display: inline-block;
   font-weight: 500;
   padding: 0;
   position: absolute;
   right: 0;
   text-align: right;
   text-transform: capitalize;
   top: 0;
}

.jq-toast-single h2 {
   font-weight: 600;
   vertical-align: middle;
}

/*-- form validation ---*/

.input-holder {
   position: relative;
   margin: 0 0 20px;
}

.input-holder input,
.input-holder textarea {
   border: 1px solid #eaeaea;
   border-radius: 4px;
   padding: 10px;
   width: 100%;
   resize: none;
   -webkit-transition: 0.3s;
   transition: 0.3s;
}

.input-holder input:focus,
.input-holder textarea:focus {
   border: 1px solid #999;
   outline: none;
}

.input-holder textarea {
   min-height: 100px;
}

/* .message {
   position: absolute;
   z-index: 9;
   display: none;
   width: auto;
   padding: 10px;
   margin-top: 10px;
   background: #d9585c;
   color: #fff;
   text-align: center;
   border-radius: 5px;
} */

/* .message:after {
   content: "";
   position: absolute;
   top: -10px;
   left: 50%;
   display: block;
   margin-left: -10px;
   border: solid;
   border-color: #d9585c transparent #d9585c;
   border-width: 0 10px 10px;
} */

.is-valid {
   border-color: #aad661 !important;
   -webkit-transition: 0;
   transition: 0;
}

.not-valid {
   border-color: #d7595f;
   -webkit-transition: 0;
   transition: 0;
}

.not-valid+.message {
   display: block !important;
}

.submit {
   display: block;
   width: 100%;
   padding: 10px;
   border: 2px solid #ccc;
   border-radius: 4px;
   background: transparent;
   color: #ccc;
   -webkit-transition: 0.3s;
   transition: 0.3s;
}

.submit:hover {
   color: #aad661;
   border-color: #aad661;
}

/*--- Paginations ---*/
.pagination>li {
   display: inline-block;
   margin: 0 5px;
}

.pagination>li a {
   background: #eee none repeat scroll 0 0;
   color: #525165;
   display: inline-block;
   font-size: 14px;
   height: 35px;
   line-height: 35px;
   text-align: center;
   transition: all 0.2s linear 0s;
   width: 35px;
   border-radius: 4px;
}

.pagination a {}

.pagination>li a:hover,
.pagination>li a.active {
   /*background: red none repeat scroll 0 0;*/
   /*border-color: red;*/
   color: #fff;
}

.pagination.text>.prev-pge a,
.pagination.text>.next-pge a {
   font-size: 12px;
   height: auto;
   padding: 0 20px;
   width: auto;
}

.pagination.medium>li a {
   height: 45px;
   line-height: 45px;
   width: 45px;
}

.pagination.rounded>li a {
   border-radius: 100%;
}

.pagination.borderd>li a {
   background: #fff none repeat scroll 0 0;
   border: 2px solid #eee;
   line-height: 32px;
}

.pagination.borderd>li a:hover {
   /*border-color: red;
    color: red;*/
   background: #fff;
}

/*--- Bootstrap Accordian / Collapse */
.panel-heading {
   background: #eaeaea none repeat scroll 0 0;
   display: inline-block;
   margin-bottom: 4px;
   width: 100%;
}

.panel-heading>h4>a {
   display: inline-block;
   margin-bottom: 0;
   padding: 15px 20px;
   width: 100%;
}

.panel-title {
   margin-bottom: 0;
}

.panel-body {
   background: #f4f4f4 none repeat scroll 0 0;
   display: inline-block;
   margin-bottom: 4px;
   padding: 30px;
   width: 100%;
}

.panel-body>p {
   margin-bottom: 0;
}

.panel-title i i {
   float: right;
}

/*--- price tables ---*/

.pricings .pricings-bottom .main-btn:hover {
   background: #d2252d;
   color: #fff;
}

.pricings>.pricings-header>h2 {
   color: #0e304c;
   font-weight: bold;
   margin: 0 0 4px;
   text-transform: uppercase;
   text-align: left;
   font-size: 20px;
}


/*--- subscription popup ---*/
.popup-wraper.subscription {
   background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
   float: left;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 9999;
   display: none;
   visibility: visible;
   opacity: 1;
}

.popup-wraper.subscription.closed {
   -webkit-opacity: 0;
   -moz-opacity: 0;
   -ms-opacity: 0;
   -o-opacity: 0;
   opacity: 0;
   visibility: hidden;
   -webkit-transition: all 0.3s linear 0s;
   -moz-transition: all 0.3s linear 0s;
   -ms-transition: all 0.3s linear 0s;
   -o-transition: all 0.3s linear 0s;
   transition: all 0.3s linear 0s;
}

.popup-wraper.subscription .popup {
   padding: 0;
}

.popup-bg {
   background-position: center top;
   background-repeat: no-repeat;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -ms-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: -2;
}

.popup.whitish:before {
   z-index: -1;
}

.sub-popup {
   float: left;
   padding-bottom: 60px;
   padding-top: 40px;
   text-align: center;
   width: 100%;
   position: relative;
}

.sub-popup>h4 {
   color: #434155;
   display: inline-block;
   letter-spacing: 0.3px;
   text-transform: capitalize;
   width: 100%;
}

.sub-popup>p {
   color: #757a95;
   display: inline-block;
   line-height: 24px;
   max-width: 60%;
   padding-bottom: 25px;
   padding-top: 8px;
   width: 100%;
}

.sub-popup>form {
   display: inline-block;
   max-width: 72%;
   width: 100%;
}

.sub-popup input {
   background: #edf2f6 none repeat scroll 0 0;
   border: 1px solid #cecece;
   border-radius: 3px;
   float: left;
   padding: 15px 20px;
   width: 100%;
}

.sub-popup button {
   border-radius: 5px;
   line-height: 25px;
   margin-top: 10px;
   text-transform: uppercase;
   width: 100%;
}

.sub-popup button i {
   margin-right: 3px;
}

.close-btn-popup {
   color: #333;
   float: right;
   margin-right: 10px;
   margin-top: 6px;
}

.blackish .sub-popup>h4,
.blackish .sub-popup>p {
   color: #fff;
}

.blackish .sub-popup button {
   color: #fff;
}

.blackish .close-btn-popup {
   color: #fff;
}

.popup::after {
   border-bottom: 5px solid;
   bottom: 0;
   content: "";
   left: 0;
   opacity: 1;
   position: absolute;
   width: 100%;
}

.sub-popup::before,
.sub-popup::after {
   bottom: 0;
   content: "";
   height: 90px;
   left: 0;
   position: absolute;
   width: 8px;
}

.sub-popup::after {
   left: initial;
   right: 0;
}

.popup-wraper.subscription .popup {
   padding: 0;
}

/*--- landing text caro --*/
/*--- featured top text carousel ---*/
.featured-caro-unit {
   position: relative;
   float: left;
   width: 100%;
}

.text-caro {
   float: left;
   width: 100%;
   position: relative;
}

.unit-text-caro:before,
.featured-caro-unit:before,
.unit-banner:before {
   content: "";
   background: rgba(0, 0, 0, 0.6);
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
}

.unit-text-caro {
   float: left;
   position: relative;
   width: 100%;
}

.text-caro-meta>img {
   display: block;
   margin: 10px auto 0;
   max-width: 260px;
}

.unit-text-caro>img {
   float: left;
   width: 100%;
}

.text-caro-meta {
   float: left;
   left: 50%;
   position: absolute;
   text-align: center;
   top: 50%;
   z-index: 3;
   transform: translate(-50%, -50%);
   width: 55%;
}

.text-caro-meta>span {
   color: #fff;
   font-size: 18px;
   letter-spacing: 2.5px;
}

.text-caro-meta>h1 {
   color: #fff;
   font-size: 50px;
   line-height: 70px;
   font-weight: 600;
   padding-bottom: 20px;
   padding-top: 7px;
}

.text-caro-meta h1 a {
   color: inherit;
}

.text-caro-meta>p {
   color: #fff;
   font-size: 17px;
}

.unit-banner {
   float: left;
   position: relative;
   width: 100%;
}

.featurd-posts {
   float: left;
   width: 100%;
   position: relative;
}

/*--- funfacts section ---*/
.counter,
.count i {
   float: left;
   font-size: 30px;
   font-weight: bold;
}

.counter {
   color: #0e304c;
}

.funfact {
   float: left;
   width: 100%;
   margin-left: 50px;
}

.count {
   float: left;
   margin-top: 80px;
   width: 110px;
}

.counter-meta {
   overflow: hidden;
   padding-right: 56px;
}

.counter-meta>h2 {
   color: #444;
   float: left;
   font-size: 18px;
   font-weight: bold;
   padding-bottom: 5px;
   padding-top: 20px;
   width: 100%;
   line-height: 22px;
}

.counter-meta>h2 a {
   color: inherit;
}

.counter-meta>span {
   float: left;
   width: 100%;
   font-size: 13px;
}

.count>i {
   font-style: normal;
}

/*--- our lab team ---*/
.team {
   float: left;
   width: 100%;
}

.team>h5 {
   color: #0e304c;
   float: left;
   font-size: 30px;
   font-weight: bold;

   width: 100%;
}

.team h5 i {
   font-style: normal;
}

.team>span {
   float: left;
   font: 13px;
   width: 100%;
}

.team>p {
   float: left;
   line-height: 28px;
   padding: 30px 0;
   width: 100%;
}

.team-info {
   background: #fbfbfb none repeat scroll 0 0;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   -ms-border-radius: 3px;
   -o-border-radius: 3px;
   border-radius: 3px;
   float: left;
   margin-left: 10px;
   padding: 25px 25px;
   text-align: center;
   width: calc(100% - 20px);
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}

.team-info h2 {
   color: #444;
   float: left;
   font-size: 14px;
   text-transform: uppercase;
   width: 100%;
}

.team-info h2 a {
   color: inherit;
}

.team-info.over-top {
   margin-top: -60px;
   position: relative;
   z-index: 22;
}

.team-info>span {
   float: left;
   font-size: 12px;
   margin-top: 5px;
   text-transform: capitalize;
   width: 100%;
}

/*--- blog post ---*/
.blog-grid {
   border: 1px solid #eee;
   border-radius: 8px;
   display: inline-block;
   padding: 10px;
   width: 100%;
}

.postby {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 20px;
   padding-left: 0;
   width: 100%;
}

.postby>li {
   display: inline-block;
   margin-right: 20px;
   vertical-align: middle;
}

.postby>li figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.postby>li figure img {
   border: 1px solid #ccc;
   border-radius: 100%;
   max-width: 30px;
}

.postby>li:last-child {
   margin-right: 0;
}

.postby>li span {
   font-size: 12px;
   font-weight: 500;
}

.postby>li>i {
   margin-right: 3px;
}

a.date {
   border-radius: 5px;
   color: #fff;
   float: left;
   height: 60px;
   margin-right: 10px;
   padding: 8px;
   text-align: center;
   vertical-align: top;
   width: 50px;
}

.blog-title {
   display: table;
   margin-bottom: 0;
   font-size: 20px;
}

.blog-title>a {
   color: #535165;
   display: inline-block;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.blog-grid>figure {
   display: inline-block;
   margin-bottom: 8px;
   overflow: hidden;
   width: 100%;
}

.blog-grid>figure img {
   border-radius: 6px;
   transition: all 0.3s linear 0s;
}

.blog-grid:hover>figure img {
   transform: scale(1.1);
}

/*---company footer ---*/
footer.dark {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eeeeee+0,fa6342+100 */
   background: #eeeeee;
   /* Old browsers */
   background: -moz-linear-gradient(top, #eeeeee 0%, #fa6342 100%);
   /* FF3.6-15 */
   background: -webkit-linear-gradient(top, #eeeeee 0%, #fa6342 100%);
   /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, #eeeeee 0%, #fa6342 100%);
   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#fa6342', GradientType=0);
   /* IE6-9 */
}

footer.style2 {
   text-align: center;
   padding-top: 130px;
}

footer.style2 .share-article {
   margin-bottom: 20px;
   margin-top: 20px;
}

.nav-links {
   display: inline-block;
   width: 100%;
}

.nav-links>a {
   color: #333145;
   display: inline-block;
   margin: 0 12px;
   text-transform: capitalize;
}

.copyright-content {
   display: inline-block;
   margin-top: 30px;
   width: 100%;
}

.copyright-content>span {
   color: #dfdfdf;
   display: inline-block;
   width: 100%;
}

.copyright-content sub a {
   color: #fff;
}

.copyright-content sub {
   color: #c9c9c9;
}

/*--- newsletter box ---*/
.news-letter-bx {
   background: #fff none repeat scroll 0 0;
   border-radius: 10px;
   display: block;
   margin: 0 auto -80px;
   max-width: 1070px;
   min-width: 1070px;
   padding: 50px;
   position: relative;
   z-index: 99;
   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.leter-meta {
   display: inline-block;
   position: relative;
   width: 100%;
}

.leter-meta>i {
   bottom: -25px;
   opacity: 0.4;
   position: absolute;
   right: -50px;
   z-index: -1;
}

.leter-input {
   display: block;
   margin: 0 auto;
   width: 80%;
}

.leter-input>form {
   display: inline-block;
   width: 100%;
}

.leter-input input {
   background: #fff none repeat scroll 0 0;
   border: 1px solid #fa6342;
   border-radius: 40px;
   display: inline-block;
   padding: 13px 20px;
   width: 100%;
}

.leter-input form button {
   display: inline-block;
   margin-top: 10px;
   padding: 13px 20px;
   width: 100%;
}

.leter-meta>h2 {
   color: #0e304c;
   display: inline-block;
   margin-bottom: 15px;
   width: 100%;
}

.leter-meta>p {
   font-size: 18px;
   max-width: 90%;
}

/*--- testimonial ---*/
.testi-silver {
   display: inline-block;
   margin-bottom: 30px;
   position: relative;
   width: 100%;
   border: 1px solid #eee;
}

.testi-silver>span {
   display: table-cell;
   position: relative;
   width: 223px;
}

.testi-meta {
   display: table-cell;
   padding: 29px;
   position: relative;
   vertical-align: top;
   width: 58%;
}

.testi-silver>span::before {
   border-left: 60px solid transparent;
   border-right: 60px solid #fff;
   border-top: 260px solid transparent;
   content: "";
   position: absolute;
   right: 0;
   top: -2px;
   z-index: 2;
}

.testi-meta>p {
   color: #737185;
   display: inline-block;
   font-size: 15px;
   line-height: 27px;
   padding-top: 20px;
   width: 100%;
}

.testi-meta>span {
   display: inline-block;
   font-size: 20px;
   letter-spacing: 1.2px;
   text-align: right;
   width: 100%;
   color: #0e304c;
}

.testi-meta>span i {
   float: left;
   font-size: 32px;
   margin-left: -50px;
   z-index: 3;
   position: relative;
}

.testi-meta::before {
   content: "ï„";
   font-family: fontawesome;
   font-size: 15px;
   left: 10px;
   position: absolute;
   top: 20px;
}

.testi-meta>i {
   float: right;
}

/*--- heading style ---*/
.sec-heading.style9>span i {
   font-size: 15px;
   margin-right: 5px;
}

.heading-2>h4 {
   color: #0e304c;
   margin-bottom: 15px;
   text-transform: uppercase;
}

.author-info>h4 {
   color: #535165;
   font-size: 30px;
}

/*--- services box ---*/
.serv-box {
   display: inline-block;
   text-align: center;
   width: 100%;
   position: relative;
}

.serv-box>i {
   font-size: 30px;
}

.serv-box>h4 {
   color: #535165;
   margin-top: 15px;
   margin-bottom: 20px;
}

.serv-box>p {
   font-size: 15px;
   margin-bottom: 0;
}

.serv-box::after {
   background: #dcdcdc none repeat scroll 0 0;
   content: "";
   height: 55%;
   position: absolute;
   right: -20px;
   top: 50%;
   width: 1px;
}

.col-lg-3:last-child .serv-box::after {
   display: none;
}

/*--- company nave ---*/
.topbar.transperent {
   background: transparent none repeat scroll 0 0;
   box-shadow: none;
   height: auto;
   padding-top: 10px;
   padding-bottom: 10px;
}

.topbar.transperent.is_stuck {
   background: rgba(26, 44, 60, 0.6);
   padding-bottom: 10px;
   padding-top: 10px;
}

.topbar.transperent .nav-list {
   background: transparent none repeat scroll 0 0;
   border-radius: 0;
   display: inline-block;
   text-align: right;
   width: 85%;
   box-shadow: none;
}

nav .main-btn {
   margin-left: 20px;
}

.topbar.transperent>nav {
   display: inline-block;
   margin-left: 0;
   width: 84%;
   z-index: 999;
}

/*--- portfolio page ---*/

.option-set.icon-style {
   margin-bottom: 0;
   width: 100%;
}

.option-set.icon-style>li {
   display: inline-block;
   padding: 0 20px;
}

.option-set.icon-style>li>a.selected {
   border-bottom: 1px solid;
}

.option-set.icon-style>li>a {
   font-size: 14px;
   font-weight: 500;
   text-transform: capitalize;
}

.isotop-full {
   margin-bottom: 50px;
   text-align: center;
   width: 100% !important;
}

.isotop-full .option-set {
   padding-left: 0;
   text-align: center;
}

.portfolio-box {
   float: left;
   margin-bottom: 30px;
   position: relative;
   width: 100%;
   border: 1px solid #eaeaea;
}

.overlinks {
   left: 50%;
   max-width: 90%;
   -webkit-opacity: 0;
   -moz-opacity: 0;
   -ms-opacity: 0;
   -o-opacity: 0;
   opacity: 0;
   position: absolute;
   text-align: center;
   top: 50%;
   -webkit-transform: translate(-50%, -80%);
   -moz-transform: translate(-50%, -80%);
   -ms-transform: translate(-50%, -80%);
   -o-transform: translate(-50%, -80%);
   transform: translate(-50%, -80%);
   visibility: hidden;
   width: 100%;
   -webkit-transition: all 0.4s linear 0s;
   -moz-transition: all 0.4s linear 0s;
   -ms-transition: all 0.4s linear 0s;
   -o-transition: all 0.4s linear 0s;
   transition: all 0.4s linear 0s;
}

.cate {
   list-style: outside none none;
   padding-left: 0;
}

.cate>li {
   color: #707070;
   display: inline-block;
   font-size: 13px;
   margin-right: 2px;
   position: relative;
   text-transform: capitalize;
}

.cate>li::before {
   bottom: 0;
   content: ",";
   position: absolute;
   right: -3px;
}

.cate>li:last-child:before {
   display: none;
}

.overlinks>h4 {
   display: inline-block;
   font-size: 24px;
   font-weight: 500;
   text-transform: capitalize;
   width: 100%;
   color: #2a2a2a;
}

.overlinks>h4 a {
   -webkit-transition: all 0.2s linear 0s;
   -moz-transition: all 0.2s linear 0s;
   -ms-transition: all 0.2s linear 0s;
   -o-transition: all 0.2s linear 0s;
   transition: all 0.2s linear 0s;
}

.portfolio-box::before {
   background: rgba(255, 255, 255, 0) none repeat scroll 0 0;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   -webkit-transform: translateY(0px) scale(0);
   -moz-transform: translateY(0px) scale(0);
   -ms-transform: translateY(0px) scale(0);
   -o-transform: translateY(0px) scale(0);
   transform: translateY(0px) scale(0);
   -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
   -moz-transition: transform 0.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
   -ms-transition: transform 0.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
   -o-transition: transform 0.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
   transition: transform 0.4s cubic-bezier(0.6, 0.2, 0.1, 1) 0s,
      opacity 0.7s cubic-bezier(0.6, 0.2, 0.1, 1) 0s;
   width: 100%;
}

.portfolio-box:hover::before {
   background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
   -webkit-transform: translateY(0px) scale(1);
   -moz-transform: translateY(0px) scale(1);
   -ms-transform: translateY(0px) scale(1);
   -o-transform: translateY(0px) scale(1);
   transform: translateY(0px) scale(1);
}

.portfolio-box:hover .overlinks {
   -webkit-transform: translate(-50%, -50%);
   -moz-transform: translate(-50%, -50%);
   -ms-transform: translate(-50%, -50%);
   -o-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   -webkit-opacity: 1;
   -moz-opacity: 1;
   -ms-opacity: 1;
   -o-opacity: 1;
   opacity: 1;
   visibility: visible;
}

.row.masonry {
   margin-bottom: -30px;
}

/*--- welcome section company home ---*/
.welcome-area {
   display: inline-block;
   padding-top: 40px;
   width: 100%;
}

.welcome-area>h2 {
   color: #535165;
   display: inline-block;
   font-size: 37px;
   margin-bottom: 30px;
   padding-bottom: 30px;
   position: relative;
   width: 100%;
}

.welcome-area>p {
   display: inline-block;
   font-size: 17px;
   width: 100%;
}

.welcome-area>h2::before {
   bottom: 0;
   content: "";
   height: 2px;
   left: 0;
   position: absolute;
   width: 100px;
}

.welcome-avatar {
   display: inline-block;
   width: 100%;
}

.fc-grid .fc-day-number {
   padding: 3px 2px;
   text-align: center;
}

.subscription .blackish::before,
.subscription .whitish:before,
.subscription .mate-black::before {
   z-index: -1;
}

/*--- new popup with comment ---*/
#img-comt .modal-header {
   border: 0 none;
   padding: 0;
   position: absolute;
   right: 15px;
   top: 5px;
}

#img-comt .modal-dialog {
   max-width: 1170px;
}

.modal-body .we-comment {
   width: 88%;
}

.modal-body .post-comt-box {
   width: calc(100% - 40px);
}

.modal-body .post-comt-box form textarea {
   height: 50px;
}

.modal-body .we-comet {
   max-height: 410px;
   position: relative;
}

#img-comt .modal-header button {
   margin: 0;
   padding: 0;
   z-index: 44;
}

.user {
   display: inline-block;
   margin-bottom: 12px;
   width: 95%;
}

.user>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.user>figure img {
   border: 2px solid #cacaca;
   border-radius: 100%;
}

.user-information {
   display: inline-block;
   padding-left: 7px;
   vertical-align: middle;
}

.user-information>h4 {
   display: inline-block;
   font-size: 14px;
   margin-bottom: 0;
   width: 100%;
}

.user-information>h4 a {
   color: #212335;
}

.user-information>span {
   display: inline-block;
   font-size: 12px;
}

.user>a {
   border: 1px solid;
   border-radius: 6px;
   float: right;
   font-size: 12px;
   margin-top: 16px;
   padding: 3px 20px;
}

.pop-image {
   background: #3c3c3c none repeat scroll 0 0;
   display: table;
   height: 100%;
   padding: 0 20px;
   width: 100%;
}

.pop-item {
   display: table-cell;
   vertical-align: middle;
}

.pop-item.my-auto>figure {
   display: inline-block;
   margin-bottom: 0;
}

/*--- blog popup ---*/
#blog-popup .modal-dialog {
   max-width: 850px;
}

#blog-popup .modal-body {
   padding: 20px 30px;
}

.blog-popup-meta {
   display: inline-block;
   width: 100%;
}

.blog-popup-meta .pop-image {
   background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
   padding: 0;
}

.blog-popup-meta .pop-image figure img {
   width: 100%;
}

.blog-paragraph {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.blog-paragraph>h4 {
   color: #535165;
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

/*--- category page ---*/
.categroy-box {
   display: inline-block;
   position: relative;
   width: 100%;
   padding: 5px;
   margin-bottom: 30px;
}

.categroy-box>img {
   display: inline-block;
   width: 100%;
}

.cat-hover {
   display: inline-block;
   left: 50%;
   position: absolute;
   text-align: center;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 80%;
   z-index: 2;
}

.cat-hover>a {
   color: #fff;
   display: inline-block;
   font-size: 26px;
   font-weight: 500;
   margin-bottom: 10px;
   width: 100%;
   text-transform: capitalize;
}

.categroy-box::before {
   bottom: 0;
   content: "";
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 1;
}

.cat-hover>p {
   color: #eee;
   margin-bottom: 0;
}

/*--- transparent header ---*/
.transparent .top-area>ul>li>a:hover,
.transparent .top-area>ul>li>a.active {
   color: #535165;
}

.transparent .top-area>ul>li>a {
   color: #9196b1;
}

.transparent .top-area .user-img>img {
   border-color: #6563ff;
}

.transparent .top-area>ul>li>a>em {
   color: #fff;
}

.transparent .top-area>ul>li>a>em.dark {
   color: #333;
}

.transparent .nav-list {
   background: #a31920;
}

.transparent .nav-list>li ul {
   background: #d2252d none repeat scroll 0 0;
}

.transparent .nav-list>li ul>li>a:hover {
   background: #6563ff;
}

.transparent .nav-list>li:hover {
   background: #d2252d;
}

/* .top-area>ul>li>a::before {
   background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
   border-radius: 100%;
   content: "";
   height: 40px;
   left: 50%;
   position: absolute;
   top: 53%;
   transform: translate(-50%, -50%) scale(0);
   transition: all 0.2s linear 0s;
   width: 40px;
   z-index: -1;
}

.top-area>ul>li>a:hover::before {
   opacity: 1;
   transform: translate(-50%, -50%) scale(1);
} */

/*-- post single detail page ---*/
.post-title.gry-bg {
   background: #e1e6ea none repeat scroll 0 0;
}

.post-title.gry-bg>h6 {
   color: #fa6342;
}

.post-title.gry-bg>span {
   color: red;
}

.post-title.gry-bg .number {
   background: #fff none repeat scroll 0 0;
}

/*--- your page widget ---*/

.job-search-form .c-form .add-loc>i {
   cursor: pointer;
   position: absolute;
   right: 30px;
   top: 20px;
}

.job-search-form .c-form .add-location-post {
   background: #fff none repeat scroll 0 0;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
   left: 0;
   position: absolute;
   top: 100%;
   width: 1070px;
   z-index: 99;
   padding: 30px;
}

.job-search-form .c-form #map,
#us3 {
   margin-top: 20px;
}

/*--- trending widget ---*/

/*--- page loader ---*/

.wavy-wraper {
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   width: 100%;
   position: fixed;
   z-index: 9999999;
   top: 0;
   left: 0;
}

.wavy-wraper.hidden {
   display: none;
}

.wavy {
   position: relative;
   -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.wavy>span {
   position: relative;
   display: inline-block;
   color: #fff;
   font-size: 2em;
   text-transform: uppercase;
   animation: animate 1.5s ease-in-out infinite;
   animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {

   0%,
   100% {
      transform: translateY(0px);
   }

   20% {
      transform: translateY(-20px);
   }

   40% {
      transform: translateY(0px);
   }
}

/*--- market Place digital product ---*/
.dig-pro {
   display: inline-block;
   position: relative;
   width: 100%;
   margin-bottom: 30px;
}

.dig-pro>figure {
   display: inline-block;
   position: relative;
   width: 100%;
}

.dig-pro>figure>img {
   width: 100%;
}

.dig-pro>figure .user-avatr {
   bottom: 10px;
   left: 10px;
   position: absolute;
}

.dig-pro>figure .user-avatr img {
   border: 2px solid #fff;
   border-radius: 100%;
}

.dig-pro>figure .user-avatr>span {
   display: block;
}

.dig-pro>figure .user-avatr>div {
   display: inline-block;
   padding-left: 8px;
   vertical-align: middle;
}

.dig-pro>figure .user-avatr>div span {
   color: #fff;
   display: inline-block;
   font-size: 10px;
   width: 100%;
}

.dig-pro>figure .user-avatr>div ins {
   color: #fff;
   font-size: 13px;
   font-weight: 500;
   text-decoration: none;
}

.digi-meta {
   display: inline-block;
   margin-top: 10px;
   padding: 0 20px;
   width: 100%;
}

.digi-meta>h4 {
   color: #535165;
   font-size: 20px;
}

.rate {
   border-top: 1px solid #eaeaea;
   display: inline-block;
   padding-top: 10px;
   width: 100%;
}

.rate .pit-rate {
   float: left;
   margin: 0;
   text-align: left;
   width: auto;
}

.rate .qeemat {
   float: right;
   font-size: 20px;
   font-weight: 500;
}

.rate .qeemat del {
   color: #999;
   font-size: 15px;
   margin-right: 5px;
}

/*--- checkout page ---*/
.checkout-meta .c-form>.row>div {
   padding: 5px;
}

.cart-prod {
   display: inline-block;
   list-style: outside none none;
   margin-top: 30px;
   padding-left: 0;
   width: 100%;
}

.cart-prod>li {
   display: inline-block;
   width: 100%;
}

.cart-prod>li>p {
   color: #535165;
   float: right;
}

.cart-prod>li>ins {
   display: inline;
   font-size: 11px;
   text-decoration: none;
}

.cart-prod>li>p>span {
   color: red;
}

.cart-prod>li>span {
   color: #535165;
   float: left;
   font-weight: 500;
   width: 40%;
}

.total-line>ul {
   border-top: 1px solid #eaeaea;
   display: inline-block;
   list-style: outside none none;
   padding-left: 0;
   padding-top: 30px;
   width: 100%;
}

.total-line>ul li {
   display: inline-block;
   text-transform: capitalize;
   width: 100%;
}

.total-line>ul li>ins {
   color: #535165;
   float: left;
   font-weight: 500;
   text-decoration: none;
}

.total-line>ul li>span {
   color: #535165;
   float: right;
   font-weight: 500;
}

.total-line>ul li>span>i {
   font-style: normal;
}

.total-line>ul li>b {
   color: red;
   display: inline-block;
   font-size: 28px;
   font-weight: 500;
   text-align: center;
}

.total-line>ul li:last-child {
   margin: 20px 0;
   text-align: center;
}

.total-line>ul li>em {
   color: #515165;
   display: inline-block;
   font-size: 28px;
   font-style: normal;
   font-weight: 500;
   margin-left: 10px;
}

.payment-method .form-radio .radio {
   margin-bottom: 6px;
   margin-right: 0;
   width: 100%;
}

.payment-method .c-form>div>label {
   font-size: 20px;
   margin-bottom: 15px;
}

.payment-method .c-form>div {
   margin-bottom: 20px;
}

/*--- shop detail page ---*/

.share>a {
   display: inline-block;
   font-size: 17px;
   margin-right: 10px;
   vertical-align: middle;
}

.full-postmeta .pit-rate {
   float: right;
   margin: 0;
   text-align: right;
   width: auto;
}

.full-postmeta>.pit-rate>li {
   font-size: 14px;
}

.full-postmeta>.pit-rate>li>span {
   color: #999;
}

.prices {
   display: inline-block;
   margin-left: 40px;
   vertical-align: top;
}

.prices.style4>ins {
   float: right;
}

.prices>ins {
   color: #535165;
   font-size: 19px;
   text-decoration: none;
}

.prices.style4>ins span {
   color: #535165;
   font-size: 24px;
}

.prices.style4>del {
   clear: both;
   color: #a3a1b5;
   display: inline-block;
   float: none;
   font-family: impact;
   font-size: 13px;
   font-weight: 400;
   margin-right: 6px;
   margin-top: 5px;
   vertical-align: bottom;
}

.full-postmeta>i {
   color: #bdbdbd;
   float: left;
   font-family: impact;
   font-size: 14px;
   font-style: normal;
   letter-spacing: 6px;
   padding-bottom: 13px;
   text-transform: uppercase;
   width: 100%;
}

.full-postmeta>h4 {
   color: #535165;
   display: inline-block;
   font-size: 36px;
   margin-bottom: 0;
   padding-bottom: 16px;
   width: 100%;
}

.qty-select {
   display: inline-block;
   width: 72px;
}

.qty-select .input-group {
   align-items: stretch;
   display: flex;
   flex-wrap: wrap;
   position: relative;
   width: 100%;
}

.input-group-btn {
   left: 50%;
   position: absolute;
   top: -10px;
   transform: translate(-50%);
   z-index: 5;
}

.btn.btn-default {
   border-radius: 100% !important;
   color: #fff;
   line-height: 17px;
   padding: 0;
   width: 18px;
}

.qty-select .qty.form-control {
   border: 2px solid;
   border-radius: 100px !important;
   color: #535165;
   font-size: 14px;
   height: 46px;
   line-height: 30px;
   text-align: center;
   width: 100%;
}

.input-group-btn:last-child {
   bottom: -8px;
   top: auto;
}

.prod-detail .full-postmeta .shopnow {
   border: 2px solid;
   border-radius: 30px;
   clear: both;
   display: table;
   float: right;
   font-size: 14px;
   margin-left: 0;
   padding: 7px 15px;
   transition: all 0.2s linear 0s;
}

.prod-detail .full-postmeta .shopnow:hover {
   color: #fff;
}

.full-postmeta>p {
   margin-bottom: 50px;
   margin-top: 10px;
}

.extras {
   float: right;
   margin-top: 50px;
}

.extras>a.play-btn {
   border: 1px solid #bababa;
   border-radius: 30px;
   padding: 7px 13px;
   text-transform: capitalize;
   color: #fff;
   transition: all 0.2s linear 0s;
}

.extras>a.play-btn:hover {
   background: transparent;
}

.post-as {
   display: inline-block;
   vertical-align: middle;
   width: 74%;
   margin-top: 40px;
}

.prod {
   display: inline-block;
   width: 100%;
}

.cat-heading {
   color: #bdbdbd;
   font-size: 14px;
   font-weight: 500;
}

.cat-heading>a {
   color: #0e0e0e;
   font-size: 13px;
   margin-left: 8px;
   position: relative;
}

.cat-heading>a::before {
   content: ",";
   position: absolute;
   right: -5px;
   top: 0;
}

/*---- description area of product detail page ---*/
.tab-section.ruby .nav.nav-tabs.single-btn {
   border-bottom: medium none;
   text-align: left;
   vertical-align: top;
   width: 28%;
   display: inline-block;
   margin-top: 20px;
}

.tab-section.ruby .nav.nav-tabs.single-btn>li {
   margin-bottom: 10px;
   margin-right: 0;
   width: 100%;
}

.single-btn>li>a {
   border: 2px solid #fff;
   border-radius: 30px;
   color: #7b7d7f;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   padding: 13px 26px;
   text-transform: uppercase;
}

.tab-section.ruby .tab-content {
   display: inline-block;
   vertical-align: top;
   width: 70%;
}

.more-pix {
   float: left;
   width: 100%;
}

.user-post {
   color: #535165;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   margin-bottom: 5px;
   text-transform: capitalize;
   width: 100%;
}

.user-post>i {
   font-size: 20px;
   margin-right: 8px;
}

#desc .more-pix .main-title {
   color: #535165;
   display: inline-block;
   font-size: 28px;
   margin-bottom: 5px;
   margin-top: 15px;
   text-align: left;
   text-transform: uppercase;
   width: 100%;
}

.prod-info {
   display: inline-block;
   margin-bottom: 0;
   padding: 10px 10px 20px;
   width: 95%;
}

.aditional-inf {
   float: left;
   width: 85%;
}

.aditional-inf .main-title {
   color: #535165;
   display: inline-block;
   font-size: 25px;
   margin-bottom: 20px;
   text-align: left;
   vertical-align: top;
   width: 100%;
}

.adition-info {
   color: #7b7d7f;
   display: inline-block;
   font-size: 15px;
   line-height: 24px;
   vertical-align: middle;
   width: 100%;
}

table.adition {
   color: #525252;
   display: table;
   float: left;
   font-size: 15px;
   margin-bottom: 0;
   margin-top: 10px;
   width: 100%;
}

table.adition tbody tr td {
   border-top: 1px dotted #d9d9d9;
   padding-bottom: 18px;
   padding-top: 18px;
   color: #535165;
   font-weight: 500;
}

.tab-content .woocommerce-Reviews {
   float: left;
   width: 100%;
}

.tab-content .woocommerce-Reviews>#comments {
   float: left;
   width: 100%;
}

.tab-content .woocommerce-Reviews-title {
   display: inline-block;
   font-size: 25px;
   letter-spacing: 0.3px;
   margin-bottom: 20px;
   width: 100%;
}

.woocommerce-Reviews .commentlist {
   list-style: outside none none;
   padding-left: 0;
   width: 100%;
}

.tab-content .commentlist>li {
   border-bottom: 1px dotted #d9d9d9;
   display: inline-block;
   margin-bottom: 30px;
   padding-bottom: 16px;
   width: 85%;
}

.tab-content .comment_container>.avatar {
   display: inline-block;
   margin-right: 10px;
   max-width: 42px;
   vertical-align: top;
   width: 42px;
}

.tab-content .comment-text {
   display: inline-block;
   vertical-align: top;
   width: 90%;
}

.tab-content .comment-text .user-name {
   font-size: 15px;
   margin-right: 35px;
   font-weight: 500;
}

.tab-content .comment-text a.post-date {
   color: #949494;
   font-size: 12px;
   margin-right: 20px;
}

.star-rating {
   color: #d4d4d4;
   display: inline-block;
   font-size: 13px;
}

.tab-content .comment-text>p {
   margin-top: 10px;
}

.comment-respond {
   float: left;
   width: 85%;
}

.reply-title {
   color: #535165;
   font-size: 24px;
}

.comment-notes {
   font-size: 13px;
}

.comment-notes>span {
   color: #535165;
   font-size: 13px;
}

.comment-form-author,
.comment-form-email {
   display: inline-block;
   margin-bottom: 20px;
   margin-right: 14px;
   width: 48.6%;
}

.comment-form-email {
   margin-right: 0;
}

.comment-form-author input,
.comment-form-email input,
.comment-notes textarea {
   border: 2px solid #eaeaeb;
   border-radius: 5px;
   color: #7b7d7f;
   font: 13px "lato";
   padding: 15px 20px;
   width: 100%;
}

.comment-notes textarea {
   height: 130px;
}

.star-rating>label {
   color: #1c1f23;
   font-size: 13px;
   font-weight: 500;
   margin-right: 10px;
}

.form-submit {
   margin-bottom: 0;
   margin-top: 15px;
}

.form-submit>input[type="submit"] {
   border: medium none;
   border-radius: 30px;
   color: #fff;
   font-size: 12px;
   padding: 11px 52px;
   text-transform: uppercase;
}

/*--- invite friends caro in group feed ---*/
.suggested-frnd-caro.invite .owl-item>li img {
   border: 2px solid #dadada;
   border-radius: 100%;
}

.suggested-frnd-caro.invite .owl-item>li {
   background: transparent;
   border: 0;
}

.sugtd-frnd-meta .send-invitation {
   border-radius: 5px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   margin-top: 5px;
   padding: 2px 5px;
   text-transform: uppercase;
}

.sugtd-frnd-meta .send-invitation:hover {
   color: #fff;
}

/*--- group info widget ---*/

.user-profile .join-btn {
   border-radius: 6px;
   bottom: 65px;
   color: #fff;
   left: 230px;
   padding: 6px 20px;
   position: absolute;
}

/*--- price range widget ---*/
.widget .filter-meta>input {
   background: transparent none repeat scroll 0 0;
   border: medium none;
   padding: 0;
   width: 100%;
}

.introjs-tooltipReferenceLayer {
   z-index: 999999 !important;
}

.topbar.introjs-fixParent {
   width: 100% !important;
}

.fixed-sidebar.left.introjs-fixParent {
   position: fixed !important;
}

.mm-page.mm-slideout.introjs-fixParent {
   width: 100%;
}

.modal {
   z-index: 999999;
}

.alert {
   display: inline-block;
   font-size: 14px;
   height: auto;
   line-height: initial;
   padding: 6px;
   width: 100%;
}

/*--- suggested friends caro ---*/
.suggested-caro {
   list-style: outside none none;
   margin: 20px 0 0;
   padding-left: 0;
}

.suggested-caro li {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.suggested-caro li figure img {
   border: 2px solid #088dcd;
   border-radius: 10px;
   max-width: 80px;
}

.suggested-caro li span {
   display: inline-block;
   font-weight: 500;
   width: 100%;
}

.suggested-caro li ins {
   color: #92929e;
   display: inline-block;
   font-size: 12px;
   text-decoration: none;
   width: 100%;
}

.suggested-caro li>a {
   background: #088dcd none repeat scroll 0 0;
   border-radius: 16px;
   color: #fff;
   display: inline-block;
   font-size: 12px;
   margin-top: 10px;
   padding: 5px 14px;
}

.suggested-caro li figure img {
   display: inline-block !important;
   width: auto !important;
}

/*--- how its work widget ---*/
.how-to {
   display: inline-block;
   margin-top: -20px;
   width: 100%;
}

.how-to .strip {
   display: inline-block;
   position: relative;
   width: 100%;
}

.how-to>a::before {
   background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 2;
}

.how-to .strip img {
   width: 100%;
}

.how-to .strip i svg {
   z-index: 5;
}

.adb {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.adb span {
   font-size: 11px;
}

/*--- Library Page ----*/
.popular-book {
   display: inline-block;
   margin-bottom: 20px;
   width: 100%;
}

.popular-book>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
   width: 80px;
}

.book-about {
   display: inline-block;
   padding-left: 7px;
   vertical-align: middle;
   width: 60%;
}

.popular-book>figure img {
   border-radius: 4px;
}

.book-about>h6 {
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 5px;
}

.book-about>span {
   font-size: 12px;
}

.book-about>a {
   float: right;
   font-size: 16px;
   color: #b2b2be;
}

.popular-book:last-child {
   margin-bottom: 0;
}

.category {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 22px;
   padding-left: 0;
   width: 100%;
}

.category>li {
   display: inline-block;
   margin-right: 6px;
   vertical-align: middle;
}

.category>li a {
   background: #deebf3 none repeat scroll 0 0;
   border: 1px solid #c9d6de;
   border-radius: 30px;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   padding: 4px 18px;
   transition: all 0.2s linear 0s;
}

.category>li a:hover {
   color: #fff;
}

.book-post {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.owl-item .book-post {
   margin-bottom: 0;
}

.book-post>figure {
   margin-bottom: 6px;
}

.book-post>figure>a {
   display: inline-block;
   overflow: hidden;
   width: 100%;
}

.book-post>figure>a img {
   border-radius: 6px;
   transition: all 0.2s ease-in-out 0s;
   width: 100%;
}

.book-post>a {
   display: inline-block;
   font-weight: 500;
   transition: all 0.2s linear 0s;
   width: 100%;
}

.book-post:hover>figure>a img {
   transform: scale(1.02);
}

.title {
   color: #525165;
   display: inline-block;
   font-size: 22px;
   margin-bottom: 20px;
   padding: 5px 10px;
   position: relative;
   width: 100%;
   z-index: 5;
}

.title::before {
   border-radius: 0 20px 20px 0;
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 3px;
   z-index: -1;
}

.title>a {
   color: red;
   float: right;
   font-size: 13px;
   margin-top: 8px;
   text-transform: capitalize;
}

/*--- course page ---*/
.course {
   background: #fff none repeat scroll 0 0;
   border-radius: 10px;
   display: inline-block;
   width: 100%;
   margin-bottom: 30px;
   box-shadow: -7.5pt 10px 10.5pt -3.75pt rgba(25, 42, 70, 0.13);
}

.course>figure {
   display: inline-block;
   margin-bottom: 0;
   position: relative;
   width: 100%;
}

.course>figure img {
   border-radius: 7px 7px 0 0;
   width: 100%;
}

.course>figure>i {
   color: #fff;
   cursor: pointer;
   font-size: 30px;
   position: absolute;
   right: 10px;
   top: -1.6px;
}

.course>figure>em {
   background: #088dcd;
   box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
   color: #fff;
   font-size: 12px;
   font-style: normal;
   left: -5px;
   padding: 3px 10px;
   position: absolute;
   text-transform: capitalize;
   top: 20px;
}

.course>figure .rate-result {
   background: #fec42d none repeat scroll 0 0;
   border-radius: 30px;
   bottom: 15px;
   color: #fff;
   font-size: 11px;
   left: 15px;
   padding: 2px 10px;
   position: absolute;
}

.course-meta {
   display: inline-block;
   width: 100%;
   padding: 20px;
}

.post-by {
   display: inline-block;
   width: 80%;
}

.post-by>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.post-by>figure img {
   border: 1px solid #cacaca;
   border-radius: 100%;
   max-width: 30px;
}

.course-cat {
   display: inline-block;
   line-height: 15px;
   padding-left: 5px;
   vertical-align: middle;
   width: 82%;
}

.course-cat>span {
   color: #82828e;
   display: inline-block;
   font-size: 11px;
   font-weight: 700;
   text-transform: capitalize;
   width: 100%;
}

.course-cat>a {
   border-right: 1px solid;
   display: inline-block;
   font-size: 11px;
   line-height: 10px;
   margin-right: 3px;
   padding-right: 5px;
}

.course-cat>a:last-child {
   border: 0 none;
}

.prise {
   float: right;
}

.prise>span {
   color: #92929e;
   font-size: 18px;
   font-weight: 500;
}

.course:hover .prise>span {
   color: #62626e;
}

.course-title {
   display: inline-block;
   font-size: 18px;
   font-weight: 500;
   margin-bottom: 12px;
   margin-top: 10px;
   width: 100%;
}

.course-info {
   display: inline-block;
   width: 100%;
}

.lecturez {
   color: #82828e;
   float: left;
   font-size: 12px;
}

.course-time {
   color: #82828e;
   float: right;
   font-size: 12px;
}

/*--- course category ---*/
.categ-card {
   background: #fff none repeat scroll 0 0;
   border-radius: 10px;
   box-shadow: -7.5pt 10px 10.5pt -3.75pt rgba(25, 42, 70, 0.13);
   display: inline-block;
   padding: 15px;
   text-align: center;
   width: 100%;
}

.categ-card>i {
   display: inline-block;
   margin-bottom: 10px;
   width: 100%;
}

.categ-card>div {
   display: inline-block;
   width: 100%;
}

.categ-card>div>p {
   font-size: 12px;
   margin-bottom: 0;
   text-align: left;
}

.categ-card>div>p span {
   float: right;
}

.categ-card>div>h6 {
   font-weight: 500;
}

ul.pagination {
   display: block;
   text-align: center;
}

.course-title>a {
   color: #535165;
   display: inline-block;
   width: 100%;
}

/*--- New Register Page ---*/
.reg-from {
   background: #fff none repeat scroll 0 0;
   border: 1px solid #eaeaea;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
   display: inline-block;
   /* margin-top: 35px; */
   padding: 30px;
   width: 100%;
   border-radius: 20px;
}

.reg-from>span {
   color: #515365;
   display: block;
   font-size: 22px;
   font-weight: 500;
   margin: 0 auto;
   text-align: center;
   width: 100%;
}

.reg-from>p {
   display: block;
   margin: 0 auto 20px;
   text-align: center;
   width: 100%;
}

.reg-from>span i {
   color: red;
   font-size: 20px;
   margin-right: 2px;
   vertical-align: middle;
}

.c-form .gender {
   display: inline-block;
   width: 100%;
}

.c-form .gender .form-radio {
   display: flex;
}

.c-form .gender .form-radio .radio {
   background: #edf2f6;
   border: 1px solid #eaeaea;
   border-radius: 5px;
   margin-right: 10px;
   min-width: 100%;
   padding: 12px 16px;
}

.c-form .gender .radio .check-box {
   top: -0.1rem;
}

.reg-from .c-form .checkbox label {
   font-size: 11px;
}

.reg-from .c-form button {
   padding: 8px 24px;
   text-transform: uppercase;
}

.reg-with {
   display: inline-block;
   font-size: 13px;
   margin-bottom: 5px;
   width: 100%;
}

.social-reg {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding: 0;
   width: 100%;
}

.social-reg>li {
   display: inline-block;
}

.social-reg>li a {
   border-radius: 5px;
   color: #fff;
   display: inline-block;
   font-size: 12px;
   padding: 5px 10px;
   width: 100%;
}

/*-- ad center page ---*/
.boost-box>a:hover {
   background: #f9f9f9;
   box-shadow: none;
   border: 1px solid #eaeaea;
}

.boost-box>a {
   border-radius: 6px;
   box-shadow: 0 4px 17px rgba(0, 0, 0, 0.2);
   display: inline-block;
   padding: 20px;
   text-align: center;
   width: 100%;
   transition: all 0.2s linear 0s;
   border: 1px solid transparent;
}

.boost-box {
   display: inline-block;
   position: relative;
   width: 100%;
   margin-bottom: 20px;
}

.boost-box>a i {
   background: #e4e6eb none repeat scroll 0 0;
   border-radius: 100%;
   color: #34465d;
   display: block;
   font-size: 20px;
   line-height: 50px;
   margin: 0 auto;
   width: 50px;
}

.boost-box>a span {
   color: #515365;
   display: inline-block;
   font-size: 15px;
   margin-top: 10px;
   max-width: 82%;
}

.meta-title {
   color: #515365;
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.meta-title>i {
   color: #a1a3b5;
}

.boost-box p {
   background: #23d2e2 none repeat scroll 0 0;
   border-radius: 0 9px 9px 0;
   color: #fff;
   font-size: 11px;
   left: -3px;
   line-height: initial;
   padding: 3px 6px;
   position: absolute;
   top: -3px;
}

/*--- video call popup ---*/
.video-live-call {
   background: #fff none repeat scroll 0 0;
   display: inline-block;
   position: relative;
   top: 53%;
   transform: translateY(-50%);
   width: 65%;
   padding: 10px;
}

.vid-call-wraper .call-box {
   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7db9e8+0,1e5799+100&0+0,1+100 */
   background: -moz-linear-gradient(top,
         rgba(125, 185, 232, 0) 0%,
         rgba(30, 87, 153, 1) 100%);
   /* FF3.6-15 */
   background: -webkit-linear-gradient(top,
         rgba(125, 185, 232, 0) 0%,
         rgba(30, 87, 153, 1) 100%);
   /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,
         rgba(125, 185, 232, 0) 0%,
         rgba(30, 87, 153, 1) 100%);
   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#1e5799', GradientType=0);
   /* IE6-9 */
   bottom: 0;
   left: 0;
   padding-bottom: 13px;
   padding-top: 10px;
   position: absolute;
   width: 100%;
}

.yesorno>a.bg-white {
   color: #535165;
}

.video-live-call>figure {
   display: inline-block;
   margin: 0;
   width: 100%;
}

.video-live-call .call-box>h6 {
   color: #fff;
   margin-top: 0;
}

.video-live-call .call-box>span {
   margin-bottom: 5px;
}

.video-live-call .call-box>ins {
   color: #fff;
   margin-bottom: 8px;
   margin-top: 0;
}

.video-live-call .call-box>i {
   color: #fff;
}

.my-cam {
   bottom: 10px;
   box-shadow: -5px -5px 15px rgba(255, 255, 255, 0.7);
   padding: 4px;
   position: absolute;
   right: 10px;
}

.video-live-call img {
   width: 100%;
}

/*--- boostup post ---*/
.promote-baner {
   display: inline-block;
   margin-bottom: 20px;
   padding: 20px;
   position: relative;
   width: 100%;
   border-radius: 6px;
}

.promote-baner.blackish:before {
   border-radius: 6px;
}

.promote-baner .bg-image {
   z-index: 0;
   border-radius: 6px;
}

.ads-links {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 0;
   position: relative;
   width: 100%;
   z-index: 2;
   text-align: right;
}

.promote-baner>span {
   color: #fff;
   display: inline-block;
   font-size: 17px;
   font-weight: 500;
   line-height: 25px;
   margin-bottom: 12px;
   position: relative;
   width: 100%;
   z-index: 1;
}

.ads-links>li {
   display: inline-block;
   margin-right: 10px;
}

.ads-links>li>a {
   background: #e6353c;
   border: 2px solid #e6353c;
   border-radius: 30px;
   display: inline-block;
   font-size: 13px;
   color: #fff;
   padding: 6px 10px 6px 6px;
   transition: all 0.2s linear 0s;
}

.ads-links>li>a:hover {
   background: #fff;
   border-color: #e6353c;
   color: #e6353c;
}

.ads-links>li>a>i {
   background: #fff none repeat scroll 0 0;
   color: #515365;
   border-radius: 100%;
   height: 25px;
   line-height: 25px;
   text-align: center;
   transition: all 0.2s linear 0s;
   width: 25px;
}

.ads-links>li>a:hover i {
   background: #e6353c;
   color: #fff;
}

.boost-box>a:hover {
   background: #f9f9f9;
   box-shadow: none;
   border: 1px solid #eaeaea;
}

.boost-box>a {
   border-radius: 6px;
   box-shadow: 0 4px 17px rgba(0, 0, 0, 0.2);
   display: inline-block;
   padding: 20px;
   text-align: center;
   width: 100%;
   transition: all 0.2s linear 0s;
   border: 1px solid transparent;
}

.boost-box {
   display: inline-block;
   position: relative;
   width: 100%;
   margin-bottom: 20px;
}

.boost-box>a i {
   background: #e4e6eb none repeat scroll 0 0;
   border-radius: 100%;
   color: #34465d;
   display: block;
   font-size: 20px;
   line-height: 50px;
   margin: 0 auto;
   width: 50px;
}

.boost-box>a span {
   color: #515365;
   display: inline-block;
   font-size: 15px;
   margin-top: 10px;
   max-width: 82%;
}

/*--- course library detail page ---*/
.course-details {
   display: inline-block;
   width: 100%;
}

.rating-stars {
   list-style: outside none none;
   margin-bottom: 10px;
   padding-left: 0;
}

.rating-stars>li {
   color: #fec42d;
   display: inline-block;
}

.course-details>h4 {
   display: inline-block;
   font-size: 30px;
   margin-bottom: 20px;
   width: 74%;
   color: #535165;
}

.course-price {
   float: right;
   font-size: 26px;
   font-weight: 500;
}

.course-price>del {
   color: #92929e;
   font-size: 14px;
}

.create-by {
   display: inline-block;
   width: 80%;
}

.create-by>figure {
   display: inline-block;
   margin-bottom: 0;
   vertical-align: middle;
}

.create-by>figure>img {
   border: 1px solid #dadada;
   border-radius: 100%;
}

.create-by>div {
   display: inline-block;
   padding-left: 10px;
   vertical-align: middle;
}

.create-by>div span {
   color: #525252;
   display: inline-block;
   font-size: 14px;
   font-weight: 500;
   width: 100%;
}

.create-by>div em {
   font-size: 11px;
   font-style: normal;
}

.course-details>a {
   background: #fff none repeat scroll 0 0;
   border: 1px solid;
   border-radius: 30px;
   color: #fff;
   float: right;
   font-weight: 500;
   padding: 5px 20px;
}

.statistic {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   margin-top: 30px;
   padding-left: 0;
   width: 100%;
}

.statistic>li {
   color: #82828e;
   display: inline-block;
   font-size: 12px;
   margin-right: 30px;
   vertical-align: middle;
}

.statistic>li i {
   color: #a2a2ae;
   font-size: 14px;
}

.course-video {
   display: inline-block;
   margin-bottom: 30px;
   position: relative;
   text-align: center;
   width: 100%;
}

.course-video figure {
   display: inline-block;
   position: relative;
   width: 100%;
}

.course-video figure img {
   border-radius: 10px;
   width: 100%;
}

.course-video figure .strip {
   background: rgba(255, 255, 255, 0.7) none repeat scroll 0 0;
   border: 1px solid;
   border-radius: 100%;
   color: #fff;
   font-size: 30px;
   left: 50%;
   line-height: 60px;
   position: absolute;
   text-align: center;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 60px;
}

.course-video figure .strip>i {
   padding-left: 6px;
}

.course-video>span {
   display: inline-block;
   font-size: 12px;
   margin-top: 15px;
   width: 100%;
}

a.wish-btn {
   background: #ffd550 none repeat scroll 0 0;
   border: 2px solid #ffa500;
   border-radius: 30px;
   color: #333;
   display: inline-block;
   font-size: 13px;
   font-weight: 500;
   padding: 9px 13px;
   vertical-align: inherit;
}

.incldes {
   display: inline-block;
   width: 100%;
}

.incldes>ul {
   display: inline-block;
   list-style: outside none none;
   margin: 0;
   padding-left: 0;
   width: 100%;
}

.incldes>ul>li {
   display: inline-block;
   margin-bottom: 15px;
   width: 49%;
}

.incldes>ul>li>i {
   margin-right: 5px;
   color: #92929e;
}

.incldes>ul>li>span {
   color: #535165;
}

.desc-course {
   display: inline-block;
   margin-bottom: 30px;
   width: 100%;
}

.video-lecture span {
   float: right;
}

.video-lecture>li {
   border-bottom: 1px solid #dadada;
   display: inline-block;
   margin-bottom: 15px;
   width: 100%;
}

.video-lecture {
   display: inline-block;
   list-style: outside none none;
   padding-left: 0 !important;
   width: 100%;
   margin: 0;
}

.video-lecture>li>i {
   margin-right: 10px;
}

.video-lecture>li>a:hover {
   color: red;
}

.video-lecture>li:last-child {
   margin-bottom: 0;
}

.info-sec {
   background: #f5f5f5 none repeat scroll 0 0;
   border-radius: 6px;
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.info-sec>i {
   border-radius: 5px;
   color: #fff;
   display: inline-block;
   font-size: 15px;
   height: 40px;
   line-height: 40px;
   text-align: center;
   vertical-align: top;
   width: 40px;
}

.info-sec>div {
   display: inline-block;
   padding-left: 15px;
   width: 85%;
}

.info-sec>div h6 {
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   line-height: 17px;
   width: 100%;
}

.info-sec>div p {
   font-size: 13px;
   line-height: 16px;
   margin-bottom: 0;
   margin-top: 8px;
}

.review-item {
   display: inline-block;
   text-align: center;
   width: 100%;
}

.student-review .owl-dots {
   margin-top: 20px;
}

.review-item>figure {
   display: inline-block;
   margin-bottom: 8px;
   width: 70px;
}

.review-item>figure img {
   border: 1px solid red;
   border-radius: 100%;
   padding: 4px;
}

.review-item>h6 {
   display: inline-block;
   font-weight: 700;
   width: 100%;
}

.review-item>span {
   color: red;
   font-size: 11px;
}

.review-item>p {
   display: inline-block;
   font-size: 13px;
   line-height: 22px;
   margin-bottom: 0;
   margin-top: 10px;
   width: 100%;
}

.rating-stars>li>span {
   background: #fec42d none repeat scroll 0 0;
   border-radius: 4px;
   color: #fff;
   font-size: 12px;
   font-weight: 500;
   line-height: 0;
   padding: 2px 15px;
}

.course-details>a {
   background: #fff none repeat scroll 0 0;
   border: 1px solid;
   border-radius: 30px;
   color: #fff;
   float: right;
   font-weight: 500;
   padding: 5px 20px;
}

.comment-area.product {
   display: inline-block;
   width: 100%;
}

.comment-area.product .comment-title {
   font-size: 20px;
   margin-bottom: 30px;
   color: #535165;
}

.book-description {
   display: inline-block;
   margin-top: 20px;
   width: 100%;
}

.book-description>p {
   margin-bottom: 0;
}

.post-subject>h1 {
   color: #ffffff;
   display: inline-block;
   font-size: 30px;
   margin-bottom: 5px;
   width: 100%;
}

.post-subject>p {
   color: #eeeeee;
   display: inline-block;
   font-size: 16px;
   margin-bottom: 0;
   max-width: 50%;
   width: 100%;
}

.comments {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}

.comment-box {
   background: #f9f9f9 none repeat scroll 0 0;
   border: 1px solid #ede9e9;
   border-radius: 5px;
   display: inline-block;
   padding: 20px;
   width: 100%;
}

.comments>li {
   border-radius: 3px;
   display: inline-block;
   margin-bottom: 20px;
   position: relative;
   width: 100%;
}

.comments>li:last-child {
   margin-bottom: 0;
}

.commenter-photo {
   display: table-cell;
   vertical-align: top;
}

.commenter-meta {
   display: table-cell;
   padding-left: 15px;
   position: relative;
   vertical-align: middle;
}

.commenter-photo>img {
   border: 2px solid;
   border-radius: 100%;
   padding: 3px;
   width: auto;
   max-width: 50px;
}

.comment-titles h6 {
   display: inline-block;
   font-size: 16px;
   font-weight: 500;
   text-transform: capitalize;
   vertical-align: top;
   color: #535165;
}

.comment-titles>span {
   color: #92929e;
   font-size: 11px;
   margin-left: 6px;
   margin-right: 22px;
   text-transform: uppercase;
}

.comment-titles a.reply {
   border-radius: 30px;
   color: #fff;
   display: inline-block;
   font-size: 13px;
   margin-top: 0;
   padding: 2px 18px;
   text-transform: capitalize;
   vertical-align: middle;
}

.comment-titles a.reply:hover {
   background: #000 none repeat scroll 0 0;
   color: #fff;
}

.commenter-meta>p {
   color: #666;
   float: left;
   font-size: 13px;
   font-weight: normal;
   line-height: 24px;
   margin-bottom: 0;
   padding-top: 10px;
   width: 90%;
}

.comments>li>ul {
   float: left;
   list-style: outside none none;
   margin-top: 17px;
   padding-left: 60px;
   width: 100%;
}

.comment-form {
   float: left;
   margin-top: 65px;
   width: 100%;
}

.comment-titles>ins {
   background: #fec42d none repeat scroll 0 0;
   border-radius: 30px;
   color: #ffffff;
   float: right;
   padding: 2px 13px;
   text-decoration: none;
}

.add-comment {
   display: inline-block;
   margin-top: 30px;
   position: relative;
   width: 100%;
}

.add-comment>span {
   float: left;
   font-weight: 500;
}

.add-comment .stars {
   float: left;
   margin-left: 14px;
   position: unset;
   vertical-align: middle;
   width: auto;
}

.stars {
   display: flex;
   left: 70px;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   position: absolute;
   top: 40px;
   width: 30%;
   z-index: 2;
}

.stars>li {
   color: #fec42d;
   font-size: 16px;
}

.card-header button.btn.collapsed::before {
   color: #72727e;
   content: "+";
   font-size: 28px;
   position: absolute;
   right: 20px;
   top: 0;
}

.card-header button.btn.collapsed::before {
   color: #72727e;
   content: "+";
   font-size: 28px;
   position: absolute;
   right: 20px;
   top: 0;
}

.card-header button.btn::after {
   color: #72727e;
   content: "-";
   font-size: 35px;
   position: absolute;
   right: 20px;
   top: -7px;
}

.question-collaps .card-header button.btn {
   display: inline-block;
   text-align: left;
   text-decoration: none;
   width: 100%;
   color: #535165;
   font-size: 14px;
   padding: 10px 20px;
}

.btn.btn-link>span {
   color: #535165;
   font-size: 12px;
   position: absolute;
   right: 60px;
   top: 10px;
}

.inner-column {
   display: inline-block;
   margin-top: 4px;
   text-align: center;
   width: 100%;
}

.total-rating {
   font-size: 32px;
   font-weight: 500;
}

.rating {
   display: inline-block;
   width: 100%;
}

.rating>span {
   color: #fec42d;
   font-size: 14px;
}

.inner-column>span {
   color: #72727e;
   display: inline-block;
   font-size: 13px;
   margin-top: 5px;
   width: 100%;
}

.rating>i {
   font-size: 11px;
   font-style: normal;
}

.skills {
   display: inline-block;
   margin-top: 8px;
   width: 100%;
}

.bar {
   margin: 0 auto 8px;
   width: 100%;
}

.bar-outer {
   background: #e9e9e9 none repeat scroll 0 0;
   border-radius: 3px;
   height: 12px;
   position: relative;
}

.bar-inner.ht {
   background: #f89d94 none repeat scroll 0 0;
}

.html:after {
   border-top-color: #f89d94;
}

.jq,
.jquery {
   background: #f89d94;
}

.jquery:after {
   border-top-color: #f89d94;
}

.sk,
.sketch {
   background: #f89d94;
}

.sketch:after {
   border-top-color: #f89d94;
}

.ph,
.photo {
   background: #f89d94;
}

.photo:after {
   border-top-color: #f89d94;
}

.il,
.illustrator {
   background: #f89d94;
}

.illustrator:after {
   border-top-color: #f89d94;
}

.in,
.indesign {
   background: #f89d94;
}

.indesign:after {
   border-top-color: #f89d94;
}

.bar-inner {
   border-radius: 3px;
   height: 100%;
   width: 0;
}

.post_action {
   height: 30px;
   width: 30px;
   display: inline-block;
   text-align: center;
   line-height: 30px;
   color: #fff;
   font-size: 16px;
   border-radius: 50%;
}

.post_action:hover,
.post_action:active,
.post_action:focus {
   color: #ccc;
}

:root {
   --small_fs: 12px;
   --regular_fs: 14px;
   --regular_lg: 18px;

   --space_1: 1.5rem;
   --space_2: 2.2rem;
   --space_3: 2.6rem;
   --space_4: 3rem;
   --space_5: 3.4rem;
   --space_6: 3.8rem;
}

.mh_300 {
   max-height: 300px;
}

.fw_500 {
   font-weight: 500;
}

.fw_600 {
   font-weight: 600;
}

/* FONT SIZE'S */
.font_sm {
   font-size: var(--small_fs);
}

.fs_12 {
   font-size: 12px;
}

.fs_14 {
   font-size: 14px;
}

.fs_16 {
   font-size: 16px;
}

.fs_18 {
   font-size: 18px;
}

.fs_20 {
   font-size: 20px;
}

.fs_22 {
   font-size: 22px;
}

.fs_24 {
   font-size: 24px;
}

.fs_26 {
   font-size: 26px;
}

.fs_28 {
   font-size: 28px;
}

.fs_30 {
   font-size: 30px;
}

/* FONT SIZE'S END */

/* PADDING TOP */
.p_t_1 {
   padding-top: var(--space_1);
}

.p_t_2 {
   padding-top: var(--space_2);
}

.p_t_3 {
   padding-top: var(--space_3);
}

.p_t_4 {
   padding-top: var(--space_4);
}

.p_t_5 {
   padding-top: var(--space_5);
}

.p_t_6 {
   padding-top: var(--space_6);
}

/* PADDING BOTTOM */
.p_b_1 {
   padding-bottom: var(--space_1);
}

.p_b_2 {
   padding-bottom: var(--space_2);
}

.p_b_3 {
   padding-bottom: var(--space_3);
}

.p_b_4 {
   padding-bottom: var(--space_4);
}

.p_b_5 {
   padding-bottom: var(--space_5);
}

.p_b_6 {
   padding-bottom: var(--space_6);
}

/* MARGIN TOP */
.m_t_1 {
   margin-top: var(--space_1);
}

.m_t_2 {
   margin-top: var(--space_2);
}

.m_t_3 {
   margin-top: var(--space_3);
}

.m_t_4 {
   margin-top: var(--space_4);
}

.m_t_5 {
   margin-top: var(--space_5);
}

.m_t_6 {
   margin-top: var(--space_6);
}

/* MARGIN BOTTOM */
.m_b_1 {
   margin-bottom: var(--comPad_12);
}

.m_b_2 {
   margin-bottom: var(--space_2);
}

.m_b_3 {
   margin-bottom: var(--space_3);
}

.m_b_4 {
   margin-bottom: var(--space_4);
}

.m_b_5 {
   margin-bottom: var(--space_5);
}

.m_b_6 {
   margin-bottom: var(--space_6);
}

.primary_bg_color {
   background: var(--primary);
}

.secondary_bg_color {
   background: var(--secondary);
}

.info_bg_color {
   background: var(--info);
}

.success_bg_color {
   background: var(--success);
}

.warning_bg_color {
   background: var(--warning);
}

.danger_bg_color {
   background: var(--success);
}

.light_bg_color {
   background: var(--light);
}

.dark_bg_color {
   background: var(--dark);
}

.accent1_bg_color {
   background: var(--accent1);
}

.accent2_bg_color {
   background: var(--accent2);
}

.accent3_bg_color {
   background: var(--accent3);
}

.gray1_bg_color {
   background: var(--gray1);
}

.gray2_bg_color {
   background: var(--gray2);
}

.bg-primary {
   background-color: var(--primary) !important;
}

.link_primary {
   color: var(--primary);
}

.link {
   font-size: 12px;
}

/* OWL CAROUSEL */
.sidebar_slide.owl-carousel .owl-dots {
   text-align: center;
}

.sidebar_slide.owl-carousel .owl-dot {
   width: 7px;
   height: 7px;
   background: var(--color60);
   margin: 0 2px;
   border-radius: 50%;
}

.sidebar_slide.owl-carousel .owl-dot.active {
   background: var(--color30);
}

.gray_1_text {
   color: var(--gray1);
}

.truncate_2 {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.truncate_3 {
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

/* WRITE POST SECTION */
.btn-blue {
   background: #23d2e2;
}

.btn-blue:hover {
   background: #fa6342;
   color: #fff;
}

.btn-orange {
   background: #fa6342;
   color: #fff;
}

.btn-orange:hover {
   background: #989dbd;
   color: #fff;
}

.btn-orange:focus {
   color: #fff;
}

a.imp {
   display: block;
   text-align: center;
   padding: 10px;
   border-radius: 4px;
   text-transform: uppercase;
   border: 1px solid;
}

.fb {
   background-color: #2f5b9d;
   color: #fff;
}

.linkedin {
   border-color: #0a66c2;
   background: #0a66c2;
   color: #fff;
}

.fb:hover {
   background: #2f5b9d;
   color: #fff;
}

.linkedin:hover {
   background: #0a66c2;
   color: #fff;
}

.accordion.completeProfile>.card>.card-header button {
   padding: 10px;
   background: #edf2f6;
}

.accordion.completeProfile>.card>.card-header button div.point {
   display: inline-block;
   width: 21px;
   text-align: center;
   margin-right: 5px;
}

.accordion.completeProfile>.card>.card-header button[aria-expanded="true"] {
   background: #fa6342;
}

.accordion.completeProfile>.card>.card-header button[aria-expanded="true"] div.point {
   background: #ffffff;
   color: #fa6342;
}

.accordion.completeProfile>.card>.card-header button[aria-expanded="false"] div.point {
   display: inline-block;
   background: #fa6342;
   width: 21px;
   color: #fff;
   text-align: center;
   margin-right: 7px;
}

.dp_upload_cont {
   background: #fafafa;
   padding: 15px;
   border-radius: 4px;
   text-align: center;
}

.dp_holder {
   border: 2px solid black;
   display: none;
   width: 150px;
   margin: 0 auto;
   border-radius: 50%;
   margin-bottom: 18px;
}

.dp_holder img {
   width: 150px;
   height: 150px;
   border-radius: 50%;
}

input[type="file"]#upload_dp {
   margin-top: 5px;
}

.dp_upload_cont .upload_dp_label {
   margin-bottom: 0;
}

.dp_upload_cont .msg {
   margin-top: 10px;
   font-size: 12px;
   font-style: italic;
}

.change-photo .fileContainer input {
   display: none;
}

.edit-phto>label {
   transition: all 0.3s linear 0s;
}

.edit-phto:hover>label {
   color: #fff;
}

.overlay_text {
   position: relative;
}

.overlay_text .verify {
   cursor: pointer;
   position: absolute;
   z-index: 1;
   right: 7px;
   top: 50%;
   transform: translateY(-50%);
   text-transform: capitalize;
   font-size: 12px;
   font-weight: 500;
   color: #fa6342;
   margin: 0;
}

.overlay_text input {
   padding-right: 45px !important;
}

.overlay_text .verify.verified {
   color: mediumseagreen;
}

/* home page quote section */

.posted_by {
   font-weight: 500;
   margin-top: 7px;
}

.aspect\[1\/1\] {
   aspect-ratio: 1/1;
}

.dahsmainmenu li a.active {
   background-color: rgba(0, 0, 0, 0.1);
   font-weight: 600;
   color: #fe434d;
}

.dahsmainmenu li a.active svg {
   fill: #fe434d;
}

a.export_contact {
   display: inline-flex;
   padding: 8px 15px;
   align-items: center;
   background: #dc3545;
   border-radius: 30px;
   color: #fff;
   font-size: 11px;
   font-weight: 500;
}

a.export_contact svg {
   margin-right: 7px;
   height: 17px;
}

.in,
.indesign {
   background: transparent;
}



.popup-box {
   display: none;
   position: fixed;
   bottom: 0px;
   right: 220px;
   height: 380px;
   background-color: #edf2f6;
   width: 300px;
   z-index: 9999;
   border-top-left-radius: 0.3rem;
   border-top-right-radius: 0.3rem;
}

.popup-box .popup-head {
   background-color: #fff;
   padding: 12px;
   color: #ddd;
   font-weight: 600;
   font-size: 12px;
   clear: both;
   line-height: 1;
   display: flex;
   align-items: center;
   border-top-left-radius: 0.3rem;
   border-top-right-radius: 0.3rem;
}

.popup-box .popup-head .popup-head-left {
   float: left;
   display: inline-flex;
   align-items: center;
}

.popup-box .popup-head .popup-head-right {
   margin-left: 10px;
}

.popup-box .popup-head .popup-head-right a {
   text-decoration: none;
   color: #dc3545;
}

/* .popup-messages .msg {
   display: flex;
   align-items: flex-start;
   margin-bottom: 7px;
} */

.msg .text {
   background: #131313;
   border-radius: 10px;
   padding: 12px;
   color: #595765;
}

.msg.rec .text {
   background: rgb(255 150 150 / 26%);
}

.msg.sent .text {
   background: rgb(226 242 255);
}

/* .popup-messages .msg img {
   height: 35px;
   width: 35px;
} */

/* .popup-messages .msg.rec img {
   margin-right: 7px;
} */

/* .popup-messages .msg.sent img {
   margin-left: 7px;
} */

/* .popup-box .popup-messages {
   overflow-y: scroll;
   padding: 10px;
   height: 71%;
} */

/* .popup-box .popup-messages .nm {
   display: flex;
   margin-bottom: 3px;
} */

/* .popup-box .popup-messages .nm .nm_text {
   color: #000;
} */

/* .popup-box .popup-messages .nm .popup-box .popup-messages::-webkit-scrollbar-track {
   background-color: transparent;
} */

/* .popup-box .popup-messages::-webkit-scrollbar {
   width: 2px;
   background-color: transparent;
} */

/* .popup-box .popup-messages::-webkit-scrollbar-thumb {
   background-color: #aaa;
} */

.chat-form input.emoji_print+a+div {
   bottom: 49px !important;
   top: inherit !important;
}

.chat-form .form-group:after {
   opacity: 0;
}

.popup-box .popup-head .popup-head-left .dot-count {
   height: 7px;
   width: 7px;
   display: inline-block;
   border-radius: 50%;
   margin-right: 6px;
}

.popup-box .popup-head .popup-head-left {
   color: #535165;
}

button.emoji {
   position: relative !important;
   color: #000;
   font-size: 16px;
   height: 17px;
   padding: 2px 12px;
}

.emoji-picker {
   z-index: 1;
}

.emoji-picker__tab svg {
   color: #878787 !important;
}

.emoji-picker__search {
   border: 0;
   background: var(--light-gray);
}

.emoji-picker__search:focus {
   border: 0;
   background: var(--light-gray);
}

.emoji-picker__content {
   height: 19.4rem !important;
}

.emoji-picker__preview {
   height: 4em !important;
   padding: 8px !important;
   background: #fff !important;
   margin-top: 14px;
   z-index: 1;
   position: relative;
}

.success-icon {
   border-radius: 50px;
   background: #dc3545;
   color: #fff;
   width: 90px;
   height: 90px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
   font-size: 30px;
   margin-bottom: 20px;
}

.success-link {
   display: flex;
   font-size: 15px;
   font-weight: 600;
   color: #d2252d;
   justify-content: center;
   align-items: center;
}

.success-link:hover {
   color: #d2252d;
}

.success-link svg {
   stroke: #d2252d;
   margin: 0;
   margin-right: 8px;
}

@media (max-width: 1210px) {
   .logo {
      width: 9%;
   }

   .top-area {
      width: 90%;
   }

   .main-menu {
      margin-right: 15px;
   }

   .top-search {
      width: 28%;
   }

   .top-area>ul {
      padding-left: 12px;
   }

   .whether {
      margin-right: 14px;
      margin-left: 14px;
   }

   .top-area>span.main-menu {
      margin-right: 10px;
   }

   .comment .text {
      font-size: 13px;
   }

   .live_widget .content {
      font-size: 13px;
   }

   .add_post_form .pos_left_icon+.write_post {
      padding-right: 30px;
      font-size: 13px;
   }

   .add_post_form .write_post {
      padding: 15px 12px;
   }

   .event_wrap .info h6 {
      font-size: 13px;
   }

   .quote blockquote {
      font-size: 15px;
   }

   .posted_by {
      font-size: 13px;
   }

   .nav.nav-tabs.trend li a {
      padding: 4px 12px;
   }

   .nav.nav-tabs.trend li {
      width: 48%;
   }

   .top-search form input {
      padding: 13px 16px;
   }

   .top-search button {
      right: -8px;
   }
}

@media (max-width: 991px) {
   .logo {
      width: 11%;
   }

   .top-area {
      width: 88%;
   }

   .top-search {
      width: 36%;
   }

   .whether {
      display: none;
   }
}