.media_box {
    margin-bottom: 20px;
    cursor: pointer;
    position: relative;
}
.media_box.image img {
    border-radius: 6px;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
}
.media_box.video img {
    border-radius: 6px;
    aspect-ratio: 16/9;
    object-fit: cover;
    width: 100%;
}
.media_box.video button.playBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    width: 60px;
    height: 35px;
    border-radius: 6px;
    min-width: auto;
}