.profile_widget {
   margin-top: -15px;
}
.cover_img {
   aspect-ratio: 24/9;
   overflow: hidden;
}
.cover_img img {
   height: 100%;
   width: 100%;
   object-fit: cover;
}
.completation {
   position: relative;
   width: 72px;
   height: 72px;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: -38px;
   margin-bottom: 15px;
}
.completation img {
   position: absolute;
   top: 50%;
   left: 50%;
   height: 70px;
   width: 70px;
   transform: translate(-50%, -50%);
   border-radius: 50%;
}
.page_meta {
   width: 100%;
   text-align: center;
   display: block;
   padding-left: 20px;
   padding-right: 20px;
}
.page_meta > a {
   font-size: 16px;
   color: var(--bs-primary);
   font-weight: 500;
   text-transform: capitalize;
}
.page_meta > span {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   width: 100%;
}
.page_meta > span.desig {
   margin-top: 7px;
   line-height: 1.4;
}
.page_likes {
   padding: 0 20px 20px;
   margin-top: 15px;
}
.page_likes ul {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
}
.page_likes ul li {
   text-align: center;
   width: 49%;
}

.total_count {
   color: var(--title_text_color);
   font-size: 20px;
   font-style: normal;
   font-weight: 500;
   text-align: center;
   width: 100%;
   display: block;
   margin-bottom: 10px;
   line-height: 1;
}

.total_count i {
   color: inherit;
   font-size: 18px;
   margin-right: 6px;
}

.users_thumb_list {
   text-align: center;
   width: 100%;
   margin-top: 10px;
}
.users_thumb_list > a {
   display: inline-block;
   margin-left: -17px;
   position: relative;
}
.users_thumb_list > a:first-child {
   margin-left: 0;
}
.users_thumb_list > a img {
   border: 2px solid var(--bs-white);
   border-radius: 50%;
}

.progresdiv {
   margin-top: 0;
   width: 70px;
   height: 70px;
}
.progresdiv svg {
   display: block;
   margin: 0 auto;
   overflow: hidden;
   transform: rotate(-90deg) rotateX(180deg);
   height: auto;
   width: auto;
   margin: 0 !important;
}
.progresdiv svg circle {
   stroke-dashoffset: 0;
   transition: stroke-dashoffset 1s ease;
   stroke: var(--bs-primary);
   stroke-width: 4px;
}

.progresdiv svg circle.bar_light {
   stroke: var(--bs-gray-200);
}
.progresdiv svg circle.bar_dark {
   stroke: var(--bs-gray-200);
}

.progresdiv a {
   position: absolute;
   top: 100%;
   left: 50%;
   font-size: 12px;
   transform: translate(-50%, -50%);
   content: attr(data-percent) "%";
   background: #ffffff;
   color: #dc3545;
   padding: 4px 6px;
   line-height: 1;
   border-radius: 50px;
   box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
   font-weight: 600;
}
