/*=========================
	FONT COLOR
=========================*/
.product-carousel .owl-nav .owl-prev::before, 
.product-carousel .owl-nav .owl-next::before,
.product-caro .owl-prev:hover:before, 
.product-caro .owl-next:hover:before,
.log-reg-area form .forgot-pwd,
.log-reg-area form .already-have,
.log-reg-area > p a,
.timeline-info > ul li a.active,
.timeline-info > ul li a:hover,
.dropdowns > a.more-mesg,
.activity-meta > h6 a,
.activity-meta > span a:hover,
.description > p a,
.we-comment > p a,
.sidebar .widget li:hover > a, .sidebar .widget li:hover > i,
.friend-meta > a,
.user-setting > a:hover,
.we-comet li a.showmore,
.twiter-feed > li p a,
.tutor-links > li i,
.tutor-links > li:hover,
.pepl-info > span,
.frnds .nav-tabs .nav-item a.active,
#work > div a,
.basics > li i,
.education > li i,
.groups > span i,
a.forgot-pwd,
.friend-meta > h4 a:hover,
.x_title > h2,
.post-meta .detail > span,
.add-btn > a:hover,
.top-area > ul.main-menu > li > ul li a:hover,
.dropdowns.active > a i,
.form-group input.form-file ~ .control-label, 
.form-group input.has-value ~ .control-label, 
.form-group input:focus ~ .control-label, 
.form-group input:valid ~ .control-label, 
.form-group select ~ .control-label, 
.form-group textarea.form-file ~ .control-label, 
.form-group textarea.has-value ~ .control-label, 
.form-group textarea:focus ~ .control-label, 
.form-group textarea:valid ~ .control-label,
.flaged > h3,
.invition .friend-meta a.invite:hover,
.more-optns > ul li:hover,
.post-title > h4 a:hover,
.post-title .p-date a:hover,
.l-post .l-post-meta > h4 a:hover,
.read:hover,
.tags > a:hover,
.comment-titles > span,
.help-list > ul li a:hover i,
.carrer-title > span a,
.open-position > h4 a:hover,
.option-set.icon-style > li > a.selected,
.category-box > i,
.branches-box > ul li i,
.help-topic-result > h2 a:hover,
.product-name > h5 a:hover,
.full-postmeta .shopnow,
.prices.style2 ins span,
.single-btn > li > a.active,
.total-box > ul > li.final-total,
.logout-meta > p a,
.forum-list table tbody tr td i,
.widget ul.recent-topics > li > i,
.date-n-reply > a,
.topic-data > span,
.help-list > ul li a:hover,
.employer-info h2,
.job-detail > ul li i,
.company-intro > a,
.your-page ul.page-publishes > li span:hover i,
.drops-menu > li > a:hover .mesg-meta h6,
.we-comment > h5:hover,
.inline-itms > a:hover,
.mesg-meta figure span,
.like-dislike > li a:hover,
.we-video-info > ul li .users-thumb-list > span strong,
.we-video-info > ul li .users-thumb-list > span a,
.add-del-friends > a:hover,
.story-box:hover .story-thumb > i,
.sugtd-frnd-meta > span > a,
.sugtd-frnd-meta > a:hover,
.create-post > a,
.mesg-meta > h6 > a:hover,
.profile-menu > li > a:hover,
.profile-menu > li > a.active,
.friend-name > ins > a,
.more-post-optns > ul > li:hover,
.more-post-optns > ul > li:hover i,
.origin-name > a,
.breadcrumb > .breadcrumb-item,
.nav-tabs--left .nav-link.active,
.nav-tabs--left .nav-link.active:hover,
.set-title > span a,
.onoff-options .setting-row > p > a,
.checkbox > p a,
.notifi-seting > p a,
.page-likes .tab-content .tab-pane > a,
.personal-head > p a,
.f-title i,
.more-opotnz > ul li a:hover,
.frnd-name > a:hover,
.option-list ul li a:hover,
.option-list ul li i,
.smal-box .fileContainer > i,
.from-gallery > i,
.over-photo > a:hover i,
.featurepost > h5 > i,
.widget .fav-community > li a,
.radio input:checked ~ .check-box::before,
.suggestd > li .sug-like:hover i,
.gen-metabox > p > a,
.widget .invitepage > li > a i ,
.see-all,
.event-title > h4 a:hover,
.location-map > p,
.event-title > span i,
.typography > a,
.main-btn2,
a.main-btn2,
blockquote p strong,
.dob-meta > h6 a,
.recent-jobs li > span a,
.recent-jobs li h6 span,
.position-meta > span,
.invite-location > span,
.invite-figure > h6 > a,
.user-add > div > i,
.logout-form > p > a,
.logout-form > a,
.login-frm > a,
.c-form.search .radio > a,
.frnd-meta > a,
.notifi-meta > span > i,
.card-body a,
.search-meta > span i,
.pit-frnz-meta > a:hover,
.pit-groups-meta > a:hover,
.pit-pages-meta > a:hover,
.related-searches > li > a:hover,
.wiki-box > h4 > a,
.wiki-box > p > a,
.p-info > a,
.widget .reg-comp-meta > ul > li a,
.re-links-meta > h6 > a:hover,
.pitnik-video-help > i,
h3.resutl-found > span,
.related-links > li > a:hover,
.attachments > ul .add-loc > i,
.colla-apps > li a:hover,
.add-location-post > span,
footer .widget .colla-apps > li a:hover,
.list-style > li a:hover,
.page-meta > a:hover,
.add-pitrest > a,
.pitrest-pst-hding:hover,
.fa.fa-heart.like,
.loc-cate > ul.loc > li i,
.loc-cate > ul > li a,
.loc-cate > ul > li::before,
.job-price > ins,
.users-thumb-list > span > a,
.we-video-info > ul li span:hover,
.we-video-info .heart:hover,
.job-search-form > a,
.user-figure > a,
.user-info > li span,
.main-color,
.pit-points > i,
.menu-list > li > a > i,
.post-up-time > li a,
.number > span.active i, 
.number > input.active,
.pit-uzr > a:hover,
.pit-post-deta > h4 > a:hover,
.view-pst-style > li.active > a,
.pit-opt > li.save,
.Rpt-meta > span,
.pitred-links > ul > li a:hover,
.smilez > li > span,
.sidebar .comnity-data > ul > li,
.comnty-avatar > a:hover,
.usr-fig > a:hover, 
.post-up-time > li .usr-fig > a:hover,
.feature-title > h2 > a:hover,
.feature-title > h4 > a:hover,
.feature-title > h6 > a:hover,
.nave-area > li > a > i,
.nave-area > li > a:hover,
.save-post.save,
.tube-title > h6 > a:hover,
.chanle-name > a,
.channl-author > em,
.pit-tags > span,
.tube-pst-meta > h5 a:hover,
.addnsend > a i,
.follow-me:hover,
.follow-me:hover i,
.contribute:hover,
.contribute:hover i,
.links-tab li.nav-item > a.active,
.post-meta > h6 > a:hover,
.fixed-sidebar .left-menu-full > ul li a.closd-f-menu,
.fixed-sidebar .left-menu-full > ul li a:hover,
.help-box > span,
.post-meta .detail > a:hover,
.sugested-photos > h5 a,
.our-moto > p > span,
.sound-right .send-mesg,
.title-block .align-left h5 > i,
.audio-user-name > h6 a:hover,
.add-send > ul > li a,
.add-send .send-mesg,
.audio-title:hover,
.sound-post-box > h4,
.singer-info > span,
.playlist-box > ul > li:hover,
.song-title > h6 > a:hover,
.song-title > a:hover,
.playlist-box > h4 i,
.prise,
.location-area > span > i,
.classic-pst-meta > h4 a:hover,
.total-area > ul li.order-total > i,
.classi-pst-meta > span ins,
.classi-pst-meta > h6 a:hover,
.classi-pst .user-fig a,
.msg-pepl-list .nav-item.unread > a > div h6,
.chater-info > h6,
.text-box > p a,
.description > h2 a:hover,
span.ttl,
.filter-meta > input,
.pagination.borderd > li a:hover,
.pricings > h1 span,
.count i,
.testi-meta > span i,
.sec-heading.style9 > h2 span,
.sec-heading.style9 > span i,
.blog-title > a:hover,
.serv-box > i,
.heading-2 span,
.team > h5 span,
.popup-closed:hover,
.text-caro-meta > span,
.text-caro-meta > h1 > a span,
.sub-popup > h4 span,
.testi-meta::before,
.user > a,
.your-page > figure > span,
.left-menu > li a:hover,
.folw-detail ins,
.profile-menu > li > a > i,
.rate .qeemat,
.cart-prod > li > p > span,
.total-line > ul li > b,
.full-postmeta > h4 > span,
.cat-heading > a,
.total-line > ul li > span > i,
.extras > a.play-btn:hover,
.single-btn > li > a,
.grouppage-info > li,
.title > a,
.reg-from > span i,
.ads-links > li > a:hover,
.course-price,
.course-details > a,
.sidebar .widget.live .widget-title>div a,
.page-meta > span em,
.live_comment ul li>.comment .comment_action span:hover, .nav-tabs--left .nav-link .u_p_status, a {
	color: var(--bs-primary);	
}

.nav-tabs--left .nav-link .u_p_status {
	float: right;
}

.live_comment ul li>.comment .comment_action span {
	color: #515365;
}

path.icon{
	fill: #fff;
}


/*=========================
	BACKGROUND COLOR
=========================*/

.timeline-info > ul li a::before,
.add-btn > a,
.activitiez > li::before,
a.underline:before,
.setting-row input:checked + label,
.user-avatar:hover .edit-phto,
.add-butn,
.nav.nav-tabs.likes-btn > li a.active,
a.dislike-btn,
.drop > a:hover,
.btn-view.btn-load-more:hover,
.accordion .card h5 button[aria-expanded="true"],
.f-page > figure em,
.inbox-panel-head > ul > li > a,
footer .widget-title h4::before,
#topcontrol,
.sidebar .widget-title::before,
.g-post-classic > figure > i::after,
.purify > a,
.open-position::before,
a.main-btn,.main-btn,
.section-heading::before,
.more-branches > h4::before,
.is-helpful > a,
.cart-optionz > li > a:hover,
.paginationz > li a:hover,
.paginationz > li a.active,
.shopping-cart,
a.btn2:hover,
.form-submit > input[type="submit"],
button.submit-checkout,
.delete-cart:hover,
.proceed .button,
.amount-area .update-cart,
a.addnewforum,
.new-postbox .post-btn:hover,
.weather-date > span,
a.learnmore,
.banermeta > a:hover,
.add-remove-frnd > li a:hover,
.profile-controls > li > a:hover,
.edit-seting:hover,
.edit-phto:hover,
.account-delete > div > button:hover,
.radio .check-box::after,
.eror::after,
.eror::before,
.big-font,
.event-time .main-btn:hover,
.group-box > button:hover,
.dropcap-head > .dropcap,
.checkbox .check-box::after, .checkbox .check-box::before,
.main-btn2:hover,
.main-btn3:hover,
.jalendar .jalendar-container .jalendar-pages .add-event .close-button,
.jalendar .jalendar-container .jalendar-pages .days .day.have-event span::before,
.user-log > i:hover,
.total > i,
.login-frm .main-btn,
.search-tab .nav-tabs .nav-item > a.active::after,
.mh-head,
.job-tgs > a:hover,
.owl-prev:hover:before, 
.owl-next:hover:before,
.help-list > a,
.title2::before,
.list-style > li a:hover:before,
.postbox .we-video-info > button:hover,
.postbox .we-video-info > button.main-btn.color,
.copy-email > ul li a:hover,
.post-status > ul li:hover,
.tags_ > a:hover,
.policy .nav-link.active::before,
a.circle-btn:hover,
.mega-menu > li:hover > a > span,
.pit-tags > a:hover,
.create-post::before,
.amount-select > li:hover,
.amount-select > li.active,
.pay-methods > li:hover,
.pay-methods > li.active,
.msg-pepl-list .nav-item.unread::before,
.menu .btn:hover,
.menu-item-has-children ul.submenu > li a::before,
.pagination > li a:hover,
.pagination > li a.active,
.sub-popup::before, .sub-popup::after,
a.date,
.welcome-area > h2::before,
.page-header.theme-bg,
.btn.btn-default,
.prod-detail .full-postmeta .shopnow:hover,
.extras > a.play-btn,
.sugtd-frnd-meta .send-invitation,
.user-profile .join-btn,
.suggested-caro li > a,
.title::before,
.ads-links > li > a,
.ads-links > li > a:hover i,
.wavy-wraper, .title-block h5 > a > span, .nav.nav-tabs.trend li a.active {
	background: var(--bs-primary);
	color: #fff;
}

.stroke-solid {
	fill: #fa6342;
}

.add-tofrndlist > a {
    background: var(--bs-primary) none repeat scroll 0 0;
}



/*=========================
	BORDER COLOR
=========================*/
.topbar .form-search input:focus,
.add-btn > a:hover,
.edit-phto:hover,
.user-avatar:hover .edit-phto,
.top-area > ul.main-menu > li ul,
.g-post-classic:hover > figure > i,
a.btn2:hover,
.single-btn > li > a.active,
.story-box:hover .story-thumb > i,
.sugestd-photo-caro > li.active img,
.radio .check-box::after,
.checkbox .check-box,
.main-btn2,
.pit-tags > a:hover,
.msg-pepl-list .nav-item > a.active,
.buttons > a,
.pagination > li a:hover,
.pagination.borderd > li a:hover,
.qty-select .qty.form-control,
.extras > a.play-btn,
.suggested-caro li figure img,
.ads-links > li > a,
.ads-links > li > a:hover,
.course-details > a
{
	border-color: #fa6342;	
}

#nprogress .spinner-icon{
	border-left-color: #fa6342;
}
#nprogress .spinner-icon,
.search-tab .nav-tabs .nav-item > a.active::before
{
	border-top-color: #fa6342;		
}
.timeline-info > ul li a:after,
.popup::after{
	border-bottom-color: #fa6342;
}




/* BUTTONS COLORS */
.audio_sec .play_btn {
	background: var(--bs-primary);
}

.btn-danger {
	background: var(--bs-primary);
}

.title-block h5 > span {
	background: var(--bs-primary);
}