ul.editPostOptions {
    padding: 0;
    list-style: none;
    margin: 0;
}

.quill .ql-toolbar.ql-snow {
    border: 0;
    background: transparent;
    border-radius: 0 0;
    padding: 0;
    display: flex;
}

.quill .ql-container.ql-snow {
    border: 0;
}

.quill .ql-container.ql-snow .ql-editor {
    padding: 15px 0 0 0;
}

.quill .ql-container.ql-snow .ql-editor.ql-blank::before {
    left: 0;
    font-size: 15px;
    color: var(--bs-gray-600);
}