.drawer_link_parent {
    display: flex;
    align-items: center;
}
.drawer_link_parent>a {
    color: var(--title_text_color);
    font-size: 16px;
}
.drawer_link_parent>a.active, 
.drawer_link_parent:hover>a {
    color: var(--bs-primary);
}
.drawer_link_parent>a>span {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 12px;
    margin-right: 10px;
    font-size: 16px;
}
.drawer_link_parent>button {
    background: transparent;
    border: 0;
    height: 24px;
    width: 24px;
    line-height: 1;
    border-radius: 12px;
    padding: 0;
    font-size: 22px;
    margin-left: auto;
    color: var(--title_text_color);
}
.buttonActive {
    background: var(--light_overBg) !important;
}




.submenu {
    display: none;
    margin-top: 10px;
    list-style: none;
    margin-left: 36px;
    padding: 0;
}
.submenu.active {
    display: block;
}
.submenu>li {
    margin-bottom: 8px;
}
.submenu>li>a {
    color: var(--title_text_color);
    font-size: 16px;
    font-weight: 400;
}
.submenu>li>a:hover,
.submenu>li>a.active {
    color: var(--bs-primary);
}