.ubuntuPlayer {
  
}
.videoPost {
    position: relative;
    overflow: hidden;
}
.ubuntuPlayer:hover .controls {
  bottom: 0;
}
.preview_post_img video {
  background: var(--bs-gray-100);
  display: block;
  object-fit: contain;
  max-height: 64vh;
}
.controls {
    --main-color: rgb(247, 247, 248);
    --main-color-hover: rgb(255 255 255 / 30%);
    --track-color: #ddd;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 10px;
    bottom: -100%;
    transition: .3s;
    background: linear-gradient(360deg, black, #00000059, #ffffff00);
}
.control_btn {
  border-radius: 50%;
  background: #fff;
  border: 0;
  width: 26px;
  height: 26px;
  font-size: 14px;
  color: #000;
}
.customSlider {
    flex: 1;
    margin: 0 10px
}
.customSlider-track {
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    background: var(--main-color-hover);
}
.customSlider-track.customSlider-track-0 {
    background: var(--main-color);
    cursor: pointer;
}
.customSlider-thumb {
  cursor: pointer;
  background: #fff;
  width: 20px;
  height: 11px;
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 10px 10px;
}
.customSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
}











/* LOADING */
.loading {
    background: #0000001c;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
}
