.table_head.has_toggle .row {
    width: calc(100% - 34px);
    margin-left: 30px;
}

.table_head_info {
    line-height: 1;
    padding: 10px 10px;
    font-weight: 600;
}

.table_row_wrapper {
    background: #fff;
    padding: 0 2px;
    border-bottom: 1px solid var(--bs-gray-200);
}

.table_row_wrapper.activeExpand {
    border-bottom: 0;
}

.table_row.has_toggle {
    display: flex;
}

.table_row button.toggle {
    border: 1px solid var(--bs-gray-200);
    background: transparent;
    font-size: 12px;
    color: var(--bs-gray-600);
    margin: 5px 10px 5px 5px;
    height: 24px;
    width: 24px;
    padding: 0;
    text-align: center;
    border-radius: 6px;
}

.table_row button.toggle+.row {
    width: calc(100% - 34px);
}

.table_info {
    line-height: 1.1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 10px;
    max-width: 100%;
}

.table_info.contact a {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin-bottom: 5px;
}

.table_info.contact a:last-child {
    margin-bottom: 0;
}

.table_actions button,
.table_actions a {
    border: 0;
    background: var(--bs-gray-200);
    padding: 0 5px;
    margin: 0 2px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    color: var(--bs-gray-700);
    height: 20px;
    line-height: 20px;
    display: inline-block;
}

.table_actions button:hover,
.table_actions a:hover {
    background: var(--bs-gray-100);
    color: var(--bs-primary);
}

.table_row.activeExpand {
    border-radius: 6px 6px 0 0;
    margin-bottom: 0;
}

.expandTable {
    padding: 10px;
    border-radius: 6px 6px;
    background: var(--bs-gray-200);
}

.table_actions {
    padding: 7px;
}

.market-img-small {
    height: 20px;
    border-right: 50%;
}