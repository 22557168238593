.helpPrimarySupport {
   display: inline-block;
   width: 100%;
}
.helpPrimarySupport > .pitnik-title {
   display: inline-block;
   margin-bottom: 50px;
   text-align: center;
   width: 100%;
}

.helpPrimarySupport > .pitnik-title > h1 {
   font-weight: 400;
   font-size: 36px;
}

.helpPrimarySupport > .pitnik-title > p {
   display: block;
   font-size: 16px;
   margin: 0 auto;
   max-width: 60%;
}
