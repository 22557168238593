.message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-bottom: 15px;
}

.message.message-out {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.message-inner {
    max-width: 45%;
    /* margin-left: 1rem; */
}

.message-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.message-out .message-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.message-text {
    background: #e5edf2;
    border-radius: 0.6rem;
    padding: 0.8rem 1.6rem;
    color: var(--bs-gray-700);
}

.message-out .message-text {
    background: #fde8e0;
    border-radius: 0.6rem;
}

.message-text p{
    margin-bottom: 0;
}

.message .avatar {
    height: 35px;
    width: 35px;
    display: block;
}

.message .avatar img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    background: var(--bs-gray-200);
}

.message-footer {
    line-height: 1.3;
    margin-top: 0.25rem;
}

.message-out .message-footer {
    text-align: right;
}

.message-action {}

.message:not(message-out) .message-action .dropdown {
    margin-left: 7px;
}

.message.message-out .message-action .dropdown {
    margin-right: 7px;
}

.message-action .dropdown button {
    min-width: auto;
    padding: 0;
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 50%;
    border: 0;
    box-shadow: var(--bs-box-shadow-sm);
}

.message-action .dropdown button:hover {
    box-shadow: var(--bs-box-shadow);
}

.message-action .dropdown button::after {
    display: none;
}

.message-action .dropdown button svg {
    height: 16px;
    color: var(--bs-gray-800);
}

.message-action .dropdown .dropdown-item svg {
    height: 14px;
    width: 14px;
    color: inherit;
    margin-left: auto;
}

.message-action .dropdown .dropdown-item {
    display: flex;
}

.img-bunch.style_with_2 .preview_chat_img {
    height: 200px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_1 {
    height: 100px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_2 {
    height: 100px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_3 {
    height: 200px;
    padding: 1px;
}

.img-bunch.style_with_4 .preview_chat_img {
    height: 100px;
    padding: 1px;
}

.download-btn {
    cursor: pointer;
}

.chat{
    height: 100vh;
    /* width: 70%; */
    overflow-x: hidden;
    background-color: var(--back);
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    flex-flow: column;
    flex: 1;
    position: relative;
}
.chat-header{
    background-color: #fff;
    padding: 12px 20px;
}

.chat_body{
    /* background-image: url("../image/bg-chat.png"); */
    overflow-y: auto;
    background-color: var(--gray3);
    padding: 16px;
    padding-bottom: 40px;
    flex: 1;
}

.chat_body::-webkit-scrollbar {
    width: 6px;
}

.chat_body::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background-color: var(--bs-gray-500) !important;
}