.modalSmall .modal-dialog {
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
}
.alert_box {
    padding: 30px 0;
    text-align: center;
}
.alertIcon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    margin: 0 auto;
    margin-bottom: 15px;
}
.alertIcon.error {
    background: var(--bs-danger);
}
.alertIcon.success {
    background: var(--bs-success);
}
.alertIcon svg {
    height: 24px;
    width: 24px;
}