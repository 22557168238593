.post_details {
    border: 1px solid var(--border-color);
}
.details_right_block  {
    /* height: 100%; */
}
.details_right_block .post_comment {
    margin-top: 0;
}
.details_right_block .post_comment .post_comment_list {
    height: calc(100% - 82px);
    overflow: auto;
}
.post_details_images .slick-arrow {
    background: #fff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #000;
}
.post_details_images .slick-arrow.slick-prev {
    left: 15px;
}
.post_details_images .slick-arrow.slick-next {
    right: 15px;
}
.post_details_images .slick-arrow.slick-prev svg, .post_details_images .slick-arrow.slick-next svg {
    fill: #000;
    height: 24px;
    width: 24px;
}
.slick-next:before, .slick-prev:before {
    display: none;
}
.post_details_images .slick-dots {
    bottom: 7px;
}
.post_details_images .slick-dots li {
    height: auto;
}
.post_details_images .slick-dots li button {
    background: #fff;
    height: 5px;
    padding: 0;
    border-radius: 4px;
    opacity: .5;
}
.post_details_images .slick-dots li.slick-active button {
    opacity: 1; 
}
.post_details_slideItem {
    display: block !important;
}
