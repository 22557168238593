.helpHelpList {
   -webkit-border-radius: 5px;
   -moz-border-radius: 5px;
   -ms-border-radius: 5px;
   -o-border-radius: 5px;
   border-radius: 5px;
   display: inline-block;
   padding: 20px 20px 20px;
   width: 100%;
   background: var(--bs-white);
}

.helpHelpList > h3 {
   display: inline-block;
   font-size: 20px;
   font-weight: 400;
   margin-bottom: 20px;
   width: 100%;
}
.helpHelpList > ul {
   display: inline-block;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   width: 100%;
}
.helpHelpList > a {
   border-radius: 30px;
   display: inline-block;
   font-size: 12px;
   font-weight: 500;
   letter-spacing: 1px;
   margin-top: 20px;
   padding: 10px 20px;
   text-align: center;
   text-transform: capitalize;
   transition: all 0.2s linear 0s;
   width: 100%;
   color: #fff;
   background: var(--bs-primary);
}
.helpHelpList > ul li {
   display: inline-block;
   margin-bottom: 13px;
   width: 100%;
}
.helpHelpList > ul li:last-child {
   margin-bottom: 0;
}
.helpHelpList > ul li a {
   display: inline-block;
   font-size: 13px;
   width: 100%;
   -webkit-transition: all 0.3s linear 0s;
   -moz-transition: all 0.3s linear 0s;
   -ms-transition: all 0.3s linear 0s;
   -o-transition: all 0.3s linear 0s;
   transition: all 0.3s linear 0s;
   color: var(--bs-gray-800);
}
.dark-theme .helpHelpList > ul li a {
   color: var(--bs-gray-200);
}
.helpHelpList > ul li a:hover {
   color: var(--red);
}
