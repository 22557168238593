.widget {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    background: var(--bs-white);
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid var(--border-color)
}
.widget_title {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
    padding: 15px 20px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
}

.lightTheme .widget_title {
    border-bottom: 1px solid #e6ecf5;
    color: var(--title_text_color);
}
.darkTheme .widget_title {
    border-bottom: 1px solid var(--border-color);
    color: var(--title_text_color);
}
.widget_title::before {
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    background: var(--bs-primary);
}