.scheduleBox {
    display: block;
    background: #f7f7f7;
    border-radius: 4px;
    transition: .3s;
    color: #172b4c;
    height: 100%;
    padding-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;
}

.scheduleBoxTop {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    text-align: center;
    justify-content: center;
    padding: 10px;
    background: #f3f3f3;
    margin-bottom: 10px;
}

.scheduleBoxTop>div {
    color: #172b4c;
    margin-left: 7px;
}

.scheduleBox a {
    font-weight: 600;
}

.scheduleLabel {
    display: flex;
    justify-content: space-between;
    padding: 3px 21px;
    /* border-bottom: 1px solid #dedede; */
}

.scheduleBox .scheduleLabel:last-child {
    margin-bottom: 0;
}

.scheduleLabel.total a {
    color: #172b4c;
}

.scheduleLabel.overdue a {}

.scheduleLabel.due a {
    color: #e69800;
}