.central-meta.postbox {
    margin-top: 0;
}

.postbox {
    z-index: 99;
    position: relative;
}

.central-meta {
    border: 1px solid var(--border-color);
    background: var(--bs-white);
    border-radius: var(--bs-border-radius-lg);
    display: block;
    width: 100%;
    margin-bottom: var(--common_space);
    padding: var(--common_space);
    position: relative;
}

.central-meta.cpost {
    background: #dadfff;
}

.central-meta.style2 {
    border: 0;
    padding: 0;
}

@media(max-width: 500px) {
    .central-meta.postbox {
        margin-bottom: var(--common_space);
    }
}

.create-post {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 17px;
    padding-bottom: 0;
    text-transform: capitalize;
    width: 100%;
    color: var(--bs-primary);
    position: relative;
}

.img-bunch.style_with_2 img {
    aspect-ratio: 2 / 2;
}

/* .create-post::before {
    content: "";
    height: 90%;
    left: -20px;
    position: absolute;
    top: -5px;
    width: 3px;
} */
.create-post>i {
    font-size: 20px;
    vertical-align: sub;
}

.create-post>a {
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
}

.new-postbox {
    display: block;
    width: 100%;
    position: relative;
}

.newpst-input {
    display: block;
    width: 100%;
}

.newpst-input>form {
    display: block;
    width: 100%;
}

.newpst-input textarea {
    background: transparent;
    width: 100%;
    border: 0 !important;
    padding: 0;
    color: var(--bs-gray-800);
    font-size: 15px;
    box-shadow: none !important;
}

.newpst-input textarea::placeholder {
    font-size: 15px;
    color: var(--bs-gray-600);
}

.newpst-input textarea:focus,
.newpst-input textarea:active {
    outline: medium none;
}

.dark-theme .newpst-input textarea {
    background: transparent;
    color: var(--bs-gray-200);
}

.dark-theme .newpst-input textarea::placeholder {
    color: var(--bs-gray-200);
}


.attachments {
    display: block;
    text-align: left;
    margin-top: 30px;
}

.attachments>ul {
    list-style: outside none none;
    margin-bottom: 0;
    padding-left: 0;
    line-height: initial;
    display: flex;
    align-items: center;
}

.attachments li {
    display: inline-block;
    margin-right: 7px;
    vertical-align: middle;
    font-size: 18px;
}

.attachments li .selectCategoy {
    font-size: 12px;
    border: 0;
    padding: 0 14px;
    height: 30px;
    border-radius: 36px;
    font-weight: 600;
    background: var(--light_overBg);
    color: var(--title_text_color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.attachments li label {
    cursor: pointer;
    font-size: 12px;
    border: 0;
    padding: 0 14px;
    height: 30px;
    border-radius: 36px;
    font-weight: 600;
    background: var(--light_overBg);
    color: var(--title_text_color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.light-theme .attachments li label {}

.attachments li svg {
    height: 14px;
    width: 14px;
    margin-right: 6px;
}

.attachments li:first-child {
    margin-left: 0;
}

.attachments li.preview-btn {
    margin-left: auto;
    margin-right: 0;
}

.attachments li .btn {
    min-width: 65px;
}

.attachments li>.btn {
    min-width: 65px;
    margin-right: 7px;
}

.attachments li label {
    cursor: pointer;
}

.attachments li label input[type="file"] {
    display: none;
}

.preview_item {
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.preview_item button {
    position: absolute;
    bottom: -10px;
    left: 50%;
    background: #fff;
    border: 0;
    border-radius: 50%;
    color: var(--bs-primary);
    padding: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .25) !important;
    transform: translateX(-50%);
}

.preview_item img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.img-bunch.style_with_five {
    display: flex;
    flex-flow: wrap;
}

.img-bunch.style_with_five .preview_post_img {
    width: 100%;
    padding: 1px;
}

.img-bunch.style_with_five .preview_post_img_1 {
    height: 253px;
}

.img-bunch.style_with_five .preview_post_img_2 {
    height: 253px;
}

.img-bunch.style_with_five .preview_post_img_3 {
    height: 168.68px;
}

.img-bunch.style_with_five .preview_post_img_4 {
    height: 168.68px;
}

.img-bunch.style_with_five .preview_post_img_5 {
    height: 168.68px;
}

.img-bunch.style_with_3 .preview_post_img_1 {
    height: 253px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_post_img_2 {
    height: 253px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_post_img_3 {
    height: calc(168.68px * 3);
    padding: 1px;
}

.preview_post_img_1 {
    padding: 1px;
}

.img-bunch.style_with_4 .preview_post_img {
    height: calc(253px * 2);
    padding: 1px;
}

.img-bunch img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-btn {
    border: 0;
    position: absolute;
    right: 8px;
    top: 14px;
    border-radius: 18px;
    background: var(--bs-white);
    width: 28px;
    height: 28px;
    color: #000;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.category_item input {
    display: none;
}

.category_item {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 7px;
}

.category_item label {
    position: relative;
    background-color: rgb(227 227 227 / 40%);
    color: #444;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: 1px;
    border-radius: 50px;
    padding: 6px 9px;
    display: inline-block;
    cursor: pointer;
    padding-left: 28px;
    font-weight: 600;
}

.category_item input[type="radio"]:checked+label {
    background: mediumseagreen;
    color: var(--bs-white);
    border-color: mediumseagreen;
}

.category_item input[type="radio"]:checked+label:before {
    color: #fff;
}

.category_item label:before {
    content: "\2714";
    opacity: 1;
    color: var(--bs-gray-500);
    font-size: 14px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

span.selectedCat {
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    /* border: 1px solid var(--bs-gray-200); */
    padding-left: 15px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    padding-right: 6px;
    color: #329c61;
    background: #3cb37126;
}

.selectedCat button {
    background: var(--bs-white);
    border: 0;
    padding: 0;
    margin-left: 7px;
    height: 20px;
    width: 20px;
    line-height: 1;
    border-radius: 50%;
    color: var(--bs-gray-900);
}

.postbox .quill {}

.postbox .quill .ql-toolbar.ql-snow {
    border: 0;
    background: transparent;
    border-radius: 0 0;
    padding: 0;
}

.postbox .quill .ql-container.ql-snow {
    border: 0;
}

.postbox .quill .ql-container.ql-snow .ql-editor {
    padding: 15px 0 0 0;
}

.postbox .quill .ql-container.ql-snow .ql-editor.ql-blank::before {
    left: 0;
    font-size: 15px;
    color: var(--bs-gray-600);
}

.post_content>*:last-child {
    margin-bottom: 0;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    padding: 2px;
}

@media (max-width: 500px) {

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        margin-right: 7px;
        padding: 1px;
    }

    .ql-snow .ql-picker-label {
        padding-left: 0;
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 18px;
        width: 18px;
    }
}

.central-top .selectCategoy {
    font-size: 12px;
    border: 0;
    padding: 0 14px;
    height: 30px;
    border-radius: 36px;
    font-weight: 600;
    background: var(--light_overBg);
    color: var(--title_text_color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.central-top svg {
    height: 14px;
    width: 14px;
}

.central-top span {
    margin-left: 6px;
    text-wrap: nowrap;
}

.cat_dropdown .dropdown-toggle::after {
    display: none;
}

.cat_dropdown .dropdown-toggle.btn.btn-success {
    background: #1f2b74;
    border: 0;
    padding: 8px 20px;
}

.cat_dropdown .dropdown-menu {
    width: 350px;
    border-radius: 8px;
    right: 0 !important;
    padding: 12px !important;
}

.cat_dropdown .category_item label {
    font-size: 12px;
}

.cat_dropdown .dropdown {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.cat_dropdown .cat_in {
    background: var(--light_overBg);
    padding: 8px 20px;
    border-radius: 8px;

}