@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.home__container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home__container>* {
    margin-bottom: 10px;
}

.home__header {
    margin-bottom: 30px;
}

.username__input {
    padding: 10px;
    width: 50%;
}

.home__cta {
    width: 200px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #607eaa;
    color: #f9f5eb;
    outline: none;
    border: none;
    border-radius: 5px;
}