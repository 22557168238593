.eventCard {
    display: block;
    position: relative;
    width: 100%;
    background: var(--bs-white);
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.eventCard .eventCardContent {}

.light-theme .eventCard {
    border: 1px solid var(--bs-gray-300);
}

.dark-theme .eventCard {
    border: 1px solid var(--bs-gray-500);
}

.eventCard>figure {
    display: block;
    margin-bottom: 0;
    position: relative;
    width: 100%;
}

.eventCard em {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    bottom: 7px;
    left: 7px;
    position: absolute;
}

.eventCard .save-post {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    font-size: 16px;
    left: 8px;
    position: absolute;
    top: 4px;
}

.eventCard .d-flex {
    margin-bottom: 10px;
}

.eventCard>figure>img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eventCard .more {
    position: absolute;
    right: 10px;
    top: 8px;
}

.eventCard>figure::before {
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 83%) repeat scroll 0 0;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s linear 0s;
    visibility: hidden;
}

.eventCard>figure>img {
    height: 220px;
}

.eventCard:hover>figure::before {
    opacity: 1;
    visibility: visible;
}

.eventCard .eventCardContent {
    padding: 15px;
}

.eventCard .eventCardContent h6 {
    margin-bottom: 7px;
    font-weight: 600;
}

.light-theme .eventCard .eventCardContent h6 a {
    color: var(--bs-gray-800);
}
.dark-theme .eventCard .eventCardContent h6 a {
    color: var(--bs-gray-200);
}

.eventCard .eventCardContent .location {
    margin-bottom: 15px;
    font-size: 12px;
}

.event_title {
    display: inline-block;
    width: 100%;
}

.event_title>h6 {
    color: var(--bs-gray-800);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 4px;
}

.eventCard .location {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    color: var(--bs-gray-600);
}

.light-theme .eventCard .location {
    color: var(--bs-gray-600);
}

.dark-theme .eventCard .location {
    color: var(--bs-gray-200);
}

.eventCard .location svg {
    display: inline-block;
}

.eventCard .location svg path {}

.light-theme .eventCard .location svg path {
    stroke: var(--bs-gray-800);
}

.dark-theme .eventCard .location svg path {
    stroke: var(--bs-gray-200);
}

.eventCard .user-fig>a {
    color: #a3a1b5;
    font-size: 12px;
}

.eventCard .upload_time {
    color: var(--bs-gray-600);
    margin-bottom: 3px;
    font-size: 12px;
    display: inline-flex;
}

.eventCard .upload_time svg {
    display: inline-block;
    margin-right: 5px;
    margin-top: 3px;
    flex: 0 0 1em;
}

.light-theme .eventCard .upload_time svg {
    color: var(--bs-gray-800);
}

.dark-theme .eventCard .upload_time svg {
    color: var(--bs-gray-200);
}

.event_title>h6>a {
    display: inline-block;
    transition: all 0.2s linear 0s;
    width: 100%;
}

.event_date {
    display: inline-block;
    line-height: 12px;
    vertical-align: middle;
    border: 1px dashed #bebebe;
    padding: 13px 2px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    left: 7px;
    top: 7px;
    width: 36px;
    text-align: center;
    background: #0000003b;
}

.light-theme .event_date {
    color: var(--bs-gray-400);
}

.dark-theme .event_date {
    color: var(--bs-gray-100);
}

.dark-theme .eventCard .upload_time {
    color: var(--bs-gray-200);
}

.light-theme .event_title>h6>a {
    color: var(--bs-gray-800);
}

.dark-theme .event_title>h6>a {
    color: var(--bs-gray-100);
}

.event_title>h6>a:hover {
    color: var(--bs-primary);
}

.eventCard .add-remove-frnd {
    display: inline-flex;
    list-style: outside none none;
    margin: 12px 0 0;
    padding: 0 0;
    text-align: center;
    width: 100%;
    justify-content: center;
}
.eventCard .add-remove-frnd > li a, 
.eventCard .add-remove-frnd > li > button.deleteBtn {
    border-radius: 4px;
    color: var(--bs-gray-900);
    display: inline-block;
    transition: all 0.2s linear 0s;
    font-size: 13px;
    height: 34.5px;
    width: 34.5px;
    line-height: 34.5px;
    padding: 0;
    border: 0;
}
.eventCard .add-remove-frnd > li a:hover {
    background-color: var(--bs-gray-300);
    border-color: var(--bs-gray-300);
    color: var(--bs-gray-900);
}
.eventCard .add-remove-frnd > li > button.deleteBtn {
    background: var(--bs-primary);
    color: #fff;
}
.eventCard .add-remove-frnd > li a > i, 
.eventCard .add-remove-frnd > li > button.deleteBtn > i {
    font-size: 14px;
}
.eventCard .add-remove-frnd.following li.following {
    flex: 1;
}
.eventCard .remove-frnd > a {
    background: #a8adcd none repeat scroll 0 0;
}
.eventCard .add-remove-frnd > li {
    display: inline-block;
    margin-right: 6px;
}
.eventCard .add-remove-frnd > li:last-child {
    margin-right: 0;
}