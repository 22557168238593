.tab-content .recent-links,
.tab-content .more-songs {
    padding: 0 20px 20px;
    margin-bottom: 0;
}

.nav.nav-tabs.trend li button {
    width: 100%;
}

.nav-item>.active{
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
}

.nav.nav-tabs.trend li {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    width: 49%;
}



.sidebar .widget > ul.nav {
    list-style: none;
    margin-bottom: 0;
    padding: 0 20px 20px;
    width: 100%;
    display: flex;
    flex-flow: row;
}
.sidebar .widget > ul.nav > li {
    margin: 0 2.5px;
}
.sidebar .widget > ul.nav > li:first-child {
    margin-left: 0;
}
.sidebar .widget > ul.nav > li:last-child {
    margin-right: 0;
}
.sidebar .widget > ul.nav.activitiez {
    max-height: 350px;
    overflow: hidden;
    position: relative;
}

.sidebar .widget > ul.recent-jobs {
    max-height: 350px;
    overflow: hidden;
    position: relative;
    padding-bottom: 0;
}


.recent-links li {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}

.recent-links li:last-child {
    margin-bottom: 0;
}

.recent-links li i {
    display: inline-block;
    font-size: 12px;
    margin-right: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    font-style: normal;
}

.recent-links li > a {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    vertical-align: top;
}




.recent-links > li > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;
    width: 80px;
}
.recent-links > li > figure img {
    border-radius: 4px;
    width: 100%;
}
.re-links-meta {
    display: inline-block;
    padding-left: 10px;
    vertical-align: middle;
    width: calc(100% - 80px);
}
.re-links-meta > h6 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
}
.light-theme .re-links-meta > h6 > a {
    color: var(--bs-gray-800);
}
.dark-theme .re-links-meta > h6 > a {
    color: var(--bs-gray-200);
}
.re-links-meta > h6 > a:hover {
    color: var(--bs-primary);
}
.re-links-meta > span {
    font-size: 12px;
    font-weight: 300;
}