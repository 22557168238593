.groupInfoWidget {
    list-style: none;
    margin-bottom: 0;
    padding: 0 20px 20px 20px;
}
.title {
    color: var(--bs-primary);
    width: 120px;
    flex: 0 0 120px;
}
.groupInfoWidget > li {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
.groupInfoWidget > li > a {
    color: var(--bs-gray-700);
}
.groupInfoWidget > li > a:hover {
    color: var(--bs-primary);
}
.groupInfoWidget > li:last-child {
    margin-bottom: 0;
}
.title > i {
    margin-right: 10px;
    text-align: center;
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 50%;
    font-size: 10px;
}
.groupInfoWidget > li p {
    line-height: 1.3;
}