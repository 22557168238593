.activity-page-top {
   display: flex;
}

.activity-page-top a {
   width: 40px;
   height: 40px;
   border-radius: 40px;
   text-align: center;
   line-height: 2;
   position: absolute;
   right: calc(100% + 10px);
   padding: 4px 0;
   background: rgba(0, 0, 0, 0.1);
   display: flex;
   justify-content: center;
   align-items: center;
}

.activity-page-top a svg {
   width: 20px;
   text-align: center;
   stroke: #383838;
}

@media (max-width: 767px) {
   .activity-page-top a {
      display: none;
   }
}

.activity-page-title h5 {
   /* color: #515365; */
   margin-bottom: 8px;
}

.activity-page-title p {
   /* color: #757a95; */
   line-height: 20px;
}

.activity-ul {
   list-style: none;
   margin: 0;
   padding: 0;
   display: flex;
   flex-flow: column;
   align-items: center;
}

.activity-ul i {
   font-size: 30px;
   margin-top: 18px;
   color: #45455a;
}

.activity-ul .activity-box {
   background: var(--bs-white);
   width: 100%;
   height: 100%;
   padding: 20px 40px 20px;
   border-radius: 10px;
   margin-bottom: 10px;
   position: relative;
}

.activity-ul .activity-box::before {
   content: "";
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: 20px;
   width: 2px;
   height: 64%;
   background: #d2252d;
}

.activity-ul .activity-box .date {
   font-size: 15px;
   font-weight: 500;
   color: #d19200;
}

.activity-ul .activity-box .time2 {
   font-style: italic;
}

.activity-ul .activity-box .activity-content {
   display: flex;
   align-items: baseline;
   margin-top: 4px;
}

.activity-ul .activity-box .activity-content h6 {
   font-size: 14px;
   font-weight: 500;
   margin: 0;
}

.activity-meta {
   display: block;
   width: 100%;
}

.activity-meta > i {
   color: #999;
   font-size: 12px;
   width: 100%;
   line-height: 1;
}

.activity-meta > span {
   display: block;
   width: 100%;
   font-size: 13px;
   font-weight: 500;
   line-height: 1;
   margin-bottom: 4px;
}

.activity-meta > h6 {
   font-size: 13px;
   width: 100%;
   margin-bottom: 0;
}

.activitiez > li::before {
   content: "";
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 2px;
}
.activitiez > li {
   padding-left: 12px;
}
