.helpPitnikTitle {
   display: inline-block;
   margin-bottom: 50px;
   text-align: center;
   width: 100%;
}
.helpPitnikTitle > h1 {
   font-weight: 400;
   font-size: 36px;
}
