.chatUserItem {
    cursor: pointer;
}

.chatUserItem .chatUserItem_img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: relative;
}

.chatUserItem .chatUserItem_img .chatUserItem_status {
    height: 12px;
    width: 12px;
    background: #000;
    display: inline-block;
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #fff;
}

.chatUserItem .chatUserItem_img .chatUserItem_status.active {
    background: var(--green);
}

.chatUserItem .chatUserItem_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.chatUserItem {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
}

.chatUserItem .chatUserItem_info {
    padding-left: 12px;
    width: calc(100% - 45px);
}

.chatUserItem .chatUserItem_info .chatUserItem_name {
    font-size: 16px;
    font-weight: 600;
    color: var(--title_text_color);
}

.chatUserItem_lastMsg {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    color: var(--ChatColor1);
}

.chatUserItem_lastMsg>p {
    margin-bottom: 0;
}