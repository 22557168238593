.feedSec .postItem {
   margin-bottom: var(--common_space);
}

.postItem {
   background: var(--bs-white);
   border: 1px solid var(--border-color);
   padding: 15px;
   border-radius: var(--bs-border-radius-lg);
   margin-bottom: var(--common_space);
}

.post_auth {
   display: flex;
   align-items: center;
   line-height: 1;
}
.auth_info>span {
   font-size: 12px;
}
@media(max-width: 500px) {
   .auth_info>span>span {
      display: block;
   }
}

.post_auth figure {
   margin: 0;
   margin-right: 10px;
}
.post_auth .auth_info .auth_nm {
    margin-bottom: 3px;
}
.post_auth figure img {
   width: 35px;
   height: 35px;
   object-fit: cover;
   border-radius: 50%;
}
.post_top {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.post_top .post_top_right {
   display: flex;
}
.post_top_right button.ant-dropdown-trigger {
   background: var(--light_overBg);
   color: inherit;
   margin-left: 7px;
}

.post_top_right button {
   background: var(--bs-gray-200);
   color: var(--bs-gray-900);
   margin-left: 7px;
}

.auth_info .auth_nm a {
   font-weight: 600;
   text-transform: capitalize;
}

.post_meta {
   margin-top: 15px;
}

.post_content {
   height: 106px;
   overflow: hidden;
   position: relative;
}

.post_content.active {
   height: auto;
}

.post_content p {
   color: #676c85;
}
.dark-theme .post_content p {
   color: var(--bs-gray-200)
}

.post_vote {
   display: flex;
   margin-right: 10px;
}

.post_vote button {
   background: transparent;
   border: 0;
   display: inline-flex;
   padding: 0 10px;
   height: 30px;
   margin-right: 0;
   align-items: center;
   border-radius: 30px;
}

.light-theme .post_vote button:hover {
   background: var(--bs-gray-200);
}

.dark-theme .post_vote button:hover {
   background: var(--bs-gray-500);
}

.post_vote button svg {
   height: 20px;
   width: 20px;
   stroke-width: 1px;
   margin-right: 5px;
}

.post_vote button.active svg path {
   fill: #000;
   stroke: #000;
}

.dark-theme .post_vote button {
   color: var(--bs-gray-200);
}

.dark-theme .post_vote button svg path {
   stroke: var(--bs-gray-200);
}




.img-bunch>.row {
   margin: 0;
}

.img-bunch {
   width: 100%;
   position: relative;
}
.preview_post_img_1 .videoPost>div {
   height: auto !important;
}
.img-bunch>.row>div {
   padding: 0;
}

.img-bunch figure {
   float: left;
   margin: 0;
   padding: 1px;
   width: 100%;
   position: relative;
}

.postItem .post_meta .img-bunch figure img {
   width: 100%;
}

.img-bunch .strip {
   display: unset;
}

.more-photos {
   color: #fff;
   font-size: 30px;
   font-weight: 500;
   height: 100%;
   left: 50%;
   position: absolute;
   text-align: center;
   top: 50%;
   transform: translate(-50%, -50%);
   width: 100%;
}

.more-photos>span {
   left: 50%;
   position: absolute;
   top: 50%;
   transform: translate(-50%, -50%);
}

.more-photos::before {
   background: var(--bs-primary);
   bottom: 0;
   content: "";
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   z-index: 0;
   opacity: .7;
}


.post_actions {
   display: flex;
   margin-top: 15px;
}

.post_actions .action {
   margin-left: 15px;
   cursor: pointer;
   font-weight: 500;
   display: flex;
   align-items: center;
   line-height: 1;
   font-size: 12px;
   position: relative;
}
.post_actions .action button {
   background: transparent;
   border: 0;
   padding: 0;
}

.post_actions .shareOptions {
   position: absolute;
   display: flex;
   right: 0;
   bottom: calc(100% + 10px);
   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
   background: var(--light_overBg);
   padding: 7px 10px;
   border-radius: 8px;
   transform: translateY(3px);
   transition: .3s;
   opacity: 0;
   visibility: hidden;
}
.post_actions .action.share.active .shareOptions {
   transform: translateY(0);
   opacity: 1;
   visibility: visible;
}

.post_actions .shareOptions button:last-child {
   margin-right: 0;
}
.post_actions .shareOptions button {
   margin-right: 7px;
}
.post_actions .shareOptions button svg {
   height: 24px;
   width: 24px;
   margin-right: 0;
}

.post_actions .action svg {
   display: inline-block;
   margin-right: 7px;
}

.post_actions .action.like {
   margin-left: 0;
   position: relative;
}

.post_actions .action.comment {
   margin-left: auto;
}



.reactionPost {
   display: flex;
   align-items: center;
   background: var(--light_overBg);
   padding: 2px 4px;
   border-radius: 4px;
   margin-right: 7px;
   height: 26px;
}

.post_react {
   position: relative;
   margin-right: 7px;
}

.post_react.show .react-box {
   display: block;
}



.post_actions .action.like:hover .react-box {
   display: block;
}

.post_content .read_more {
   text-align: center;
   font-weight: 500;
   color: var(--bs-gray-600);
   margin-top: 8px;
   cursor: pointer;
}

.post_content .read_more svg {
   stroke: var(--bs-gray-600);
   display: inline-block;
   margin-right: 5px;
   height: 16px;
   width: 16px;
}

.dark-theme .post_content .read_more {
   color: var(--bs-gray-300);
}

.dark-theme .post_content .read_more svg {
   stroke: var(--bs-gray-300);
}

.post_content:not(.active) .read_more svg {
   stroke: var(--bs-primary);
}

.post_content:not(.active) .read_more {
   position: sticky;
   bottom: 0;
   background: var(--bs-white);
   color: var(--bs-primary);
   line-height: 1;
   padding-top: 2px;
}



/* EDIT POST */
.edit_post ul {
   padding: 0;
   list-style: none;
   margin-top: 10px;
   margin-bottom: 0;
}
.edit_post textarea {
   width: 100%;
   color: var(--bs-gray-800);
   font-size: 15px;
   overflow: hidden;
}
.edit_post ul li label {
   margin-bottom: 0;
}


.post_category {
   background: var(--bs-success-bg-subtle);
   color: var(--bs-success);
   padding: 3px 10px;
   border-radius: 50px;
   font-weight: 600;
   font-size: 12px;
}



.post_action_group {
   display: flex;
   align-items: center;
}
.post_action_group>button {
   margin-right: 7px;
}
.post_action_group button {
   background: transparent;
   padding: 0;
   border: 0;
   display: flex;
   align-items: center;
   line-height: 1;
}
.post_action_group>button {
   padding: 2px 4px;
   border-radius: 6px;
   background: var(--light_overBg);
   height: 26px;
}
.post_action_group button svg {
   height: 20px;
   width: 20px;
   margin-right: 5px;
   color: var(--lightOverBgText);
}
.post_action_group .postReactList button svg {
   height: 14px;
   width: 14px;
   margin-right: 0;
}
.post_action_group button span {
   font-weight: 600;
   font-size: 12px;
}

.post_content h1, .post_content h2, 
.post_content h3, .post_content h4, 
.post_content h5, .post_content h6 {
   margin-bottom: 0;
   line-height: 1.2;
}
.post_content h1 {

}
.post_content h2 {
   
}
.post_content h3 {
   
}
.post_content h4 {
   
}
.post_content h5 {
   
}
.post_content h6 {
   
}
.post_content p {
   margin: 0;
}
.post_content p br {
   line-height: 1;
}
.post_content p strong {
   font-weight: 600;
   color: var(--bs-heading-color);
}

