.faqAccordionButton {
   height: 50px;
}
.faqAccordionButton .accordion-button:not(.collapsed) {
   background-color: var(--bs-gray-600);
   color: var(--bs-gray-200);
   box-shadow: none;
}

.light-theme .faqAccordionButton .accordion-button:not(.collapsed) {
   background-color: var(--red);
}

.faqAccordionItem .collapsed {
   background-color: var(--bs-white);
   margin-top: 5px;
}

.faqAccordionItem .accordion-collapse .accordion-body {
   background-color: var(--bs-gray-100);
   border: none;
}

.dark-theme .faqAccordionItem .accordion-collapse .accordion-body {
   color: var(--bs-gray-200);
   background-color: var(--bs-white);
   border: none;
}

.dark-theme .faqAccordionItem .collapsed {
   color: var(--bs-gray-200);
}
.light-theme .faqAccordionItem .collapsed {
   color: var(--bs-gray-800);
}
.accordion {
   --bs-accordion-border-color: none;
}

.faqArea {
   display: inline-block;
   width: 100%;
}

.faqArea > p {
   color: #707070;
   display: inline-block;
   width: 100%;
}
