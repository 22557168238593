:where(.css-dev-only-do-not-override-1ycqda6).ant-picker {
   background: var(--bs-gray-500);
   border-color: var(--bs-gray-500);
}
:where(.css-dev-only-do-not-override-1ycqda6).ant-picker:hover, :where(.css-dev-only-do-not-override-1ycqda6).ant-picker-focused {
   border-color: var(--bs-gray-500);
}
input:-internal-autofill-selected {
   background-color: transparent !important;
   background: transparent !important;
}

.dark-theme .input-group-text {
   
}