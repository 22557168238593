.react-tagsinput-tag {
    display: inline-block;
    background: #f1f1f1;
    margin-right: 2px;
    padding: 6px;
    line-height: 1;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
}
.form-control.tagInput {
    min-height: 39px;
    height: auto;
}
input.react-tagsinput-input {
    border: 0;
}