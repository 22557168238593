.folder {
    position: relative;
    max-width: 216px;
    width: 100%;
    height: 120px;
    background: var(--bg);
    border-radius: 2rem;
    padding: 1rem;
    --bg: var(--bs-gray-200);
    transition: 0.2s ease-out;
    margin-top: 23px;
}

.folder:hover {
    --bg: var(--bs-primary);
    color: #fff;
}

.top {
    position: absolute;
    left: 0;
    top: -22px;
    height: 50px;
    max-width: 135px;
    width: 100%;
    background: var(--bg);
    -webkit-clip-path: polygon(50% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
    overflow: hidden;
    border-radius: 2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: 0.2s ease-out;
}

.head {
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 0 0;
}

.head .icon {
    position: relative;
    bottom: 18px;
    left: 5px;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.info {
    margin-top: 8px;
    line-height: 8px;
    padding-left: 8px;
}
.info h5 {
    font-size: 1rem;
    font-weight: 700;
}
.folder:hover .info h5 {
    color: #fff;
}

.info p {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;
    line-height: 1;
}

.info p span {
    color: #8989ff;
    font-size: 1rem;
}
