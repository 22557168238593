.chatBottom{
    box-shadow: 0px -3px .25rem rgba(0, 0, 0, .075) !important;
}


.chat-form {
    background: var(--bs-gray-200);
    padding: 5px 10px;
}

.chatAttachmentButton {
    cursor: pointer;
}

.writeMessage .quill .ql-toolbar {
    display: none;
}

.writeMessage .quill .ql-container {
    border: 0 !important;
}

.chat-form .chatAttachmentButton {
    margin-bottom: 0;
    background: var(--bs-gray-400);
    padding: 6px;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-form .chatAttachmentButton svg {
    width: 20px;
    height: 20px;
}

.chat-form .quill .ql-container.ql-snow .ql-editor {
    padding: 0;
}

.chatBtnSent {
    min-width: auto;
    padding: 10px;
}

.chatBtnSent svg {
    height: 20px;
    width: 20px;
}

/* image preview style in chat */
.img-bunch img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat-remove-btn {
    border: 0;
    position: absolute;
    right: 650px;
    top: 14px;
    border-radius: 18px;
    background: var(--bs-white);
    width: 28px;
    height: 28px;
    color: #6a6969;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.img-bunch.style_with_2 .preview_chat_img {
    height: 200px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_1 {
    height: 100px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_2 {
    height: 100px;
    padding: 1px;
}

.img-bunch.style_with_3 .preview_chat_img_3 {
    height: 200px;
    padding: 1px;
}

.img-bunch.style_with_4 .preview_chat_img {
    height: 100px;
    padding: 1px;
}

.file-view{
    height: 100px;
    border: 1px solid;
    text-align: center;
}