.register {
    position: relative;
    z-index: 3;
    padding: 30px 0;
    height: 100%;
    display: flex;
    align-items: center;
}
.login-back {
    position: relative;
    z-index: 1;
}
.login-back::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}


.forgot {
    float: right;
    font-size: 12px;
    margin-top: -27px;
    text-transform: capitalize;
    color: #000;
}
.forgot:hover {
    color: #dc3545;
    text-decoration: underline;
}
.c-form span {
    text-transform: capitalize;
    font-size: 12px;
    color: #000;
}

.fun-fact {
    display: inline-block;
    margin-top: 30px;
    width: 100%;
}
.fun-box {
    display: inline-block;
    width: 100%;
}
.fun-box > i {
    border-radius: 100%;
    color: #fff !important;
    display: inline-block;
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
    text-align: center;
    width: 40px;
}
.fun-box > img {
    margin-bottom: 10px;
}
.fun-box > h6 {
    color: #535165;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}
.fun-box > span {
    display: inline-block;
    font-size: 15px;
    width: 100%;
}



.barcode {
    display: inline-block;
    width: 100%;
    margin-top: 50px;
}

.barcode > figure {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    width: 100px;
}

.barcode > figure > img {
    border: 6px solid #fff;
}


.app-download {
    display: inline-block;
    vertical-align: middle;
    width: 80%;
    margin-left: 20px;
}
.app-download > span {
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    color: #232135;
}
.app-download > ul.colla-apps {
    margin-bottom: 0;
}
.app-download > ul.colla-apps > li {
    display: inline-block;
    width: 25%;
}




.colla-apps {
    float: left;
    list-style: outside none none;
    padding-left: 0;
    width: 100%;
    margin-bottom: 0;
}

.colla-apps > li {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}

.colla-apps > li:last-child {
    margin-bottom: 0;
}

.colla-apps > li a {
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    text-align: left;
    text-transform: capitalize;
    transition: all 0.2s linear 0s;
    width: 100%;
    color: #232135;
    font-weight: 500;
}

.colla-apps > li:last-child a {
    margin-bottom: 0;
}

footer .widget .colla-apps > li a {
    color: inherit;
    font-weight: 400;
}

.colla-apps > li a img {
    display: inline-block;
    margin-right: 9px;
    vertical-align: sub;
}

.colla-apps > li a:hover {
    color: #fff;
}

.colla-apps > li a i {
    margin-right: 12px;
}