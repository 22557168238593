body.light-theme {
    --light_overBg: var(--bs-gray-100);
    --lightOverOverBg: var(--bs-gray-300);
    --lightOverBgText: var(--bs-gray-900);
    color: var(--bs-gray-700);
    --border-color: var(--bs-gray-200);
    --title_text_color: #262730;
    --bs-text-blue: #283790;
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    /* --bs-primary: #ff5003;
    --bs-primary-dark: #d14204; */
    --bs-primary: #1f2b74;
    --bs-primary-dark: #000a45;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-orange: #f79c31;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
        "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,
            rgba(255, 255, 255, 0.15),
            rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: var(--bs-dark);
    --bs-link-color: #d2252d;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
}

body.dark-theme {
    --light_overBg: var(--bs-gray-500);
    --lightOverOverBg: var(--bs-gray-700);
    --lightOverBgText: var(--bs-gray-200);

    background: var(--bs-gray-900);
    color: var(--bs-gray-200);
    --border-color: var(--bs-gray-900);
    --title_text_color: var(--bs-gray-100);
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #222428;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #dedede;
    --bs-gray-200: #ababab;
    --bs-gray-300: #757575;
    --bs-gray-400: #494949;
    --bs-gray-500: #333333;
    --bs-gray-600: #2c2c2c;
    --bs-gray-700: #272727;
    --bs-gray-800: #222222;
    --bs-gray-900: #121212;
    --bs-primary: #fd7e14;
    --bs-primary-dark: #d14204;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-primary-text-emphasis: #052c65;
    --bs-secondary-text-emphasis: #2b2f32;
    --bs-success-text-emphasis: #0a3622;
    --bs-info-text-emphasis: #055160;
    --bs-warning-text-emphasis: #664d03;
    --bs-danger-text-emphasis: #58151c;
    --bs-light-text-emphasis: #495057;
    --bs-dark-text-emphasis: #495057;
    --bs-primary-bg-subtle: #cfe2ff;
    --bs-secondary-bg-subtle: #e2e3e5;
    --bs-success-bg-subtle: #d1e7dd;
    --bs-info-bg-subtle: #cff4fc;
    --bs-warning-bg-subtle: #fff3cd;
    --bs-danger-bg-subtle: #f8d7da;
    --bs-light-bg-subtle: #fcfcfd;
    --bs-dark-bg-subtle: #ced4da;
    --bs-primary-border-subtle: #9ec5fe;
    --bs-secondary-border-subtle: #c4c8cb;
    --bs-success-border-subtle: #a3cfbb;
    --bs-info-border-subtle: #9eeaf9;
    --bs-warning-border-subtle: #ffe69c;
    --bs-danger-border-subtle: #f1aeb5;
    --bs-light-border-subtle: #e9ecef;
    --bs-dark-border-subtle: #adb5bd;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
        "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
        "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,
            rgba(255, 255, 255, 0.15),
            rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg: #fff;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-emphasis-color: #000;
    --bs-emphasis-color-rgb: 0, 0, 0;
    --bs-secondary-color: rgba(33, 37, 41, 0.75);
    --bs-secondary-color-rgb: 33, 37, 41;
    --bs-secondary-bg: #e9ecef;
    --bs-secondary-bg-rgb: 233, 236, 239;
    --bs-tertiary-color: rgba(33, 37, 41, 0.5);
    --bs-tertiary-color-rgb: 33, 37, 41;
    --bs-tertiary-bg: #f8f9fa;
    --bs-tertiary-bg-rgb: 248, 249, 250;
    --bs-heading-color: inherit;
    --bs-link-color: #d2252d;
    --bs-link-color-rgb: 13, 110, 253;
    --bs-link-decoration: underline;
    --bs-link-hover-color: #0a58ca;
    --bs-link-hover-color-rgb: 10, 88, 202;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-xxl: 2rem;
    --bs-border-radius-2xl: var(--bs-border-radius-xxl);
    --bs-border-radius-pill: 50rem;
    --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-focus-ring-width: 0.25rem;
    --bs-focus-ring-opacity: 0.25;
    --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
    --bs-form-valid-color: #198754;
    --bs-form-valid-border-color: #198754;
    --bs-form-invalid-color: #dc3545;
    --bs-form-invalid-border-color: #dc3545;
}

body {
    --common_space: 15px;
    font-family: "DM Sans", sans-serif;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: var(--bs-gray-600);
    font-size: 14px;
    letter-spacing: 0.2px;
}

a,
button {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

button {
    color: inherit;
}

p {
    letter-spacing: 0.1px;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: none;
}

input::placeholder,
textarea::placeholder,
select::placeholder,
button::placeholder {
    font-size: 14px;
}

.ant-input::placeholder {
    color: var(--bs-gray-300);
}

.ant-select-dropdown .ant-select-item-option-selected {
    background-color: rgba(255, 255, 255, 0.08) !important;
}

.ant-dropdown-menu {
    min-width: 120px;
}

.ant-dropdown-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown .dropdown-menu {
    border: 0 !important;
    box-shadow: var(--bs-box-shadow);
    margin-top: 7px;
}

.dropdown-item {
    font-size: 14px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-gray-800);
    background-color: var(--bs-gray-100);
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-gray-900);
    text-decoration: none;
    background-color: var(--bs-gray-200);
}

.light-theme .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--bs-gray-600);
}

.dark-theme .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--bs-gray-200);
}

a,
.btn-link {
    text-decoration: none;
}

.gray-bg {
    width: 100%;
}

.light-theme .gray-bg {
    background: transparent;
}

.dark-theme .gray-bg {
    background: var(--bs-gray-900);
}

.full_height {
    min-height: calc(100vh - 66px);
}

.gap2 {
    padding: 30px 0;
    position: relative;
}

@media(max-width: 500px) {
    .gap2 {
        padding: var(--common_space) 0;
    }
}

.gap2 .ant-breadcrumb {
    /* margin-top: -20px; */
    margin-bottom: 10px;
}

/* INPUT STYLE HERE */
.form-control,
.form-select {
    font-size: 14px;
    height: 33px;
}

.form-floating>label {
    padding: 1.2rem 0.75rem;
}

.was-validated .prevent-validation.form-control:valid,
.prevent-validation.form-control.is-valid {
    padding: 0.375rem 0.75rem;
    border-color: #ced4da;
    background-image: none;
}

.was-validated .prevent-validation.form-control:valid:focus,
.prevent-validation.form-control.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dark-theme .form-control,
.dark-theme .form-select,
.dark-theme .input-group-text {
    background: var(--bs-gray-500);
    color: var(--bs-gray-200);
    border-color: var(--bs-gray-500);
}

.dark-theme input.form-control::placeholder {
    color: var(--bs-gray-300);
}

.dark-theme .was-validated .prevent-validation.form-control:valid,
.dark-theme .prevent-validation.form-control.is-valid {}

.dark-theme .form-floating>.form-control-plaintext~label::after,
.dark-theme .form-floating>.form-control:focus~label::after,
.dark-theme .form-floating>.form-control:not(:placeholder-shown)~label::after,
.dark-theme .form-floating>.form-select~label::after {
    background: var(--bs-gray-500);
}

.dark-theme .form-floating>.form-control-plaintext~label,
.dark-theme .form-floating>.form-control:focus~label,
.dark-theme .form-floating>.form-control:not(:placeholder-shown)~label,
.dark-theme .form-floating>.form-select~label {
    color: var(--bs-gray-200);
}

/* BUTTON STYLE HERE */
.btn {
    font-size: 12px;
    min-width: 100px;
    font-weight: 600;
    padding: 8px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-primary {
    background: var(--bs-primary);
    border-color: var(--bs-primary);
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
    background: var(--bs-primary-dark);
    border-color: var(--bs-primary-dark);
    color: var(--bs-gray-100);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    opacity: var(--bs-btn-disabled-opacity);
}

.btn-light {
    background-color: var(--light_overBg);
    border-color: var(--light_overBg);
}

.btn-light:hover {
    background-color: var(--bs-gray-300);
    border-color: var(--bs-gray-300);
    color: var(--bs-gray-900);
}

.dark-theme .btn-light {
    background-color: var(--bs-gray-500);
    border-color: var(--bs-gray-500);
    color: var(--bs-gray-200);
}

.dark-theme .btn-light:hover {
    background-color: var(--bs-gray-400);
    border-color: var(--bs-gray-400);
    color: var(--bs-gray-200);
}

.text-primary {
    color: var(--bs-primary) !important;
}

.underline.btn {
    min-width: auto;
}

.underline span {
    position: relative;
}

.underline span:before,
.list-style li>a:before {
    background: var(--bs-primary);
    bottom: -5px;
    content: "";
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    transition: all 0.2s linear 0s;
}

.underline span:hover:before,
.list-style li>a:hover:before {
    opacity: 1;
    visibility: visible;
    bottom: 2px;
}

/* BOOTSTRAP CARD */
.card {
    background: var(--bs-white);
    color: inherit;
}

a,
a:hover,
a:focus,
a:active {
    color: inherit;
    outline: none;
    -webkit-text-decoration: none;
    -moz-text-decoration: none;
    -ms-text-decoration: none;
    -o-text-decoration: none;
    text-decoration: none;
}


/* MODAL STYLE HERE */
.modal-body {
    padding: 20px;
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 1200px;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1320px;
    }
}



@media (max-width: 500px) {
    .btn {
        min-width: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .light-theme .gray-bg {
        background: transparent;
    }
}



.postbox .quill .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
}

.ql-toolbar.ql-snow .ql-formats {
    display: flex;
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px !important;
}

.ql-toolbar.ql-snow .ql-formats:last-child {
    margin-right: 0 !important;
}

.ql-snow .ql-editor h1,
.ql-snow .ql-editor h2,
.ql-snow .ql-editor h3,
.ql-snow .ql-editor h4,
.ql-snow .ql-editor h5,
.ql-snow .ql-editor h6 {
    font-size: initial !important;
}

.ql-toolbar.ql-snow .ql-picker-label svg {}

.ql-snow .ql-stroke {
    stroke: currentColor !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: currentColor !important;
}

.dark-theme .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid transparent;
    color: var(--bs-gray-200);
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
    padding: 3px 5px;
    width: 24px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    right: 0;
}

.ql-snow .ql-picker.ql-header .ql-picker-item,
.ql-snow .ql-picker.ql-header .ql-picker-item:before {
    line-height: 1;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "H1" !important;
    font-size: 1.4em !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "H2" !important;
    font-size: 1.3em !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "H3" !important;
    font-size: 1.25em !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "H4" !important;
    font-size: 1.15em !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "H5" !important;
    font-size: 1em !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "H6" !important;
    font-size: 0.95em !important;
}

/*  */
.ant-pagination-options {
    display: none !important;
}

.ant-select-selector {
    display: none !important;
}

.ant-notification-notice {
    cursor: pointer;
}

/* for bold in mention */
.commentText>a {
    font-weight: 600;
}

.post_content>a {
    font-weight: 600;
}

.was-validated .prevent-validation.form-control.password:valid,
.prevent-validation.form-control.password.is-valid {
    padding: 0 0.75rem;
    adding-top: 1.625rem;
    padding-bottom: .625rem;
}


textarea.mentionList__input {
    padding: 0.4rem;
    min-height: 80px;
    border: 0;
    background-color: #ffffff !important;
    overflow: auto !important;
    display: block !important;
    position: relative !important;
}

.mentionList__highlighter {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}

textarea.mentionList__input::-webkit-scrollbar {
    width: 0;
    display: none;
}

.mentionList.mentionList--multiLine {
    height: 100% !important;
}