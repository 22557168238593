
.wrapperBlock {
    display: block;
    line-height: 38px;
    width: 100%;
 }
 
 .wrapperBlock form {
    display: block;
    position: relative;
    width: 100%;
 }
 .wrapperBlock form button {
    background: rgba(0, 0, 0, 0);
    color: inherit;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
 }
 
.light-theme .wrapperBlock form input, .light-theme .wrapperBlock select {
    background-color: var(--bs-white);
    border: 1px solid var(--border-color);
    color: var(--bs-gray-900);
}
.dark-theme .wrapperBlock form input, .dark-theme .wrapperBlock select {
    background-color: var(--bs-gray-500);
    border: 1px solid var(--border-color);
    color: var(--bs-gray-200);
}
.dark-theme .wrapperBlock form input::placeholder {
    color: var(--bs-gray-200);
}


.option-list > i {
    font-size: 16px;
    position: relative;
    z-index: 2;
    width: 39px;
    height: 39px;
    text-align: center;
    line-height: 39px;
}
.option-list {
    cursor: pointer;
    height: 39px;
    line-height: 39px;
    position: relative;
    text-align: right;
    top: 4px;
    width: 39px;
    float: right;
}
.option-list::after {
    border-radius: 100%;
    content: "";
    left: 0;
    line-height: 30px;
    position: absolute;
    top: 0;
    transition: all 0.2s linear 0s;
    width: 39px;
    height: 39px;
    z-index: 0;
    transform: scale(0);
}
.option-list:hover::after {
    transform: scale(1);
}
.option-list > ul {
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
                0 9px 28px 8px rgba(0, 0, 0, 0.05);
    display: block;
    list-style: outside none none;
    margin: 0;
    opacity: 0;
    padding: 6px;
    position: absolute;
    right: 0px;
    text-align: left;
    top: 130%;
    transition: all 0.2s linear 0s;
    visibility: hidden;
    width: 160px;
    z-index: 22;
}
.option-list ul li {
    display: block;
    position: relative;
    padding: 6px 12px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 12px;
    line-height: 1.6666666666666667;
    border-radius: 4px;
}
.option-list > ul > li:hover {
    background: rgba(0, 0, 0, 0.04);
}

.option-list:hover ul {
    opacity: 1;
    visibility: visible;
}


.light-theme .option-list > ul {
    background: var(--bs-white);
}
.dark-theme .option-list > ul {
    background: var(--bs-white);
}
.dark-theme .option-list::after {
    background: var(--bs-gray-500);
    color: var(--bs-gray-200);
}
.light-theme .option-list::after {
    background: var(--bs-gray-200);
    color: var(--bs-gray-600);
}

.dark-theme .option-list > ul > li {
    color: var(--bs-gray-200);
}



@media(min-width: 500px) {
    .filterDashboard {
        border-radius: 0 0 var(--bs-border-radius-lg) var(--bs-border-radius-lg);
        display: block;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        padding: 10px;
    }
    .light-theme .filterDashboard {
        background: #fff;
        box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
    .dark-theme .filterDashboard {
        background: var(--bs-white);
        border-top: 1px solid var(--bs-gray-500);
    }
    .filterDashboard .wrapperBlock .filterWrapDropBtn {
        width: 39px;
        height: 39px;
        min-width: auto;
        background: transparent;
        padding: 0;
    }
    .filterDashboard .wrapperBlock .filterWrapDropBtn svg {
        height: 14px;
        width: 14px;
    }

    .light-theme .filterDashboard .wrapperBlock .filterWrapDropBtn {
        color: #ccc;
        border: 1px solid var(--border-color);
    }
    .dark-theme .filterDashboard .wrapperBlock .filterWrapDropBtn {
        border: 1px solid var(--border-color);
        background: var(--bs-gray-500);
    }
    .light-theme .filterDashboard .wrapperBlock .filterWrapDropBtn svg {
        color: var(--bs-gray-900);
    }
    .dark-theme .filterDashboard .wrapperBlock .filterWrapDropBtn svg {
        color: var(--bs-gray-300);
    }
}


@media(max-width: 500px) {
    .filterDashboard {
        margin-bottom: 15px;
    }
    .DashboardMenuLinks {
        line-height: 1;
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 10px;
        margin-bottom: 18px;
    }
}