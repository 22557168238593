body {
   padding-right: 0 !important;
   overflow-y: auto !important;
}

.side_panel {
   background: var(--bs-white);
   width: 333px;
}
.offcanvas.side_panel {
   max-width: 85%;
}
.panel_title {
   color: var(--title_text_color);
   font-size: 14px;
   font-weight: 500;
   margin-bottom: 20px;
   text-transform: capitalize;
}
.side_panel .panel_title {
   font-size: 16px;
   margin-bottom: 15px;
}
.mm-panel.mm-hasnavbar .panel_title {
   margin-top: 20px;
}
.side_panel > form {
   float: left;
   width: 100%;
   margin-bottom: 20px;
}

.offcanvas-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   background-color: #00000021;
   backdrop-filter: blur(10px);
}
.offcanvas {
   color: var(--bs-gray-500);
}
.side_panel.offcanvas .offcanvas-body {
   padding: 0;
   margin: 15px;
}

.offcanvas-backdrop.fade.show {
   opacity: 1;
}
.offcanvas-backdrop.fade {
   opacity: 0;
}

.setting_row {
   display: flex;
   margin-bottom: 9px;
   padding: 13px;
   text-align: center;
   width: 100%;
   align-items: center;
   border-radius: 6px;
}
.light-theme .setting_row {
   background: var(--bs-gray-200);
   color: var(--bs-gray-800);
}
.dark-theme .setting_row {
   background: var(--bs-gray-500);
   color: var(--bs-gray-200);
}
.setting_row > span,
.setting_row > label {
   display: inline-block;
   vertical-align: text-top;
}
.setting_row > span {
   font-size: 14px;
   line-height: initial;
   text-align: left;
   text-transform: capitalize;
   width: 70%;
}
.setting_row > label {
   margin-bottom: 0;
}
.setting_row input {
   display: none;
}

.setting_row input + label {
   background-color: #989dbd;
   background-image: none;
   cursor: pointer;
   display: inline-block;
   font-family: inherit;
   font-size: 1em;
   height: 25px;
   line-height: 1;
   padding: 0.16667rem;
   position: relative;
   text-align: center;
   transition: all 0.1s ease-in-out 0s;
   width: 52px;
   border-radius: 30px;
   margin-left: auto;
}

.setting_row input + label::before {
   color: #b7b7b7;
   content: attr(data-off-label);
   display: block;
   font-family: inherit;
   font-size: 0.7rem;
   font-weight: 500;
   line-height: 18px;
   margin: 0.21667rem;
   min-width: 25px;
   overflow: hidden;
   position: absolute;
   right: 0.017rem;
   text-align: center;
   text-transform: uppercase;
   top: 0;
   transition: all 0.1s ease-in-out 0s;
}

.setting_row input + label::after {
   background-color: #f7f7f7;
   border-radius: 20px;
   box-shadow: none;
   content: "";
   height: 19px;
   left: 0.267rem;
   top: 3px;
   position: absolute;
   transition: all 0.1s ease-in-out 0s;
   width: 19px;
}
/*input[switch]:checked + label {
    background-color: #33b7a0;
}*/
.setting_row input:checked + label::before {
   color: #fff;
   content: attr(data-on-label);
   left: 0.21667rem;
   right: auto;
}
.setting_row input:checked + label::after {
   background-color: #f7f7f7;
   box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
   left: 1.867rem;
}
/*input[switch="bool"] + label {
    background-color: #ed6b75;
}*/

.setting_row input + label::before {
   color: #fff;
}
/*input[switch="bool"]:checked + label {
    background-color: #bce954;
}*/
