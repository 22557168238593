.recentPhotos {
   display: inline-block;
   padding-bottom: 20px;
   width: 100%;
   list-style: outside none none;
   margin-bottom: 0;
   padding: 0 20px 20px;
   margin-top: 0;
   margin-block-start: 1em;
   margin-block-end: 1em;
   margin-inline-start: 0px;
   margin-inline-end: 0px;
   /* padding-inline-start: 40px; */
}

.recentPhotos li {
   margin-bottom: 5px;
   margin-left: 2.5px;
   margin-right: 2.5px;
   width: 30.9%;
   position: relative;
   display: inline-block;
   text-align: -webkit-match-parent;
}

.recentPhotos li > a {
   display: inline-block;
   font-size: 13px;
   font-weight: 400;
   position: relative;
   text-transform: capitalize;
   vertical-align: top;
}

.recentPhotos li > a > img {
   height: auto;
   max-width: 100%;
   vertical-align: middle;
   border-style: none;
   overflow-clip-margin: content-box;
   overflow: clip;
}
