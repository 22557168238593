.advertisment_box {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.advertisment_box > figure {
    margin-bottom: 0;
}

.advertisment_box > h4 {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    text-transform: capitalize;
    width: 100%;
}

.advertisment_box img {
    display: inline-block;
    margin: 0 auto;
    border-radius: 5px;
    width: 100%;
}

.advertisment_box a {
    display: inline-block;
    width: 100%;
}