.quote {
    text-align: center;
}
.quote blockquote {
    font-size: 16px;
    padding-top: 40px;
    font-style: italic;
    width: 100%;
    display: block;
    color: var(--lightOverBgText);
}
.quote blockquote {
    color: var(--title_text_color);
}

.quote blockquote .quote_sign {
    color: var(--title_text_color);
    font-size: 80px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 35px;
    line-height: 1;
}
.dark-theme .quote blockquote .quote_sign {
    color: var(--title_text_color);
}
.quote blockquote > i {
    color: rgba(0, 0, 0, 0.1);
    font-size: 70px !important;
    left: 15%;
    position: relative;
    top: 40px;
    z-index: 0;
}