.star {
    font-size: 18px;
}

.createJobBtn {
    width: 100%;
    margin: 0 0 10px 0;
}

.createJobBtn>a {
    color: var(--bs-gray-100);
}



.jobDropdown {
    background: var(--bs-gray-200);
    color: var(--bs-gray-900);
    margin-left: 7px;
    float: right;
}

.job_item {
    background: #fff;
    border-radius: 6px;
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
}

.job_salary {
    color: var(--bs-primary);
    font-weight: 500;
}

.job_description {
    color: var(--bs-dark-text-emphasis);
    margin-bottom: 15px;
}

.job_bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.job_bottom_info {
    color: var(--bs-dark-text-emphasis);
}

.apply_btn {
    background: var(--bs-primary);
    color: #fff;
    border-color: transparent;
    border-radius: 30px;
    font-size: 13px;
    font-weight: 500;
    display: block;
    transition: all 0.2s linear 0s;
    box-shadow: 4px 7px 12px 0 rgba(250, 99, 66, 0.2);
    padding: 6px 15px;
    margin-top: -13px;
    float: right;
}

.apply_btn:hover {
    color: #fff;
}