.profileAuthor {
   display: inline-block;
   margin-top: -145px;
   text-align: center;
   width: 100%;
}

.profileAuthorThumb {
   display: inline-block;
   position: relative;
   text-align: center;
}

.dateContainer {
   height: 130px;
   width: 130px;
   vertical-align: middle;
   border-style: none;
   overflow-clip-margin: content-box;
   overflow: clip;
   border-radius: 5px;
   box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.blank {
   height: 40px;
   background-color: rgb(243, 66, 95);
   display: flex;
   text-align: center;
   align-items: center;
   gap: 5px;
   color: aliceblue;
   padding-left: 20px;
}

.date {
   background-color: white;
   height: 100%;
   display: flex;
   /* align-items: center; */
   justify-content: center;
   font-size: 70px;
   
}
