.central_meta {
    background: var(--bs-white);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}
.central_meta .card_body {
    padding: 0;
}
.central_meta .card_title {
    color: var(--title_text_color);
}
.invalidMessage{
    color: var(--red);
}
.ant-picker-focused{
    border-color: blue;
}