.pit_reg_complete {
   display: block;
   width: 100%;
   position: relative;
}

.progresdiv {
   display: block;
   margin-top: 15px;
   position: relative;
   width: 100%;
}
.progresdiv:after {
   position: absolute;
   top: 50%;
   left: 50%;
   font-size: 20px;
   transform: translate(-50%, -50%);
   content: attr(data-percent) "%";
}

.progres {
   display: block;
   margin: 0 auto;
   overflow: hidden;
   transform: rotate(-90deg) rotateX(180deg);
}
.progres circle {
   stroke-dashoffset: 0;
   transition: stroke-dashoffset 1s ease;
   stroke: var(--bs-primary);
   stroke-width: 4px;
}
.progres .bar {
   stroke: #edf2f6;
}

.reg_comp_meta {
   display: inline-block;
   padding: 20px;
   text-align: center;
   width: 100%;
}

.reg_comp_meta > p {
   font-size: 12.3px;
   line-height: 18px;
}
.reg_comp_meta > ul {
   font-size: 13px;
   list-style: outside none none;
   margin-bottom: 0;
   padding-left: 0;
   text-align: left;
}

.reg_comp_meta > ul > li {
   margin-bottom: 10px;
   display: flex;
   align-items: center;
}
.reg_comp_meta > ul > li:last-child {
   margin-bottom: 0;
}
.reg_comp_meta > ul > li > span {
   width: 24px;
   height: 24px;
   margin-left: auto;
   background: #3cb37126;
   text-align: center;
   border-radius: 12px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
}
.reg_comp_meta > ul > li > span svg {
   width: 13px;
   height: 13px;
}

.reg_comp_meta > ul > li > i {
   border-radius: 100%;
   color: #fff;
   height: 24px;
   line-height: 24px;
   text-align: center;
   width: 24px;
   margin-right: 12px;
}
.reg_comp_meta > ul > li:hover > i {
   color: #fff;
}

.user_link {
   font-size: 12px;
   float: none;
   font-weight: 500;
}
.user_link:hover {
   color: var(--bs-primary);
}


.lightTheme {
   opacity: 1;
}
.user_link.light_theme {
   color: var(--bs-gray-800);
}
.user_link.dark_theme {
   color: var(--bs-gray-200);
}


