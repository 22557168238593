.planSecHeading {
   display: inline-block;
   margin-bottom: 50px;
   width: 100%;
}
.planSecHeading p {
   max-width: 800px;
   margin: 0 auto;
}
.planSecHeading > h2 {
   font-size: 32px;
   font-weight: bold;
   text-transform: uppercase;
}

.planSecHeading > h2 > span {
   color: #a31920;
}

.planSecHeading > span {
   font-size: 12px;
   text-transform: capitalize;
}
