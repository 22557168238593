.table_head {
    background: var(--bs-gray-100);
    font-weight: 600;
    margin-bottom: 2px;
    border-radius: 4px;
}
.table_head.has_toggle {

}


table.adminTable {
    width: 100%;
}
table.adminTable thead {
    background: var(--bs-gray-100);
    font-weight: 600;
    margin-bottom: 2px;
    border-radius: 4px;
}
table.adminTable thead tr {
    
}
table.adminTable thead tr th {
    padding: 0;
    font-weight: 500;
}
table.adminTable tbody tr {
    background: #fff;
    border-bottom: 1px solid var(--bs-gray-200);
}
table.adminTable tbody tr td {
    padding: 0;
}
table.adminTable tbody tr.noData {
    border: 0;
}


.createBtn {
    color: var(--bs-primary);
    font-weight: 600;
    border: 1px solid var(--bs-gray-200);
    background: var(--bs-gray-100);
    display: inline-flex;
    align-items: center;
    padding: 2px 7px;
    border-radius: 4px;
}
.createBtn svg {
    margin-right: 3px;
    font-size: 10px;
}
