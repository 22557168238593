.helpCommonHelp {
   background: var(--bs-white) none repeat scroll 0 0;
   border-radius: 4px;
   padding: 30px 20px 20px;
   text-align: center;
   border-top: 1px solid #fa6342;
   height: 100%;
}
.helpCommonHelp > span > i {
   font-size: 50px;
}

.helpCommonHelp > h4 {
   font-size: 20px;
   font-weight: 400;
   margin-top: 20px;
}

.helpCommonHelp > p {
   line-height: 22px;
   margin-bottom: 30px;
   margin-top: 10px;
}
