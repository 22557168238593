.dpUploadAction {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #0003; */
    border-radius: 50%;
}
.dpUploadAction button.cancel,
.dpUploadAction button.save {
    opacity: 1 !important;
}
.dpUploadAction button {
    margin: 0 3px;
    border: 0;
    padding: 6px 0px;
    border-radius: 4px;
    font-weight: 600;
    min-width: 40px;
}

.dpUploadAction button.cancel {
    background: #ffe2e2;
    color: #b30909;
    opacity: .6;
}

.dpUploadAction button.save {
    background: #ebfff4;
    color: #0d8141;
    opacity: .6;
}