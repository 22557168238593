.groupCard {
    display: inline-block;
    text-align: center;
    width: 100%;
    border-radius: 6px;
    position: relative;
    background: var(--bs-white);
}

.groupCard .groupCardcoverPhoto {
    aspect-ratio: 16/7;
    background-position: center;
    border-radius: 6px 6px 0 0;
    background-size: cover;
}

.groupCard .group_content {
    padding: 0 10px 10px 10px;
}

.light-theme .groupCard {
    border: 1px solid var(--bs-gray-300);
}

.dark-theme .groupCard {
    border: 1px solid var(--bs-gray-500);
}

.groupCard .more-opotnz {
    position: absolute;
    top: 7px;
    right: 11px;
    text-align: left;
}

.groupCard .more-opotnz>ul {
    bottom: initial;
    top: 25px;
    right: -8px;
}

.groupCard figure {
    display: inline-block;
    width: 100%;
    margin-bottom: 12px;
    margin-top: -38px;
    display: block;
}

.groupCard a {
    color: #535165;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 100%;
}

.light-theme .groupCard a {
    color: var(--bs-gray-800);
}

.dark-theme .groupCard a {
    color: var(--bs-gray-200);
}

.groupCard figure img {
    border-radius: 100%;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.groupCard .group_content span {
    font-size: 12px;
    font-weight: 400;
}

.groupCard button {
    text-transform: capitalize;
}

.light-theme .groupCard .connection-btn {
    background-color: var(--bs-gray-200);
    border-color: var(--bs-gray-200);
    color: var(--bs-gray-900);
}

.light-theme .groupCard .connection-btn:hover {
    background-color: var(--bs-gray-300);
}

.dark-theme .groupCard .connection-btn {
    background-color: var(--bs-gray-500);
    border-color: var(--bs-gray-500);
    color: var(--bs-gray-200);
}

.dark-theme .groupCard .connection-btn:hover {
    background-color: var(--bs-gray-600);
}

.groupCard a:hover {
    color: var(--bs-primary);
}

.groupCard .add-remove-frnd {
    display: inline-flex;
    list-style: outside none none;
    margin: 12px 0 0;
    padding: 0 0;
    text-align: center;
    width: 100%;
    justify-content: center;
}

.groupCard .add-remove-frnd>li a {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s linear 0s;
    font-size: 13px;
    height: 34.5px;
    width: 34.5px;
    line-height: 34.5px;
}

.groupCard .add-remove-frnd>li a>i {
    font-size: 14px;
}

.groupCard .add-remove-frnd.following li.following {
    flex: 1;
}

.groupCard .remove-frnd>a {
    background: #a8adcd none repeat scroll 0 0;
}

.groupCard .add-remove-frnd>li {
    display: inline-block;
    margin-right: 6px;
}

.groupCard .add-remove-frnd>li:last-child {
    margin-right: 0;
}



.groupCard button.ant-dropdown-trigger {
    position: absolute;
    top: 7px;
    right: 7px;
    height: 24px;
    width: 24px !important;
    border: 0;
    border-radius: 50%;
    padding: 0;
}

.light-theme .groupCard button.ant-dropdown-trigger {
    background: var(--bs-white);
    color: var(--bs-gray-900);
}

.dark-theme .groupCard button.ant-dropdown-trigger {
    background: var(--bs-white);
    color: var(--bs-gray-100);
}

.groupCard .add-remove-frnd>li>button.deleteBtn {
    background: var(--bs-primary);
    color: #fff;
}

.groupCard .add-remove-frnd>li a {
    color: var(--bs-gray-900);
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s linear 0s;
    font-size: 13px;
    height: 34.5px;
    width: 34.5px;
    line-height: 34.5px;
    padding: 0;
    border: 0;
}

.groupCard .add-remove-frnd>li>button.deleteBtn {
    border-radius: 4px;
    display: inline-block;
    transition: all 0.2s linear 0s;
    font-size: 13px;
    height: 34.5px;
    width: 34.5px;
    line-height: 34.5px;
    padding: 0;
    border: 0;
}
.groupCard .add-remove-frnd > li a:hover {
    background-color: var(--bs-gray-300);
    border-color: var(--bs-gray-300);
    color: var(--bs-gray-900);
}