.createProductBtn {
    width: 100%;
    margin: 0 0 10px 0;
}

.createProductBtn>a {
    color: var(--bs-gray-100);
}

.central_meta {
    background: var(--bs-white);
    border-radius: 5px;
    display: inline-block;
    width: 100%;
    padding: 20px;
}

.central_meta .card_body {
    padding: 0;
}

.central_meta .card_title {
    color: var(--title_text_color);
}

button.delete_from_input {
    background: transparent;
    border: 0;
    background: var(--bs-gray-200);
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    height: 26px;
    width: 26px;
    border-radius: 50%;
    padding: 0;
}

.market_details {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.market_details .search input {
    width: 100% !important;
    height: 40px;
    background: #f0f2f5;
    border: 0;
}

.market_details .search input::placeholder {
    color: var(--bs-gray);
    font-weight: 500;
}

.market_option {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    padding-left: 0;
    list-style: none;
}

.market_option li {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #202020;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
}

.market_option li.active,
.market_option li:hover {
    background: #f0f2f5;
}

.market_option li:last-child {
    margin-bottom: 0;
}

.market_option li .dropdown-toggle.btn {
    background: transparent !important;
    border: 0;
    color: #202020 !important;
    padding: 0;
    display: flex;
    font-size: 14px;
    font-weight: 500;
}

.market_option li .dropdown .dropdown-menu {
    position: static !important;
    inset: 0px !important;
    transform: unset !important;
    background: transparent !important;
    box-shadow: none !important;
    width: 100% !important;
    margin: 5px 0;
    padding: 0 0 0 26px;
    color: #202020 !important;
}

.market_option li .dropdown .dropdown-menu a.dropdown-item {
    font-size: 14px;
    color: var(--bs-gray-700);
    background: transparent;
}

.market_option li .dropdown .dropdown-menu a.dropdown-item:focus,
.market_option li .dropdown .dropdown-menu a.dropdown-item:hover {
    color: var(--bs-gray-800);
    background: transparent;
    text-decoration: underline;
}

/* .dropdown-item:focus, .dropdown-item:hover {
    color: var(--bs-gray-800);
    background-color: var(--bs-gray-100);
} */
.market_option li .icon {
    background: var(--bs-primary);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

.market_option li .icon svg {
    color: var(--bs-white);
    font-size: 20px;
}

.ceate_list {
    padding: 10px 15px;
    background: #f0f2f5;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ceate_list .icon {
    background: var(--bs-primary);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

.ceate_list .icon svg {
    color: var(--bs-white);
    font-size: 14px;
}

.ceate_list span {
    font-weight: 500;
    color: #202020;
}

.market_location {
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.market_location .icon {
    background: var(--bs-primary);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
}

.market_location .icon svg {
    color: var(--bs-white);
    font-size: 20px;
}

.editEventUploadImgCard {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 150px;
}

.editEventUploadImgCard button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

.editEventUploadImgCard2 {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 300px;
}

.editEventUploadImgCard2 button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

.editEventUploadImgCard3 {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 300px;
}

.editEventUploadImgCard3 button {
    position: absolute;
    right: 20px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

.editEventUploadImgCard4 {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 300px;
}

.editEventUploadImgCard4 button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

/* design for product details */


.productDesc p {
    margin-bottom: 3px;
}

.productCard .card-img-top {
    object-fit: cover;
    aspect-ratio: 12 / 6;
    background: #f5f5f7;
    height: auto;
    box-shadow: 0 0 10px rgb(70 70 70 / 30%);
}



.productCard {
    border: 0;
}

.productCard img {
    border-radius: 8px;
    margin-bottom: 10px;
}

.productCard .card-body {
    padding: 0;
}

.productCard .proTitle a {
    font-size: 15px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 20px;
}

.productCard .proTitle a:hover {
    color: var(--bs-orange);
}

.productCard p.proDesc {
    font-size: 14px;
    line-height: 1.4;
}

.productCard p.proPrice {
    color: #202020;
    font-size: 18px;
    font-weight: 600;
}

.post_details_images .thum-image {
    margin-top: 5px;
}

.post_details_images .thum-image .thum-col {
    width: 20%;
}

.post_details_images .thum-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    aspect-ratio: 12 / 8;
    object-fit: cover;
}

.post_details_images .thum-image img.active {
    border: 3px solid var(--bs-orange);
}

.details-container {
    padding-left: 60px;
}

.details-container h1.heading {
    color: var(--bs-primary);
    font-size: 28px;
    font-weight: 600;
}

.similar_box {
    margin-top: 80px;
}

.marketQuill {
    border: 1px solid var(--bs-border-color);
    padding: 10px;
    border-radius: 10px;
}