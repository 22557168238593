.admin_menu {
    margin-bottom: 20px;
    border: 1px solid var(--border-color);
    border-radius: var(--bs-border-radius-lg);
}
.admin_menu .toggle_button {
    background: #ff500338;
    color: #ff5003;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    position: relative;
    text-transform: capitalize;
    width: 100%;
    border-radius: var(--bs-border-radius-lg) var(--bs-border-radius-lg);
    cursor: pointer;
}
.admin_menu .toggle_button.active {
    border-radius: var(--bs-border-radius-lg) var(--bs-border-radius-lg) 0 0;
}
.admin_menu .toggle_button.active svg {
    transform: rotate(-180deg);
}
.admin_menu .toggle_button svg {
    margin-left: 5px;
}

/* MAIN MENU */
.admin_menu>ul {
    background: var(--bs-white);
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 10px 0;
    border-radius: 0 0 6px 6px;
}
.admin_menu>ul>li {
    padding: 0 10px;
    margin-bottom: 12px;
}
.admin_menu>ul>li:hover .parent>button,
.admin_menu>ul>li>.parent>button.active {
    background: var(--light_overBg);
}
.admin_menu>ul>li>.parent {
    display: flex;
    justify-content: space-between;
}
.admin_menu>ul>li>.parent>button {
    background: transparent;
    border: 0;
    height: 24px;
    width: 24px;
    line-height: 1;
    border-radius: 12px;
    padding: 0;
    font-size: 22px;
    color: var(--title_text_color);
}
.admin_menu>ul>li>.parent a {
    display: flex;
    align-items: center;
    line-height: 1;
    border-radius: 4px;
    font-weight: 600;
    color: var(--title_text_color);
}
.admin_menu>ul>li:hover>.parent>a {
    color: var(--bs-primary);
}
.admin_menu>ul>li>.parent>a.active {
    color: var(--bs-primary);
}
.admin_menu>ul>li>a.active .admin_icon {
    color: var(--bs-gray-900);
}
.admin_menu>ul>li>.parent>a .admin_icon {
    background: var(--light_overBg);
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 26px;
    border-radius: 12px;
    margin-right: 10px;
    font-size: 12px;
}
.admin_menu>ul>li:hover>a .admin_icon {
    background: var(--bs-white);
}

/* SUB MENU */
.admin_menu>ul>li>ul {
    margin-top: 7px;
    list-style: none;
    margin-left: 36px;
    padding: 0;
    display: none;
}
.admin_menu>ul>li>ul.active {
    display: block;
}
.admin_menu>ul>li>button {
    border: 0;
    background: transparent;
    padding: 0;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 1;
}
.admin_menu>ul>li>ul>li>a {
    color: var(--title_text_color);
}
.admin_menu>ul>li>ul>li>a:hover, .admin_menu>ul>li>ul>li>a.active {
    color: var(--bs-primary);
}



@media(max-width: 500px) {
    .admin_menu>ul>li>.parent a {
        font-size: 16px;
    }
    .admin_menu>ul>li>.parent>a .admin_icon {
        display: inline-block;
        height: 24px;
        width: 24px;
        text-align: center;
        line-height: 24px;
        border-radius: 12px;
        margin-right: 10px;
        font-size: 16px;
        background: transparent;
    }
    .admin_menu>ul>li>ul>li>a {
        font-size: 16px;
        font-weight: 400;
    }
    .submenu {
        margin-top: 8px;
        margin-left: 34px;
    }
}