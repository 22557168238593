.uploadProfileFormGroup {
    margin-bottom: 17px;
}
.uploadProfileFormGroup input {
    height: auto;
}
.UploadImgCard {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 200px;
}
.UploadImgCard button {
    position: absolute;
    right: 9px;
    top: 9px;
    padding: 0;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 0;
}

.light-theme .UploadImgCard button {
    background: #fff;
    color: #000;
}
.dark-theme .UploadImgCard button {
    background: var(--bs-white);
    color: #fff;
}

.dark-theme .img-thumbnail {
    background: var(--bs-gray-500);
    border-color: var(--bs-gray-500);
}